import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actionCloseModal } from '../../../actions/modal'
import { actionTrySignIn, actionTrySignUp } from '../../../actions/accounts'
import { actionNavigate } from '../../../actions/navigation'
import { actionShowModal } from '../../../actions/modal'
import { actionCreateNotification } from '../../../actions/notifications'
// import { PageType } from '../../../helpers/pageTypes'
import LoginForm from './LoginForm'
// import RegistrationForm from './RegistrationForm'
// import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as KattanaShiningLogo } from './kattana_shining_logo.svg'
import "./styles.scss"
import ModalTypes from '../../../helpers/modals'

interface ILoginModal {
    locked: boolean;
    actionTrySignIn: Function;
    actionTrySignUp: Function;
    actionCloseModal: Function;
    actionNavigate: Function;
    actionShowModal: Function;
    actionCreateNotification: (payload: any) => void;
}

const tabClassName = (selected: number, current: number) =>
    (selected === current) ?
        'login__modal__tabs__tab login__modal__tabs__tab--active'
        : 'login__modal__tabs__tab'

const LoginModal = (props: ILoginModal) => {
    const [selected, setSelected] = useState(1)

    const onForgotPassword = () => {
        props.actionShowModal(ModalTypes.forgot_password)
        // props.actionCloseModal()
        // props.actionNavigate(PageType.forgot_password)
    }

    return (
        <div className="login__modal">
            <div className="login__modal__header">
                <span className="login__modal__header__caption">ACCOUNT</span>
                {/* <span className="login__modal__header__close" onClick={() => props.actionCloseModal()}><CloseIcon /></span> */}
            </div>
            <div className="login__modal__logo">
                <KattanaShiningLogo />
            </div>
            <div className="login__modal__tabs">
                {/* <div
                    className={tabClassName(selected, 0)}
                    onClick={() => setSelected(0)}
                >Create account</div> */}
                <div
                    className={tabClassName(selected, 1)}
                    onClick={() => setSelected(1)}
                >Login</div>
            </div>
            {/* {(selected === 0) && <RegistrationForm
                locked={props.locked}
                onSignUp={props.actionTrySignUp}
                onForgotPassword={onForgotPassword}
            />} */}
            {(selected === 1) && <LoginForm
                locked={props.locked}
                onSignIn={props.actionTrySignIn}
                onForgotPassword={onForgotPassword}
                onNotify={(message: string) => props.actionCreateNotification({ message })}
            />}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    locked: state.accounts.locked
})

export default connect(mapStateToProps, {
    actionShowModal,
    actionCloseModal,
    actionNavigate,
    actionTrySignIn,
    actionTrySignUp,
    actionCreateNotification
})(LoginModal)