/**
 * Ported from Kattana-app
 * dependecies requires convertion to typescript
 */
import React from 'react'
import ExchangeExposureChart from './ExchangeExposureChart'
// import ExchangeExposureSwitcher from './ExchangeExposureSwitcher'
// import ExchangeExposureLines from './ExchangeExposureLines'
import './styles.scss'

export enum Modes {
  bars = 'bars',
  lines = 'lines'
}

const bem = {
  element: 'exchange_exposure_chart',
  caption: 'exchange_exposure_chart__title',
  switcher: 'kt-tp-switcher'
};

const ExchangeExposures = (props: IExchangeExposuresProps) => {
  // const [mode, setMode] = useState(Modes.lines)
  const {
    rates,
    selection,
    positions,
    exchanges,
    totalValue,
    onSelect
  } = props;
  // const isBars = (mode === Modes.bars);
  // const isLines = (mode === Modes.lines);
  return (
    <div className={bem.element}>
      <div className={bem.caption}>Exchange Exposure</div>
      {/* <ExchangeExposureSwitcher
        mode={mode}
        onSelect={(mode: Modes) => setMode(mode)}
      /> */}
      {/* //{ isBars && */}
        <ExchangeExposureChart
          rates={rates}
          selection={selection}
          positions={positions}
          exchanges={exchanges}
          totalValue={totalValue}
          onSelect={onSelect}
        />
      {/* } */}
      {/* { isLines &&
        (<ExchangeExposureLines
          rates={rates}
          positions={positions}
          exchanges={exchanges}
          totalValue={totalValue}
        />)
      } */}
    </div>
  );
}

interface IExchangeExposuresProps {
  positions: any;
  exchanges: any;
  rates: any;
  selection: string;
  totalValue: string;
  onSelect: Function;
};

export default ExchangeExposures;