import React from 'react'
import SearchKeybinding from '../../SearchKeybinding';
import TabsBar from '../../tabsBar/index'
import WidgetsGrid from '../../WidgetsGrid/index'

const TradingPage = () => (
    <>
        <TabsBar />
        <WidgetsGrid />
        <SearchKeybinding />
    </>
)

export default TradingPage;