import React from 'react'
import { connect } from 'react-redux'
import { ReactComponent as EmptyWatchlist } from './empty_watchlist_big.svg';
import { IWatchlistItem } from '../../../helpers/watchlist'
import { actionSwitchCurrent } from '../../../actions/current'
import { actionRemoveWatchlist } from '../../../actions/watchlist'
import { actionCloseModal } from '../../../actions/modal'
import { ThPill, decorateVolumeWatchlist, decoratePair } from './helper';
import "./styles.scss"

interface IWatchlistPanelProps {
    watchlist: IWatchlistItem[];
    tickers: any;
    rates: any;
    current: any;
    actionRemoveWatchlist: Function;
    actionCloseModal: Function;
    actionSwitchCurrent: Function;
}

const WatchlistPanel = (props: IWatchlistPanelProps) => {
    const { current, watchlist, tickers, rates } = props
    let items = watchlist

    const onSelect = (e: any, item: IWatchlistItem) => {
        e.preventDefault()
        props.actionSwitchCurrent(item.exchange, item.base, item.quote)
        props.actionCloseModal()
    }

    return (
        <div className="tickers__modal__watchlist">
            <div className="tickers__modal__th">
                <ThPill width="21%">Pair</ThPill>
                <ThPill width="22%">Price</ThPill>
                <ThPill width="21%">Change (24)</ThPill>
                <ThPill width="28%">Volume (USD)</ThPill>
                <ThPill width="20px">&nbsp;</ThPill>
            </div>
            {(items.length === 0) && <EmptyWatchlist />}
            {items.map((item: IWatchlistItem, index: number) => {
                const symbol = decoratePair(item)
                const isSelected = isActive(item, current)
                /**
                 * get ticker item - actual data
                 */
                const tickerItem = tickers[`${item.exchange}|${symbol}`]
                const tickerPrice = tickerItem ? tickerItem.last : item.price
                const priceChangePercent = tickerItem ? tickerItem.change : item.priceChangePercent

                const percentageClass = priceChangePercent > 0.0 ?
                            "tickers__modal__items__item__col3--green"
                            : "tickers__modal__items__item__col3--red"

                const elementClassName = isSelected ?
                        "tickers__modal__items__item tickers__modal__items__item--active"
                        : "tickers__modal__items__item"

                return (<div key={`${item.base}-${item.quote}`} className={elementClassName} onClick={(e) => onSelect(e, item)}>
                    <span className="tickers__modal__items__item__col1">{symbol}</span>
                    <span className="tickers__modal__items__item__col2">{tickerPrice}</span>
                    <span className={`tickers__modal__items__item__col3 ${percentageClass}`}>{priceChangePercent}%</span>
                    <span className="tickers__modal__items__item__col4">{decorateVolumeWatchlist(item, rates, tickerItem)}</span>
                    <span className="tickers__modal__items__item__col5--active" onClick={(e) => {
                        e.stopPropagation();
                        props.actionRemoveWatchlist(index)
                    }}/>
                </div>)
            })}
        </div>
    )
}


const isActive = (item: any, current: any) =>
    (item.exchange === current.exchange &&
        item.base === current.base &&
        item.quote === current.quote)

const mapStateToProps = (state: any) => ({
    watchlist: state.watchlist,
    tickers: state.tickers,
    current: state.current,
    rates: state.rates
})

export default connect(mapStateToProps, {
    actionSwitchCurrent,
    actionRemoveWatchlist,
    actionCloseModal
})(WatchlistPanel);