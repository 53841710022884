/**
 * Implements a Factory which returns a widget element
 */
import React, { useRef, useEffect, useState } from 'react'
import { IWidget } from '../../helpers/widgets'
import Watchlist from '../Watchlist'
import Chart from "../Chart/TradingViewChart"
import OrderBook from '../Orderbook'
import TradingHistory from '../TradingHistory'
import PositionsOrders from '../PositionsOrders'
import CreateOrder from '../CreateOrder'
import TreemapWidget from '../Treemap'
import KattanaChart from '../Chart/KattanaChart'
import DexTrades from '../DexTrades'

interface IWidgetProps {
    data: IWidget;
    element: string;
    index: number;
}

const componentsMatch = {
    'TradingChart': Chart,
    'Chart': KattanaChart,
    'OrderBook': OrderBook,
    'PositionsOrders': PositionsOrders,
    'TradingHistory': TradingHistory,
    'CreateOrder': CreateOrder,
    'Watchlist': Watchlist,
    'Treemap': TreemapWidget,
    'DexTrades': DexTrades
}

const widgetStyles: any = {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden" // it depends, right?
}

/**
 * Get width/height from DOM
 * useEffect subscribed to ref, data prop (which will be affected by grid relayout)
 */
const Widget = ({ data, element }: IWidgetProps) => {
    const ref: any = useRef(null)
    const [ width, setWidth ] = useState(0)
    const [ height, setHeight ] = useState(0)
    useEffect(() => {
        const parent = ref.current ? ref.current.closest('.react-grid-item') : null
        if (parent) {
            const { width, height } = parent.getBoundingClientRect()
            setWidth(width)
            setHeight(height - 22)
        }
    }, [ref.current, data.w, data.h])

    const WidgetInternals = (componentsMatch as any)[element]
    return (
        <div style={widgetStyles} ref={ref}>
            {WidgetInternals ? (<WidgetInternals width={width} height={height} />) : null}
        </div>
    )
}

export default Widget;
