import { ICandle } from "./interfaces";

/**
 * https://stackoverflow.com/a/40559448
 */
export const getCrispRatio = (ctx: any) => {
    const dpr = window.devicePixelRatio || 1;
    const bsr = ctx.webkitBackingStorePixelRatio ||
        ctx.mozBackingStorePixelRatio ||
        ctx.msBackingStorePixelRatio ||
        ctx.oBackingStorePixelRatio ||
        ctx.backingStorePixelRatio || 1;

    return dpr / bsr;
}

export const setCrispCanvas = (canvas: any, width: number, height: number) => {
    const ratio = getCrispRatio(canvas.getContext("2d"));
    const can = canvas;
    can.width = width * ratio;
    can.height = height * ratio;
    can.style.width = width + "px";
    can.style.height = height + "px";
    can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
}
/**
 * Ranges
 */
export const getRanges = (collection: ICandle[]) => {
    const priceMin = Math.min(...collection.map((item: any) => parseFloat(item.low))) * (1 - 0.1);
    const priceMax = Math.max(...collection.map((item: any) => parseFloat(item.high))) * (1 + 0.1);
    const timeMin = collection[0].time;
    const timeMax = collection[collection.length - 1].time;
    const volumeMin = Math.min(...collection.map((item: any) => parseFloat(item.volume))) * (1 - 0.1);
    const volumeMax = Math.max(...collection.map((item: any) => parseFloat(item.volume))) * (1 + 0.1);

    return {
        priceMin,
        priceMax,
        timeMin,
        timeMax,
        volumeMin,
        volumeMax
    }
}

/**
 * Minimalistic formatter for long format
 * @param time 
 */
export const formatCandleDate = (time: number): string => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date  = new Date(time)
    return `${date.toLocaleString("en-US", options)} ${date.toLocaleTimeString()}`
}