/**
 * Show list of coins
 * sorted by tickers_count
 * each coin have a news, volumes, price charts
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ErrorBoundary from 'react-error-boundary'
import { actionSwitchCurrent } from '../../../actions/current'
import { actionNavigate } from '../../../actions/navigation'
import AssetsMarkets from './AssetsMarkets';
import HomepageAssets from '../HomePage/HomepageAssets';
// import AssetsNews from './AssetsNews';
import { PageType } from '../../../helpers/pageTypes';
import "./styles.scss"
import UserAssets from '../HomePage/UserAssets';
import AssetData from './AssetData';

interface IAssetsPageProps {
    id: string; // "BTC"
    tickers: any; // { binance|ADA/BNB: {} }
    signedin: boolean;
    actionSwitchCurrent: Function;
    actionNavigate: Function;
}
/**
 * 
 * @param base - ADA
 * @param key - binance|ADA/BNB
 */
const filterKey = (base: string, key: string): Boolean => {
    try {
        return (key.split('|')[1].split('/')[0]) === base
    } catch (err) {
        return false
    }
};

const AssetsPage = (props: IAssetsPageProps) => {
    const { id, signedin } = props
    const assetsSelected = !!id
    const [markets, setMarkets] = useState<any[]>([])

    useEffect(() => {
        const keys: string[] = Object.keys(props.tickers)
            .filter((key: string) => filterKey(id, key))
        
        setMarkets(keys.map((key: string) =>
            Object.assign(props.tickers[key], { exchange: key.split('|')[0] })
        ))

    }, [props.tickers, id])

    const onSelect = (exchange: string, symbol: string) => {
        const [base, quote] = symbol.split('/');
        props.actionSwitchCurrent(exchange, base, quote)
        props.actionNavigate(PageType.trading)
    }

    if (assetsSelected) {
        return (
            <div className="assets__page">
                <AssetData
                    id={id}
                />
                <AssetsMarkets
                    markets={markets}
                    onSelect={onSelect}
                />
            </div>
        )
    }
    return (
        <ErrorBoundary>
            <div className="assets__page">
                { signedin ? <UserAssets /> : <HomepageAssets />}
            </div>
        </ErrorBoundary>
    )
}


const mapStateToProps = (state: any) => ({
    id: state.navigation.id,
    tickers: state.tickers,
    signedin: state.accounts.signedin
})

export default connect(mapStateToProps, { actionSwitchCurrent, actionNavigate })(AssetsPage)