enum PageType {
    trading = "trading",
    marketscanner = "marketscanner",
    performance = "performance",
    settings = "settings",
    homepage = "homepage",
    forgot_password = "forgot_password",
    change_password = "change_password",
    assets = "assets"
}

export {
    PageType,
}