/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Facade before TradingView component
 */
import React, { useEffect, useState } from "react";
import ErrorBoundary from 'react-error-boundary'
import { connect } from "react-redux";
import { IOrder } from "../../helpers/tradingModes";
import { actionCancelOrders } from "../../actions/orders";
import { actionShowModal } from "../../actions/modal";
import {
  IChartProps,
  chartMapStateToProps,
  chartFilterOrders,
} from "./genericChart";
import KattanaChart from "./chart";
import { getDatafeed } from "../TradingViewChart";
import { IDatafeed } from "./chart/utils/interfaces";
import ModalTypes from "../../helpers/modals";
import LoadingMarkets from "./chart/LoadingMarkets";

const Chart = (props: IChartProps) => {
  const {
    exchange,
    base,
    quote,
    mode,
    width,
    height,
    orders,
    markets,
    pricePrecision
  } = props;

  const [datafeed, setDatafeed] = useState<IDatafeed | any>();

  useEffect(() => {
    setDatafeed(getDatafeed(exchange, markets));
  }, [exchange, markets]);

  if (
    !exchange ||
    !base ||
    !quote ||
    exchange === "bitmex" ||
    width === 0 ||
    !datafeed
  ) {
    return <div />;
  }

  const symbolName = `${base}${quote}`;
  // const compact = width < window.innerWidth * 0.25;

  return (
    <ErrorBoundary>
      <KattanaChart
        id={Math.random()}
        exchange={exchange}
        symbolName={symbolName}
        width={width}
        height={height}
        visibleCandles={60}
        datafeed={datafeed}
        pricePrecision={pricePrecision}
        orders={chartFilterOrders(exchange, base, quote, mode, orders)}
        onCancelOrders={(orders: IOrder[]) => props.actionCancelOrders(orders)}
        onTradingModal={(price: number) => null}
        // onTradingModal={(price: number) => props.actionShowModal && props.actionShowModal(ModalTypes.trading, { price })}
      />
    </ErrorBoundary>
  );
};


/**
  * Show loader unless we got all markets
*/
const MarketsWrapper = (props: IChartProps) => {
  const anyMarkets = Object.keys(props.markets).length > 0
  if (!anyMarkets) {
    return <LoadingMarkets />
  }
  return <Chart {...props} />
}


export default connect(chartMapStateToProps, {
  actionCancelOrders,
  actionShowModal,
})(MarketsWrapper);
