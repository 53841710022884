/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable new-cap */
// import settings from 'electron-settings';
import React, { Component } from 'react';
import isEqual from 'lodash.isequal';
import BinanceDatafeed from './datafeeds/BinanceDatafeed';
import BittrexDatafeed from './datafeeds/BittrexDatafeed';
import HitbtcDatafeed from './datafeeds/HitbtcDatafeed';
import PoloniexDatafeed from './datafeeds/PoloniexDatafeed';
import BitfinexDatafeed from './datafeeds/BitfinexDatafeed';
import KrakenDatafeed from './datafeeds/KrakenDatafeed';
import OkexDatafeed from './datafeeds/OkexDatafeed';
import HuobiDatafeed from './datafeeds/HuobiDatafeed';
import { widget } from "./charting_library/charting_library.min"
import { StorageKeys } from '../../helpers/localStorage';
import DexDatafeed from './datafeeds/DexDatafeed';

// const tradingViewStudyKey = 'tradingViewStudy';
class TradingViewChart extends Component<TradingViewChartProps> {
  widget: any
  divRef: any
  orderLines: any
  withChartReadyTimer: any
  chartData: any
  chartSettingsLoaded: boolean | undefined

  constructor(props: TradingViewChartProps) {
    super(props);
    this.divRef = React.createRef()
    this.orderLines = []
    this.chartData = null;
    try {
      const persistedChartData = localStorage.getItem(StorageKeys.tv_chart);
      if (persistedChartData) {
        this.chartData = JSON.parse(persistedChartData);
      }
    } catch (err) {
      this.chartData = null;
    }
  }

  componentDidMount() {
    const { exchange, pair } = this.props;
    this.mountChart(exchange, pair);
  }

  /**
   * In some cases we need to change symbol
   * in other cases - resize
   * if exchange changed - remount
   */
  UNSAFE_componentWillReceiveProps(nextProps: TradingViewChartProps) {
    const { pair, exchange, orders, height, width } = nextProps;
    const pairChanged = !isEqual(pair, this.props.pair);
    const exchangeChanged = !isEqual(exchange, this.props.exchange);
    const heightChanged = !isEqual(height, this.props.height);
    const widthChanged = !isEqual(width, this.props.width);
    const ordersChanged = !isEqual(orders, this.props.orders);
    if (heightChanged || widthChanged) {
      if (this.divRef) this.resizeIFrame(width, height);
    }
    if (exchangeChanged) {
      this.dismountChart();
      setTimeout(() => {
        this.mountChart(exchange, pair);
      }, 1000)
      return
    }
    /**
     * Pair could be switched event when chart is not ready
     */
    if (!exchangeChanged && pairChanged) {
      this.withChartReady((chart: any) => {
        const symbol = pair.split('-').reverse().join('');
        chart.setSymbol(symbol);
      });
    }
    if (ordersChanged) {
      this.withChartReady((chart: any) => this.mountOrders(chart, orders));
    }
  }

  /**
   * Return callback if ready or wait 3 seconds
   */
  withChartReady = (onReady: any) => {
    // eslint-disable-next-line no-underscore-dangle
    if (this.widget && this.widget._ready) {
      onReady(this.widget.chart());
    } else {
      this.withChartReadyTimer = setTimeout(() => this.withChartReady(onReady), 3000);
    }
  }

  /**
   * Remove all orders in this.orderLines
   * Fill current with data from redux (props)
   */
  mountOrders = (chart: any, orders: any) => {
    this.orderLines.forEach((o: any) => o.remove());
    this.orderLines = orders.map((order: any) => {
      const { price, side, amount } = order;
      const Text = ['sell','ask', 1].includes(side) ? 'Sell' : 'Buy';
      return chart.createOrderLine({ Editable: true })
        .setPrice(price)
        .setQuantity(amount)
        .setText(Text)
        .onCancel(() => this.props.onCancelOrder(order));
    });
  }

  adjustWidth = (width: number) => (width - 15);

  resizeIFrame = (width: number, height: number) => {
    const { current } = this.divRef;
    const { children } = current;
    const iframe = children[0];
    iframe.height = height;
    iframe.width = this.adjustWidth(width);
  }

  /**
   * We should remove widget to avoid glitches
   */
  dismountChart = () => {
    if (this.widget) {
      try {
        this.widget.remove();
      } catch (e) {
        console.error(e);
      }
    }
  }
 

  /**
   * Don't save if chart studies was not loaded
   */
  saveChartState = (chart: any) => {
    if (this.chartSettingsLoaded) {
      this.chartData = chart.createStudyTemplate({});
      localStorage.setItem(StorageKeys.tv_chart, JSON.stringify(this.chartData));
    }
  };

  mountChart = (exchange: string, pair: string) => {
    const datafeed = getDatafeed(exchange, this.props.markets);
    const { width, height, orders, id, canChangePair, onPairChanged, compact } = this.props;
    const symbol = pair.split('-').reverse().join('');

    const disabled_features = [];
    if (!canChangePair) {
      disabled_features.push('header_symbol_search');
    }

    const additionalFeatures: any = {};
    if (compact) {
      additionalFeatures['preset'] = 'mobile'
    }
  
    if (!exchange || !datafeed) return;
    this.widget = new widget({
        // debug: false, // uncomment this line to see Library errors and warnings in the console
        fullscreen: false,
        symbol,
        interval: '60',
        toolbar_bg: '#1f1f1f',
        loading_screen: { backgroundColor: '#1f1f1f' },
        // allow_symbol_change: false,
        container_id: `tradingviewchart_${id}`,
        datafeed,
        library_path: '/charting_library/', // IMPORTANT
        custom_css_url: 'styles.css',
        locale: 'en',

        disabled_features,
        enabled_features: ['use_localstorage_for_settings', 'move_logo_to_main_pane'],
        charts_storage_url: 'https://analytics.kattana.trade/charts',
        snapshot_url: 'https://analytics.kattana.trade/charts/snapshot',
        charts_storage_api_version: '1.1',
        client_id: 'kattana-app',
        user_id: "", // settings.get('token'),
        theme: 'Dark',
        overrides: {
          'paneProperties.background': '#1f1f1f',
          'paneProperties.vertGridProperties.color': '#464646',
          'paneProperties.horzGridProperties.color': '#464646',
          'symbolWatermarkProperties.transparency': 90,
          'scalesProperties.textColor': '#AAA'
        },
        height,
        width: this.adjustWidth(width),
        // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        ...additionalFeatures
    });
    this.widget.onChartReady(() => {
      try {
        const chart = this.widget.chart();
        if (this.chartData) {
          chart.applyStudyTemplate(this.chartData);
        }
        this.chartSettingsLoaded = true;
        this.widget.subscribe('onAutoSaveNeeded', this.saveChartState.bind(this, chart));
        this.widget.subscribe('study', this.saveChartState.bind(this, chart));
        chart.onSymbolChanged().subscribe(null, (sym: any) => onPairChanged(sym.pair));
        this.mountOrders(chart, orders);
      } catch (err) {
        console.warn('TradingView chart catched error');
      }
    });
  }

  render() {
    const { id } = this.props;
    const chartId = `tradingviewchart_${id}`;
    return (<div
      ref={this.divRef}
      id={chartId}
    />);
  }
}
interface TradingViewChartProps {
  embed: boolean
  intervals: any
  width: number
  height: number
  exchange: string
  pair: string
  orders: any[]
  rawOrders: any[]
  id: number
  markets: any
  compact: boolean
  canChangePair: boolean
  onPairChanged: Function
  onCancelOrder: Function
};

/**
 * Return UDF datafeed instance
 * @param exchange 
 */
export const getDatafeed = (exchange: string, markets: any) => {
  switch (exchange) {
    case 'binance':
      return new BinanceDatafeed({ debug: false, markets });
    case 'bittrex':
      return new BittrexDatafeed({ debug: false });
    case 'paper':
      return new BittrexDatafeed({ debug: false });
    case 'hitbtc':
      return new HitbtcDatafeed({ debug: false });
    case 'poloniex':
      return new PoloniexDatafeed({ debug: false });
    case 'bitfinex':
      return new BitfinexDatafeed({ debug: false });
    case 'kraken':
      return new KrakenDatafeed({ debug: false });
    case 'huobi':
      return new HuobiDatafeed({ debug: false });
    case 'okex':
      return new OkexDatafeed({ debug: false });
    case 'uniswap':
      return new DexDatafeed({
        exchange: 'uniswap',
        debug: false,
        markets
      })
    case 'sushiswap':
      return new DexDatafeed({
        exchange: 'sushiswap',
        debug: false,
        markets
      })
    case 'pancakeswap':
      return new DexDatafeed({
        exchange: 'pancakeswap',
        debug: false,
        markets
      })
    default:
      return null;
  }
}

export default TradingViewChart;
