import React, { useState } from 'react'
import { getCoinName } from './helper'
import "./styles.scss"

interface ITotalValueProps {
    balances: any;
    rates: any;
    selection: string;
    coins: any[];
    totalValue: string;
    onSelect: Function;
}

const TotalBalance = ({ balances, rates, coins, totalValue, onSelect }: ITotalValueProps) => {
    const [search, setSearch] = useState("")

    return (
        <div className="total_balance">
            <span className="total_balance__title">Total Balance</span>
            <div className="total_balance__value">$ {totalValue}</div>
            <input
                type="text"
                className="total_balance__search"
                placeholder="SEARCH"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
            />
            <div className="total_balance__items">
                {coins.map((coin: any, index: number) =>
                    (<div
                        onClick={() => onSelect(coin)}
                        key={index}
                        className="total_balance__items__item">
                            <div className="total_balance__items__item__circle">{index + 1}</div>
                            <div className="total_balance__items__item__title">{getCoinName(coin[0])}</div>
                            <div className="total_balance__items__item__amount">{coin[2]} {coin[0]}</div>
                            <div className="total_balance__items__item__exposure">{coin[3]}%</div>
                            <div className="total_balance__items__item__value">$ {coin[1].toFixed(2)}</div>
                    </div>)
                )}
            </div>
        </div>
    )
}

export default TotalBalance