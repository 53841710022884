import React from 'react'
import { connect } from 'react-redux'
import { actionConnect } from '../../../actions/backend'
import { ReactComponent as ReconnectingImage } from './reconnecting.svg'
import InfoModal from '../InfoModal'

interface IReconnectingModalProps {
    actionConnect: Function;
}

const ReconnectingModal = (props: IReconnectingModalProps) =>
    (<InfoModal
        modalTitle="Connection issues"
        heading="Lost connections"
        text="Oops! We lost your exchange connections. Please, hit the button below to reconnect."
        btnTitle="try again"
        onClick={() => props.actionConnect()}
    >
        <ReconnectingImage />
    </InfoModal>)

export default connect(null, { actionConnect })(ReconnectingModal)