/**
 * Navigation bar for Settings page
 */
import React from 'react'

interface INavigationProps {
    items: string[];
    active: string;
    onSelect: Function;
}

const Navigation = ({ items, active, onSelect }: INavigationProps) => {
    return (
        <div className="settingsnavbar__panel">
            {items.map((item: string) =>
                (<div
                    key={item}
                    onClick={() => onSelect(item)}
                    className={`settingsnavbar__panel__item ${item === active ? 'settingsnavbar__panel__item--active' : ''}`}
                >{item}</div>)
            )}
        </div>
    )
}

export default Navigation