/**
 * ported from redux-notifications
 */
import React from 'react';
import { connect } from 'react-redux';
import Notif from './Notification';
import "./styles.css";

const Notifs = (props: any) => {
  const { notifications } = props;

  const className = props.className || null;
  const componentClassName = props.componentClassName || 'notif'
  const CustomComponent = props.CustomComponent || null
  const renderedNotifications = notifications.map((notification: any) => {
    if (CustomComponent) {
      return (
        <CustomComponent
          {...props}
          componentClassName={componentClassName}
          key={notification['id']}
          id={notification['id']}
          message={notification['message']}
          kind={notification['kind']}
        />
      );
    }
    return (
      <Notif
        {...props}
        componentClassName={componentClassName}
        key={notification['id']}
        id={notification['id']}
        message={notification['message']}
        kind={notification['kind']}
      />
    );
  });

  return (
    <div className={`${`${componentClassName}__container`} ${className}`}>
      {renderedNotifications}
    </div>
  );
};

const mapStateToProps = (state: any) => 
    ({ notifications: state.notifications })

export default connect(mapStateToProps)(Notifs);
