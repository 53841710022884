/**
 * Implements a step buttons [10, 15, 20, 25, 50, 75]
 */
import React from 'react'
import { connect } from 'react-redux'

interface IAmountStepProps {
    onChange: Function;
    price: number;
    amountMin: number;
    amountPrecision: number;
    orderType: string;
    side: string;
    base: string;
    quote: string;
    exchanges: any;
    exchange: string;
}

const buttons = [10, 15, 20, 25, 50, 75, 100];

const AmountStep = (props: IAmountStepProps) => {
    const { side, price, orderType, amountMin, amountPrecision } = props;
    const isSell = side === "sell";
    const isLimitOrder = (orderType === 'limit');
    const baseValue = getNumericBaseValue(props)
    const quoteValue = getNumericQuoteValue(props)

    const withPrecision = (value: number): number =>
        parseFloat(value.toFixed(amountPrecision))

    /**
     * When you want to sell - amount is in baseValue
     * Sell Bitcoin for USD and % is in BTC
     */
    if (isSell && baseValue) {
        return (
            <div className="create__order__amount__step">
                {buttons.map((button: number) => {
                    return (<div
                        key={button}
                        className="create__order__amount__step__btn"
                        onClick={() =>
                            props.onChange(withPrecision(baseValue * button / 100.0))
                        }
                        >
                        {button}%
                    </div>)
                })}
            </div>
        )
    }
    /**
     * When you want to buy than this % is total, not amount
     * % regulates amount correlated to quote currency
     */
    if (!isSell && quoteValue && price > 0.0 && isLimitOrder) {
        return (
            <div className="create__order__amount__step">
                {buttons.map((button: number) => {
                    return (<div
                        key={button}
                        className="create__order__amount__step__btn"
                        onClick={() => {
                            const qty = quoteValue / price;
                            const amount = qty * button / 100.0;
                            const target = amountMin ?
                                (amount < amountMin ? amountMin : amount)
                                : amount;
                            props.onChange(withPrecision(target));
                        }}
                        >
                        {button}%
                    </div>)
                })}
            </div>
        )
    }
    return null; // Skip rendering
}

const getNumericBaseValue = ({ exchanges, exchange, base }: any) => {
    const instance = exchanges[exchange]
    if (instance) {
        if (instance[base]) {
            return parseFloat(instance[base])
        }
        return null;
    }
    return null
}

const getNumericQuoteValue = ({ exchanges, exchange, quote }: any) => {
    const instance = exchanges[exchange]
    if (instance) {
        if (instance[quote]) {
            return parseFloat(instance[quote])
        }
        return null;
    }
    return null
}

const mapStateToProps = (state: any) => ({
    base: state.current.base,
    quote: state.current.quote,
    exchange: state.current.exchange,
    exchanges: state.balances.exchanges || {}
})
export default connect(mapStateToProps)(AmountStep)