import { action } from 'typesafe-actions';

const entity = "dex_trades";
const ACTION_CLEAR = `${entity}/CLEAR`;
const ACTION_REPLACE = `${entity}/ADD_TRADE`

const actionClear = () =>
    action(ACTION_CLEAR)

const actionUpdateDexHistory = (state: any) =>
    action(ACTION_REPLACE, state)

export {
    ACTION_CLEAR,
    ACTION_REPLACE,
    actionClear,
    actionUpdateDexHistory
}