/**
 * Well, each DEX will have a seperate trading component
 * But lets start with a simple link
 */
import React from 'react'
import { connect } from 'react-redux'
import { getCurrentMarket } from '../../../selectors'

interface IDEXTradingProps {
    exchange: string
    market: any
}

/**
 * Return string if market is present or null if not
 * @param exchange - string
 * @param market - market or null
 * @returns 
 */
const createTradeLink = (exchange: string, market: any): string | null => {
    if (market) {
        const { contract } = market
        const { baseAddress, quoteAddress } = contract
        switch (exchange) {
            case 'uniswap':
                return `https://app.uniswap.org/#/swap?inputCurrency=${baseAddress}`
            case 'pancakeswap':
                return `https://exchange.pancakeswap.finance/#/swap?inputCurrency=${baseAddress}&outputCurrency=${quoteAddress}`
            default:
                return null
        }
    }
    return null
}

const DEXTrading = ({ exchange, market }: IDEXTradingProps) => {
    /**
     * Should open a link to trading on DEX website
     */
    const onSubmit = () => {
        const url = createTradeLink(exchange, market)
        if (url) {
            window.open(url)
        }
    }

    const createOrderBtnClassName = "create__order__create create__order__create--active create__order__create--buy"

    return (
        <div className="create__order">
            <div className={createOrderBtnClassName} onClick={() => onSubmit()}>TRADE</div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    exchange: state.current.exchange,
    market: getCurrentMarket(state)
})

export default connect(mapStateToProps)(DEXTrading)
