import { getPancakePairContract, pancakeProvider } from "./pancake";
import { getUniswapPairContract, uniswapProvider } from "./uniswap";

/**
 * 
 * @param exchange 
 * @param market 
 * @returns 
 */
const getPairContract = async (exchange: string, market: any) => {
    switch (exchange) {
        case "uniswap":
            return getUniswapPairContract(market)
        case "pancakeswap":
            return getPancakePairContract(market);
        default:
            return null;
    }
}
/**
 * Accepts provider and base/quote contract address
 * @returns array of dex trades
 */
const DexTradesProvider = async (exchange: string, contract: string, current: any) => {
    if (contract) {
        switch (exchange) {
            case "uniswap":
                return uniswapProvider(contract, current)
            case "pancakeswap":
                return pancakeProvider(contract, current)
            default:
                return []
        }
    }
    return []
}
    

export {
    DexTradesProvider,
    getPairContract
}