import axios from 'axios'
/* eslint-disable no-restricted-syntax */
class BittrexDatafeed {
//   symbolsPairs: any;
  constructor(options = {}) {
      this.binanceHost = 'https://broker.kattana.trade';
      this.debug = options.debug || false;
      this.symbolsPairs = {};
      this.pairsSymbols = {};
      this.symbols = [];
      this.barsInterval = null;
  }

  bittrexSymbols = async () => {
    if (!window.bittrex_symbols) {
      try {
        const { data } = await axios(`${this.binanceHost}/bittrex/symbols`)
        const { result } = data.data
        window.bittrex_symbols = result;
        return result
      } catch (err) {
        console.error(err)
        return []
      }
    }
    return window.bittrex_symbols
  }

  bittrexOHLCV = async (symbol, interval, startTime, endTime, limit) => {
      const url = `${this.binanceHost}/bittrex/ohlcv/${symbol}/${interval}/${limit}/${startTime}/${endTime}`;
      try {
        const { data } = await axios(url)
        return data.data.result
      } catch (err) {
        console.error(err)
        return []
      }
  }

  bittrexLastCandle = async (symbol, interval) => {
    const url = `${this.binanceHost}/bittrex/last_candle/${symbol}/${interval}`;
    try {
        const { data } = await axios.get(url)
        return data.data.result
    } catch (err) {
        console.error(err)
        return []
    }
  }

  onReady = async (callback) => {
      try {
          const symbols = await this.bittrexSymbols();
          symbols.forEach(symbol => {
            const { Market } = symbol;
            const { MarketName } = Market;
            const sym = MarketName.split('-').reverse().join('');
            this.symbolsPairs[sym] = MarketName;
            this.pairsSymbols[MarketName] = sym;
          });
          this.symbols = symbols;
          callback({
              supports_marks: false,
              supports_timescale_marks: false,
              supports_time: true,
              supported_resolutions: [
                  '1', '5', '30', '60', '1D'
              ]
          });
      } catch (err) {
          console.error(err);
      }
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
      const input = userInput.toUpperCase();
      onResultReadyCallback(this.symbols
              .filter((symbol) => symbol.Market.MarketName.split('-').reverse().join('').indexOf(input) >= 0)
              .map((symbol) => ({
                  symbol: symbol.Market.MarketName.split('-').reverse().join(''),
                  full_name: symbol.Market.MarketName,
                  description: `${symbol.Market.BaseCurrency} / ${symbol.Market.MarketCurrency}`,
                  pair: `${symbol.Market.MarketCurrency}-${symbol.Market.BaseCurrency}`,
                  ticker: symbol.Market.MarketName.split('-').reverse().join('')
              })));
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
      if (this.debug) console.log('👉 resolveSymbol:', symbolName);

      const comps = symbolName.split(':');
      // eslint-disable-next-line no-param-reassign
      symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

      // eslint-disable-next-line no-unused-vars
      const pricescale = ({ Summary }) => {
        const { Ask } = Summary;
        if (Ask > 100.0) return 100;
        if (Ask > 1.0) return 1000;
        return 100000000;
      };

      for (const symbol of this.symbols) {
          const pair = this.pairsSymbols[symbol.Market.MarketName];
          if (pair === symbolName) {
              setTimeout(() => {
                  onSymbolResolvedCallback({
                      name: symbol.Market.MarketName,
                      description: `${symbol.Market.BaseCurrency} / ${symbol.Market.MarketCurrency}`,
                      ticker: symbol.Market.MarketName.split('-').reverse().join(''),
                      pair: `${symbol.Market.MarketCurrency}-${symbol.Market.BaseCurrency}`,
                      session: '24x7',
                      minmov: 1,
                      pricescale: pricescale(symbol),
                      timezone: 'UTC',
                      has_intraday: true,
                      has_daily: true,
                      has_weekly_and_monthly: true,
                      currency_code: symbol.Market.MarketCurrency
                  });
              }, 0);
              return;
          }
      }

      onResolveErrorCallback('not found');
  }

  getInterval = (resolution) => ({
        1: 'oneMin',
        5: 'fiveMin',
        30: 'thirtyMin',
        60: 'hour',
        '1D': 'day',
        D: 'day'
    }[resolution]);

  getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
      if (this.debug) {
          console.log('👉 getBars:', symbolInfo.name, resolution);
          console.log('First:', firstDataRequest);
          console.log('From:', from, new Date(from * 1000).toGMTString());
          console.log('To:  ', to, new Date(to * 1000).toGMTString());
      }

      const interval = this.getInterval(resolution);

      if (!interval) {
          onErrorCallback('Invalid interval');
      }

      let totalKlines = [];

      const finishKlines = () => {
          if (this.debug) {
              console.log('📊:', totalKlines.length);
          }

          if (totalKlines.length === 0) {
              onHistoryCallback([], {
                  noData: true
              });
          } else {
              onHistoryCallback(totalKlines.map(kline => ({
                    time: Date.parse(kline.T),
                    close: parseFloat(kline.C),
                    open: parseFloat(kline.O),
                    high: parseFloat(kline.H),
                    low: parseFloat(kline.L),
                    volume: parseFloat(kline.V)
                  })), {
                  noData: false
              });
          }
      };

      // eslint-disable-next-line no-shadow
      const getKlines = (from, to) => {
          this.bittrexOHLCV(symbolInfo.name, interval, from, to, 500).then(klines => {
            totalKlines = totalKlines.concat(klines);
            finishKlines();
            return null;
          }).catch(err => {
            console.error(err);
            onErrorCallback('Some problem');
          });
      };

      // eslint-disable-next-line no-param-reassign
      from *= 1000;
      // eslint-disable-next-line no-param-reassign
      to *= 1000;

      getKlines(from, to);
  }

  // eslint-disable-next-line no-unused-vars
  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    if (this.debug) console.log('👉 subscribeBars:', subscriberUID);
    const { name } = symbolInfo;

    const interval = this.getInterval(resolution);
    this.barsInterval = setInterval(() => {
        this.bittrexLastCandle(name, interval)
            .then((data) => {
                const kline = data[data.length - 1];
                const bar = {
                    time: Date.parse(kline.T),
                    close: parseFloat(kline.C),
                    open: parseFloat(kline.O),
                    high: parseFloat(kline.H),
                    low: parseFloat(kline.L),
                    volume: parseFloat(kline.V)
                };
                onRealtimeCallback(bar);
                return null;
            }).catch((err) => {
                console.error(err);
            });
    }, 30000);
  }

  unsubscribeBars(subscriberUID) {
      if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
      clearInterval(this.barsInterval);
  }
}

export default BittrexDatafeed;
