/* eslint-disable no-restricted-syntax */
const DAY = 24 * 60 * 60 * 1000;

class PoloniexDatafeed {
    // symbolsPairs: any;
    constructor(options = {}) {
        this.host = 'https://poloniex.com/public';
        this.debug = options.debug || false;
        this.symbolsPairs = {};
        this.pairsSymbols = {};
        this.barsInterval = null;
    }

    poloniexSymbols() {
        return fetch(`${this.host}?command=returnTicker`)
            .then(res => res.json())
            .then(json => json)
            .catch(() => []);
    }

    poloniexOHLCV(symbol, interval, startTime, endTime, limit) {
        const url = `${this.host}?command=returnChartData&currencyPair=${symbol}&start=${Math.ceil(startTime / 1000)}&end=${Math.ceil(endTime / 1000)}&period=${interval}&limit=${limit}`;
        return fetch(url)
            .then(res => res.json())
            .then(json => json)
            .catch(() => []);
    }
    poloniexLastCandle(symbol, interval) {
      const startTime = new Date() - DAY;
      const url = `${this.host}?command=returnChartData&currencyPair=${symbol}&period=${interval}&start=${startTime}`;
      return fetch(url)
          .then(res => res.json())
          .then(json => json)
          .catch(() => []);
    }

    onReady = async (callback) => {
        try {
            const symbols = await this.poloniexSymbols();
            this.symbols = Object.keys(symbols).map(symbol => {
              const sp = symbol.split('_');
              const baseCurrency = sp[1];
              const quoteCurrency = sp[0];
              const pair = `${quoteCurrency}-${baseCurrency}`;
              this.symbolsPairs[symbol] = pair;
              this.pairsSymbols[pair] = symbol;
              return ({
                  id: `${baseCurrency}${quoteCurrency}`,
                  name: `${quoteCurrency}${baseCurrency}`,
                  pair,
                  symbol,
                  full_name: `${baseCurrency} / ${quoteCurrency}`,
                  description: `${baseCurrency} / ${quoteCurrency}`,
                  ticker: symbol,
                  last: symbols[symbol].last,
                  pricescale: scalePrice(symbols[symbol].last)
              });
            });
            callback({
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: true,
                supported_resolutions: [
                    '5', '15', '30', '120', '1D', '1W'
                ]
            });
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols.filter((symbol) => symbol.id.indexOf(input) >= 0));
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);
        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();
        for (const symbol of this.symbols) {
            if (symbol.id === symbolName) {
                setTimeout(() => {
                    onSymbolResolvedCallback({
                        name: symbol.symbol,
                        description: symbol.description,
                        ticker: symbol.symbol,
                        pair: symbol.pair,
                        session: '24x7',
                        minmov: 1,
                        pricescale: symbol.pricescale,
                        timezone: 'UTC',
                        has_intraday: true,
                        has_daily: true,
                        has_weekly_and_monthly: true,
                        currency_code: symbol.baseCurrency
                    });
                }, 0);
                return;
            }
        }

        onResolveErrorCallback('not found');
    }
    // 300, 900, 1800, 7200, 14400, and 86400
    getInterval = (resolution) => ({
        5: '300',
        15: '900',
        30: '1800',
        60: '1800',
        120: '7200',
        '1D': '14400',
        D: '14400',
        '1W': '86400'
    }[resolution]);

    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toGMTString());
            console.log('To:  ', to, new Date(to * 1000).toGMTString());
        }

        const interval = this.getInterval(resolution);

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }

            if (totalKlines.length === 0) {
                onHistoryCallback([], {
                    noData: true
                });
            } else {
                onHistoryCallback(totalKlines.map(kline => ({
                      time: kline.date * 1000,
                      close: kline.close,
                      open: kline.open,
                      high: kline.high,
                      low: kline.low,
                      volume: kline.volume
                    })), {
                    noData: false
                });
            }
        };

        // eslint-disable-next-line no-shadow
        const getKlines = (from, to) => {
            this.poloniexOHLCV(symbolInfo.name, interval, from, to, 1000).then(klines => {
              totalKlines = totalKlines.concat(klines);
              if (klines.length === 1000) {
                const lastCandle = klines[klines.length - 1];
                const { timestamp } = lastCandle;
                // eslint-disable-next-line no-param-reassign
                from = Date.parse(timestamp) + 1;
                if (Number.isNaN(from)) {
                  finishKlines();
                } else {
                  getKlines(from, to);
                }
              } else {
                finishKlines();
              }
              return null;
            }).catch(err => {
              console.error(err);
              onErrorCallback('Some problem');
            });
        };

        // eslint-disable-next-line no-param-reassign
        from *= 1000;
        // eslint-disable-next-line no-param-reassign
        to *= 1000;

        getKlines(from, to);
    }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);

        const { name } = symbolInfo;
        const interval = this.getInterval(resolution);
        this.barsInterval = setInterval(() => {
            this.poloniexLastCandle(name, interval)
                .then((data) => {
                    const kline = data[data.length - 1];
                    const bar = {
                        time: kline.date * 1000,
                        close: kline.close,
                        open: kline.open,
                        high: kline.high,
                        low: kline.low,
                        volume: kline.volume
                    };
                    onRealtimeCallback(bar);
                    return null;
                }).catch((err) => {
                    console.error(err);
                });
        }, 15000);
    }

    unsubscribeBars(subscriberUID) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        clearInterval(this.barsInterval);
    }
  }

const scalePrice = (last) => {
    if (last >= 1.0) {
        return 10000;
    }
    return 10000000;
};

export default PoloniexDatafeed;
