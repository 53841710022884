import cointitles from '../../../helpers/cointitles.json'

interface ICoin {}
/**
 * Returns filtered and ordered coins set
 * @param spot 
 */
const getCoins = (spot: any, rates: any, totalValue: number): ICoin[] =>
    Object.keys(spot)
        .map((coin: string) => {
            const value = parseFloat(rates[coin]) * spot[coin]
            const amount = spot[coin]
            const exposure = ((value / totalValue) * 100).toFixed(0)

            return [coin, value, amount, exposure]
        })
        .filter((item) => item[1] > 0.0)
        .sort((a, b) =>  b[1] - a[1])

/**
 * Calculate current portfolio value
 * @param balances - balances redux object
 * @param rates - rates redux object
 */
const getTotalValue = (balances: any, rates: any) =>
    getCoinsValue(balances.spot, rates)

/**
 * Calculates value of key-value object
 * @param coins 
 * @param rates 
 */
const getCoinsValue = (coins: any, rates: any) => {
    let summ = 0
    Object.keys(coins)
        .forEach((coin: string) => {
            const amount = coins[coin]
            if (rates[coin] && amount > 0.0) {
                const value = amount * parseFloat(rates[coin])
                summ += value
            }
        })
    return summ.toFixed(2)
}

/**
 * get coin name
 * @param coin 
 */
const getCoinName = (coin: string) => (cointitles as any)[coin] || coin


/**
 * Setup 'other' coin
 * Aggregate small coins under other section
 */
const other = {
    title: 'Other',
    rate: 10
}

export {
    getCoins,
    getTotalValue,
    getCoinsValue,
    getCoinName,
    other
}