/**
 * Implements a Donut chart based on ApexCharts
 */
import React from "react";
import Chart from "react-apexcharts";
import palette from "./palette.json";
import "./styles.scss";

const options = (labels: string[]) => ({
  labels,
  fill: {
    colors: palette.bluetone,
  },
  stroke: {
    width: 0,
    lineCap: "round",
  },
  plotOptions: {
    pie: {
      expandOnClick: true,
    },
  },
});

const CryptoExposureChart = ({
  balances,
  rates,
  coins,
  selection,
  onSelect,
}: ICryptoExposureChartProps) => {
  const labels = coins.map((coin: any) => coin[0]);
  const series = coins.map((coin: any) => coin[1]);
  return (
    <div className="crypto_exposure_chart">
      <span className="crypto_exposure_chart__title">Crypto Exposure</span>
      <Chart
        options={options(labels)}
        series={series}
        type="donut"
        height={350}
        width={"100%"}
      />
    </div>
  );
};

interface ICryptoExposureChartProps {
  balances: any;
  rates: any;
  coins: any[];
  totalValue: string;
  selection: string;
  onSelect: Function;
}

export default CryptoExposureChart;
