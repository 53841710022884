import { action } from "typesafe-actions";

const entity = 'accounts';

const SIGN_IN_FAIL = `${entity}/SIGN_IN_FAIL`;
const SIGN_IN_SUCCESS = `${entity}/SIGN_IN_SUCCESS`;
const SIGN_IN_REQUEST = `${entity}/SIGN_IN_REQUEST`;

const SIGN_UP_FAIL = `${entity}/SIGN_UP_FAIL`;
const SIGN_UP_SUCCESS = `${entity}/SIGN_UP_SUCCESS`;
const SIGN_UP_REQUEST = `${entity}/SIGN_UP_REQUEST`;

const SIGN_OUT = `${entity}/SIGN_OUT`;
const UPDATE = `${entity}/UPDATE`;

/**
 * Sign In
 */
const actionSignIn = (token: string, account_secret: string, email: string, session_id: any) =>
    action(SIGN_IN_SUCCESS, { token, account_secret, email, session_id })

const actionSignInFailure = (message: string) =>
    action(SIGN_IN_FAIL, { failed: true, message })

const actionTrySignIn = (email: string, password: string) =>
    action(SIGN_IN_REQUEST, { email, password })

/**
 * Sign Up
 */
const actionTrySignUp = (email: string, password: string) =>
    action(SIGN_UP_REQUEST, { email, password })

const actionSignUpFailure = (message: string) =>
    action(SIGN_UP_FAIL, { failed: true, message })

const actionSignOut = () => action(SIGN_OUT)
const actionAccountUpdate = (data: any) => action(UPDATE, data)

export {
    SIGN_IN_FAIL,
    SIGN_IN_SUCCESS,
    SIGN_IN_REQUEST,
    SIGN_UP_FAIL,
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_OUT,
    UPDATE,
    actionSignIn,
    actionSignInFailure,
    actionSignUpFailure,
    actionTrySignIn,
    actionTrySignUp,
    actionSignOut,
    actionAccountUpdate
}