/**
 * if user is logged in - save watchlist on server
 */
import { takeLatest, delay, put } from "redux-saga/effects";
import { NOTIF_SEND, notifDismiss } from "../actions/notifications";

function* onNotification(action: any) {
    const { payload } = action
    const { id, dismissAfter } = payload
    if (dismissAfter) {
        yield delay(dismissAfter);
        yield put(notifDismiss(id))
    }
}

export default function* notificationsSaga() {
    yield takeLatest(NOTIF_SEND, onNotification);
}
  