/**
 * Modal state
 * based on Dan Abramov: https://stackoverflow.com/a/35641680
 */
import { SHOW, HIDE, HIDE_IF } from "../actions/modal"
import ModalTypes from "../helpers/modals"

interface IModalReducerState {
  modalName: null | ModalTypes;
  props: any;
}

const defaultState = {
  modalName: null,
  props: {}
}

const modal = (state: IModalReducerState = defaultState, action: any) => {
  switch (action.type) {
    case SHOW:
      return action.payload
    case HIDE:
      return defaultState
    case HIDE_IF:
      return (state.modalName === action.payload.modalName) ?
        defaultState
        : state
    default:
      return state
  }
}

export default modal