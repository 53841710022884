import React from "react";
import { IOrder } from "../../helpers/tradingModes";
import Placeholder from "./Placeholder";
interface IOrdersPanelProps {
  data: IOrder[];
  height: number;
  selected: string[];
  signedin: boolean;
  tickers?: any;
  onSelect: Function;
}

const decoratePrice = (order: IOrder) => {
  if (order.type === "stop_loss_limit") {
    return `stop:${order.info.stopPrice} \n\rlimit:${order.price}`
  }
  return order.price
}

const OrdersPanel = ({
  data,
  height,
  selected,
  signedin,
  onSelect,
  tickers
}: IOrdersPanelProps) => {

  const getCurrentPrice = (exchange: string, symbol: string) => {
    const key = `${exchange}|${symbol}`
    const item = tickers[key]
    if (item) {
      return item.close
    }
    return 'n/a'
  }

  if (!signedin) {
    return (
      <div className="positions__panel">
        <div className="positions__panel__orders">
            <Placeholder
                title="No orders"
                text="Sign in to see your orders, history, positions"
            />
        </div>
      </div>
    );
  }
  return (
    <div className="positions__panel">
      <div className="positions__panel__orders" style={{ height: height - 90 }}>
        <table>
          <thead>
            <tr>
              <td>symbol</td>
              <td>type</td>
              <td>side</td>
              <td>price</td>
              {tickers && <td>market price</td>}
              <td>amount</td>
              <td>filled</td>
              <td>remaining</td>
              <td>status</td>
            </tr>
          </thead>
          <tbody>
            {data.map((order: IOrder, index: number) => (
              <tr
                key={order.id}
                className={
                  selected.includes(order.id) ? "table__row--selected" : ""
                }
                onClick={() => {
                  onSelect(order.id);
                }}
              >
                <td>{order.symbol}</td>
                <td>{order.type}</td>
                <td>{order.side}</td>
                <td>{decoratePrice(order)}</td>
                {tickers && <td><span>{getCurrentPrice(order.exchange, order.symbol)}</span></td>}
                <td>{order.amount}</td>
                <td>{order.filled}</td>
                <td>{order.remaining}</td>
                <td>{order.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrdersPanel;
