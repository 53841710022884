/**
 * Implements tabs on top of table
 */
import React from 'react'
import "./styles.scss"

interface ITabProps {
    tabs: string[];
    selected: number;
    onSelect: Function;
}
const Tabs = ({ tabs, selected, onSelect }: ITabProps) => {
    return (
        <div className="positions__tabs">
            {tabs.map((title: string, index: number) => {
                const active = (selected === index)
                const className = active ?
                    "positions__tabs__tab positions__tabs__tab--active"
                    : "positions__tabs__tab"
                return (<div key={index} className={className} onClick={() => onSelect(index)}>{title}</div>)
                })}
        </div>
    )
}

export default Tabs