import React from 'react';
import { connect } from 'react-redux';
import { isUserSignedIn } from './selectors';

import NavigationBar from './components/NavigationBar';
import TradingPage from './components/pages/TradingPage';
import MarketsPage from './components/pages/MarketsPage';
import SettingsPage from './components/pages/SettingsPage';
import HomePage from './components/pages/HomePage';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage';
import ChangePasswordPage from './components/pages/ChangePasswordPage';
import PerformancePage from './components/pages/PerformancePage';
import AssetsPage from './components/pages/AssetsPage';

import { PageType } from './helpers/pageTypes';
import Modal from './components/Modal';
import ParticlesBackground from './components/ParticlesBackground';

const App = (props: any) => {
  const { page, signed_in } = props;

  if (!signed_in) {
    return (<div className="App">
      <ParticlesBackground />
      <Modal />
    </div>)
  }

  return (
    <div className="App">
      <NavigationBar />
      <Page selected={page} />
      <Modal />
    </div>
  );
}

interface IPageProps {
  selected: PageType;
}
/**
 * Implements factory to pick a proper page
 */
const Page = ({ selected }: IPageProps) => {
  switch (selected) {
    case PageType.homepage:
      return <HomePage />;
    case PageType.trading:
      return <TradingPage />;
    case PageType.marketscanner:
      return <MarketsPage />;
    case PageType.settings:
      return <SettingsPage />
    case PageType.forgot_password:
      return <ForgotPasswordPage />
    case PageType.change_password:
      return <ChangePasswordPage />
    case PageType.performance:
      return <PerformancePage />
    case PageType.assets:
      return <AssetsPage />
    default:
      return <>Not implemented yet</>;
  }
}

const mapStateToProps = (state: any) => ({
  page: state.navigation.page,
  signed_in: isUserSignedIn(state)
})

export default connect(mapStateToProps)(App);
