/**
 * Bar on top of each widget
 */
import React from 'react'
import { IWidget } from '../../helpers/widgets'
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Minimize } from "./minimize.svg";
import { ReactComponent as Maximize } from "./maximize.svg";

import "./widgetControls.scss"

interface IWidgetControlsProps {
    element: string;
    data: IWidget;
    onClose: Function;
    onMinimize: Function;
    onMaximize: Function;
}

const matchElement = (element: string) => {
    const widgetsList = {
        TradingChart: 'Chart',
        OrderBook: 'Order book',
        CreateOrder: 'Trading',
        TradingHistory: 'Time & Sales',
        PositionsOrders: 'Positions & Orders',
        Watchlist: 'Watchlist',
        DexTrades: 'Trades'
    }
    return (widgetsList as any)[element] || element;
}

const WidgetControls = ({ element, onClose, onMinimize, onMaximize }: IWidgetControlsProps) => {
    const title = matchElement(element)

    const onCloseBtnClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        onClose()
    }

    return (
        <div className="widget_controls">
            <div className="widget_controls_caption">{title}</div>
            <div
                className="widget_controls_btn_close"
                onClick={onCloseBtnClick}
            >
                <Close />
            </div>
            <div
                className="widget_controls_btn_minimize"
                onClick={() => onMinimize()}
            >
                <Minimize />
            </div>
            <div
                className="widget_controls_btn_expand"
                onClick={() => onMaximize()}
            >
                <Maximize />
            </div>
        </div>
    )
}

export default WidgetControls