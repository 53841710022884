/**
 * Call SWITCH for current when tab selected
 */
import { put, select, takeLatest } from 'redux-saga/effects'
import { actionSwitchCurrent } from '../actions/current'
import { ACTION_SELECT_TAB, ACTION_SELECT_TAB_BY_INDEX } from '../actions/tabs'

/**
 * Change current having exact tab data
 */
function* callDeps(action: any) {
    const { exchange, base, quote } = action.payload
    yield put(actionSwitchCurrent(exchange, base, quote ? quote : ''));
}

/**
 * Change current having exact tab index
 */
function* callDepsWithIndex(action: any) {
    const { index } = action.payload
    const tabs = yield select(state => state.tabs)
    const tab = tabs[index]

    if (tab) {
        const { exchange, base, quote } = tab
        yield put(actionSwitchCurrent(exchange, base, quote ? quote : ''))
    }
}

function* selectTab() {
    yield takeLatest(ACTION_SELECT_TAB, callDeps);
    yield takeLatest(ACTION_SELECT_TAB_BY_INDEX, callDepsWithIndex)
}
  
export default selectTab;