import axios from 'axios'
import { StorageKeys } from '../helpers/localStorage'
import { analytics } from '../core/endpoints'

const eventEndpoint = `${analytics}/track_event`

/**
 * Enumerate supported events
 */
export const AnalyticsEvents = {
    signIn: 'SIGN_IN',
    authenticate: 'SIGN_UP',
    openApp: 'OPEN_APP',
    openMarketScanner: 'OPEN_MARKET_SCANNER',
    openTradingPerformance: 'OPEN_TRADING_PERFORMANCE',
    openNews: 'NEWS_HUB_OPEN',
    activateExchange: 'ACTIVATE_EXCHANGE',
    createOrder: 'CREATE_ORDER',
    demoActivated: 'DEMO_ACTIVATED',
    portfolioCurrencyChanged: 'PORTFOLIO_CURRENCY_CHANGED',
    addToWatchlist: 'ADD_TO_WATCHLIST',
    resetLayout: 'RESET_LAYOUT',
    newTab: 'NEW_TAB',
    addWidget: 'ADD_WIDGET',
    addWorkspace: 'ADD_WORKSPACE',
    saveChart: 'SAVE_CHART',
    addIndicator: 'ADD_INDICATOR',
    chartDrawItem: 'ADD_CHART_DRAWING',
    freeze: 'APP_FREEZE',
    selectMarketScannerLayout: 'SELECT_MS_LAYOUT',
    buyPriceEntered: 'BUY_PRICE_ENTERED',
    touchbarWatchlistEnabled: 'TB_WATCHLIST_ENABLED',
    passcodeChanged: 'CHANGE_PASSCODE',
    addWidgetPlaceholderClick: 'CLICK_ADDWIDGET_PLACEHOLDER',
    newLayoutSaved: 'NEW_LAYOUT_SAVED',
    newsHubFilterSelected: 'NEWS_HUB_FILTER_CLICK',
    newsHubItemClick: 'NEWS_HUB_ITEM_CLICK',
    newsHubAutorefresh: 'NEWS_HUB_AUTOREFRESH'
}
/**
 * Bundle screen width and height for these events
 */
const sendScreenResolution = [
    AnalyticsEvents.addWidget,
    AnalyticsEvents.newTab,
    AnalyticsEvents.addWorkspace,
    AnalyticsEvents.resetLayout
];

/**
 * Send event to the server
 * @param eventName - name of event
 * @param data - JS object
 */
const storeEvent = (eventName: string, data?: any) =>
    axios.post(eventEndpoint, prepareEvent(eventName, data))
        .then(() => {})
        .catch(console.error);

/**
 * Form a JSON object of event
 * @param eventName - name of events
 * @param data - JS object payload
 */
const prepareEvent = (eventName: string, data?: any) => {
    const eventData: any = {
        version: 'web',
        name: eventName,
        token: localStorage.getItem(StorageKeys.token),
        created_at: new Date(),
        ...data
    }

    if (sendScreenResolution.includes(eventName)) {
        eventData.screenWidth = window.screen.width;
        eventData.screenHeight = window.screen.height;
    }

    return {
        hash: btoa(JSON.stringify(eventData))
    }
}

export { storeEvent }