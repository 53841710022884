/**
 * Implements AddWidgets Modal
 * Presentation component
 */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actionCloseModal } from '../../actions/modal'
import { actionAddWidgetsList } from '../../actions/widgets'
import { ReactComponent as CloseIcon } from './close.svg'
import { ReactComponent as Time } from './Time.svg'
import { ReactComponent as Watchlist } from './Watchlist.svg'
import { ReactComponent as Trading } from './Trading.svg'
import { ReactComponent as Orderbook } from './Orderbook.svg'
import { ReactComponent as Positions } from './Positions.svg'
import { ReactComponent as Chart } from './Chart.svg'
import { ReactComponent as Treemap } from './Treemap.svg'
import "./styles.scss"
import { getActiveTabId } from '../../selectors'

interface IAddWidgetModalProps {
    tabId: number;
    actionAddWidgetsList: Function;
    actionCloseModal: Function;
}

const AddWidgetModal = (props: IAddWidgetModalProps) => {
    const { tabId } = props;
    const [list, setList] = useState({
        Chart: false,
        TradingChart: false,
        OrderBook: false,
        TradingHistory: false,
        CreateOrder: false,
        Watchlist: false,
        PositionsOrders: false,
        Treemap: false,
        DexTrades: false
    })

    const onItemClick = (name: string) => {
        (list as any)[name] = !(list as any)[name]
        setList({ ...list })
    }

    const anySelected = Object.values(list)
        .filter((item: boolean) => item === true)
        .length > 0

    return (
        <div className="add_widget__modal">
            <div className="add_widget__modal__header">
                <span className="add_widget__modal__header__caption">ADD WIDGET</span>
                <span className="add_widget__modal__header__close" onClick={() => props.actionCloseModal()}><CloseIcon /></span>
            </div>
            <div className="add_widget__modal__contents">
                <div className="add_widget__modal__contents__row">
                    <Item
                        name="TradingChart"
                        caption="TradingView Chart"
                        checked={list.TradingChart}
                        onClick={onItemClick}>
                        <Chart />
                    </Item>
                    <Item
                        name="Chart"
                        caption="Kattana Chart"
                        checked={list.Chart}
                        onClick={onItemClick}>
                        <Chart />
                    </Item>
                    <Item
                        name="OrderBook"
                        caption="Order book"
                        checked={list.OrderBook}
                        onClick={onItemClick}>
                        <Orderbook />
                    </Item>
                </div>
                <div className="add_widget__modal__contents__row">
                    <Item
                        name="CreateOrder"
                        caption="Trading"
                        checked={list.CreateOrder}
                        onClick={onItemClick}>
                            <Trading />
                    </Item>
                    <Item
                        name="Watchlist"
                        caption="Watchlist"
                        checked={list.Watchlist}
                        onClick={onItemClick}>
                            <Watchlist />
                    </Item>
                    <Item
                        name="PositionsOrders"
                        caption="Positions & orders"
                        checked={list.PositionsOrders}
                        onClick={onItemClick}>
                            <Positions />
                    </Item>
                </div>
                <div className="add_widget__modal__contents__row">
                    <Item
                        name="Treemap"
                        caption="Markets treemap"
                        checked={list.Treemap}
                        onClick={onItemClick}>
                            <Treemap />
                    </Item>
                    <Item
                        name="TradingHistory"
                        caption="Time & sales"
                        checked={list.TradingHistory}
                        onClick={onItemClick}>
                        <Time />
                    </Item>
                    <Item
                        name="DexTrades"
                        caption="DEX Trades"
                        checked={list.DexTrades}
                        onClick={onItemClick}>
                        <Time />
                    </Item>
                </div>
            </div>
            <div className="add_widget__modal__buttons">
                <div
                    className="add_widget__modal__buttons__addall"
                    onClick={() => props.actionAddWidgetsList(markAll(list), tabId)}
                >
                    ADD ALL
                </div>
                {anySelected && (<div
                    className="add_widget__modal__buttons__add"
                    onClick={() => props.actionAddWidgetsList(list, tabId)}
                >add selected</div>)}
                <div
                    className="add_widget__modal__buttons__cancel"
                    onClick={() => props.actionCloseModal()}
                >CANCEL</div>
            </div>
        </div>
    )
}

/**
 * Mark all keys to true
 * @param list - widgets list
 */
const markAll = (list: any) => {
    const result: any = {}
    Object.keys(list).forEach((key: string) => {
        result[key] = true
    })
    return result
}

const getCheckedClassName = (checked: boolean) =>
    checked ?
    "add_widget__modal__contents__row__item__checker add_widget__modal__contents__row__item__checker--active"
    : "add_widget__modal__contents__row__item__checker"

/**
 * Widgets clickable item decorator
 * @param props
 */
const Item = ({ name, caption, children, checked, onClick }: any) =>
    (<div
        className="add_widget__modal__contents__row__item"
        onClick={() => onClick(name)}>
            <div className={getCheckedClassName(checked)} />
            {children}
            <span>{caption}</span>
    </div>)


const mapStateToProps = (state: any) => ({ tabId: getActiveTabId(state) })

export default connect(mapStateToProps, { actionCloseModal, actionAddWidgetsList })(AddWidgetModal)