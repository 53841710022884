import { action } from 'typesafe-actions';
import { IWatchlistItem } from '../helpers/watchlist';

const entity = 'watchlist';
const ADD = `${entity}/ADD`;
const REMOVE = `${entity}/REMOVE`;
const REMOVE_ARGS = `${entity}/REMOVE_ARGS`;
const ACTIVATE = `${entity}/ACTIVATE`;
const REFRESH = `${entity}/REFRESH`;

const actionAddWatchlist = (item: IWatchlistItem) =>
    action(ADD, item)
const actionRemoveWatchlist = (index: number) =>
    action(REMOVE, { index })
const actionRemoveByArgsWatchlist = (exchange: string, base: string, quote: string) =>
    action(REMOVE_ARGS, { exchange, base, quote })
const actionActivateWatchlist = (index: number) =>
    action(ACTIVATE, { index })
const actionRefreshWatchlist = (items: any) =>
    action(REFRESH, items)

export {
    ADD,
    REMOVE,
    REMOVE_ARGS,
    REFRESH,
    ACTIVATE,
    actionAddWatchlist,
    actionRemoveWatchlist,
    actionRemoveByArgsWatchlist,
    actionActivateWatchlist,
    actionRefreshWatchlist
}