import React from 'react'
import "./styles.scss"
import { IWatchlistItem } from '../../helpers/watchlist';
import { formatAmount } from '../../helpers/formatters';

interface IWatchlistItemProps {
    item: IWatchlistItem;
    active?: boolean;
    onClick: Function;
    tickers: any;
    canDelete: boolean;
    onDelete: Function;
}
const lazyLoadedItem = {
    baseVolume: 0,
    percentage: 0,
    last: 0
}
const WatchlistItem = ({ item, active, tickers, onClick, canDelete, onDelete }: IWatchlistItemProps) => {
    const tickerKey = `${item.exchange}|${item.base}/${item.quote}`
    const tickerItem = tickers[tickerKey] || lazyLoadedItem;
    const classList = ["watchlist__item"];
    if (active) {
        classList.push("watchlist__item--active");
    }
    if (canDelete) {
        classList.push("watchlist__item--trashcan");
    }
    const isPercentPositive = (tickerItem.percentage > 0.0)
    const priceChangeClass = (isPercentPositive) ?
        "watchlist__item__pricechange--green"
        : "watchlist__item__pricechange--red"
    const decoratedPercent = parseFloat(tickerItem.percentage).toFixed(2)
    return <div className={classList.join(' ')} onClick={() => onClick(item.exchange, item.base, item.quote)}>
        <div className="watchlist__item__title">{item.title}</div>
        <div className="watchlist__item__volume">{parseFloat(tickerItem.baseVolume).toFixed(2)} {item.base}</div>
        <div className={`watchlist__item__pricechange ${priceChangeClass}`}>{decoratedPercent}%</div>
        <div className="watchlist__item__price">{formatAmount(tickerItem.last)} {item.quote}</div>
        {canDelete && (<div className="watchlist__item__trashcan" onClick={(e) => {
            e.stopPropagation()
            onDelete()
        }}/>)}
    </div>
}

export default WatchlistItem;