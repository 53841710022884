import React, { useState } from 'react'
import Tabs from './tabs';
import "./styles.scss";
import TickersPanel from './TickersPanel/index';
import WatchlistPanel from './WatchlistPanel';

const TickersModal = () => {
    const [tab, setTab] = useState(0)
    const isWatchList = (tab === 1)
    return (
        <div className="tickers__modal">
            <Tabs
                tabs={["Symbol Search", "Watchlist"]}
                selected={tab}
                onSelect={setTab}
            />
            {!isWatchList && <TickersPanel />}
            {isWatchList && <WatchlistPanel />}
        </div>
    )
}

export default TickersModal
