import { ACTION_CLEAR, ACTION_REPLACE } from "../actions/dex_trades"

interface IDexTradesState {
  loading: boolean
  pair: string | null
  trades: any[]
}
const defaultState = {
  loading: true,
  pair: null,
  trades: []
}

const dexTrades = (state: IDexTradesState = defaultState, action: any) => {
    switch (action.type) {
      case ACTION_CLEAR:
        return []
      case ACTION_REPLACE:
        return {
          ...state,
          ...action.payload
        }
      default:
        return state
    }
}

export default dexTrades