import { action } from "typesafe-actions";
import { ITradingAccount } from "../reducers/trading_accounts";

const entity = 'trading_accounts';

const FETCH_REQUEST = `${entity}/REQUEST`;
const ADD_REQUEST = `${entity}/ADD_REQUEST`;
const UPDATE = `${entity}/UPDATE`;
const DELETE = `${entity}/DELETE`;
const UNLOCK = `${entity}/UNLOCK`;
const UPDATE_ACCOUNT= `${entity}/UPDATE_ACCOUNT`;

const actionRequestExchangeAccounts = () =>
    action(FETCH_REQUEST)

const actionRequestAddAccount = (formData: ITradingAccount) =>
    action(ADD_REQUEST, formData)

const actionUpdate = (payload: any) =>
    action(UPDATE, payload)

const actionUpdateAccount = (payload: ITradingAccount) =>
    action(UPDATE_ACCOUNT, payload)

const actionDeleteAccount = (accountKey: string) =>
    action(DELETE, { accountKey })

const actionUnlock = () =>
    action(UNLOCK)

export {
    FETCH_REQUEST,
    ADD_REQUEST,
    UPDATE,
    UPDATE_ACCOUNT,
    DELETE,
    UNLOCK,
    actionRequestExchangeAccounts,
    actionRequestAddAccount,
    actionUpdate,
    actionUpdateAccount,
    actionDeleteAccount,
    actionUnlock
}