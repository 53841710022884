/**
 * Important notice
 * Unixtime is in seconds, not milliseconds
 * So pass unixtime to adapter and adapter/server convert to ms if necessary
 */
import { ICandle } from "./interfaces";
import sub from 'date-fns/sub'

const minutes = (n: number) => n * 60000

/**
 * Return resolution is timestamp
 * @param resolution 
 */
const getDistance = (resolution: any) => {
    switch (resolution) {
        case 1:
            return minutes(1)
        case 3:
            return minutes(3)
        case 5:
            return minutes(5)
        case 15:
            return minutes(15)
        case 30:
            return minutes(30)
        case 60:
            return minutes(60)
        case 120:
            return minutes(120)
        case 240:
            return minutes(240)
        case 360:
            return minutes(360)
        case 480:
            return minutes(480)
        case 720:
            return minutes(720)
        case 'D':
            return minutes(1440)
        case '1D':
            return minutes(1440)
        case '3D':
            return minutes(1440) * 3
        case 'W':
            return minutes(1440) * 7
        case '1W':
            return minutes(1440) * 7
        case 'M':
            return minutes(1440) * 31
        case '1M':
            return minutes(1440) * 31
        default:
            return minutes(1);
    }
}
/**
 * So we can compare candles only within resolution, like A - B < distance
 * Distance - resolution step
 * @param old - number
 * @param target - number 
 * @param resolution - UDF resolution
 */
const isOneCandle = (old: ICandle, target: ICandle, resolution: any): boolean => {
    if (old && target) {
        const distance: number = getDistance(resolution)
        return (old.open === target.open)
            && ((old.time - target.time) <= distance)
    }
    return false
}

/**
 * Query different amount of candles, depending on resolution
 */
const getDefaultDistance = (end: number, resolution: any) => {
    switch (resolution) {
        case '1W':
            return sub(end, { weeks: 10 }) // 20 weeks
        case '1M':
            return sub(end, { months: 12 }) // 12 months
        default:
            if (resolution <= 60) {
                return sub(end, { days: 5 }) // 5 days if less
            }
            return sub(end, { days: 12 }) // 2 month is more than 1 hour
    }
}

/**
 * Validates that values are sorted and unique by time
 * @param newData 
 * @param oldData 
 */
const mergeData = (newData: ICandle[], oldData: ICandle[]): ICandle[] => {
    const result: any = {}
    newData.forEach((candle: ICandle) => {
        result[candle.time] = candle
    })
    oldData.forEach((candle: ICandle) => {
        result[candle.time] = candle
    })
    return Object.keys(result).sort().map((key: any) => result[key])
}

export { isOneCandle, getDefaultDistance, mergeData }