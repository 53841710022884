import React from 'react'

interface IPriceFieldProps {
    label: string;
    value: any;
    disabled: boolean;
    currency: string;
    min?: string;
    max?: string;
    onChange: Function;
}

/**
 * Implements numeric TextField with label
 */
const PriceField = ({ label, value, disabled, currency, min, max, onChange }: IPriceFieldProps) => {
    const name = `field_${label}`

    const onValueChange = (e: any) => {
        if (onChange) {
            onChange(e.target.value)
        }
    }

    return <div className="create__order__field">
        <label htmlFor={name}>{label}</label>
        <input
            type="number"
            className={value > 0.0 ? 'create__order__field__input--filled': ''}
            value={value}
            name={name}
            step="0.01"
            min={min || "0"}
            max={max || ""}
            disabled={disabled}
            onChange={onValueChange}
        />
        <span className="create__order__field__postfix">{currency}</span>
    </div>
}

export default PriceField