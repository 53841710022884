/**
 * Implements slider for last 15 news
 */
import React, { useEffect, useState } from 'react'

import { ReactComponent as ArrowNext } from './arrow-right.svg'
import { INewsItem } from '../../../core/news'

interface IHomepageNewsProps {
    news: INewsItem[];
}
const HomepageNews = ({ news }: IHomepageNewsProps) => {
    const [item, setItem] = useState<INewsItem | null>(null)
    const [index, setIndex] = useState<number>(0)

    useEffect(() => {
        setItem(news[0])
        setIndex(0)
    }, [news])

    const onPrev = () => {
        const newIndex = index - 1
        const newItem = news[newIndex]
        if (newItem) {
            setItem(newItem)
            setIndex(newIndex)
        } else {
            setItem(news[news.length - 1])
            setIndex(news.length - 1)
        }
    }
    const onNext = () => {
        const newIndex = index + 1
        const newItem = news[newIndex]
        if (newItem) {
            setItem(newItem)
            setIndex(newIndex)
        } else {
            setItem(news[0])
            setIndex(0)
        }
    }
    const openLink = () => {
        if (item) {
            window.open(item?.url, "_blank")
        }
    }

    /**
     * Hide widgets when no news
     */
    if (news.length < 1) {
        return null
    }

    return (
        <div className="homepage__news">
            <h2>Crypto news</h2>
            <div className="homepage__news__card">
                <div className="homepage__news__card__title">News</div>
                <div className="homepage__news__card__counter">
                    <span>{index + 1}</span>
                    &nbsp;/&nbsp;{news.length}
                </div>
                <div
                    className="homepage__news__card__prev"
                    onClick={onPrev}
                    ><ArrowNext /></div>
                <div
                    className="homepage__news__card__next"
                    onClick={onNext}
                ><ArrowNext /></div>
                {item &&
                    (<h3 onClick={openLink}>{item.title}</h3>)
                }
            </div>
        </div>
    )
}

export default HomepageNews