/* eslint-disable no-restricted-syntax */
class HuobiDatafeed {
    // symbolsPairs: any;
    constructor(options = {}) {
        this.huobiHost = 'https://api.huobi.com';
        this.debug = options.debug || false;
        this.symbolsPairs = {};
        this.pairsSymbols = {};
        this.precisions = {};
        this.barsInterval = null;
    }

    HuobiSymbols() {
        return fetch(`${this.huobiHost}/v1/common/symbols`)
            .then(res => res.json())
            .then(json => json.data)
            .catch(() => []);
    }

    HuobiOHLCV(symbol, interval, limit) {
        const url = `${this.huobiHost}/market/history/kline?symbol=${symbol.toLowerCase()}&period=${interval}&size=${limit}`;
        return fetch(url)
            .then(res => res.json())
            .then(json => json.data)
            .catch(() => []);
    }

    HuobiLastCandle = (symbol, interval) => {
        const url = `${this.huobiHost}/market/history/kline?symbol=${symbol.toLowerCase()}&period=${interval}&size=1`;
        return fetch(url)
            .then(res => res.json())
            .then(json => json.data)
            .catch(() => []);
    }

    onReady = async (callback) => {
        try {
            const symbols = await this.HuobiSymbols();
            this.symbols = symbols.map(item => {
                const base = item['base-currency'];
                const quote = item['quote-currency'];
                const pair = `${quote.toUpperCase()}-${base.toUpperCase()}`;
                const symbol = `${base.toUpperCase()}${quote.toUpperCase()}`;
                this.symbolsPairs[symbol] = pair;
                this.pairsSymbols[pair] = symbol;
                const precision = 10 ** item['price-precision'];
                this.precisions[symbol] = precision;

                return {
                    name: symbol,
                    pair,
                    precision,
                    base,
                    quote
                };
            });
            callback({
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: true,
                supported_resolutions: [
                    '1', '5', '15', '30', '60', '1D', '1W'
                ]
            });
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols
            .filter((symbol) => symbol.name.toUpperCase().indexOf(input.toUpperCase()) >= 0)
            .map((symbol) => ({
                symbol: symbol.name.toUpperCase(),
                full_name: symbol.name.toUpperCase(),
                description: `${symbol.base.toUpperCase()} / ${symbol.quote.toUpperCase()}`,
                pair: `${symbol.quote.toUpperCase()}-${symbol.base.toUpperCase()}`,
                ticker: symbol.name
            })));
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);

        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

        // eslint-disable-next-line no-unused-vars
        const pricescale = ({ Summary }) => {
            const { Ask } = Summary;
            if (Ask > 100.0) return 100;
            if (Ask > 1.0) return 1000;
            return 100000000;
        };

        for (const symbol of this.symbols) {
            const pair = this.pairsSymbols[symbol.pair];
            if (pair === symbolName) {
                setTimeout(() => {
                    onSymbolResolvedCallback({
                        name: symbol.name.toUpperCase(),
                        description: `${symbol.base.toUpperCase()} / ${symbol.quote.toUpperCase()}`,
                        pair: `${symbol.quote.toUpperCase()}-${symbol.base.toUpperCase()}`,
                        ticker: symbol.pair.toUpperCase(),
                        session: '24x7',
                        minmov: 1,
                        pricescale: this.precisions[symbol.name],
                        timezone: 'UTC',
                        has_intraday: true,
                        has_daily: true,
                        has_weekly_and_monthly: true,
                        currency_code: symbol.quote
                    });
                }, 0);
                return;
            }
        }

        onResolveErrorCallback('not found');
    }

    getInterval = (resolution) => ({
        1: '1min',
        5: '5min',
        15: '15min',
        30: '30min',
        60: '60min',
        '1D': 'day',
        '7D': '1week',
        W: '1week',
        '30D': '1mon',
        D: '1day',
        Y: '1year'
    }[resolution]);

    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toGMTString());
            console.log('To:  ', to, new Date(to * 1000).toGMTString());
        }

        const interval = this.getInterval(resolution);

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }

            if (totalKlines.length !== 2000) {
                onHistoryCallback([], {
                    noData: true
                });
            } else {
                onHistoryCallback(totalKlines.reverse().map(kline => ({
                    time: (kline.id * 1000.0),
                    close: parseFloat(kline.close),
                    open: parseFloat(kline.open),
                    high: parseFloat(kline.high),
                    low: parseFloat(kline.low),
                    volume: parseFloat(kline.vol)
                })), {
                        noData: false
                    });
            }
        };

        // eslint-disable-next-line no-param-reassign
        // from *= 1000;
        // eslint-disable-next-line no-param-reassign
        // to *= 1000;

        this.HuobiOHLCV(symbolInfo.name, interval, 2000).then(klines => {
            totalKlines = totalKlines.concat(klines);
            finishKlines();
            return null;
        }).catch(err => {
            console.error(err);
            onErrorCallback('Some problem');
        });
    }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);
        const { name } = symbolInfo;

        const interval = this.getInterval(resolution);
        this.barsInterval = setInterval(() => {
            this.HuobiLastCandle(name, interval)
                .then((data) => {
                    const kline = data[data.length - 1];
                    const bar = {
                        time: (kline.id * 1000.0),
                        close: parseFloat(kline.close),
                        open: parseFloat(kline.open),
                        high: parseFloat(kline.high),
                        low: parseFloat(kline.low),
                        volume: parseFloat(kline.vol)
                    };
                    onRealtimeCallback(bar);
                    return null;
                }).catch((err) => {
                    console.error(err);
                });
        }, 30000);
    }

    unsubscribeBars(subscriberUID) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        clearInterval(this.barsInterval);
    }
}

export default HuobiDatafeed;
