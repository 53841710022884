/**
 * Implements a wrapper around heavy search component
 */
import React from 'react'
import ComplexSearch from './ComplexSearch'
import "./styles.scss"

const SearchModal = () =>
    (<div className="search__modal">
        <ComplexSearch />
    </div>)

export default SearchModal;
