import { IWidget } from "../helpers/widgets"
import {
  CLOSE,
  UPDATE,
  LAYOUT_UPDATE,
  MINIMIZE,
  ADD_LIST_DONE
} from "../actions/widgets"
import {
  defaultWidgets,
  advancedWidgets
} from "../helpers/defaultWidgets"
import { storeEvent, AnalyticsEvents } from "../core/analytics";

/**
 * Basic persistance
 */
const lsKey = 'kattana.widgets-layout-v3';

const mapWidgets = (input: IWidget[]): any => {
  const obj: any = {};
  input.forEach((w: IWidget) => {
    obj[w.i] = (w as any).klass
  })
  return obj
}

/**
 * If klass is defined eg. "pending add" than add id to mapping
 * @param input 
 * @param mapping 
 */
const verifyMapping = (input: IWidget[], mapping: any): any => {
  input.forEach((w: any) => {
    if (w.klass) {
      mapping[w.i] = w.klass
    }
  })
  return mapping
}

const getInitialState = () => {
  const item = localStorage.getItem(lsKey)
  if (item) {
    try {
      return JSON.parse(item)
    } catch (err) {
      console.error(err)
      //
      return {
        0: defaultWidgets,
        mapping: mapWidgets(defaultWidgets)
      }
    }
  }
  return {
    0: defaultWidgets,
    mapping: mapWidgets(defaultWidgets)
  }
}
const initialState = getInitialState()

/**
 * Accumulates prevH
 */
const prevHAccumulator: any = {}
/**
 * Implements a list of CURRENT WIDGETS
 */
const widgets = (state: any = initialState, action: any) => {
    switch (action.type) {
      case ADD_LIST_DONE:
        return addWidgetsFromList(state, action.payload.list, action.payload.tabId)
      case CLOSE:
        return closeWidget(state, action.payload.index, action.payload.tabId)
      case UPDATE:
        return state
      case MINIMIZE:
        return minimizeWidgetState(state, action.payload.index, action.payload.tabId)
      case LAYOUT_UPDATE:
        return onLayoutUpdate(state, action.payload.layout, action.payload.tabId)
      default:
        return state
    }
  }

/**
 * Handles layout update
 * @param layout 
 */
const onLayoutUpdate = (state: any, items: any, tabdId: number) => {
  const newState = ({
    ...state,
    [tabdId]: items,
    mapping: state.mapping
  })
  localStorage.setItem(lsKey, JSON.stringify(newState))
  return newState
}

/**
 * Remove widget by filtering it out
 * delete item from mapping
 */
const closeWidget = (state: any, index: number, tabId: number) => {
  const { mapping } = state
  const newItems = state[tabId].filter((w: IWidget, order: number) => {
    if (order === index) {
      delete mapping[w.i]
    }
    return (order !== index)
  })
  return ({
    ...state,
    [tabId]: newItems,
    mapping
  })
}

/**
 * Recieves array of widgets klasses
 * Returns array of widgets
 * @param list 
 */
const createAddWidgetsList = (list: any): IWidget[] => {
  const addList: any[] = filterList(list) // form list of items to add
  const addWidgetsList: IWidget[] = []
  addList.forEach((key: string) => {
    const toAdd = defaultWidgets.filter((widget: IWidget) => (widget as any).klass === key)[0]
    if (toAdd) {
      addWidgetsList.push(toAdd)
    } else {
      const additional = advancedWidgets.filter((widget: IWidget) => (widget as any).klass === key)[0]
      if (additional) {
        addWidgetsList.push(additional)
      }
    }
  })
  return addWidgetsList
}
/**
 * list is true-false hash
 */
const addWidgetsFromList = (state: any, list: any, tabId: number): any => {
  const addWidgetsList = createAddWidgetsList(list)
  storeEvent(AnalyticsEvents.addWidget, addWidgetsList) // send async request to the server
  const current = state[tabId] || []
  const newItems = [...current,...addWidgetsList]

  return ({
    ...state,
    [tabId]: newItems,
    mapping: verifyMapping(newItems, state.mapping)
  })
}

/**
 * Return only true keys
 * @param list 
 */
const filterList = (list: any) =>
  Object.keys(list)
    .map((key: string) => {
      const value = list[key]
      if (value) {
        return key
      }
      return null
    })
  .filter((key: string | null) => typeof key === 'string')

/**
 * Set height to 1 of that specific widget
 * @param state 
 * @param index 
 */
const minimizeWidgetState = (state: any, index: number, tabId: number): any =>
  ({
    ...state,
    [tabId]: minimizeWidget(state[tabId], index),
    mapping: state.mapping
  })
/**
 * Find widget in collection and minimize or maximize it
 * @param items 
 * @param index 
 */
const minimizeWidget = (items: IWidget[], index: number) =>
  items.map((widget: IWidget, order: number) => {
    if (order === index) {
      // Check if we have a previous state
      if (widget.h === 1 && prevHAccumulator[widget.i]) {
        return {
          ...widget,
          h: prevHAccumulator[widget.i]
        }
      }
      // Write event into accumulator
      prevHAccumulator[widget.i] = widget.h
      return {
        ...widget,
        h: 1
      }
    }
    return widget
  }).slice()

export default widgets