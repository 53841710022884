/**
 * Transporting tickers in array format is more effice
 * @param items
 */
const unpackTickers = (items: any[]) => {
  const result: any = {};
  items.forEach((item: any) => {
    const [
      key,
      high,
      low,
      bid,
      bidVolume,
      ask,
      askVolume,
      vwap,
      open,
      close,
      last,
      previousClose,
      change,
      percentage,
      baseVolume,
      quoteVolume,
      usdVolume
    ] = item;
    const symbol = key.split("|")[1];
    result[key] = {
      symbol,
      high,
      low,
      bid,
      bidVolume,
      ask,
      askVolume,
      vwap,
      open,
      close,
      last,
      previousClose,
      change,
      percentage,
      baseVolume,
      quoteVolume,
      usdVolume
    };
  });
  return result;
};

export default unpackTickers;