/**
 * Implements an input with dropdown
 * all the logic handled in reducer
 */
import React, { useState } from 'react'
import { ReactComponent as ArrowImage } from './arrow.svg'
import { ReactComponent as ArrowActiveImage } from './arrow_active.svg'
import "./styles.scss"

interface IBuyPriceProps {
    coin: string;
    positions: any;
    onUpdate: Function;
}
/**
 * Main widget
 */
const BuyPrice = (props: IBuyPriceProps) => {
    const { coin, positions, onUpdate } = props;
    const position = positions[coin] || {};

    const [value, setValue] = useState<string>(position.price || "0.0")
    const [active, setActive] = useState(false)
    const [inputCurrency, setInputCurrency] = useState(position.currency || "USD")

    /**
     * Decorators
     */
    const iconElement = active ?
        (<ArrowActiveImage className="buy_price__picker__arrow" />)
        :
        (<ArrowImage className="buy_price__picker__arrow" />)

    const pickerClassName = active ? 'buy_price__picker buy_price__picker--active' : 'buy_price__picker'
    return (
        <div className="buy_price">
            <input
                className="buy_price__field"
                type="number"
                min="0.0"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onUpdate(coin, value, inputCurrency);
                }}
            />
            {active && <div className="buy_price__backbone" onClick={() => setActive(false)}/>}
            <div className={pickerClassName} onClick={() => setActive(!active)}>
                {inputCurrency}
                {iconElement}
                {active && <InputCurrencySelector
                    selected={inputCurrency}
                    onSelect={(currency: string) => {
                        setInputCurrency(currency);
                        onUpdate(coin, value, inputCurrency);
                    }}
                />}
            </div>
        </div>
    )
}

/**
 * Dropdown list
 */
const InputCurrencySelector = ({ selected, onSelect }: any) => {
    const list = ['ETH', 'BTC', 'USD'];
    return (<div className="buy_price__picker__list">
        {list.map((item: string) => {
            const itemClassName = selected === item ? 'buy_price__picker__list__item--active' : ''
            return (<div
                key={item}
                onClick={() => onSelect(item)}
                className={`buy_price__picker__list__item ${itemClassName}`}>{item}</div>)
        })}
    </div>)
}

export default BuyPrice