/**
 * Markets reducer
 * state is key-value hash
 */
import { UPDATE } from "../actions/markets"

const markets = (state = {}, action: any) => {
  switch (action.type) {
    case UPDATE:
      return action.payload
    default:
      return state
  }
}

export default markets
