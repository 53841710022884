import axios from 'axios'
import { backend } from "../../../core/endpoints"

/**
 * Convert markets hash to array of symbols
 * @param {*} markets 
 */
const convertMarketsToSymbols = (markets) =>
    Object.keys(markets).map((key) => {
        const [ _exchange, pair ] = key.split('|')
        const [ baseAsset, quoteAsset ] = pair.split('/')
        const { precision } = markets[key]
        return ({
            symbol: `${baseAsset}${quoteAsset}`,
            baseAsset,
            quoteAsset,
            pricescale: 10 ** precision.price 
        })
    })
class BinanceDatafeed {
    constructor(options) {
        this.debug = options.debug || false;
        this.barsInterval = null;
        /**
         * Realtime candles
         */
        this.lastBar = null;
        this.lastPriceFn = () => {};
        this.symbols = convertMarketsToSymbols(options.markets || [])
        this.subscriptionMapping = {};
        // this.klines = [];
    }

    binanceServerTime() {
        return fetch(`${backend}/binance/time`)
            .then(res => res.json())
            .then(json => json.data.serverTime)
            .catch(() => []);
    }

    binanceKlines = async (symbol, interval, startTime, endTime, limit) => {
        try {
            const { data } = await axios(`${backend}/binance/${symbol}/chart/${interval}/${startTime}/${endTime}/${limit}`)
            return data.data
        } catch (err) {
            console.warn(err)
            return []
        }
    }

    binanceLastCandle(symbol, interval) {
        const url = `${backend}/binance/${symbol}/candle/${interval}`;
        return fetch(url)
            .then(res => res.json())
            .then(json => json.data)
            .catch(() => []);
    }

    onReady = (callback) => {
        try {
            callback({
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: true,
                supported_resolutions: [
                    '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720', '1D', '3D', '1W', '1M'
                ]
            });
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols
                .filter((symbol) => symbol.symbol.indexOf(input) >= 0)
                .map((symbol) => ({
                    symbol: symbol.symbol,
                    full_name: symbol.symbol,
                    description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
                    pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
                    ticker: symbol.symbol
                })));
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);

        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

        for (const symbol of this.symbols) {
            if (symbol.symbol === symbolName) {
                setTimeout(() => {
                    onSymbolResolvedCallback({
                        name: symbol.symbol,
                        description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
                        pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
                        ticker: symbol.symbol,
                        session: '24x7',
                        minmov: 1,
                        pricescale: symbol.pricescale,
                        timezone: 'UTC',
                        has_intraday: true,
                        has_daily: true,
                        has_weekly_and_monthly: true,
                        currency_code: symbol.quoteAsset
                    });
                }, 0);
                return;
            }
        }

        onResolveErrorCallback('not found');
    }

    getInterval = (resolution) => {
        const intervals = {
            1: '1m',
            3: '3m',
            5: '5m',
            15: '15m',
            30: '30m',
            60: '1h',
            120: '2h',
            240: '4h',
            360: '6h',
            480: '8h',
            720: '12h',
            D: '1d',
            '1D': '1d',
            '3D': '3d',
            W: '1w',
            '1W': '1w',
            M: '1M',
            '1M': '1M',
        };
        return intervals[resolution];
    }

    /**
     * Convert Binance's kline to candle
     * @param {*} kline 
     */
    klineToCandle = (kline) => ({
        time: kline[0],
        close: parseFloat(kline[4]),
        open: parseFloat(kline[1]),
        high: parseFloat(kline[2]),
        low: parseFloat(kline[3]),
        volume: parseFloat(kline[5])
    })

    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toGMTString());
            console.log('To:  ', to, new Date(to * 1000).toGMTString());
        }

        const interval = this.getInterval(resolution);

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }

            if (totalKlines.length === 0) {
                onHistoryCallback([], {
                    noData: true
                });
            } else {
                const result = totalKlines.map(this.klineToCandle)
                // this.klines = result;
                // this.setLastCandle(result[result.length - 1])
                onHistoryCallback(result, { noData: false })
            }
        };

        // eslint-disable-next-line no-shadow
        const getKlines = (from, to) => {
            this.binanceKlines(symbolInfo.name, interval, from, to, 500).then(klines => {
                totalKlines = totalKlines.concat(klines);
                if (klines.length === 500) {
                    // eslint-disable-next-line no-param-reassign
                    from = klines[klines.length - 1][0] + 1;
                    getKlines(from, to);
                } else {
                    finishKlines();
                }
                return null;
            }).catch(err => {
                console.error(err);
                onErrorCallback('Some problem');
            });
        };

        // eslint-disable-next-line no-param-reassign
        from *= 1000;
        // eslint-disable-next-line no-param-reassign
        to *= 1000;

        getKlines(from, to);
    }

    // setLastCandle(lastBar) {
    //     this.lastBar = lastBar
    // }
    /**
     * Handle last price event
     * @param {*} event 
     */
    // onLastPrice(onRealtimeCallback, price, lastBar) {
    //     if (lastBar) {
    //         console.log(lastBar.time)
    //         lastBar.close = price;
    //         onRealtimeCallback(lastBar);
    //     }
    // }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);

        // const { name } = symbolInfo;
        // const interval = this.getInterval(resolution);

        // this.lastPriceFn = (event) => {
        //     this.onLastPrice(onRealtimeCallback, event.detail, this.lastBar)
        // }
            
        // const subName = `lastPrice_binance_${name}`
        // this.subscriptionMapping[subscriberUID] = subName
        // window.addEventListener(subName, this.lastPriceFn)

        // this.barsInterval = setInterval(() => {
        //     this.binanceLastCandle(name, interval)
        //         .then((data) => {
        //             const kline = data[0];
        //             console.log('binanceLastCandle', data, kline)
        //             const lastBar = this.klineToCandle(kline)
        //             this.setLastCandle(lastBar)
        //             onRealtimeCallback(lastBar)
        //         }).catch((err) => {
        //             console.error(err);
        //         });
        // }, 15000);
    }

    unsubscribeBars(subscriberUID) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        // clearInterval(this.barsInterval);

        this.lastBar = null;
        // window.removeEventListener(this.subscriptionMapping[subscriberUID], this.lastPriceFn);
    }

    getServerTime = async (callback) => {
        try {
            const time = await this.binanceServerTime();
            callback(Math.floor(time / 1000));
        } catch (err) {
            console.error(err);
        }
    }
}

export default BinanceDatafeed;
