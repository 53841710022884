/**
 * This module implements a premium features permissions
 */
import { takeLatest, put } from "redux-saga/effects";
import { actionCloseModal } from "../actions/modal";
// import ModalTypes from "../helpers/modals";
import { ACTION_NEW_TAB, actionNewTabDone } from "../actions/tabs";
// import { TABS_NONPREMIUM_MAX } from "../helpers/premium";
import { ADD_LIST, actionAddWidgetsListDone } from "../actions/widgets";

/**
 * Why it here? Heavily depends on premium functionality
 * Pass premium boolean flag into reducer
 * @param action
 */
function* onNewTabRequestFn(action: any): any {
    // const { premium } = yield select(state => state.accounts)
    // const tabs = yield select(state => state.tabs)
    // if (!premium) {
    //     if (tabs.length + 1 > TABS_NONPREMIUM_MAX) {
    //         yield put(actionShowModal(ModalTypes.premium_feature_tabs, {}))
    //     }
    // }
    // yield put(actionNewTabDone(premium))
    yield put(actionNewTabDone(true))
}

/**
 * Can user add multiple widgets?
 */
function* onNewWidgetsListRequested(action: any) {
    /**
     * Get list on new widgets
     */
    const { payload } = action
    const { list, tabId } = payload
    yield put(actionCloseModal())
    /**
     * Get list of old widgets
     */
    yield put(actionAddWidgetsListDone(list, tabId))
    // const { premium } = yield select(state => state.accounts)
    // if (premium) {
    //     yield put(actionAddWidgetsListDone(list, tabId))
    // } else {
    //     const widgetsState = yield select(state => state.widgets)
    //     const currentWidgets: any[] = widgetsState[tabId]
    //     yield put(actionAddWidgetsListDone(list, tabId))
    //     if (currentWidgets) {
    //         const currentNamesMap = currentWidgets.map((widget: any) => widgetsState.mapping[widget.i])
    //         const listNamesMap = Object.keys(list).map((key: any) => list[key] ? key : null).filter((item: any) => !!item)
    //         const intersection = currentNamesMap.filter(value => listNamesMap.includes(value))
    //         if (intersection.length > 0) {
    //             yield put(actionShowModal(ModalTypes.premium_widgets, {}))
    //         } else {
    //         yield put(actionAddWidgetsListDone(list, tabId))
    //         }
    //     } else {
    //         yield put(actionAddWidgetsListDone(list, tabId))
    //     }
    // }
}

export default function* premiumSaga() {
    yield takeLatest(ACTION_NEW_TAB, onNewTabRequestFn);
    yield takeLatest(ADD_LIST, onNewWidgetsListRequested)
}
  