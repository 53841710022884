/**
 * Calculate P/L
 */
import React from 'react'

interface IPNLProps {
    coin: string;
    positions: any;
    rates: any;
    amount: number;
}

const ProfitLoss = ({ coin, positions, rates, amount }: IPNLProps) => {
    const position = positions[coin];
    if (!position) {
        return (<div> $ 0.0 (0.0%)</div>)
    }
    if (position.price === 0.0 || position.price === 0) {
        return (<div> $ 0.0 (0.0%)</div>)
    }
    /**
     * Position value
     * a) get value of position by price / rate
     * b) amount * value - current value of position in dollars
     */
    const buyRate = position.currency === 'USD' ? 1.0 : position.price * rates[position.currency]; // 0.03 * BTC[rate]
    const assetsBuy = amount * buyRate; // USD value
    /**
     * Market value
     * a) get rate from hash
     * b) amount * value
     */
    const keyRate = rates[coin] || 1.0; // Market USD value
    const assetsMarket = amount * keyRate;
    // P/L
    const absoluteDifference = (assetsMarket - assetsBuy).toFixed(6);
    const percentDifference = (((assetsMarket - assetsBuy) / assetsBuy) * 100);
    const isPositive = percentDifference > 0.0
    const isFinite = Number.isFinite(percentDifference)
    return (
        <div className={isPositive ? 'positions__panel__balances__item__col--profitloss-green' : ''}>
            $ {absoluteDifference} ({ isFinite ? percentDifference.toFixed(2) : '0'}%)
        </div>
    )
}

export default ProfitLoss