import { ACTION_CLEAR, ACTION_ADD_TRADE, ACTION_UPDATE_HISTORY } from "../actions/history"

const history = (state = [], action: any) => {
    switch (action.type) {
      case ACTION_CLEAR:
        return []
      case ACTION_ADD_TRADE:
        return addHistoryItem(state, action.payload)
      case ACTION_UPDATE_HISTORY:
        return action.payload
      default:
        return state
    }
  }

const addHistoryItem = (state: any, payload: any) => {
  if (state.length > 100) {
    state.length = 99
  }
  return [...payload, ...state]
}

export const getLastTradeFromHistory = (history: any[]) => {
  if (history.length > 0) {
    return history[0]
  }
  return [0.0, false]
}

export default history