import React, { useState } from 'react'
import { connect } from 'react-redux'
import Tabs from './tabs'
import BalancesPanel from './BalancesPanel'
import OrdersPanel from './OrdersPanel'
// import NotificationsPanel from './NotificationsPanel'
import { ReactComponent as RefreshIcon } from './refresh.svg'
import { IOrdersState, IOrder } from '../../helpers/tradingModes'
import { actionRefreshAccountData } from '../../actions/backend'
import { actionCancelOrders } from '../../actions/orders'
import MetamaskPanel from './Metamask'

interface IPositionsOrdersProps {
    orders: IOrdersState;
    updating: boolean;
    mode: string;
    height: number;
    signedin: boolean;
    tickers: any;
    actionRefreshAccountData: Function;
    actionCancelOrders: Function;
}

const PositionsOrders = (props: IPositionsOrdersProps) => {
    const { orders, mode, height, signedin, updating, tickers } = props
    const [ tab, setTab ] = useState(0)
    const [ selectedOrders, setSelectedOrders ] = useState<string[]>([])
    const [ open, closed ] = getOrdersForMode(orders, mode)
    const canCancelOrders = (selectedOrders.length > 0) && (tab === 1)

    /**
     * Buttons events
     */
    const onSelectOrder = (orderId: string) => {
        if (selectedOrders.includes(orderId)) {
            setSelectedOrders(selectedOrders.filter((id: string) => id !== orderId))
        } else {
            setSelectedOrders([...selectedOrders, orderId])
        }
    }
    const onCancelOrders = () => {
        props.actionCancelOrders(collectOrders(open, selectedOrders))
        setSelectedOrders([])
    }

    const isMetamask = (typeof (window as any)["ethereum"] !== 'undefined');
    const tabs = isMetamask ?
        ["Open Positions","Open Orders","Trading History", "ETH Wallet"] :
        ["Open Positions","Open Orders","Trading History"]

    return (
        <>
            <Tabs
                tabs={tabs}
                selected={tab}
                onSelect={setTab}
            />
            {(tab === 0) && <BalancesPanel />}
            {(tab === 1) && <OrdersPanel
                selected={selectedOrders}
                onSelect={onSelectOrder}
                tickers={tickers}
                data={open}
                height={height}
                signedin={signedin}
            />}
            {(tab === 2) && <OrdersPanel
                selected={selectedOrders}
                onSelect={onSelectOrder}
                data={closed}
                height={height}
                signedin={signedin}
            />}
            {(tab === 3) && <MetamaskPanel
                height={height}
            />}
            {/* {(tab === 3) && <NotificationsPanel
                signedin={signedin}
                height={height}
            />} */}
            <div className="positions__bottom" >
                <span
                    onClick={() => props.actionRefreshAccountData()}
                    className={`${updating ? 'positions__bottom__btn positions__bottom__btn--updating' : 'positions__bottom__btn'}`}
                ><RefreshIcon />REFRESH</span>
                {canCancelOrders && (<span
                    onClick={onCancelOrders}
                    className="positions__bottom__btn"
                >CANCEL ORDERS</span>)}
            </div>
        </>
    )
}

export interface ICancelOrderPayload {
    exchange: string;
    id: string;
    symbol: string;
} 
/**
 * Collects order by ids to cancel order format
 * @param open 
 * @param ids 
 */
const collectOrders = (open: any[], ids: string[]): ICancelOrderPayload[] =>
    open
        .filter((order: any) => ids.includes(order.id))
        .map((order: any) =>
            ({
                exchange: order.exchange,
                id: order.id,
                symbol: order.symbol
            })
        )

/**
 * Different structures used for margin and spot orders
 */
const getOrdersForMode = (orders: any, mode: string) => {
    const collection = (mode === "margin") ?  orders.margin : orders.spot
    const open: IOrder[] = []
    const closed: IOrder[] = []
    collection.forEach((order: IOrder) => {
        if (['active','open'].includes(order.status)) {
            open.push(order)
        } else {
            closed.push(order)
        }
    });
    return [ open, closed ]
}

const mapStateToProps = (state: any) => ({
    orders: state.orders,
    updating: state.orders.updating,
    mode: state.current.mode,
    signedin: state.accounts.signedin,
    tickers: state.tickers
})

export default connect(mapStateToProps, { actionRefreshAccountData, actionCancelOrders })(PositionsOrders)