/**
 * Implements OrderBook component
 */
import React from 'react'
import { connect } from 'react-redux'
import ErrorBoundary from 'react-error-boundary'
import Delimeter from './Delimeter'
import Bookside from './Bookside'
import "./styles.scss"
import { getOrders } from '../../selectors'

interface IOrderbookProps {
    width: number;
    height: number;
    bids: any;
    asks: any;
    current: any;
    markets: any;
    orders: any;
}

const OrderBook = (props: IOrderbookProps) => {
    const { height, bids, asks, current, markets, orders } = props;
    const { exchange, base, quote } = current
    const key = `${exchange}|${base}/${quote}`;
    const price = markets[key] ? markets[key].precision.price : 4;

    const bookHeight = (height - 44 - 24) / 2;
    return (
        <ErrorBoundary>
            <div className="orderbook__header">
                <span className="orderbook__header__left">Price</span>
                <span className="orderbook__header__right">Quantity</span>
                <span className="orderbook__header__right">Total</span>
            </div>
            <Bookside
                height={bookHeight}
                side="ask"
                data={asks}
                orders={orders.ask}
            />
            <Delimeter
                base={base}
                precision={price}
            />
            <Bookside
                height={bookHeight}
                side="bid"
                data={bids}
                orders={orders.bid}
            />
        </ErrorBoundary>
    )
}

const mapStateToProps = (state: any) =>
    ({
        asks: state.orderbook.asks,
        bids: state.orderbook.bids,
        current: state.current,
        markets: state.markets,
        orders: getOrders(state)
    })

export default connect(mapStateToProps)(OrderBook);