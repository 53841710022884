/**
 * Call SWITCH for current when tab selected
 */

import { put, takeLatest, all, call, select } from 'redux-saga/effects'
import { actionUpdateTickers } from '../actions/tickers';
import { actionUpdateRates } from '../actions/rates';
import { fetchRates, fetchTickers, fetchMarkets, fetchBalances, fetchOrders, cancelOrder } from '../core/tradingData';
import { actionUpdateMarkets } from '../actions/markets';
import { actionUpdateBalances } from '../actions/balances';
import { actionUpdateOrders, CANCEL, actionUpdateOrdersRequested, actionUpdateOrdersDone } from '../actions/orders';
import { REFRESH, CONNECT } from '../actions/backend';
import { ICancelOrderPayload } from '../components/PositionsOrders';
import { tryReconnect } from '../core/wst';
import { requestAccountData, requestExchangeAccounts } from '../core/accountData';
import { actionAccountUpdate, actionSignOut } from '../actions/accounts';
import { StorageKeys } from '../helpers/localStorage';
import { actionRefreshWatchlist } from '../actions/watchlist';
import { actionUpdate } from '../actions/trading_accounts';
import { EXCHANGES_LIST, TICKERS_EXCHANGES_LIST } from '../helpers/exchanges';

/**
 * Fetch necessary trade data
 * Makes no sence to load orders/balances for public
 */
function* fetchData() {
    const signedin = localStorage.getItem(StorageKeys.session_id)
    tryReconnect()
    if (signedin) {
        yield fetchDataAuthenticated()
    } else {
        yield fetchDataPublic()
    }
}

function* fetchDataAuthenticated(): any {
    // Fetch and update accounts
    const { watchlist, user, status } = yield call(requestAccountData)
    // error means not authenticated, so remove token and sign out
    if (status === "error") {
        yield put(actionSignOut())
        yield fetchDataPublic() // acts as not authenticated
    } else {
        yield all([
            yield put(actionAccountUpdate(user)),
            yield put(actionRefreshWatchlist(watchlist ? JSON.parse(watchlist) : []))
        ])

        const { token, account_secret } = user
        const { base, quote } = yield select(state => state.current)

        const balances = yield call(fetchBalances, token, account_secret)
        yield put(actionUpdateBalances(balances))

        const exchangeNames = yield select(state => Object.keys(state.balances.exchanges)) || EXCHANGES_LIST
        const symbol = `${base}/${quote}`
        // reference: https://redux-saga.js.org/docs/advanced/RunningTasksInParallel.html
        const [rates, tickers, markets, orders, trading_accounts] = yield all([
            call(fetchRates),
            call(fetchTickers, exchangeNames),
            call(fetchMarkets, exchangeNames),
            call(fetchOrders, token, account_secret, symbol),
            call(requestExchangeAccounts, token, account_secret)
        ])
        yield all([
            yield put(actionUpdateRates(rates)),
            yield put(actionUpdateTickers(tickers)),
            yield put(actionUpdateMarkets(markets)),
            yield put(actionUpdateOrders(orders)),
            yield put(actionUpdate(trading_accounts))
        ])
    }
}

function* fetchDataPublic(): any {
    const [rates, tickers, markets] = yield all([
        call(fetchRates),
        call(fetchTickers, TICKERS_EXCHANGES_LIST),
        call(fetchMarkets, TICKERS_EXCHANGES_LIST),
    ])
    yield all([
        yield put(actionUpdateRates(rates)),
        yield put(actionUpdateTickers(tickers)),
        yield put(actionUpdateMarkets(markets))
    ])
}

/**
 * Fetches balances and orders
 */
export function* onRefreshPositionsOrders(): any {
    const { token, account_secret } = yield select(state => state.accounts)
    const { base, quote } = yield select(state => state.current)
    const symbol = `${base}/${quote}`

    yield put(actionUpdateOrdersRequested())

    try {
        const [balances, orders] = yield all([
            call(fetchBalances, token, account_secret),
            call(fetchOrders, token, account_secret, symbol)
        ])
        yield all([
            yield put(actionUpdateBalances(balances)),
            yield put(actionUpdateOrders(orders))
        ])
    } catch (err) {
        console.error(err)
        yield put(actionUpdateOrdersDone())
    }
}

/**
 * Fetches balances and orders for a certain symbol
 */
export function* onRefreshOrdersForSymbol(symbol: string): any {
    const { token, account_secret } = yield select(state => state.accounts)
    yield put(actionUpdateOrdersRequested())

    try {
        const orders = yield fetchOrders(token, account_secret, symbol)
        yield put(actionUpdateOrders(orders))
    } catch (err) {
        console.error(err)
        yield put(actionUpdateOrdersDone())
    }
}

/**
 * User requested to cancel multiple orders
 * @param action 
 */
function* onCancelOrders(action: any): any {
    const orderCollections: any[] = action.payload
    const { token, account_secret } = yield select(state => state.accounts)

    yield all(orderCollections.map((collection: ICancelOrderPayload) =>
        call(cancelOrder, token, account_secret, collection)
    ))

    const uniqueSymbols = Array.from(
        new Set(
            orderCollections.map((c: ICancelOrderPayload) => c.symbol)
        ))

    yield all(uniqueSymbols.map((symbol: string) =>
        call(onRefreshOrdersForSymbol, symbol)
    ))

    yield onRefreshPositionsOrders()
}

function* fetchTickersAndRates() {
    yield takeLatest(CONNECT, fetchData); // called on start
    yield takeLatest(REFRESH, onRefreshPositionsOrders);
    yield takeLatest(CANCEL, onCancelOrders);
}
  
export default fetchTickersAndRates;