import { action } from 'typesafe-actions';
import ModalTypes from '../helpers/modals';

const entity = `modal`;

const SHOW = `${entity}/SHOW`
const HIDE = `${entity}/HIDE`
const HIDE_IF = `${entity}/HIDE_IF`

const actionShowModal = (modalName: ModalTypes, props: any) =>
    action(SHOW, { modalName, props });

const actionOpenTickers = () =>
    action(SHOW, { modalName: ModalTypes.tickers, props: {}})

const actionOpenAddWidget = () =>
    action(SHOW, { modalName: ModalTypes.add_widget, props: {}})

const actionOpenLogin = () =>
    action(SHOW, { modalName: ModalTypes.login, props: {}})

const actionOpenReconnecting = () =>
    action(SHOW, { modalName: ModalTypes.reconnecting, props: {}})

const actionHideReconnecting = () =>
    action(HIDE_IF, { modalName: ModalTypes.reconnecting })

const actionCloseModal = () => action(HIDE, {})

export {
    SHOW,
    HIDE,
    HIDE_IF,
    actionShowModal,
    actionOpenTickers,
    actionOpenAddWidget,
    actionOpenLogin,
    actionOpenReconnecting,
    actionHideReconnecting,
    actionCloseModal
}