/* eslint-disable no-restricted-syntax */
class OkexDatafeed {
    // symbolsPairs: any;
    constructor(options = {}) {
        this.OKEXHost = 'https://www.okex.com';
        this.debug = options.debug || false;
        this.symbolsPairs = {};
        this.pairsSymbols = {};
        this.precisions = {};
        this.barsInterval = null;
    }

    OKEXSymbols() {
        return fetch(`${this.OKEXHost}/api/spot/v3/instruments`)
            .then(res => res.json())
            .then(json => json)
            .catch(() => []);
    }

    // eslint-disable-next-line no-unused-vars
    OKEXOHLCV(symbol, interval, from, to) {
        const url = `${this.OKEXHost}/api/spot/v3/instruments/${symbol.toLowerCase()}/candles?granularity=${interval}&start=${new Date(from).toISOString()}&end=${new Date(to).toISOString()}`;
        return fetch(url)
            .then(res => res.json())
            .then(json => json)
            .catch(() => []);
    }

    OKEXLastCandle = (symbol, interval) => {
        const url = `${this.OKEXHost}/api/spot/v3/instruments/${symbol.toLowerCase()}/candles?&granularity=${interval}&size=1`;
        return fetch(url)
            .then(res => res.json())
            .then(json => json)
            .catch(() => []);
    }

    onReady = async (callback) => {
        try {
            const symbols = await this.OKEXSymbols();
            this.symbols = symbols.map(item => {
                const base = item.base_currency;
                const quote = item.quote_currency;
                const pair = `${quote.toUpperCase()}-${base.toUpperCase()}`;
                const symbol = `${base.toUpperCase()}${quote.toUpperCase()}`;
                const okexSymbol = `${base.toUpperCase()}-${quote.toUpperCase()}`;
                this.symbolsPairs[symbol] = pair;
                this.pairsSymbols[pair] = symbol;
                const precision = Math.round(1 / item.tick_size);
                this.precisions[symbol] = precision;

                return {
                    name: symbol,
                    okexSymbol,
                    pair,
                    precision,
                    base,
                    quote
                };
            });
            callback({
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: true,
                supported_resolutions: [
                    '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W'
                ]
            });
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols
            .filter((symbol) => symbol.name.toUpperCase().indexOf(input.toUpperCase()) >= 0)
            .map((symbol) => ({
                symbol: symbol.name.toUpperCase(),
                okexSymbol: symbol.okexSymbol,
                full_name: symbol.name.toUpperCase(),
                description: `${symbol.base.toUpperCase()} / ${symbol.quote.toUpperCase()}`,
                pair: `${symbol.quote.toUpperCase()}-${symbol.base.toUpperCase()}`,
                ticker: symbol.name
            })));
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);

        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

        for (const symbol of this.symbols) {
            if (symbol.name === symbolName) {
                setTimeout(() => {
                    onSymbolResolvedCallback({
                        name: symbol.name.toUpperCase(),
                        okexSymbol: symbol.okexSymbol,
                        description: `${symbol.base.toUpperCase()} / ${symbol.quote.toUpperCase()}`,
                        pair: `${symbol.quote.toUpperCase()}-${symbol.base.toUpperCase()}`,
                        ticker: symbol.pair.toUpperCase(),
                        session: '24x7',
                        minmov: 1,
                        pricescale: this.precisions[symbol.name],
                        timezone: 'UTC',
                        has_intraday: true,
                        has_daily: true,
                        has_weekly_and_monthly: true,
                        currency_code: symbol.quote
                    });
                }, 0);
                return;
            }
        }

        onResolveErrorCallback('not found');
    }

    getInterval = (resolution) => ({
        1: 60,
        3: 180,
        5: 300,
        15: 900,
        30: 1800,
        60: 3600,
        120: 7200,
        240: 14400,
        360: 21600,
        720: 43200,
        '1D': 86400,
        '7D': 604800,
        W: 604800,
        D: 86400
    }[resolution]);

    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toGMTString());
            console.log('To:  ', to, new Date(to * 1000).toGMTString());
        }

        const interval = this.getInterval(resolution);

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }

            if (totalKlines.length < 1) {
                onHistoryCallback([], {
                    noData: true
                });
            } else {
                onHistoryCallback(totalKlines.reverse().map(kline => ({
                    time: Date.parse(kline[0]),
                    close: parseFloat(kline[4]),
                    open: parseFloat(kline[1]),
                    high: parseFloat(kline[2]),
                    low: parseFloat(kline[3]),
                    volume: parseFloat(kline[5])
                })), {
                        noData: false
                    });
            }
        };

        // eslint-disable-next-line no-param-reassign
        from *= 1000;
        // eslint-disable-next-line no-param-reassign
        to *= 1000;

        this.OKEXOHLCV(symbolInfo.okexSymbol, interval, from, to).then(klines => {
            totalKlines = totalKlines.concat(klines);
            finishKlines();
            return null;
        }).catch(err => {
            console.error(err);
            onErrorCallback('Some problem');
        });
    }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);
        const { okexSymbol } = symbolInfo;

        const interval = this.getInterval(resolution);
        this.barsInterval = setInterval(() => {
            this.OKEXLastCandle(okexSymbol, interval)
                .then((data) => {
                    const kline = data[data.length - 1];
                    const bar = {
                        time: Date.parse(kline[0]),
                        close: parseFloat(kline[4]),
                        open: parseFloat(kline[1]),
                        high: parseFloat(kline[2]),
                        low: parseFloat(kline[3]),
                        volume: parseFloat(kline[5])
                    };
                    onRealtimeCallback(bar);
                    return null;
                }).catch((err) => {
                    console.error(err);
                });
        }, 30000);
    }

    unsubscribeBars(subscriberUID) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        clearInterval(this.barsInterval);
    }
}

export default OkexDatafeed;
