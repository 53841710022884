/**
 * Implements bid/ask table
 */
import React from 'react'
import { formatAmount } from '../../helpers/formatters'
import { IOrder } from '../../helpers/tradingModes';
import { ReactComponent as BulletAsk } from './ask.svg'
import { ReactComponent as BulletBid } from './bid.svg'

interface PriceAmount {
    [key: string]: string;
 } 
interface IBooksideProps {
    height: number;
    side: string;
    data: PriceAmount;
    orders: IOrder[];
}
const lineHeight = 18; // 16 + 2 margin

const Bookside = (props: IBooksideProps) => {
    const { data, height, side, orders } = props
    const priceLevels = getLevels(height, data, side)
    const orderPrices = orders.map((order: IOrder) => order.price)

    return (
        <div style={{ height }} className={`bookside bookside--${side}`}>
            {priceLevels.map((price: string) => {
                const precision = 2;
                const numericQuantity = parseFloat(data[price]);
                const numericPrice = parseFloat(price);
                const total = (numericPrice * numericQuantity).toFixed(precision);
                const hasOrder = orderPrices.includes(numericPrice)
                const Bullet = side ? BulletAsk : BulletBid
                const className = hasOrder ? "bookside__row__withOrder" : "bookside__row"
                return (
                    <div key={price} className={className}>
                        <div className={`${className}__price`}>{price} {hasOrder && <Bullet />}</div>
                        <div className={`${className}__quantity`}>{formatAmount(numericQuantity)}</div>
                        <div className={`${className}__total`}>{total}</div>
                    </div>
                );
            })}
        </div>
    )
}

const ascSort = (a: string, b: string) => parseFloat(a) - parseFloat(b)
const descSort = (a: string, b: string) => parseFloat(b) - parseFloat(a)

const getLevels = (height: number, data: PriceAmount, side: string) => {
    const linesCount = Math.round(height / lineHeight);
    const sortFn = (side === "asks") ? ascSort : descSort

    return Object.keys(data || {})
        .sort(sortFn)
        .filter((_, index: number) => index < linesCount)
}

export default Bookside
