/**
 * Facade before TradingView component
 */
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
// import TradingViewChart from '../TradingViewChart'
import { IOrder } from '../../helpers/tradingModes'
import { actionCancelOrders } from '../../actions/orders'
import { IChartProps, chartMapStateToProps, chartFilterOrders } from './genericChart'
import LoadingMarkets from './chart/LoadingMarkets'
const TradingViewChart = React.lazy(() => import('../TradingViewChart'))

const Chart = (props: IChartProps) => {
    const { exchange, base, quote, mode, width, height, orders, markets } = props
    if ((!exchange || !base || !quote) || (exchange === 'bitmex') || width === 0) {
        return <div/>
    }
    const compact = width < (window.innerWidth * 0.25)

    /**
     * Show loader unless we got all markets
     */
    const anyMarkets = Object.keys(props.markets).length > 0
    if (!anyMarkets) {
        return <LoadingMarkets />
    }

    return (
        <Suspense fallback={null}>
            <TradingViewChart
                embed={false}
                intervals={[]}
                exchange={exchange}
                pair={`${quote}-${base}`}
                orders={chartFilterOrders(exchange, base, quote, mode, orders)}
                rawOrders={[]}
                id={Math.random()}
                canChangePair={true}
                onPairChanged={() => {}}
                onCancelOrder={(order: IOrder) => props.actionCancelOrders([order])}
                width={width}
                height={height}
                compact={compact}
                markets={markets}
            />
        </Suspense>
    )
}

export default connect(chartMapStateToProps, { actionCancelOrders })(Chart);
