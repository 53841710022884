import React, { useState } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as LoadingIcon } from './loader.svg'
import { requestForgotPassword } from '../../../core/accountData'
import { actionNavigate } from '../../../actions/navigation'
import { actionCreateNotification } from '../../../actions/notifications'
import "./styles.scss"
import { PageType } from '../../../helpers/pageTypes'


interface IForgotPasswordPageProps {
    actionNavigate: Function;
    actionCreateNotification: Function;
}

const ForgotPasswordPage = (props: IForgotPasswordPageProps) => {
    const [email, onChange] = useState("")
    const [lock, setLock] = useState(false)

    const onSubmit = async () => {
        if (!lock) {
            setLock(true)
            const status = await requestForgotPassword(email)

            if (status) {
                props.actionCreateNotification({ message: "We've reset your password, please check your mailbox" })
                props.actionNavigate(PageType.homepage)
            } else {
                props.actionCreateNotification({ message: "Could not reset your password, email not found" })
            }

            setLock(false)
        } else {
            props.actionCreateNotification({ message: "Request already in process" })
        }
    }

    return (
        <div className="forgot_password__page">
            <form className="forgot_password__page__header" onSubmit={onSubmit}>
                <h1>Password recovery page</h1>
                <span>Enter your email and we will send a link to reset password</span>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => onChange(e.target.value)}
                />
                <div
                    className="forgot_password__page__btn"
                    onClick={onSubmit}
                >
                    {!lock && <>Submit</>}
                    {lock && <LoadingIcon />}
                </div>
            </form>
        </div>
    )
}

export default connect(null, { actionNavigate, actionCreateNotification })(ForgotPasswordPage)