/**
 * Display text helper with OHLCV data
 */
import React from 'react'
import { formatCandleDate } from './utils';
import { ICandle } from './utils/interfaces';

interface ITooltipProps {
    selection: null | any;
    width: number;
}

const Tooltip = ({ selection, width }: ITooltipProps) => {
    if (selection?.candle?.item) {
        const candle: ICandle = selection.candle.item;
        return (
            <div className="candlechart_container__row__tooltip" style={{ width }}>
                <span className="candlechart_container__row__tooltip__time">
                    {formatCandleDate(candle.time)}
                </span>
                <span className="candlechart_container__row__tooltip__caption">
                    O:
                </span>
                <span className="candlechart_container__row__tooltip__value">
                    {candle.open}
                </span>
                <span className="candlechart_container__row__tooltip__caption">
                    H:
                </span>
                <span className="candlechart_container__row__tooltip__value">
                    {candle.high}
                </span>
                <span className="candlechart_container__row__tooltip__caption">
                    L:
                </span>
                <span className="candlechart_container__row__tooltip__value">
                    {candle.low}
                </span>
                <span className="candlechart_container__row__tooltip__caption">
                    C:
                </span>
                <span className="candlechart_container__row__tooltip__value">
                    {candle.close}
                </span>
                <span className="candlechart_container__row__tooltip__caption">
                    V:
                </span>
                <span className="candlechart_container__row__tooltip__value">
                    {candle.volume}
                </span>
            </div>
        )
    }
    return null;
}

export default Tooltip