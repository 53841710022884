import { put, select, takeLatest } from 'redux-saga/effects'
import {
    FETCH_REQUEST,
    ADD_REQUEST,
    UPDATE_ACCOUNT,
    DELETE,
    actionUpdate,
    actionUnlock
} from '../actions/trading_accounts'
import { requestCreateAccount, requestExchangeAccounts, requestDeleteAccount, requestUpdateAccount } from '../core/accountData';
import { actionCloseModal } from '../actions/modal';
import { actionCreateNotification } from '../actions/notifications';

function* fetchTradingAccounts(action: any) {
    const { token, account_secret } = yield select(state => state.accounts)
    try {
        const data = yield requestExchangeAccounts(token, account_secret)
        yield put(actionUpdate(data))
    } catch (err) {
        console.error(err)
    }
}
function* addTradingAccount(action: any) {
    const { token, account_secret } = yield select(state => state.accounts)
    try {
        const oldState = yield select(state => state.trading_accounts.items);
        const newState = [...oldState, action.payload];
        const response = yield requestCreateAccount(token, account_secret, action.payload);
        if (response === "error") {
            yield put(actionCloseModal())
            yield put(actionUnlock())
            yield put(actionCreateNotification({ message: "Could not save trading account" }))
        } else {
            yield put(actionUpdate(newState))
            yield put(actionCloseModal())
        }
    } catch (err) {
        yield put(actionCloseModal())
        yield put(actionUnlock())
        console.error(err)
    }
}

function* removeTradingAccount(action: any) {
    const { token, account_secret } = yield select(state => state.accounts)
    try {
        yield requestDeleteAccount(token, account_secret, action.payload.accountKey)
    } catch (err) {
        yield put(actionUnlock())
        console.error(err)
    }
}

function* updateTradingAccount(action: any) {
    const { token, account_secret } = yield select(state => state.accounts)
    try {
        const oldState = yield select(state => state.trading_accounts.items);
        yield requestUpdateAccount(token, account_secret, action.payload)

        yield put(actionUpdate(oldState.map((item: any) => item.apiKey === action.payload.apiKey ? action.payload : item )))
    } catch (err) {
        yield put(actionUnlock())
        console.error(err)
    }
}


function* tradingAccountsSaga() {
    yield takeLatest(FETCH_REQUEST, fetchTradingAccounts);
    yield takeLatest(ADD_REQUEST, addTradingAccount)
    yield takeLatest(DELETE, removeTradingAccount)
    yield takeLatest(UPDATE_ACCOUNT, updateTradingAccount)
}
  
export default tradingAccountsSaga;