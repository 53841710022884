import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actionCloseModal, actionShowModal } from '../../../actions/modal'
import { actionRequestAddAccount } from '../../../actions/trading_accounts'
import { ReactComponent as CloseIcon } from '../LoginModal/close.svg'
import { EXCHANGES_LIST, DEX_LIST } from '../../../helpers/exchanges'
import "./styles.scss"

const AddAccountModal = (props: any) => {
    const [exchange, setExchange] = useState(EXCHANGES_LIST[0])
    const [apiKey, setKey] = useState("")
    const [apiSecret, setSecret] = useState("")

    const keysRequired = !DEX_LIST.includes(exchange)

    const onSendForm = (e: any) => {
        e.preventDefault();
        const payload = {
            exchangeName: exchange,
            connected: true,
            accountKey: apiKey,
            accountSecret: apiSecret
        }
        if (DEX_LIST.includes(exchange)) {
            payload['accountKey'] = exchange
            payload['accountSecret'] = exchange
        }
        props.actionRequestAddAccount(payload)
    }

    return (<div className="add_account__modal add_account__modal--small">
    <div className="add_account__modal__header">
        <span className="add_account__modal__header__caption">Add account</span>
        <span className="add_account__modal__header__close" onClick={() => props.actionCloseModal()}><CloseIcon /></span>
    </div>
    <form className="add_account__modal__form" onSubmit={onSendForm}>
            <select
                className="add_account__modal__form__select"
                value={exchange}
                onChange={(e: any) => setExchange(e.target.value)}>
                {EXCHANGES_LIST.map((exchange: string) => (<option key={exchange}>{exchange}</option>))}
            </select>
            {keysRequired && <>
                    <input
                        type="text"
                        className="add_account__modal__form__text"
                        placeholder="API key"
                        value={apiKey}
                        onChange={(e: any) => setKey(e.target.value)}
                    />
                    <input
                        type="text"
                        className="add_account__modal__form__text"
                        placeholder="API Secret"
                        value={apiSecret}
                        onChange={(e: any) => setSecret(e.target.value)}
                    />
            </>}
            <button className="login__modal__form__btn" onClick={onSendForm}>
                Add account
            </button>
    </form>
</div>)
}

export default connect(null, { actionCloseModal, actionShowModal, actionRequestAddAccount })(AddAccountModal)