import React from 'react';
import { connect } from "react-redux";
import { actionNavigate } from "../../actions/navigation";
import { actionOpenLogin } from "../../actions/modal";
import { PageType } from '../../helpers/pageTypes';

import { ReactComponent as Trading } from './trading.svg';
import { ReactComponent as Scanner } from './scanner.svg';
import { ReactComponent as Report } from './report.svg';
// import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as Assets } from './assets.svg';

import './styles.scss';

interface INavigationBarProps {
    page: string | PageType;
    signedin: boolean;
    actionNavigate: Function;
    actionOpenLogin: Function;
}

/**
 * Icon element factory
 */
const Icon = ({ kind }: any) => {
    switch (kind) {
        case PageType.trading:
            return <Trading />;
        case PageType.marketscanner:
            return <Scanner />;
        case PageType.performance:
            return <Report />;
        case PageType.settings:
            return <Profile />;
        case PageType.assets:
            return <Assets />;
        default:
            return null;
    }
}
/**
 * Facade component for buttons
 */
const NavItem = ({ active, title, right, role, onClick }: any) => {
    const classList = ["navbar__panel__btn"];
    if (right) { classList.push("navbar__panel__btn--right") }
    if (active) { classList.push("navbar__panel__btn--active") }
    return (
        <div className={classList.join(' ')} onClick={onClick}>
            <Icon kind={role} /><span>{title}</span>
        </div>
    )
}

/**
 * Implements navigation bar
 */
const NavigationBar = (props: INavigationBarProps) => {
    const { page, signedin } = props
    const onPrivatePageClick = (ptype: PageType) => {
        if (signedin) {
            props.actionNavigate(ptype)
        } else {
            props.actionOpenLogin()
        }
    }
    return (
        <div className="navbar__panel">
            <div
                className="navbar__panel__logo"
                onClick={() => props.actionNavigate(PageType.trading)}
            />
            <NavItem
                active={page === PageType.trading}
                role={PageType.trading}
                onClick={() => props.actionNavigate(PageType.trading)}
                title="Trading"
            />
            <NavItem
                active={page === PageType.marketscanner}
                role={PageType.marketscanner}
                onClick={() => props.actionNavigate(PageType.marketscanner)}
                title="MARKET SCANNER"
            />
            <NavItem
                active={page === PageType.performance}
                role={PageType.performance}
                onClick={() => props.actionNavigate(PageType.performance)}
                title="Perfomance Analysis"
            />
            <NavItem
                active={page === PageType.assets}
                role={PageType.assets}
                onClick={() => props.actionNavigate(PageType.assets)}
                title="Assets"
            />
            <NavItem
                right
                role={PageType.settings}
                active={page === PageType.settings}
                onClick={() => onPrivatePageClick(PageType.settings)}
                title="Profile"
            />
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    page: state.navigation.page,
    signedin: state.accounts.signedin
})

export default connect(mapStateToProps, { actionNavigate, actionOpenLogin })(NavigationBar);
