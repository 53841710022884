/**
 * Implements a PriceAxis element
 */
import React, { useEffect, useRef } from 'react'
import { IOrder } from '../../../../helpers/tradingModes';
import { setCrispCanvas } from '../utils';
import { fillRectRadius } from '../utils/canvas';
import { ISelection, ICandle, IPriceTick } from '../utils/interfaces';

interface IPriceAxisProps {
    width: number;
    height: number;
    colors: any;
    yScale: any;
    priceMin: number;
    priceMax: number;
    priceFormatter: any;
    orders: IOrder[];
    ticks: IPriceTick[];
    selection: null | ISelection;
    lastCandle: ICandle;
    showLastCandle: boolean;
    onLeftClick?: (price: number) => void;
    onContextMenu?: (x: number, y: number) => void;
}

/**
 * Reverse scale
 * @param y 
 */
const getPriceFromY = (y: number, height: number, priceMax: number, priceMin: number) =>
    ((1 - (y / height)) * (priceMax - priceMin)) + priceMin

const PriceAxis = (props: IPriceAxisProps) => {
    const { width, height, colors, yScale, priceMin, priceMax, priceFormatter, orders, ticks, selection, lastCandle, showLastCandle, onLeftClick, onContextMenu } = props;
    const canvasRef = useRef(null);

    /**
     * Handle left and return price
     * @param e - mouse event
     */
    const onCanvasClick = (e: any) => {
        e.preventDefault();
        const { offsetY } = e.nativeEvent;
        onLeftClick && onLeftClick(getPriceFromY(offsetY, height, priceMax, priceMin))
    }
    const onContextClick = (e: any) => {
        e.preventDefault();
        onContextMenu && onContextMenu(e.nativeEvent.offsetX, e.nativeEvent.offsetY)
    }

    useEffect(() => {
        const { current }: any = canvasRef;
        if (current) {
            setCrispCanvas(current, width, height);
        }
    }, [width, height]);

    useEffect(() => {
        const { current }: any = canvasRef;
        if (current) {
            requestAnimationFrame(() => {
                const context: any = current.getContext('2d')
                /**
                * Rendering code
                */
                context.clearRect(0, 0, width, height);
                context.fillStyle = colors.background;
                context.fillRect(0, 0, width, height);
                /**
                 * Get min and max of price
                 */
                context.strokeStyle = colors.axis.line;
                context.strokeRect(0, 0, 1, height);
                /**
                 * Render ticks
                 */
                context.font = colors.axis.tickFont;
                context.fillStyle = colors.axis.color; 
                const textX = 8;
                const y = (arg: number) => height - Math.ceil(yScale(arg) * height);
                // Ticks generated in parent component
                ticks.forEach((tick: IPriceTick) => {
                    context.strokeRect(1, tick.y, 3, 1);
                    context.fillText(priceFormatter(tick.price), textX, tick.y + 4);
                })
                /**
                 * lastCandle
                 * Could be null
                 */
                if (showLastCandle && lastCandle) {
                    const { open, close } = lastCandle;
                    const isGreen = close > open;
                    const color = isGreen ? colors.green : colors.red;
                    const middleY = y(close);
                    context.fillStyle = color;
                    context.strokeStyle = color;
                    fillRectRadius(context, 2, middleY - 13, width, 26, 4)

                    context.font = colors.axis.tickFont;
                    context.fillStyle = colors.axis.selection.color; 
                    const price = priceFormatter(close)
                    context.fillText(price, textX, middleY + 4);
                }
                /**
                 * Orders price levels
                 */
                if (orders.length > 0) {
                    orders.forEach(({ price, side }: IOrder) => {
                        const color = side === 'buy' ? colors.green : colors.red;
                        const middleY = y(price);
                        context.fillStyle = color;
                        context.strokeStyle = color;
                        fillRectRadius(context, 2, middleY - 13, width, 26, 4)

                        context.font = colors.axis.tickFont;
                        context.fillStyle = colors.axis.selection.color; 
                        const priceString = priceFormatter(price)
                        context.fillText(priceString, textX, middleY + 4);
                    })
                }
                /**
                 * Selection
                 */
                if (selection) {
                    context.fillStyle = colors.axis.selection.background;
                    context.fillRect(0, selection.y - 10, width, 20);
                    context.font = colors.axis.tickFont;
                    context.fillStyle = colors.axis.selection.color; 
                    /**
                     * Map selection.y to height
                     * 0,0 is top and 0, height is bottom
                     * (selection.y / height) - % of bottom
                     */
                    const rawPrice = getPriceFromY(selection.y, height, priceMax, priceMin)
                    const price = priceFormatter(rawPrice)
                    context.fillText(price, textX, selection.y + 4);
                }
            })
        }
    }, [width, height, priceMin, priceMax, selection, lastCandle, showLastCandle, orders]);

    return (
        <canvas
            ref={canvasRef}
            width={props.width}
            height={props.height}
            onClick={onCanvasClick}
            onContextMenu={onContextClick}
        />
    )
}

export default PriceAxis