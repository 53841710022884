/**
 * Implements a TimeAxis element
 */
import React, { useEffect, useState, useRef } from 'react'
import { setCrispCanvas } from '../utils';
import { ISelection, ITimeTick } from '../utils/interfaces';

interface ITimeAxisProps {
    width: number;
    height: number;
    colors: any;
    timeMin: number;
    timeMax: number;
    xScale: any;
    // ticksCount: number;
    timeTicks: ITimeTick[];
    timeFormatter: any;
    tooltipFormatter: any;
    selection: null | ISelection;
    priceAxisWidth: number;
}

const tooltipWidth = 120;

const TimeAxis = (props: ITimeAxisProps) => {
    const { width, height, colors, xScale, timeTicks, timeFormatter, tooltipFormatter, selection, priceAxisWidth } = props;
    const canvasRef = useRef(null);

    useEffect(() => {
        const { current }: any = canvasRef;
        if (current) {
            setCrispCanvas(current, width, height);
        }
    }, [width, height]);

    useEffect(() => {
        const { current }: any = canvasRef;
        if (current) {
            const context: any = current.getContext('2d')
            const availableWidth = width - priceAxisWidth;
            /**
            * Rendering code
            */
            context.clearRect(0, 0, width, height);
            context.fillStyle = colors.background;
            context.fillRect(0, 0, width, height);
            /**
             * Get min and max of price
             */
            context.strokeStyle = colors.axis.line;
            context.strokeRect(1, 0, availableWidth, 1);
            /**
             * Render ticks
             */
            context.font = colors.axis.tickFont;
            context.fillStyle = colors.axis.color; 
            const textY = 20;

            const x = (arg: number) => Math.ceil(xScale(arg) * availableWidth);

            /**
             * Render ticks
             */
            timeTicks.forEach(({ x, time }: ITimeTick) => {
                context.fillText(timeFormatter(time), x, textY);
                context.strokeRect(x, 0, 1, 3);
            })

            if (selection) {
                if (selection.candle) {
                    const { time } = selection.candle.item;
                    let startX = x(time);
                    if (startX < (tooltipWidth / 2)) {
                        startX = (tooltipWidth / 2)
                    }
                    if ((startX + (tooltipWidth / 2)) > availableWidth) {
                        startX = availableWidth - (tooltipWidth / 2);
                    }
                    context.fillStyle = colors.axis.selection.background;
                    context.fillRect(startX - (tooltipWidth / 2), 0, tooltipWidth, 20);
                    context.font = colors.axis.tickFont;
                    context.textAlign = "center";
                    context.fillStyle = colors.axis.selection.color; 
                    context.fillText(tooltipFormatter(time), startX, 14, tooltipWidth);
                }
            }
        }
    }, [width, height, selection]);

    return (
        <canvas
            ref={canvasRef}
            width={props.width}
            height={props.height}
        />
    )
}

export default TimeAxis