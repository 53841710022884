import { action } from "typesafe-actions";

const entity = 'backend';

const CONNECT = `${entity}/CONNECT`;
const REFRESH = `${entity}/REFRESH`;
const ETH_BLOCK_MINED = `${entity}/ETH_BLOCK_MINED`
const BNB_BLOCK_MINED = `${entity}/BNB_BLOCK_MINED`

const actionRefreshAccountData = () => action(REFRESH)
const actionConnect = () => action(CONNECT)
const actionEthBlockMined = (block: number) => action(ETH_BLOCK_MINED, block)
const actionBnbBlockMined = (block: number) => action(BNB_BLOCK_MINED, block)

export {
    CONNECT,
    REFRESH,
    ETH_BLOCK_MINED,
    BNB_BLOCK_MINED,
    actionConnect,
    actionRefreshAccountData,
    actionEthBlockMined,
    actionBnbBlockMined
}