import { put, select, takeLatest } from 'redux-saga/effects'
import { ACTION_SET_BUY_PRICE, actionSetBuyPriceDone, actionDeletePosition } from '../actions/positions'

function* onSetBuyPrice(action: any) {
    const { coin, price, currency } = action.payload
    const rates = yield select(state => state.rates)
    const rate = rates[currency] || 1.0; // get rate in USD for input

    if (price === "" || price === "0" || price === "0.0") {
        yield put(actionDeletePosition(coin))
    } else {
        if (rate) {
            const value = rate * Math.abs(parseFloat(price));
            yield put(actionSetBuyPriceDone(coin, price, currency, value))
        }
    }
}

function* positionsSaga() {
    yield takeLatest(ACTION_SET_BUY_PRICE, onSetBuyPrice);
}
  
export default positionsSaga;