/**
 * Implements a password reset modal
 */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actionShowModal } from '../../../actions/modal'
import { ReactComponent as KattanaShiningLogo } from '../LoginModal/kattana_shining_logo.svg'
import ModalTypes from '../../../helpers/modals'
import { requestForgotPassword } from '../../../core/accountData'
import { actionCreateNotification } from '../../../actions/notifications'
import { ReactComponent as CloseIcon } from '../LoginModal/close.svg'
import { ReactComponent as LoadingIcon } from '../LoginModal/loader.svg'
import '../LoginModal/styles.scss'


interface IPasswordResetModal {
    actionShowModal: Function;
    actionCreateNotification: Function;
}
const PasswordResetModal = (props: IPasswordResetModal) => {
    const [email, setEmail] = useState("")
    const [lock, setLock] = useState<boolean>(false)

    const onSubmit = async () => {
        if (!lock) {
            setLock(true)
            const status = await requestForgotPassword(email)

            if (status) {
                props.actionCreateNotification({ message: "We've reset your password, please check your mailbox" })
                props.actionShowModal(ModalTypes.login)
            } else {
                props.actionCreateNotification({ message: "Could not reset your password, email not found" })
            }

            setLock(false)
        } else {
            props.actionCreateNotification({ message: "Request already in process" })
        }
    }

    return (
        <div className="login__modal">
            <div className="login__modal__header">
                <span className="login__modal__header__caption">PASSWORD RESET</span>
                <span className="login__modal__header__close" onClick={() => props.actionShowModal(ModalTypes.login)}><CloseIcon /></span>
            </div>
            <div className="login__modal__logo">
                <KattanaShiningLogo />
            </div>
            <form className="login__modal__form" onSubmit={onSubmit}>
                <span>Enter your email and we will send a link to reset password</span>
                <input
                    type="email"
                    className="login__modal__form__text"
                    placeholder="Email address"
                    autoComplete="email"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                />
                {lock && <LoadingIcon />}
                {!lock && <button className="login__modal__form__btn" onClick={onSubmit}>
                    Reset password
                </button>}
            </form>
        </div>
    )
}

export default connect(null, { actionShowModal, actionCreateNotification,  })(PasswordResetModal);
