/**
 * Side effect
 * Update browser history with navigation state
 */
import { takeLatest } from 'redux-saga/effects'
import { ACTION_NAVIGATE } from '../actions/navigation'
import { PageType } from '../helpers/pageTypes'

const mapping: any = {
    [PageType.assets]: '/assets',
    [PageType.marketscanner]: '/scanner',
    [PageType.performance]: '/performance',
    [PageType.settings]: '/settings',
    [PageType.trading]: '/trading',
    [PageType.homepage]: '/'
}
// eslint-disable-next-line require-yield
function* onChangeState(action: any) {
    const { payload } = action
    const { page, id } = payload
    const url = mapping[page]
    if (url) {
        window.history.pushState(payload, page, (id ? `${url}/${id}`: url))
    }
}

function* navigateSaga() {
    yield takeLatest(ACTION_NAVIGATE, onChangeState);
}
  
export default navigateSaga;