
const analytics = 'https://analytics.kattana.trade'
const accountsEndpoint = `${analytics}/accounts`
const backend = process.env.NODE_ENV === 'production' ?
    'https://broker.kattana.trade'
    : 'http://localhost:8081'

const wsBackend = process.env.NODE_ENV === 'production' ?
    'wss://broker.kattana.trade/'
    : 'ws://localhost:8081/'

const iconsEndpoint = 'https://d201tgt1xqcc9u.cloudfront.net'

export {
    analytics,
    accountsEndpoint,
    backend,
    wsBackend,
    iconsEndpoint
}