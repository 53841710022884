/**
 * Table with Assets (Coins)
 */
import React, { useState, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { fetchCoinStats, ICoin } from "../../../core/coins"
import VolumeChart from "./VolumeChart"
import "./styles.scss"
import PriceChart from "./PriceChart"
import { actionNavigate } from '../../../actions/navigation'
import { PageType } from "../../../helpers/pageTypes"

interface IHomepageAssetsProps {
  rates: any;
  actionNavigate: Function;
}
/**
 * Adjust table column width
 */
const advProps = {
  width: 300
}

interface IHomePageAssetsState {
  visibility: boolean;
  coins: any[];
}

const formatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
})

interface ISort {
  key: string; // title
  mode: string; // asc, desc
}

const HomepageAssets = (props: IHomepageAssetsProps) => {
  const [state, setState] = useState<IHomePageAssetsState>({ visibility: false, coins: []})
  const [sort, setSort] = useState<ISort>()

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();
    const cancelToken = source.token;
  
    const fetchFn = async () => {
      try {
        let coins = await fetchCoinStats(cancelToken)
        if (!unmounted) {
          setState({
            coins,
            visibility: true
          })
        }
      } catch (err) {
        console.warn(err)

        setState({
          coins: [],
          visibility: false
        })
      }
    }
    fetchFn()
    // Cleanup
    return function () {
      unmounted = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [props.rates])

    const { coins, visibility } = state
    const { rates } = props
    const sorted = sortCoins(coins, sort)

    return (<div className="homepage__assets" style={{ visibility:  (visibility ? 'visible' : 'hidden') }}>
      <h2>Top crypto assets</h2>
      <table>
        <thead>
          <tr>
            <td>#</td>
            <SortableHeader collapse={false} field="title" sort={sort} onChange={setSort}>name</SortableHeader>
            <SortableHeader collapse field="tickers_count" sort={sort} onChange={setSort}>tickers count</SortableHeader>
            <SortableHeader collapse={false} field="lastPrice" sort={sort} onChange={setSort}>price (usd)</SortableHeader>
            <td {...advProps}>price 24h</td>
            <SortableHeader collapse field="convertedVolume" sort={sort} onChange={setSort}>volume (usd)</SortableHeader>
            <td className="collapse" {...advProps}>volume 24h</td>
          </tr>
        </thead>
        <tbody>
          {sorted.map((coin: ICoin, index: number) => {
            const baseVolume = parseFloat(coin.volume)
            const lastPrice = coin.values[0][1]
            const convertedVolume = Math.round(parseFloat(rates[coin.name] || 0.0) * baseVolume)
            const prices = coin.values.filter(i => !!i[1]).map((i: any[]) => parseFloat(i[1]))
            const volumes = coin.values.map((i: any[]) => parseFloat(i[0]))
            return (
              <tr key={index} onClick={() => props.actionNavigate(PageType.assets, coin.name)}>
                <td>{index + 1}</td>
                <td>{coin.title}</td>
                <td className="collapse">{coin.tickers_count}</td>
                <td>{formatter.format(lastPrice)}</td>
                <td>
                  <PriceChart
                    data={prices}
                  />
                </td>
                <td className="collapse">{formatter.format(convertedVolume)}</td>
                <td className="collapse">
                  <VolumeChart
                    data={volumes}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
interface ISortableHeader {
  field: string;
  sort: ISort | undefined;
  onChange: Function;
  collapse?: Boolean;
  children: React.ReactChild;
}
/**
 * Implements a clickable tableheader
 * @param props
 */
const SortableHeader = ({ field, sort, onChange, children, collapse }: ISortableHeader) => {
  let arrow = '';
  if (sort) {
    if (sort.key === field) {
      arrow = sort.mode === 'asc' ? '↓' : '↑'
    }
  }
  const onClick = () => {
    if (sort) {
      if (sort.key === field) {
        const mode = sort.mode === 'asc' ? 'desc' : 'asc'
        onChange({ key: field, mode })
      } else {
        onChange({ key: field, mode: 'asc' })
      }
    } else {
      onChange({ key: field, mode: 'asc' })
    }
  }
  const className = collapse ? "homepage__assets__table_header collapse" : "homepage__assets__table_header"
  return <td onClick={onClick} className={className}>{children}&nbsp;{arrow}</td>
}

/**
 * Sorts coin array
 * @param coins - raw coins array
 * @param sort - sort object
 */
const sortCoins = (coins: ICoin[], sort: ISort | undefined) => {
  if (sort) {
    try {
        const isAsc = sort.mode === 'asc';
        switch (sort.key) {
          case 'title':
            const predicate = (a: any, b: any) => a.title[0] < b.title[0] ? -1 : 1;
            return isAsc ?
              coins.sort(predicate)
              : coins.sort(predicate).reverse()
          case 'tickers_count':
            return isAsc ? 
              coins.sort((a: ICoin, b: ICoin) => Number(a.tickers_count) - Number(b.tickers_count))
              :  coins.sort((a: ICoin, b: ICoin) => Number(b.tickers_count) - Number(a.tickers_count))
          case 'lastPrice':
            return isAsc ? 
              coins.sort((a: ICoin, b: ICoin) => Number(a.values[0][1]) - Number(b.values[0][1]))
              :  coins.sort((a: ICoin, b: ICoin) => Number(b.values[0][1]) - Number(a.values[0][1]))
          case 'convertedVolume':
            return isAsc ? 
              coins.sort((a: ICoin, b: ICoin) => Number(a.volume) - Number(b.volume))
              :  coins.sort((a: ICoin, b: ICoin) => Number(b.volume) - Number(a.volume))
          default:
            return coins;
        }
      } catch (err) {
        console.error(err);
        return coins;
      }
  } else {
    return coins
  }
}

const mapStateToProps = ({ rates }: any) => ({ rates })
export default connect(mapStateToProps, { actionNavigate })(HomepageAssets)