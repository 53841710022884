/**
 * OrderType tabs picker
 */
import React from 'react'

interface IOrderTypeProps {
    exchange: string;
    orderType: string;
    onUpdate: Function;
}

const orderTypeTitles: any = {
    'limit': 'Limit',
    'market': 'Market',
    'STOP_LOSS_LIMIT': 'StopLimit'
}

const OrderType = (props: IOrderTypeProps) => {
    const supportedOrderTypes = (props.exchange === 'binance') ?
        ['limit', 'market','STOP_LOSS_LIMIT']
        : ['limit', 'market']
    return (<div className="create__order__ordertypes">
        {supportedOrderTypes.map((orderType: string) =>
            (<span
                key={orderType}
                onClick={() => props.onUpdate(orderType)}
                className={(props.orderType === orderType) ?
                    'create__order__ordertypes__tab create__order__ordertypes__tab--active'
                    : 'create__order__ordertypes__tab'}
            >{orderTypeTitles[orderType]}</span>)
        )}
    </div>)
}

export default OrderType