/**
 * Implements a coin icons
 * Hides a component if icons could not be loaded
 */
import React, { useEffect, useState } from 'react'
import { iconsEndpoint } from '../../../../core/endpoints'

interface IPairIconsProps {
    pair: string
}

const PairIcons = ({ pair }: IPairIconsProps) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [isError, setError] = useState<boolean>(false)
    const [loadedCount, setLoadedCount] = useState(0)
    /**
     * Show pair only when two images done loading
     */
    useEffect(() => {
        if (loadedCount === 2) {
            setLoading(false)
        }
    }, [loadedCount])

    let [ base, quote ] = pair.split('/')

    if (base === 'WBTC') {
        base = 'BTC'
    }

    if (quote === 'WETH') {
        quote = 'ETH'
    }

    const basePath = `${iconsEndpoint}/${base.toLowerCase()}.svg`
    const quotePath = `${iconsEndpoint}/${quote.toLowerCase()}.svg`

    if (isError) {
        return <span className="tickers__panel__table__row__pair__icons tickers__panel__table__row__pair__icons--loading" />
    }

    return (
        <span className={`tickers__panel__table__row__pair__icons ${loading ? 'tickers__panel__table__row__pair__icons--loading' : ''}`}>
            <img
                className="tickers__panel__table__row__pair__icons__base"
                alt={base}
                src={basePath}
                onLoad={() => setLoadedCount((count: number) => count + 1)}
                onError={() => setError(true)}
            />
            <img
                className="tickers__panel__table__row__pair__icons__quote"
                alt={quote}
                src={quotePath}
                onLoad={() => setLoadedCount((count: number) => count + 1)}
                onError={() => setError(true)}
            />
        </span>
    )
}

export default PairIcons;
