import { ITimeTick } from "./interfaces";
import { removeDays } from "./timeMath";

/**
 * Day-based ticks, behave like TV
 * @param timeMax 
 * @param timeMin 
 * @param Difference_In_Days 
 */
const fewDaysTicks = (timeMax: number, timeMin: number, Difference_In_Days: number): number[] => {
    const fullDays = Math.round(Difference_In_Days);
    const rawTicks: number[] = [];
    for (let index = 0; index <= fullDays; index++) {
        rawTicks.push(removeDays(timeMax, index));
    }
    return rawTicks
}

/**
 * Get simple (naive) ticks
 * @param timeMax 
 * @param timeMin 
 */
const simpleTicks = (timeMax: number, timeMin: number, count: number): number[] => {
    const rawTicks: number[] = [];
    const distance = (timeMax - timeMin) / count;
    for (let index = 1; index < count; index++) {
        rawTicks.push(Math.round(timeMax - distance * index));
    }
    return rawTicks;
}

/**
 * Return ticks
 * @param timeMax
 * @param timeMin
 */
const getTimeTicks = (timeMax: number, timeMin: number): number[] => {
    /**
     * Logic
     * If we have more than 2 days - render days
     */
    const Difference_In_Time = timeMax - timeMin;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days > 2 && Difference_In_Days < 6) {
       return fewDaysTicks(timeMax, timeMin, Difference_In_Days);
    }
    return simpleTicks(timeMax, timeMin, 6);
}
/**
 * Return a specific ticks
 * @param timeMax - end time
 * @param timeMin - start time
 * @param xScale - fn to scale
 * @param ticks - time array
 */
const getMappedTimeTicks = (timeMax: number, timeMin: number, xScale: any, width: number): ITimeTick[] =>
    getTimeTicks(timeMax, timeMin).map((time: number) =>
        ({
            x: xScale(time) * width,
            time
        })
    )

export { getTimeTicks, getMappedTimeTicks }