import React, { useState, useEffect } from 'react'
import { requestAccountSessions, deleteAccountSession, ISession } from '../../../core/accountData'
import { ReactComponent as RemoveIcon } from './remove.svg'
import { StorageKeys } from '../../../helpers/localStorage'

const AccountSessions = () => {
    const [state, setState] = useState<any>()
    const [loaded, setLoaded] = useState(false)
    const currentSessionID = localStorage.getItem(StorageKeys.session_id)

    const fetchFn = async () => {
        try {
            const sessions: ISession[] = await requestAccountSessions()
            setState(sessions)
            setLoaded(true)
        } catch (err) {
            setLoaded(false)
        }
    }

    useEffect(() => {
        fetchFn()
    }, [])

    const onDisableSession = async (id: any) => {
        setLoaded(false)
        try {
            await deleteAccountSession(id)
            await fetchFn()
            // delete session
        } catch (err) {
            setLoaded(true)
        }
    }

    if (loaded && state) {
        return (<div className="settings__page__accountinfo">
            <table className="kattana__generic__table">
               <thead>
                    <tr>
                        <td>ip</td>
                        <td>browser</td>
                        <td>os</td>
                        <td>delete</td>
                    </tr>
               </thead>
               <tbody>
                    {state.map((session: ISession) => {
                        const browser = JSON.parse(session.browser)
                        const os = JSON.parse(session.os)
                        const isCurrent = session.uuid === currentSessionID
                        return (<tr key={session.uuid}>
                            <td>{session.ip}</td>
                            <td>{browser.name} {browser.version}</td>
                            <td>{os.name} {os.version}</td>
                            {isCurrent ?
                                (<td>&nbsp;</td>)
                                : (<td onClick={() => onDisableSession(session.id)}><RemoveIcon /></td>)
                            }
                        </tr>)
                    })}
               </tbody>
            </table>
        </div>)
    }
    return (
        <div className="settings__page__accountinfo">
            Loading data
        </div>
    )
}

export default AccountSessions