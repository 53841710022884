/**
 * Entry component
 * Handles data as array, all data management happens in diffeerent component
 */
import React, { Component } from 'react'
import Candles from './Candles';
import { createPriceScale } from './scales/priceScale';
import { createTimeScale } from './scales/timeScale';
import PriceAxis from './axis/PriceAxis';
import TimeAxis from './axis/TimeAxis';
import { getRanges } from './utils';
import { ICandle, IContainerProps, IPriceTick, ISelection } from './utils/interfaces';
import ResolutionSelect from './ResolutionSelect';
import { getMappedTimeTicks } from './utils/timeTicksMath';
import "./styles.scss";
import { IOrder } from '../../../helpers/tradingModes';
import ChartOrder from './ChartOrder';
import Tooltip from './Tooltip';
import GenericChartSelect from './GenericChartSelect';

const axisWidth = 90;
const axisHeight = 60;
const navbarHeight = 40;

/**
 * Returns a function depending on a resolution
 * @param resolution 
 */
const createTimeFormatter = (resolution: any) => {
    if (resolution < 60) {
        return (timestamp: number) => new Date(timestamp).toTimeString().substr(0,5)
    }
    return (timestamp: number) => new Date(timestamp).toLocaleDateString("en-US")
}

const tooltipFormatter = (timestamp: number): string => {
    const date = new Date(timestamp);
    const minutes = (date.getMinutes() < 10 ? '0':'') + date.getMinutes()
    const year = String(date.getFullYear()).slice(-2);
    const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
    const month = formatter.format(date);

    return `${date.getDate()} ${month} ${year} ${date.getHours()}:${minutes}`
}
/**
 * Return sector index and candle data
 * @param x 
 * @param sectors 
 */
const getSector = (x: number, sectors: any[]) => {
    const entity = sectors.filter(({ startX, endX }: any) => (x >= startX) && (x <= endX))[0]
    return ({
        index: sectors.indexOf(entity),
        candle: entity
    })
}

const getPriceTicks = ({ height, yScale, priceMin, priceMax }: any): IPriceTick[] => {
    const result: IPriceTick[] = [];
    const ticksCount = 20;
    const y = (arg: number) => height -  Math.ceil(yScale(arg) * height);

    for (let index = 0; index < ticksCount; index++) {
        const tick = (priceMax - priceMin) / ticksCount;
        const price = priceMin + tick * index;

        result.push({
            y: y(price),
            price
        })
    }
    return result;
}

export enum ChartTypes {
    Candlestick = 'Candlestick',
    Line = 'Line'
}
class Container extends Component<IContainerProps, any> {
    sectors: any[] = [];

    constructor(props: IContainerProps) {
        super(props);
        const collectionStart = props.data.length - props.visibleCandles;
        const collection = [...props.data.slice(collectionStart, collectionStart + props.visibleCandles)];

        const firstCandle = collection[0]
        const lastCandle = collection[collection.length - 1];

        this.state = {
            scopeStart: firstCandle?.time,
            scopeEnd: lastCandle?.time,
            lastCandle: lastCandle,
            collection,
            zoomFactor: 1.0,
            chartType: ChartTypes.Candlestick
        }
    }
    /**
     * Props arrived!
     * @param props 
     * @param state 
     */
    static getDerivedStateFromProps(props: any, state: any) {
        if (props.data) {
            const collection = props.data.filter(
                (candle: ICandle) => candle.time >= state.scopeStart && candle.time <= state.scopeEnd
            )
            return {
                collection,
                lastCandle: props.data[props.data.length - 1],
            }
        }
    }

    onCrosshair = (x: number, y: number) => {
        const { index, candle } = getSector(x, this.sectors);
        this.setState({
            selection: { index, candle, y }
        })
    }
    /**
     * Drag chart
     * @param pxDelta - distance in pixels
     */
    onChartMove = (coordX: number) => {
        const pxDelta = coordX - this.state.prevX;
        const { scopeStart, scopeEnd } = this.state
        const timeDelta = (pxDelta / this.props.width) * (scopeEnd - scopeStart)

        /**
         * Detect if we scrolled left and shrinking
         */
        const currentDelta = scopeEnd - scopeStart
        const newDelta = (scopeEnd - scopeStart - timeDelta)
        if (newDelta < currentDelta) {
            this.props.onMoreDataRequested(scopeStart, currentDelta)
        }

        this.setState({ 
            scopeStart: scopeStart - timeDelta,
            scopeEnd: scopeEnd - timeDelta,
            prevX: coordX
        })
    }

    matchZoomBounds = (newZoom: number): number => {
        if (newZoom <= 0.8) {
            return 0.8
        }
        if (newZoom >= 1.6) {
            return 1.6
        }
        return newZoom
    }
    /**
     * Change zoom by changing time scope
     * 0.02761504888534546 => 2% 
     * @param value
     */
    onZoom = (value: number) => {
        const { scopeStart, scopeEnd } = this.state
        const actualDiff = scopeEnd - scopeStart
        const zoomFactor = this.matchZoomBounds(this.state.zoomFactor + value);
        const newDiff = (actualDiff * value)
        this.setState({ 
            scopeStart: scopeStart - newDiff,
            scopeEnd: scopeEnd + newDiff,
            zoomFactor
        })
    }

    onSectors = (sectors: any[]) =>
        this.sectors = sectors;

    onOrderMove = (order: IOrder, newPx: number) => {
        // clone order attributes
        // await close order
        // await setup new order
    }

    render() {
        const { width, height, colors, pricePrecision, resolution, orders } = this.props
        const { collection, scopeStart, scopeEnd } = this.state

        const rowHeight = height - axisHeight - navbarHeight;
        const chartWidth = width - axisWidth;

        if (collection.length > 0) {
            const { priceMin, priceMax, volumeMin, volumeMax } = getRanges(collection);
    
            const yScale = createPriceScale(priceMin, priceMax);
            const yVolumeScale = createPriceScale(volumeMin, volumeMax);
            const xScale = createTimeScale(scopeStart, scopeEnd);
    
            const lastCandleVisible = collection.includes(this.state.lastCandle)
    
            const priceTicks = getPriceTicks({ height: rowHeight, yScale, priceMax, priceMin })
            const timeTicks = getMappedTimeTicks(scopeEnd, scopeStart, xScale, width - axisWidth)
            const timeFormatter = createTimeFormatter(resolution)
            const priceFormatter = (price: number) => price.toFixed(pricePrecision)
            const visibleOrders = orders.filter((order: IOrder) => order.price < priceMax && order.price > priceMin)
    
            return (
                <div className="candlechart_container">
                    <div className="candlechart_container__navbar">
                        <ResolutionSelect
                            selection={this.props.resolution}
                            resolutions={this.props.resolutions}
                            onResolutionChange={this.props.onResolutionChange}
                        />
                        <GenericChartSelect
                            options={[ChartTypes.Candlestick, ChartTypes.Line]}
                            selection={this.state.chartType}
                            onChange={(chartType: any) => this.setState({ chartType })}
                        />
                    </div>
                    <div
                        className="candlechart_container__row"
                        style={{ height: rowHeight }}
                        >
                        <Candles
                            width={chartWidth}
                            height={rowHeight}
                            chartType={this.state.chartType}
                            data={this.state.collection}
                            orders={visibleOrders}
                            priceTicks={priceTicks}
                            timeTicks={timeTicks}
                            xScale={xScale}
                            yScale={yScale}
                            yVolumeScale={yVolumeScale}
                            colors={colors}
                            selection={this.state.selection}
                            symbolName={this.props.symbolName}
                            drawVolume={true}
                            lastCandle={this.state.lastCandle}
                            scopeStart={this.state.scopeStart}
                            scopeEnd={this.state.scopeEnd}
                            onSectors={this.onSectors}
                            onCrosshair={this.onCrosshair}
                            onStartDrag={(prevX: number) => this.setState({ prevX })}
                            onMove={this.onChartMove}
                            onZoom={this.onZoom}
                        />
                        <Tooltip width={chartWidth} selection={this.state.selection} />
                        {visibleOrders.map((order: IOrder) =>
                            <ChartOrder
                                key={order.id}
                                order={order}
                                yScale={yScale}
                                height={rowHeight}
                                paddingRight={axisWidth}
                                onClose={() => this.props.onCancelOrders([order])}
                                onMove={(newPosition: number) => this.onOrderMove(order, newPosition)}
                            />)
                        }
                        <PriceAxis
                            width={axisWidth}
                            height={rowHeight}
                            colors={colors}
                            orders={visibleOrders}
                            yScale={yScale}
                            ticks={priceTicks}
                            priceMin={priceMin}
                            priceMax={priceMax}
                            priceFormatter={priceFormatter}
                            selection={this.state.selection}
                            showLastCandle={lastCandleVisible}
                            lastCandle={this.state.lastCandle}
                            onLeftClick={this.props.onTradingModal}
                            onContextMenu={(x: number, y: number) => null } //alert(`${x}-${y}`)}
                        />
                    </div>
                    <div className="candlechart_container__row">
                        <TimeAxis
                            width={width}
                            height={axisHeight}
                            colors={colors}
                            timeMin={scopeStart}
                            timeMax={scopeEnd}
                            xScale={xScale}
                            timeTicks={timeTicks}
                            timeFormatter={timeFormatter}
                            tooltipFormatter={tooltipFormatter}
                            selection={this.state.selection}
                            priceAxisWidth={axisWidth}
                        />
                    </div>
                </div>
            )
        } else {
            const noop = () => {}
            const noopFormatter = () => ""

            return (
            <div className="candlechart_container">
                    <div className="candlechart_container__row" style={{ height: rowHeight }}>
                        <Candles
                            width={chartWidth}
                            height={rowHeight}
                            chartType={this.state.chartType}
                            data={this.state.collection}
                            orders={[]}
                            priceTicks={[]}
                            timeTicks={[]}
                            xScale={noop}
                            yScale={noop}
                            yVolumeScale={noop}
                            colors={colors}
                            selection={this.state.selection}
                            symbolName={this.props.symbolName}
                            drawVolume={true}
                            lastCandle={this.state.lastCandle}
                            scopeStart={this.state.scopeStart}
                            scopeEnd={this.state.scopeEnd}
                            onSectors={this.onSectors}
                            onCrosshair={this.onCrosshair}
                            onStartDrag={(prevX: number) => this.setState({ prevX })}
                            onMove={this.onChartMove}
                            onZoom={this.onZoom}
                        />
                        <ResolutionSelect
                            selection={this.props.resolution}
                            resolutions={this.props.resolutions}
                            onResolutionChange={this.props.onResolutionChange}
                        />
                        <PriceAxis
                            width={axisWidth}
                            height={rowHeight}
                            colors={colors}
                            yScale={noop}
                            ticks={[]}
                            priceMin={0}
                            priceMax={1}
                            priceFormatter={noopFormatter}
                            selection={this.state.selection}
                            showLastCandle={false}
                            lastCandle={this.state.lastCandle}
                            orders={[]}
                        />
                    </div>
                    <div className="candlechart_container__row">
                        <TimeAxis
                            width={width}
                            height={axisHeight}
                            colors={colors}
                            timeMin={scopeStart}
                            timeMax={scopeEnd}
                            xScale={noop}
                            timeTicks={[]}
                            timeFormatter={noopFormatter}
                            tooltipFormatter={tooltipFormatter}
                            selection={this.state.selection}
                            priceAxisWidth={axisWidth}
                        />
                    </div>
                </div>
            )
        }
    }
}

export default Container;
