/**
 * Implements Balances Panel
 * Presentation component
 */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { actionSetBuyPrice } from '../../actions/positions'
import { actionNavigate } from '../../actions/navigation'
import { formatAmount } from '../../helpers/formatters'
import Placeholder from './Placeholder'
import BuyPrice from './BuyPrice'
import ProfitLoss from './ProfitLoss'
import "./styles.scss"
import { PageType } from '../../helpers/pageTypes'

interface ISelection {
    key: string;
    mode: string; // asc, desc, none
}
/**
 * Table header Pill with smart sorting
 */
const Pill = ({ width, title, selection, onChange }: any) => {
    let arrow = '';
    if (selection) {
        if (selection.key === title) {
            arrow = selection.mode === 'asc' ? '↓' : '↑'
        }
    }

    const onClick = () => {
        if (selection) {
            onChange({
                key: title,
                mode: selection.mode === 'asc' ? 'desc' : 'asc'
            })
        }
    }

    return (<th
        className="positions__panel__pills__pill"
        onClick={() => onClick()}
        {... {width}}
    >{title}&nbsp;{arrow}</th>)
}
/**
 * Convert from hashmap to array
 * @param total 
 * @param rates 
 * @param selection 
 */
const convertBalancesToArray = (total: any, rates: any, selection: ISelection): any[] => {
    const rawArray: any[] = [];
    Object.keys(total).forEach((key: string) => {
        const value = total[key]
        if (value > 0.0) {
            rawArray.push([key, value, getMarketValue(rates, key, value)])
        }
    })
    return sortBalances(rawArray, selection)
}

/**
 * Sort balances array by three parameters
 * @param rawArray 
 * @param selection 
 */
const sortBalances = (rawArray: any[], selection: ISelection) => {
    const { key, mode } = selection;
    const isA = (mode === 'asc')
    const stringSort = (a: any, b: any) => {
        return a[0] < b[0] ? -1 : 1;
    };
    switch (key) {
        case 'asset':
            return isA ? rawArray.sort(stringSort) : rawArray.sort(stringSort).reverse()
        case 'amount':
            return isA ? rawArray.sort((a, b) => a[1] - b[1]) : rawArray.sort((a, b) => b[1] - a[1])
        case 'market price':
            return isA ? rawArray.sort((a, b) => a[2] - b[2]) : rawArray.sort((a, b) => b[2] - a[2])
        default:
            return rawArray;
    }
}

/**
 * Get total value by multipling amount on market price
 * @param input 
 */
const getTotalFiatValue = (input: any[]): number => {
    let result = 0;
    input.forEach((item: any) => {
        result += parseFloat(item[2])
    })
    return result;
}

interface IBalancesPanelProps {
    mode: string;
    spot: any;
    margin: any;
    rates: any;
    positions: any;
    signedin: boolean;
    actionSetBuyPrice: Function;
    actionNavigate: Function;
}

const BalancesPanel = (props: IBalancesPanelProps) => {
    const { mode, spot, margin, rates, positions, signedin } = props
    const [selection, setSelection] = useState<ISelection>({ key: 'asset', mode: 'asc'})

    const balancesRows = () =>
        (<tbody className="positions__panel__balances">
        {totalBalances.map((item: any) =>
            (<tr key={item[0]} className="positions__panel__balances__item">
                <td
                    onClick={() => props.actionNavigate(PageType.assets, item[0])}
                    className="positions__panel__balances__item__col positions__panel__balances__item__col--symbol">{item[0]}</td>
                <td className="positions__panel__balances__item__col positions__panel__balances__item__col--price">{formatAmount(item[1])}</td>
                <td className="positions__panel__balances__item__col positions__panel__balances__item__col--input">
                    <BuyPrice
                        coin={item[0]}
                        positions={positions}
                        onUpdate={props.actionSetBuyPrice}
                    />
                </td>
                <td className="positions__panel__balances__item__col positions__panel__balances__item__col--marketprice">$ {item[2]}</td>
                <td className="positions__panel__balances__item__col positions__panel__balances__item__col--profitloss">
                    <ProfitLoss
                        coin={item[0]}
                        rates={rates}
                        positions={positions}
                        amount={item[1]}
                    />
                </td>
            </tr>)
        )}
        </tbody>)

    const total = mode === 'margin' ? margin : spot
    const totalBalances = convertBalancesToArray(total, rates, selection)
    const totalFiatValue = getTotalFiatValue(totalBalances);
    return (
        <div className="positions__panel">
            <table className="positions__panel__table">
                <thead className="positions__panel__pills">
                    <tr>
                        <Pill width="62px" title="asset" selection={selection} onChange={setSelection}/>
                        <Pill width="90px" title="amount" selection={selection} onChange={setSelection} />
                        <Pill width="151px" title="buy price" />
                        <Pill width="20%" title="market price" selection={selection} onChange={setSelection} />
                        <Pill width="25%" title="Profit/Loss" />
                    </tr>
                </thead>
                { signedin ? balancesRows() : null}
                {totalFiatValue > 0 && (<tfoot>
                    <tr>
                        <td>Summ:</td>
                        <td></td>
                        <td></td>
                        <td>$ {totalFiatValue.toFixed(2)}</td>
                    </tr>
                </tfoot>)}
            </table>
            { !signedin && <Placeholder title="No positions yet" text="Sign in to see your balances and positions" />}
        </div>
    )
}

/**
 * Return market value of item
 * @param rates - hash { BTC: 0.23 }
 */
const getMarketValue = (rates: any, key: string, amount: number) => {
    if (rates[key]) {
        return (rates[key] * amount).toFixed(2)
    }
    return 0.0;
}

const mapStateToProps = (state: any) =>
    ({
        signedin: state.accounts.signedin,
        mode: state.current.mode,
        spot: state.balances.spot,
        margin: state.balances.margin,
        rates: state.rates,
        positions: state.positions
    })

export default connect(mapStateToProps, { actionSetBuyPrice, actionNavigate })(BalancesPanel)