import React from 'react'
import { connect } from 'react-redux'
import { formatAmount } from '../../../helpers/formatters'

interface IBalanceBarProps {
    base: string;
    exchange: string;
    exchanges: any;
}
/**
 * Balance Bar with balance of base currency
 */
const BalanceBar = (props: IBalanceBarProps) => {
    const { base, exchanges, exchange } = props
    const value = getValue({ base, exchanges, exchange })
    return (
        <div className="create__order__balance">
            <div className="create__order__balance__label">
                Balance
            </div>
            <div className="create__order__balance__value">
                {value}
                <span>{base}</span>
            </div>
        </div>
    )
}

const getValue = ({ base, exchanges, exchange }: any): string => {
    const instance = exchanges[exchange]
    if (instance) {
        if (instance[base]) {
            return formatAmount(instance[base])
        }
        return '0.00'
    }
    return '0.00'
}

const mapStateToProps = (state: any) => ({
    base: state.current.base,
    exchange: state.current.exchange,
    exchanges: state.balances.exchanges || {}
})
export default connect(mapStateToProps)(BalanceBar)