/* eslint-disable no-restricted-syntax */
class BitfinexDatafeed {
    constructor(options = {}) {
        this.bitfinexHost = 'https://api-pub.bitfinex.com';
        this.debug = options.debug || false;
        this.barsInterval = null;
        this.symbols = [];
    }

    bitfinexSymbols() {
        return fetch(`${this.bitfinexHost}/v2/tickers?symbols=ALL`)
            .then(res => res.json())
            .then(json => json.map((item) => {
                const id = item[0];
                const baseAsset = id.slice(1, 4);
                const quoteAsset = id.slice(4, 7);
                return ({
                    symbol: id,
                    name: `${baseAsset}${quoteAsset}`,
                    description: `${baseAsset}/${quoteAsset}`,
                    baseAsset,
                    quoteAsset,
                    currency_code: quoteAsset,
                    last_price: item[7]
                });
            })).catch(() => []);
    }

    bitfinexKlines(symbol, interval, startTime, endTime, limit) {
        const url = `${this.bitfinexHost}/v2/candles/trade:${interval}:${symbol}/hist?&limit=${limit}&start=${startTime}&sort=1`; // &end=${endTime}`;

        return fetch(url)
            .then(res => res.json())
            .catch(() => []);
    }

    bitfinexLastCandle(symbol, interval) {
        const url = `${this.bitfinexHost}/v2/candles/trade:${interval}:${symbol}/last`;

        return fetch(url)
            .then(res => res.json())
            .catch(() => []);
    }

    onReady = async (callback) => {
        try {
            const symbols = await this.bitfinexSymbols();
            this.symbols = symbols;
            callback({
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: false,
                supported_resolutions: [
                    '1', '5', '15', '30', '60', '180', '360', '720', '1D', '1W', '2W', '1M'
                ]
            });
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols
                .filter((symbol) => symbol.symbol.indexOf(input) >= 0)
                .map((symbol) => ({
                    symbol: symbol.symbol,
                    full_name: symbol.name,
                    description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
                    pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
                    ticker: symbol.name
                })));
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);

        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

        // eslint-disable-next-line no-unused-vars
        const pricescale = (last) => {
            if (last > 100.0) return 100;
            if (last > 1.0) return 1000;
            return 100000000;
        };

        for (const symbol of this.symbols) {
            if (symbol.name === symbolName) {
                setTimeout(() => {
                    onSymbolResolvedCallback({
                        name: symbol.name,
                        description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
                        pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
                        ticker: symbol.symbol,
                        session: '24x7',
                        minmov: 1,
                        pricescale: pricescale(symbol.last),
                        timezone: 'UTC',
                        has_intraday: true,
                        has_daily: true,
                        has_weekly_and_monthly: true,
                        currency_code: symbol.quoteAsset
                    });
                }, 0);
                return;
            }
        }

        onResolveErrorCallback('not found');
    }

    getInterval = (resolution) => {
        const intervals = {
            1: '1m',
            5: '5m',
            15: '15m',
            30: '30m',
            60: '1h',
            180: '3h',
            360: '6h',
            720: '12h',
            D: '1D',
            '1D': '1D',
            '7D': '7D',
            W: '7D',
            '1W': '7D',
            '2W': '14D',
            M: '1M',
            '1M': '1M',
        };
        return intervals[resolution];
    }
    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toGMTString());
            console.log('To:  ', to, new Date(to * 1000).toGMTString());
        }

        const interval = this.getInterval(resolution);

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }

            if (totalKlines.length === 0) {
                onHistoryCallback([], {
                    noData: true
                });
            } else {
                onHistoryCallback(totalKlines.map(kline => ({
                        time: kline[0],
                        close: parseFloat(kline[2]),
                        open: parseFloat(kline[1]),
                        high: parseFloat(kline[3]),
                        low: parseFloat(kline[4]),
                        volume: parseFloat(kline[5])
                    })), {
                    noData: false
                });
            }
        };

        // eslint-disable-next-line no-shadow
        const getKlines = (from, to) => {
            this.bitfinexKlines(symbolInfo.ticker, interval, from, to, 1000).then(klines => {
                totalKlines = totalKlines.concat(klines);
                if (klines.length === 1000) {
                    // eslint-disable-next-line no-param-reassign
                    from = klines[klines.length - 1][0] + 1;
                    getKlines(from, to);
                } else {
                    finishKlines();
                }
                return null;
            }).catch(err => {
                console.error(err);
                onErrorCallback('Some problem');
            });
        };

        getKlines(from * 1000, to * 1000);
    }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);

        const { ticker } = symbolInfo;
        const interval = this.getInterval(resolution);
        this.barsInterval = setInterval(() => {
            this.bitfinexLastCandle(ticker, interval)
                .then((kline) => {
                    const bar = {
                        time: kline[0],
                        close: parseFloat(kline[2]),
                        open: parseFloat(kline[1]),
                        high: parseFloat(kline[3]),
                        low: parseFloat(kline[4]),
                        volume: parseFloat(kline[5])
                    };
                    onRealtimeCallback(bar);
                    return null;
                }).catch((err) => {
                    console.error(err);
                });
        }, 15000);
    }

    unsubscribeBars(subscriberUID) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        clearInterval(this.barsInterval);
    }
}

export default BitfinexDatafeed;
