/**
 * Implements a loader when we need to display
 * that we are waiting for market load
 */
import React from 'react'
import { ReactComponent as Loading } from '../assets/loader.svg'

const LoadingMarkets = () =>
    (<div
        className="candlechart_loader"
        style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'transparent'
        }}
    >
        <div className="candlechart_loader__holder">
            <Loading />
            <p>Loading market data</p>
        </div>
    </div>)

export default LoadingMarkets