/* eslint-disable eqeqeq */
/**
 * Implements a dropdown with timeframes
 */
import React, { useState } from "react";
import { Menu, MenuItem } from "./menu";
import { longResolutionFormatter, shortResolutionFormatter } from "./utils/resolutionFormatter";

interface IResolutionSelectProps {
  resolutions: any[];
  selection: any;
  onResolutionChange: any;
}

const ResolutionSelect = ({
  resolutions,
  selection,
  onResolutionChange,
}: IResolutionSelectProps) => {
    const [isOpen, setOpen] = useState<Boolean>(false)

    if (isOpen) {
        return (<>
            <div className="candlechart_container__navbar__backdrop" onClick={() => setOpen(false)} />
            <div className="candlechart_container__navbar__select candlechart_container__navbar__select--opened">
                <Menu>
                    {resolutions.map((resolution: any) =>
                        (<MenuItem
                            key={resolution}
                            title={longResolutionFormatter(resolution)}
                            active={(resolution == selection)}
                            onClick={(e: MouseEvent) => {
                                e.preventDefault()
                                setOpen(false)
                                onResolutionChange(resolution)
                            }}
                        />)
                    )}
                </Menu>
            </div>
        </>);
    }

    return (<div className="candlechart_container__navbar__select candlechart_container__navbar__select--closed" onClick={(e: any) => setOpen(!isOpen)}>
        {shortResolutionFormatter(selection)}
    </div>);
}

export default ResolutionSelect;
