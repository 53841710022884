const shortResolutionFormatter = (resolution: any): string => {
    switch (resolution) {
        case '1':
            return '1m'
        case '3':
            return '3m'
        case '5':
            return '5m'
        case '15':
            return '15m'
        case '30':
            return '30m'
        case 60:
            return '1H'
        case '60':
            return '1H'
        case '120':
            return '2H'
        case '240':
            return '4H'
        case '360':
            return '6H'
        case '480':
            return '8H'
        case '720':
            return '12H'
        case 'D':
            return '1D'
        case '1D':
            return '1D'
        case '3D':
            return '3D'
        case 'W':
            return '1W'
        case '1W':
            return '1W'
        case 'M':
            return '1M'
        case '1M':
            return '1M'
        default:
            return resolution;
    }
}

const longResolutionFormatter = (resolution: any): string => {
    switch (resolution) {
        case '1':
            return '1 minute'
        case '3':
            return '3 minutes'
        case '5':
            return '5 minutes'
        case '15':
            return '15 minutes'
        case '30':
            return '30 minutes'
        case '60':
            return '1 hour'
        case '120':
            return '2 hours'
        case '240':
            return '4 hours'
        case '360':
            return '6 hours'
        case '480':
            return '8 hours'
        case '720':
            return '12 hours'
        case 'D':
            return '1 day'
        case '1D':
            return '1 day'
        case '3D':
            return '3 days'
        case 'W':
            return '1 week'
        case '1W':
            return '1 week'
        case 'M':
            return '1 month'
        case '1M':
            return '1 month'
        default:
            return resolution;
    }
}

export {
    shortResolutionFormatter,
    longResolutionFormatter
}