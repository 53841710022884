import React from 'react'
import { connect } from 'react-redux'
import { getLastTradeFromHistory } from '../../reducers/history'

interface IDelimeterProps {
    history: any[];
    rate: any;
    precision: number;
}

const Delimeter = (props: IDelimeterProps) => {
    const { history, rate, precision } = props
    const trade = getLastTradeFromHistory(history)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ price, _, maker ] = trade
    const arrow = maker ? "↑" : "↓"

    const convPrice = parseFloat(price).toFixed(precision) || "0.0"
    const convRate = parseFloat(rate).toFixed(precision) || "0.0"

    return (
        <div className="orderbook__delimeter">
            <div className={`orderbook__delimeter__price orderbook__delimeter__price--${maker}`}>{convPrice}</div>
            <div className={`orderbook__delimeter__arrow orderbook__delimeter__arrow--${maker}`}>{arrow}</div>
            <div className="orderbook__delimeter__realprice">$ {convRate}</div>
        </div>
    )
}

const mapStateToProps = (state: any, ownProps: any) => ({
    rate: state.rates[ownProps.base] || 0.0,
    history: state.history
})

export default connect(mapStateToProps)(Delimeter)