/**
 * Watchlist state
 * Array of IWatchlistItems
 */
import { IWatchlistItem } from "../helpers/watchlist"
import { ADD, REMOVE, ACTIVATE, REMOVE_ARGS, REFRESH } from "../actions/watchlist"

const watchlist = (state: IWatchlistItem[] = [], action: any) => {
    switch (action.type) {
      case ADD:
        return [...state, action.payload ]
      case REMOVE:
        return state.filter((_: IWatchlistItem, index: number) =>
          index !== action.payload.index
        )
      case REMOVE_ARGS:
        return state.filter((item: IWatchlistItem) =>
          !(item.exchange === action.payload.exchange
          && item.base === action.payload.base
          && item.quote === action.payload.quote)
        )
      case REFRESH:
        return action.payload
      case ACTIVATE:
        return state;
      default:
        return state
    }
}



export default watchlist
