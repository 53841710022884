import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import BalanceBar from './BalanceBar'
import { createOrder, IOrderPayload, ISubmitOrderState } from '../../../core/tradingData'
import { actionOpenLogin } from "../../../actions/modal"
import { actionCreateNotification } from "../../../actions/notifications"
import { actionRefreshAccountData } from "../../../actions/backend"
import OrderButtons from './OrderButtons'
import PriceField from './PriceField'
import OrderType from './OrderType'
import AmountStep from './AmountStep'
import { storeEvent, AnalyticsEvents } from '../../../core/analytics'

interface ICreateOrderProps {
    signedin: boolean;
    account_secret: string;
    token: string;
    exchange: string;
    base: string;
    quote: string;
    tickers: any;
    markets: any;
    actionOpenLogin: Function;
    actionRefreshAccountData: Function;
    actionCreateNotification: Function;
}
/**
 * Implements createOrder
 * Checks for authentication
 */
const CreateOrder = (props: ICreateOrderProps) => {
    const [orderType, setOrderType] = useState("limit")
    const [side, setSide] = useState("buy")
    const [price, onPriceChange] = useState(0.00)
    const [stop, onStopChange] = useState(0.00)
    const [amount, onAmountChange] = useState(0.00)
    const [amountMin, setAmountMin] = useState("")
    const [amountMax, setAmountMax] = useState("")
    const [amountPrecision, setAmountPrecision] = useState(0)
    const [lock, setLock] = useState(false)
    const active = !lock && props.signedin
    const total = calculateTotal(price, amount)
    const createOrderBtnClassName = `
        create__order__create
        ${active ? 'create__order__create--active' : ''}
        ${side === 'buy' ? 'create__order__create--buy' : 'create__order__create--sell'}
        `
    const isLimitOrder: boolean = ['limit','STOP_LOSS_LIMIT'].includes(orderType)
    const isStopLimit: boolean = (orderType === 'STOP_LOSS_LIMIT')
    
    /**
     * React to pair change - set last price to last
     */
    useEffect(() => {
        const path = `${props.exchange}|${props.base}/${props.quote}`;
        /**
         * Set last price
         */
        const ticker = props.tickers[path];
        if (ticker) {
            onPriceChange(ticker.last)
        }
        /**
         * Set amount limits
         */
        const market = props.markets[path];
        if (market) {
            if (market.limits) {
                if (market.limits.amount) {
                    setAmountMin(market.limits.amount.min)
                    setAmountMax(market.limits.amount.max)
                }
            }
            if (market.precision) {
                if (market.precision.amount) {
                    setAmountPrecision(market.precision.amount)
                }
            }
        }
        /**
         * Reset amount when pair changed
         */
        onAmountChange(0.00)
    }, [props.markets, props.tickers, props.exchange, props.base, props.quote])

    const onSubmit = async () => {
        if (props.signedin) {
            if (!lock) {
                setLock(true)
                const orderPayload: IOrderPayload = {
                    exchange: props.exchange,
                    base: props.base,
                    quote: props.quote,
                    orderType,
                    amount,
                    stopPrice: stop,
                    price,
                    side
                }
                if (isLimitOrder) {
                    orderPayload.price = price
                }
                const resp: ISubmitOrderState = await createOrder(props.token, props.account_secret, orderPayload)

                storeEvent(AnalyticsEvents.createOrder, {
                    exchange: props.exchange,
                    base: props.base,
                    quote: props.quote,
                    orderType,
                    side
                })

                if (resp.status) {
                    props.actionCreateNotification({ message: "Order submitted successfully" })
                    onPriceChange(0.0)
                } else {
                    const message = resp.message ?
                        `Could not submit order: ${resp.message}`
                        : 'Could not submit order';
                    props.actionCreateNotification({ message })
                }
                props.actionRefreshAccountData()
                setLock(false)
            }
        } else {
            props.actionOpenLogin()
        }
    }
    return (
        <div className="create__order">
            <OrderType
                exchange={props.exchange}
                orderType={orderType}
                onUpdate={setOrderType}
            />
            <OrderButtons
                side={side}
                onUpdate={setSide}
            />
            <BalanceBar />
            {isStopLimit && <PriceField
                label="Stop"
                value={stop}
                disabled={false}
                currency={props.quote}
                onChange={onStopChange}
            />}
            {isLimitOrder && <PriceField
                label="Price"
                value={price}
                disabled={false}
                currency={props.quote}
                onChange={onPriceChange}
            />}
            <PriceField
                label="Amount"
                value={amount}
                disabled={false}
                currency={props.base}
                onChange={onAmountChange}
                min={amountMin}
                max={amountMax}
            />
            {(amountMin && amountMax) &&
                <div className="create__order__annotation">
                    <span> min: {amountMin} &nbsp; max: {amountMax}</span>
                </div>
            }
            <AmountStep
                side={side}
                price={price}
                amountMin={parseFloat(amountMin)}
                amountPrecision={amountPrecision}
                orderType={orderType}
                onChange={onAmountChange}
            />
            {isLimitOrder && <PriceField
                label="Total"
                value={total}
                disabled={true}
                currency={props.quote}
                onChange={() => {}}
            />}
            <div
                className={createOrderBtnClassName}
                onClick={() => onSubmit()}
            >CREATE ORDER</div>
        </div>
    )
}

const calculateTotal = (price: number, amount: number): any => {
    if (Number.isNaN(price) || Number.isNaN(amount)) {
        return 0.0
    }
    try {
        return (price * amount).toFixed(8)
    } catch (err) {
        return 0.0
    }
}

const mapStateToProps = (state: any) =>
({
    signedin: state.accounts.signedin,
    token: state.accounts.token,
    account_secret: state.accounts.account_secret,
    exchange: state.current.exchange,
    base: state.current.base,
    quote: state.current.quote,
    tickers: state.tickers,
    markets: state.markets
})

export default connect(mapStateToProps, { actionOpenLogin, actionRefreshAccountData, actionCreateNotification })(CreateOrder)