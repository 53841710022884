/**
 * Implements a data-layer
 */
import React, { Component } from 'react'
import { IChartProps, ICandle } from './utils/interfaces'
import { isOneCandle, getDefaultDistance, mergeData } from './utils/candleMath';
import Container from './Container'
import Loader from './Loader';

const colorSchema = {
    background: "#1A1A1A",
    green: "#16C466",
    red: "#D04343",
    line: '#fefefe',
    volume: {
        green: "rgba(22, 196, 102, 0.4)",
        red: "rgba(208, 67, 67, 0.4)"
    },
    selection: "#464646",
    textColor: "#FFFFFF",
    captionColor: "#959595",
    secondaryColor: "#5A5A5A",
    textFont: "13px 'Source Code Pro', 'Poppins', serif",
    titleFont: "18px 'Source Code Pro', 'Poppins', serif",
    ticks: {
        price: 'rgba(70, 70, 70, 0.3)'
    },
    axis: {
        line: "#464646",
        color: "#C7C7C7",
        tickFont: "12px 'Source Code Pro', 'Poppins', serif",
        selection: {
            background: "#464646",
            color: "#FFFFFF"
        }
    }
};

class Chart extends Component<IChartProps, any> {
    /**
     * Things like lastCandle can cause mutations in data, so we need trusted data source
     */
    rawData: ICandle[];
    /**
     * Name of subscription
     */
    realtimeSubscription: string;

    constructor(props: IChartProps) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            resolutions: [],
            resolution: 60,
            symbol: null,
            error: false,
            lastCandle: null,
            subscriptionId: '',
            preloadingData: false
        }
        this.realtimeSubscription = `lastPrice_${props.exchange}_${props.symbolName}`
        this.rawData = []
        this.getData()
    }

    /**
     * Destroy datafeed on unmount
     */
    componentWillUnmount = () => {
        this.unsubscribeRealtimeUpdates()
    }

    onResolutionChange = (resolution: any) => {
        this.props.datafeed.unsubscribeBars(this.state.subscriptionId) // unsubscribe old before new
        this.setState({
            loading: true,
            resolution
        }, () => {
            this.getData()
        })
    }

    /**
     * Handle side effects
     * @param prevProps 
     * @param prevState 
     */
    componentDidUpdate = (prevProps: IChartProps) => {
        if (this.props.symbolName !== prevProps.symbolName) {
            this.getData() // trigger reload
        }
    }

    /**
     * Some adapters may not have server time
     * @param callback
     */
    withTime = (callback: any) => {
        if (this.props.datafeed.getServerTime) {
            this.props.datafeed.getServerTime((time: number) => {
                callback(time)
            })
        } else {
            callback(Number(new Date()))
        }
    }

    /**
     * Do initial fetch
     * @param callback 
     */
    onDataFeedInitial = (callback: any) => {
        if (this.state.resolutions.length === 0) {
            this.props.datafeed.onReady(({ supported_resolutions }: any) => {
                this.setState({ resolutions: supported_resolutions })
                this.withTime(callback)
            })
        } else {
            this.withTime(callback)
        }
    }

    /**
     * Datafeed adapter
     * @param lastCandle 
     */
    onLastPrice = (e: any) => {
        const { detail } = e;
        const { data } = this.state
        const lastCandle = data[data.length - 1];
        data.splice(-1);
        lastCandle.close = detail;
        this.setState({ data: [...data, lastCandle ] })
    }

    /**
     * Datafeed adapter to comply TV standarts
     * @param lastCandle 
     */
    onLastCandle = (lastCandle: ICandle) => {
        const { data, resolution } = this.state
        if (lastCandle) {
            const lastDataCandle = data[data.length - 1];
            if (isOneCandle(lastDataCandle, lastCandle, resolution)) {
                data.splice(-1);
                this.setState({ data: [...data, lastCandle ] })
            } else {
                this.setState({ data: [...data, lastCandle ] })
            }
        }
    }

    /**
     * Lock UI and fetch
     * Do nothing if we already preloading
     */
    onMoreDataRequested = (from: number, delta: number) => {
        if (!this.state.preloadingData) {
            this.setState({
                preloadingData: true
            }, () => {
                /**
                 * Call datafeed
                 */
                const to = from - delta; // will be different for different timeframess
                this.props.datafeed.getBars(
                    this.state.symbol,
                    this.state.resolution,
                    Math.ceil(to / 1000), 
                    Math.ceil(from / 1000),
                    (candles: ICandle[]) => {
                        this.rawData = mergeData(candles, this.rawData)
                        this.setState({
                            data: this.rawData.slice(),
                            preloadingData: false
                        })
                    },
                    () => this.setState({
                        error: true,
                        preloadingData: false
                    }),
                    false);
            })
        }
    }

    /**
     * Subscribe to realtime window event
     */
    subscribeRealtimeUpdates = () => {
      window.addEventListener(this.realtimeSubscription, this.onLastPrice)
    }

    /**
     * Unsubscribe to realtime window event
     */
    unsubscribeRealtimeUpdates = () => {
        window.removeEventListener(this.realtimeSubscription, this.onLastPrice);
    }


    getData = () => {
        const { datafeed, symbolName } = this.props;
        const { resolution } = this.state
        this.rawData = []; // reset rawData because on this stage it will be invalid

        this.onDataFeedInitial((_time: number) => {
            datafeed.resolveSymbol(
                symbolName,
                (symbolData: any) => {
                    /**
                     * So what is this 1000? 
                     * TV adapter works in seconds, not ms
                     * Query: https://broker.kattana.trade/binance/BTCUSDT/chart/1h/1593703751000/1598887811000/500
                     */
                    const from = Number(new Date())
                    const to = Number(getDefaultDistance(from, resolution));
                    this.setState({ subscriptionId: `${symbolData.title}-${resolution}`, loading: true });
                    datafeed.getBars(
                        symbolData,
                        resolution,
                        Math.ceil(to / 1000), 
                        Math.ceil(from / 1000),
                        (dataReady: any) => {
                            this.rawData = dataReady;
                            this.setState({
                                symbol: symbolData,
                                data: dataReady,
                                loading: false
                            }, () => {
                                this.subscribeRealtimeUpdates();
                            })
                        },
                        () => this.setState({ error: true }),
                        false);
                    datafeed.unsubscribeBars(this.state.subscriptionId)
                    datafeed.subscribeBars(
                        symbolData,
                        resolution,
                        this.onLastCandle,
                        this.state.subscriptionId
                    )
                },
                () => this.setState({ error: true })
            );
        });
    }

    render() {
        const { loading, resolution, resolutions, data } = this.state;
        const { width, height, visibleCandles, symbolName, pricePrecision, orders } = this.props;

        if (loading) {
            return <Loader
                width={width}
                height={height}
                colors={colorSchema}
            />
        }
        return (
            <Container
                width={width}
                height={height}
                visibleCandles={visibleCandles}
                pricePrecision={pricePrecision}
                orders={orders}
                data={data}
                colors={colorSchema}
                symbolName={symbolName}
                resolution={resolution}
                resolutions={resolutions}
                onResolutionChange={this.onResolutionChange}
                onCancelOrders={this.props.onCancelOrders}
                onTradingModal={this.props.onTradingModal}
                onMoreDataRequested={this.onMoreDataRequested}
            />
        )
    }
}


export default Chart