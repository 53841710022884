/**
 * Contains connection state and link to last DEX blocks
 */
import { BNB_BLOCK_MINED, ETH_BLOCK_MINED } from "../actions/backend";

export interface IBackendState {
  ethBlock: number;
  bnbBlock: number;
}

const backendReducer = (
  state: IBackendState = { ethBlock: 0, bnbBlock: 0 },
  action: any
) => {
  switch (action.type) {
    case ETH_BLOCK_MINED:
      return {
        ...state,
        ethBlock: action.payload,
      }
    case BNB_BLOCK_MINED:
      return {
        ...state,
        bnbBlock: action.payload,
      }
    default:
      return state;
  }
};

export default backendReducer;
