/**
 * Implements VolumeChart
 */
import React from 'react'
import SimpleLineChart from './SimpleLineChart'

const VolumeChart = ({ data }: any) => {
    const stroke = isRising(data) ? '#16C466' : '#D04343'
    return (<SimpleLineChart
        width={300}
        height={55}
        data={data}
        stroke={stroke}
        title={getChange(data)}
    />)
}

/**
 * If first elements lower than last than trend is rising
 * @param dots 
 */
const isRising = (dots: number[]) => (dots[0] < dots[dots.length - 1])

/**
 * Difference between last and first
 * @param dots 
 */
const getChange = (dots: number[]) => {
    const percentage = ((dots[dots.length - 1] - dots[0]) / dots[0]) * 100.0
    return `${percentage > 0.0 ? '+' : ''}${percentage.toFixed(0)}%`
}

export default VolumeChart