/**
 * Implements a smart placeholder
 * accepts title and text
 */
import React from 'react'
import { ReactComponent as PlaceholderImage } from './Placeholder.svg'
import "./styles.scss"

interface IPlaceholderProps {
    title: string;
    text: string;
}

const Placeholder = ({ title, text }: IPlaceholderProps) => (
        <div className="positions__placeholder">
            <PlaceholderImage />
            <div className="positions__placeholder__title">{title}</div>
            <div className="positions__placeholder__text">{text}</div>
        </div>
    )

export default Placeholder