import React from 'react'
import { IWatchlistItem } from "../../../helpers/watchlist"

/**
 * Uppercase first letter
 */
const capitalize = (s: string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export interface ISort {
    key: string; // 'name'
    mode: string; // asc, desc
}

interface IThPillProps {
    width: any;
    field?: string;
    sort?: ISort;
    onChange?: Function;
    children: React.ReactChild;
}
/**
 * Decorate pills
 * Works for table and watchlist
 */
const ThPill = ({ width, field, sort, onChange, children }: IThPillProps) => {
    let arrow = ''
    if (sort) {
        if (sort.key === field) {
            arrow = sort.mode === 'asc' ? '↓' : '↑'
        }
    }

    const onClick = () =>
    onChange && field &&
        onChange({
            key: field,
            mode: sort ? (sort.mode === 'asc' ? 'desc' : 'asc') : 'asc'
        })

    const isFlexible = field === 'volume'
    const grow = isFlexible ? 1 : 0

    return (<div
        style={{ flex: `${grow} 1 ${width}` }}
        onClick={onClick}
        className="tickers__modal__th__pill">{children}&nbsp;{arrow}</div>)
}

const decoratePair = ({ base, quote }: IWatchlistItem) =>
    (quote ? `${base}/${quote}` : `${base}`)

const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
})
const decorateVolume = (item: any, rates: any, exchange: string) => {
    if (item.symbol) {
        const [ base, quote ] = item.symbol.split('/')
        // if (exchange === 'bitmex') {
        //     const numeric = formatter.format(Math.round(parseFloat(item.quoteVolume)))
        //     if (quote) {
        //         return `${numeric} ${quote}`
        //     }
        //     return `${numeric}`
        // }
        if (rates[base]) {
            return formatter.format(Math.round(item.baseVolume * rates[base]))
        }
        if (rates[quote]) {
            if (quote === 'WETH') {
                return formatter.format(Math.round(item.quoteVolume * rates.ETH))
            }
            return formatter.format(Math.round(item.quoteVolume * rates[quote]))
        }
        //
        return `${parseFloat(item.baseVolume).toFixed(2)} ${base}`
    }
    return ""
}

const decorateDEXVolume = (usdVolume: string) =>
    formatter.format(parseFloat(usdVolume))

const decorateVolumeWatchlist = (item: any, rates: any, tickerItem: any) => {
    try {
        if (tickerItem) {
            const { base, quote, exchange } = item
            if (exchange === 'bitmex') {
                if (quote) {
                    return `${parseFloat(tickerItem.quoteVolume).toFixed(2)} ${quote}`
                }
                return `$ ${parseFloat(tickerItem.quoteVolume).toFixed(2)}`
            }
            if (rates[base]) {
                return `$ ${(tickerItem.baseVolume * rates[base]).toFixed(2)}`
            }
            return `${tickerItem.baseVolume} ${base}`
        }
        return ''
    } catch (e) {
        console.error(e)
        return ''
    }
}

export {
    capitalize,
    ThPill,
    decoratePair,
    decorateVolume,
    decorateVolumeWatchlist,
    decorateDEXVolume
}