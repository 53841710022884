import React from 'react'
import { connect } from 'react-redux';
import { actionNewTab, actionSelectTabByIndex, actionCloseTab, actionDragoutTab } from "../../actions/tabs";
import { actionOpenTickers, actionOpenAddWidget } from "../../actions/modal"
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as AddWidget } from './add_widget.svg';
import { ReactComponent as Chevron } from './arr_down.svg';

import './styles.scss';

interface ITabItemProps {
    title: string;
    index: number;
    active: boolean;
    onClick: any;
    onClose: any;
    canClose: boolean;
    onDragout: Function;
}

let draggedId: number = 0;

const onDragLeave = (e: any, callback: Function) => {
    if (!e.clientX && !e.clientY) {
        callback(draggedId);
    }
}
const onDragStart = (e: any, id: number, callback: Function) => {
    e.target.classList.add('dragged');
    draggedId = id;
    document.body.ondragleave = (e) => onDragLeave(e, callback);
}
const onDragEnd = (e: any) => {
    e.target.classList.remove('dragged')
    draggedId = -1;
    document.body.ondragleave = () => {}
}

const TabItem = (props: ITabItemProps) => {
    const { title, index, active, onClick, onClose, canClose, onDragout } = props;
    const classList = ["tabsbar__panel__tab"];
    if (active) {
        classList.push("tabsbar__panel__tab--active");
    }
    return <div
        onDragStart={(e) => onDragStart(e, index, onDragout)}
        onDragEnd={onDragEnd}
        className={classList.join(' ')}
        draggable="true"
        onClick={onClick}>
        {title}
        {canClose && <span className="tabsbar__panel__tab__close" onClick={onClose}/>}
    </div>
}

interface ITabsBarProps {
    tabs: any[];
    exchange: string;
    base: string;
    quote: string;
    actionOpenTickers: Function;
    actionOpenAddWidget: Function;
    actionSelectTabByIndex: Function;
    actionNewTab: Function;
    actionCloseTab: Function;
    actionDragoutTab: Function;
}
/**
 * Entry component for bar
 */
const TabsBar = (props: ITabsBarProps) => {
    const { tabs, exchange, base, quote } = props;
    const title = `${exchange} ${base}${quote ? `/${quote}` : ''}`; // Binance BTC/USD
    return (
        <div className="tabsbar__panel">
            <div className="tabsbar__panel__pick" onClick={() => props.actionOpenTickers()}>
                <Search /><span>{title}</span><Chevron />
            </div>
            <div className="tabsbar__panel__add_widget" onClick={() => props.actionOpenAddWidget()}>
                <AddWidget /><span>Add Widget</span>
            </div>
            {tabs.map((tab: any, index: number) =>
                <TabItem
                    index={index}
                    title={tab.title}
                    active={tab.active}
                    key={index}
                    canClose={tabs.length > 1}
                    onClick={() => props.actionSelectTabByIndex(index)}
                    onClose={() => props.actionCloseTab(index)}
                    onDragout={() => props.actionDragoutTab(index)}
                />
            )}
            <div
                className="tabsbar__panel__plus"
                onClick={(e) => props.actionNewTab()}
            />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    tabs: state.tabs,
    exchange: state.current.exchange,
    base: state.current.base,
    quote: state.current.quote
});

export default connect(mapStateToProps,
    {
        actionNewTab,
        actionSelectTabByIndex,
        actionOpenTickers,
        actionOpenAddWidget,
        actionCloseTab,
        actionDragoutTab
    })(TabsBar);