import { combineReducers } from 'redux'
import notifications from './notifications' // notifications
import accounts from './accounts' // accounts
import navigation from './navigation' // navigation
import tickers from './tickers' // current tickers
import rates from './rates' // current rates 
import watchlist from './watchlist' // watchlist
import widgets from './widgets' // widgets for current tab
import tabs from './tabs' // tabs
import current from './current' // selection
import backend from './backend' // backend state
import history from './history' // trading history
import orderbook from './orderbook' // orderbook
import markets from './markets' // market info
import balances from './balances' // balances
import orders from './orders' // balances
import positions from './positions'
import trading_accounts from './trading_accounts' // trading_accounts
import dex_trades from './dex_trades'
import modal from './modal'

export default combineReducers({
  notifications,
  accounts,
  navigation,
  tickers,
  rates,
  watchlist,
  widgets,
  tabs,
  current,
  backend,
  history,
  orderbook,
  markets,
  balances,
  orders,
  positions,
  modal,
  trading_accounts,
  dex_trades
})