/**
 * Implement a DEX trading history
 * How-to calculate a price? 
 * Imagine pair WBTC/WETH
 * We got 3 coins for 10 coins, so price of 1 BTC is 10 coins / 3 coins
 */
import React from "react";
import { connect } from "react-redux"
import { IDexSwap } from "../../sagas/coroutines/uniswap";
import { formatTrade, formatTimestamp } from "./formatters"
import { ReactComponent as Loading } from './loader.svg'
import "./styles.scss"

/**
 * Component for uniswap and its forks
 * @param props 
 * @returns 
 */
const UniswapDexTrades = (props: any) => {
  const { loading, trades } = props

  const openTx = (tx: string) =>
    window.open(`https://etherscan.io/tx/${tx}`)

  if (loading) {
    return (<div className="dexTrades">
        <div className="dexTrades__loader">
            <Loading />
        </div>
    </div>)
  }

  return (<div className="dexTrades">
    <table className="dexTrades__table">
      <thead>
        <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Price</th>
            <th>Send</th>
            <th>Receive</th>
            <th>Txn Value ($)</th>
            <th>Txn Hash</th>
        </tr>
      </thead>
      <tbody>
        {trades.map((trade: IDexSwap) => {
            const { transaction, timestamp } = trade
            const { side, send, receive, price, sendToken, receiveToken } = formatTrade(trade)

            return (<tr key={trade.id}>
                <td>{formatTimestamp(timestamp)}</td>
                <td>
                    <span className={`dexTrades__table__${side}`}>{side}</span>
                </td>
                <td>{price}</td>
                <td><span className="dexTrades__table__nowrap">{send} {sendToken.symbol}</span></td>
                <td><span className="dexTrades__table__nowrap">{receive} {receiveToken.symbol}</span></td>
                <td>{parseFloat(trade.amountUSD).toFixed(2)}</td>
                <td onClick={() => openTx(transaction.id)}>
                    <span className="dexTrades__table__link">{transaction.id.substring(0, 10)}</span>
                </td>
            </tr>)
        })}
      </tbody>
    </table>
</div>)
};

/**
 * Component for uniswap and its forks
 * @param props 
 * @returns 
 */
 const PancakeDexTrades = (props: any) => {
  const { loading, trades } = props

  const openTx = (tx: string) =>
    window.open(`https://bscscan.com/tx/${tx}`)

  if (loading) {
    return (<div className="dexTrades">
        <div className="dexTrades__loader">
            <Loading />
        </div>
    </div>)
  }

  return (<div className="dexTrades">
    <table className="dexTrades__table">
      <thead>
        <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Price</th>
            <th>Send</th>
            <th>Receive</th>
            <th>Txn Value ($)</th>
            <th>Txn Hash</th>
        </tr>
      </thead>
      <tbody>
        {trades.map((trade: IDexSwap) => {
            const { transaction, timestamp } = trade
            const { side, send, receive, price, sendToken, receiveToken } = formatTrade(trade)

            return (<tr key={trade.id}>
                <td>{formatTimestamp(timestamp)}</td>
                <td>
                    <span className={`dexTrades__table__${side}`}>{side}</span>
                </td>
                <td>{price}</td>
                <td><span className="dexTrades__table__nowrap">{send} {sendToken.symbol}</span></td>
                <td><span className="dexTrades__table__nowrap">{receive} {receiveToken.symbol}</span></td>
                <td>{parseFloat(trade.amountUSD).toFixed(2)}</td>
                <td onClick={() => openTx(transaction.id)}>
                    <span className="dexTrades__table__link">{transaction.id.substring(0, 10)}</span>
                </td>
            </tr>)
        })}
      </tbody>
    </table>
</div>)
};
/**
 * Generic wrapper
 * @param props 
 * @returns 
 */
const DexTrades = (props: any) => {
    const { exchange } = props
    switch (exchange) {
        case 'uniswap':
          return <UniswapDexTrades {...props} />
        case 'pancakeswap':
          return <PancakeDexTrades {...props} />
        default:
            return null
    }
}

const mapStateToProps = (state: any) => ({
    exchange: state.current.exchange,
    loading: state.dex_trades.loading,
    trades: state.dex_trades.trades
})

export default connect(mapStateToProps)(DexTrades);

