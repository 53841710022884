/**
 * Implements DEX wallet
 */
import React, { useEffect, useState } from "react";
import { ethCoinDiscovery } from "../../core/coins";
import Placeholder from "./Placeholder";

interface IMetamaskPanelProps {
  height: any;
}

interface IDexWallet {
  account: string;
  balance: string;
}

/**
 * Fetch balance for each wallet
 */
// for await (const account of accounts) {
//     const balance = await provider.request({ method: 'eth_getBalance', params: [account, "latest"] })
//     result.push({ account, balance });
// }

/**
 * Get wallet data and balance
 * @param provider
 */
const getWalletData = async (provider: any): Promise<any[]> =>
  provider.request({ method: "eth_requestAccounts" });

const getNumericBalance = (coin: any) =>
  coin.tokenInfo.symbol === "ETH"
    ? coin.balance
    : coin.balance * 10 ** -coin.tokenInfo.decimals;

const formatCoinRate = (coin: any) => {
  const rate = coin.tokenInfo.price.rate;
  return rate > 0 ? rate.toFixed(2) : rate.toFixed(8);
};

const openCoinInfo = (coin: any) => {
  if (coin.tokenInfo.website) {
    window.open(coin.tokenInfo.website);
  }
};

interface IWalletCoinsProps {
  wallet: string;
}

/**
 * Renders coins per wallet
 * @param props 
 * @returns 
 */
const WalletCoins = (props: IWalletCoinsProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [coins, setCoins] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const resp = await ethCoinDiscovery(props.wallet)
            if (resp) {
                setCoins(resp)
            }
            setLoading(false)
        } catch (err) {
            console.warn(err)
            setLoading(false)
        }
    }

    fetchData();
  }, [props.wallet]);

  if (loading) {
    return null
  }

  return (
    <table>
      <thead>
        <tr>
          <td>asset</td>
          <td>amount</td>
          <td>market price</td>
          <td>rate</td>
        </tr>
      </thead>
      <tbody>
        {coins.map((coin: any) => (
          <tr key={coin.tokenInfo.address}>
            <td onClick={() => openCoinInfo(coin)}>
              <span>{coin.tokenInfo.symbol}</span>
            </td>
            <td>{getNumericBalance(coin)}</td>
            <td>
              $ {(getNumericBalance(coin) * coin.tokenInfo.price.rate).toFixed(2)}
            </td>
            <td>$ {formatCoinRate(coin)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const MetamaskPanel = (props: IMetamaskPanelProps) => {
  const [available, setAvailalble] = useState(false);
  const [wallets, setWallets] = useState<string[]>([])

  useEffect(() => {
    const fn = async () => {
      const provider = (window as any).ethereum;
      if (provider) {
        try {
          const wallets = await getWalletData(provider);
          if (wallets) {
            setWallets(wallets)
            setAvailalble(true);
          } else {
            setAvailalble(false);
          }
        } catch (err) {
          console.error("web3", err);
          setAvailalble(false);
        }
      } else {
        setAvailalble(false);
      }
    };
    fn();
  }, []);
  const { height } = props;

  if (!available) {
    return (
      <div className="positions__panel">
        <div
          className="positions__panel__orders"
          style={{ height: height - 90 }}
        >
          <Placeholder
            title="No etherium wallet found"
            text="Connect etherium wallet to continue"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="positions__panel">
      <div
        className="positions__panel__orders"
        style={{ height: height - 90 }}
      >
        {wallets.map((wallet: string) =>
            (<WalletCoins
                key={wallet}
                wallet={wallet}
            />)
        )}
      </div>
    </div>
  );
};

export default MetamaskPanel;
