/**
 * Implements Trading history widget
 */
import React from 'react'
import { connect } from 'react-redux'
import "./styles.scss"
import { formatAmount } from '../../helpers/formatters'

/**
 * Haha!
 * reference: https://stackoverflow.com/questions/8935414/getminutes-0-9-how-to-display-two-digit-numbers
 */
const formatDate = (timestamp: string) => {
    const timeString = new Date(timestamp).toTimeString()
    const minutes = timeString.slice(3, 5);
    const hours   = timeString.slice(0, 2);
    const seconds = timeString.slice(6, 8);
    return `${hours}:${minutes}:${seconds}`
}

/**
 * History item format:
 * 0 - price
 * 1 - amount
 * 2 - side (true / false)
 * 3 - ISO String
 */

 const TradingHistory = (props: any) => {
    const { data, height } = props;
    return (
        <div className="history" style={{ height }}>
            <div className="history__header">
                <span className="history__header__left">Price</span>
                <span className="history__header__right">Quantity</span>
                <span className="history__header__right">Time</span>
            </div>
            <div className="history__items">
                {data.map((historyItem: any) =>
                    (<div key={`${historyItem[3]}-${historyItem[0]}-${historyItem[1]}-${Math.random()}`} className={`history__items__row history__items__row--${historyItem[2]}`}>
                        <div className="history__items__row__price">{historyItem[0]}</div>
                        <div className="history__items__row__quantity">{formatAmount(historyItem[1])}</div>
                        <div className="history__items__row__total">{formatDate(historyItem[3])}</div>
                    </div>)
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    data: state.history
})

export default connect(mapStateToProps)(TradingHistory);
