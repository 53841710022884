import React from 'react'
import { ReactComponent as CloseIcon } from '../LoginModal/close.svg'
import "./styles.scss"

const TradingModal = (props: any) => {
    return (
        <div className="trading__modal">
            <div className="trading__modal__header">
                <span className="trading__modal__header__caption">CREATE ORDER</span>
                <span className="trading__modal__header__close" onClick={() => props.actionCloseModal()}><CloseIcon /></span>
            </div>
        </div>
    )
}

export default TradingModal;