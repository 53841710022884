/**
 * This component binds search keys for search modal
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook';
import { connect } from 'react-redux';
import { actionShowModal } from '../actions/modal'
import ModalTypes from '../helpers/modals';

const SearchKeybinding = (props: any) => {
    useHotkeys('cmd+f', (e: any) => {
        e.preventDefault()
        props.actionShowModal(ModalTypes.search)
    })
    return null
}

export default connect(null, { actionShowModal })(SearchKeybinding)
