import axios from "axios"
import { analytics } from "./endpoints"

interface NewsSource {
    title: string;
    region: string;
    domain: string;
    path:  string | null;
}
interface NewsCurrency {
    code: string;
    title: string;
    slug: string;
    url: string;
}

export interface INewsItem {
    kind: string;
    domain: string;
    source: NewsSource;
    title: string;
    slug: string;
    currencies: NewsCurrency[];
    id: number;
    url: string;
    votes: any;
    created_at: string;
    published_at: string;
}
/**
 * Fetch last news 
 */
const fetchLastNews = async (): Promise<INewsItem[]> => {
    try {
        const { data } = await axios(`${analytics}/news`)
        const { items } = data

        return items
    } catch (err) {
        console.error(err)

        return []
    }
}
/**
 * Fetch last news for coin
 */
const fetchLastNewsCoin = async (coin: string): Promise<INewsItem[]> => {
    try {
        const { data } = await axios(`${analytics}/news/${coin}/`)
        const { items } = data

        return items
    } catch (err) {
        console.error(err)

        return []
    }
}

export {
    fetchLastNews,
    fetchLastNewsCoin
}