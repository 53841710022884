/**
 * Contains constants to map exchange names to avoid typos
 */
export enum EExchangeNames {
    BINANCE = "binance",
    BITTREX = "bittrex",
    HITBTC = "hitbtc",
    OKEX = "okex",
    KUCOIN = "kucoin",
    UNISWAP = "uniswap",
    SUSHISWAP = "sushiswap",
    PANCAKESWAP = "pancakeswap"
}
export const EXCHANGES_LIST: string[] = [
    EExchangeNames.BINANCE,
    EExchangeNames.BITTREX,
    EExchangeNames.HITBTC,
    // 'okex',
    // 'kucoin',
    EExchangeNames.UNISWAP,
    // 'sushiswap',
    EExchangeNames.PANCAKESWAP
]

export const DEX_LIST: string[] = [
    EExchangeNames.UNISWAP,
    EExchangeNames.SUSHISWAP,
    EExchangeNames.PANCAKESWAP
]

export const TICKERS_EXCHANGES_LIST: string[] = [
    EExchangeNames.BINANCE,
    EExchangeNames.HITBTC,
    EExchangeNames.UNISWAP,
    EExchangeNames.PANCAKESWAP
]