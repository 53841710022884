/**
 * Rates state
 * Rates are key-value hash
 * Key - base currency
 * Value - number of USD price
 */
import { UPDATE } from "../actions/rates"

const rates = (state = {}, action: any) => {
    switch (action.type) {
      case UPDATE:
        return action.payload
      default:
        return state
    }
  }
  export default rates