/**
 * Ported from desktop app
 * spaghetti
 */

/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { isEqual } from 'lodash';
import './styles.scss';
import palette from '../palette.json';
import { other as otherSettings } from '../helper';

const invalidSymbols = []

const bem = {
  container: 'kt-tp-exposure__container'
};

const paddings = {
  axisPaddingX: 30,
  axisPaddingY: 20,
  barPaddingX: 50,
  barPaddingY: 10
};

const getColor = (index) => palette.medium[index];

const getExchangeStyles = (exposure, viewHeight) => {
  const decimalExposure = exposure / 100.0;
  const exchangeHeight = decimalExposure * viewHeight;
  const exchangeTop = (1 - decimalExposure) * viewHeight;

  return {
      exchangeHeight,
      exchangeTop
  };
};

const otherCurrencyPercentFormatter = (percent) => (percent <= 0.99) ? '<1%' : `${percent}%`;

const getExposure = (a, b) => Math.round((a / b) * 100);
export default class ExchangeExposureChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            convertedData: [],
            width: 0,
            height: 0,
            ready: false,
            tooltip: false
        };
        this.divElement = null;
    }
    componentWillMount() {
        const { exchanges, positions, rates } = this.props;
        const { convertedData } = this.convertExchanges(exchanges, positions, rates);
        this.setState({ convertedData });
    }

    componentDidMount() {
      const height = this.divElement.clientHeight;
      const width = this.divElement.clientWidth;
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ height, width, ready: true });
    }

    componentWillReceiveProps(nextProps) {
        const positionsChanged = !isEqual(nextProps.positions, this.props.positions);
        const ratesChanged = !isEqual(nextProps.rates, this.props.rates);
        const exchangesChanged = !isEqual(nextProps.rates, this.props.exchanges);
        if (positionsChanged || ratesChanged || exchangesChanged) {
            const { convertedData } = this.convertExchanges(nextProps.exchanges, nextProps.positions, nextProps.rates);
            this.setState({ convertedData });
        }
    }

    componentDidCatch(e) {
      console.log(e);
      this.setState({ ready: false });
    }

    /**
     * Calculate summ of exchange balances
     */
    calculateExchagePortfolioSumm = (balances, rates) => {
      let summ = 0;
      Object.entries(balances).forEach(([k, value]) => {
          if (value) {
              let rate = rates[k] || 1.0;
              if (invalidSymbols.includes(k)) {
                rate = 0;
              }
              const amount = value * rate;
              summ += amount;
          }
      });

      return summ;
    }
    /**
      * Calculate summ for exchange
      * Binance - 1000$ ( total - 2000, than binance - 50%)
    */
    calculateExchangePortfolioValues = (exchangesKeys, exchanges, rates) => {
      const exchangesPortfolios = {};
      exchangesKeys.forEach((key) => {
          const exchange = exchanges[key];
          if (exchange) {
              exchangesPortfolios[key] = this.calculateExchagePortfolioSumm(exchange, rates);
          }
      });

      return exchangesPortfolios;
    }

    /**
     * Calculate items exposure and reduce small coins
     */
    calculateItems = (balances, rates, portfolioSumm) => {
      const rawItems = Object.entries(balances).map(([k, value]) => {
        let rate = rates[k] || 1.0;
        if (invalidSymbols.includes(k)) {
          rate = 0;
        }
        const amount = value * rate;
        const exposure = getExposure(amount, portfolioSumm);
        return [k, exposure];
      });

      return ({
        items: rawItems.filter(i => i[1] > otherSettings.rate),
        smallItems: rawItems.filter(i => i[1] < otherSettings.rate)
      });
    }

    /*
    * Add coins to other if < less than rate
    */
    calculateOther = (smallItems, items) => {
      const exposure = smallItems.reduce((a, b) => a + b[1], 0);
      if (exposure > 1) {
        items.push([otherSettings.title, exposure, smallItems]);
      }
    }

    convertExchanges = (exchangesKeys, rawPositions = {}, rates = {}) => {
        const exchangesPortfolios = this.calculateExchangePortfolioValues(exchangesKeys, rawPositions, rates);
        /**
         * Calculate exchange rates to render bars in percent
         */
        const convertedData = exchangesKeys
        .map((key) => {
            const exchange = rawPositions[key]
            if (exchange) {
                const portfolioSumm = exchangesPortfolios[key];
                const exchangeExposure = getExposure(portfolioSumm, this.props.totalValue); // this exchange has 30% of portfolio
                const { items, smallItems } = this.calculateItems(exchange, rates, portfolioSumm); // Items exposure calculation
                this.calculateOther(smallItems, items); // Items less than 10%
                return {
                    name: key,
                    exposure: exchangeExposure,
                    items
                };
            }
            return {
                name: key,
                exposure: [],
                items: []
            };
        }).filter(e => e.items.length > 0);

        return ({
          convertedData
        });
    }
    showOtherTooltip = (e) => {
      const { layerX, layerY } = e.nativeEvent;
      this.setState({
        tooltip: true,
        tooltipX: layerX + 20,
        tooltipY: layerY
      });
    }
    moveOtherTooltip = (e) => {
      const { layerX, layerY } = e.nativeEvent;
      this.setState({
        tooltipX: layerX + 20,
        tooltipY: layerY
      });
    }
    hideOtherTooltip = () => {
      this.setState({ tooltip: false });
    }
    /**
     * Render exchange bar
     */
    renderBar = ({ items, x, exchangeTop, exchangeHeight, onSelect, selection, tooltip, viewHeight }) => {
      let lastEnd = exchangeTop + paddings.barPaddingY;
      return items.map((currency, ndex) => {
        const name = currency[0];
        const exposure = currency[1] / 100.0;
        const other = currency[2];
        //
        const isOther = (name === otherSettings.title);
        const itemHeight = exchangeHeight * exposure;
        lastEnd += itemHeight;
        const startY = lastEnd - itemHeight;
        const textY = startY + (itemHeight / 2);
        const textX = x + 30;
        const textVisible = (itemHeight > 15);
        let itemWidth = 80;
        const mixins = {};
        if (selection.includes(name)) {
          itemWidth = 96;
          mixins.transform = 'translate(-8, 0)';
        }
        if (isOther) {
          mixins.onMouseEnter = this.showOtherTooltip;
          mixins.onMouseLeave = this.hideOtherTooltip;
          mixins.onMouseMove = this.moveOtherTooltip;
        }

        return (<g key={currency} {...mixins} onClick={() => onSelect([name])}>
          <rect
            x={x}
            y={startY}
            width={itemWidth}
            height={itemHeight}
            fill={getColor(ndex)}
            key={currency}
          />
          {textVisible && (<text
            x={textX}
            y={textY}
            dominantBaseline="middle"
            textAnchor="middle"
          >
            <tspan
              dx={10}
              fillOpacity={0.44}
              fill="#ffffff"
            >{name}</tspan>
            <tspan
              dx={10}
              fill="#ffffff"
            >{currency[1]}%</tspan>
          </text>)}
          {(tooltip && isOther && other.length > 0) && this.renderOtherTooltip(other, viewHeight)}
        </g>);
      });
    }

    renderOtherTooltip = (input, viewHeight) => {
      const other = input.slice();
      if (other.length > 4) {
        other.length = 4;
      }
      const { tooltipX } = this.state;
      let { tooltipY } = this.state;
      const height = (other.length * 20) + 10;
      const width = 116;
      const isFlipped = (height + tooltipY) > viewHeight;
      if (isFlipped) {
        tooltipY -= height;
      }

      return (<g id="tooltip">
        <rect
          x={tooltipX}
          y={tooltipY}
          rx={3}
          ry={3}
          width={width}
          height={height}
          fill="#0c0c0c"
          fillOpacity={0.9}
        />
        {other.map((otherCurrency, index) => {
          const y = (index * 20) + tooltipY + 20;
          return (<text
            x={tooltipX + 20}
            y={y}
            fontSize="13px"
            key={otherCurrency}
          >
            <tspan
              fill="#c7c7c7"
            >{otherCurrency[0]}:</tspan>
            <tspan
              x={tooltipX + 85}
              fill="#ffffff"
              textAnchor="middle"
            >{otherCurrencyPercentFormatter(otherCurrency[1])}</tspan>
          </text>);
        })}
      </g>);
    }

    render() {
        const { selection, onSelect } = this.props;
        const { convertedData, width, height, ready, tooltip } = this.state;
        const viewport = `0 0 ${width} ${height}`;
        const viewHeight = height - paddings.axisPaddingX;

        if (!ready) {
          return (<div className={bem.container} ref={(ref) => { this.divElement = ref; }} />);
        }

        return (
          <div className={bem.container} ref={(ref) => { this.divElement = ref; }}>
            <svg viewport={viewport}>
              <g id="lines">
                <rect
                  x={paddings.axisPaddingX}
                  y={10}
                  height={viewHeight}
                  width={1}
                  fill="#464646"
                />
                {[1.0, 0.8, 0.6, 0.4, 0.2, 0].map((level) => {
                  const y = (viewHeight * (1 - level)) + 10;
                  return (<g key={level}>
                    <text
                      x={paddings.axisPaddingX - 10}
                      y={y + 5}
                      width={23}
                      textAnchor="end"
                      fill="#777677"
                    >
                      {level * 100}
                    </text>
                    <rect
                      x={paddings.axisPaddingX}
                      y={y}
                      width={width}
                      height={1}
                      fill="#464646"
                      fillOpacity={(level !== 0 ? 0.3 : 1.0)}
                    /></g>);
                })}
              </g>
              <g id="exchanges">
                {convertedData.map((exchange, index) => {
                  const { name, exposure, items } = exchange;
                  const { exchangeHeight, exchangeTop } = getExchangeStyles(exposure, viewHeight);
                  const x = ((index + 1) * paddings.barPaddingX) + paddings.axisPaddingX + (index * 80);
                  const textX = x + 20;
                  return (<g key={name}>
                    <text
                      x={textX}
                      y={viewHeight + 28}
                      width={80}
                      fontSize="13px"
                      fill="#777677"
                    >
                      {name}
                    </text>
                    {this.renderBar({ items, x, exchangeTop, exchangeHeight, onSelect, selection, tooltip, viewHeight })}
                  </g>);
                })}
              </g>
            </svg>
          </div>
        );
    }
}

// ExchangeExposureChart.propTypes = {
//   positions: PropTypes.shape({}).isRequired,
//   exchanges: PropTypes.shape({}).isRequired,
//   rates: PropTypes.shape({}).isRequired,
//   selection: PropTypes.arrayOf(PropTypes.string).isRequired,
//   onSelect: PropTypes.func.isRequired
// };
