/**
 * Implements Presentation component of Watchlist
 * Logic held under reducer
 */
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { ReactComponent as PencilIcon } from "./pencil.svg"
import { ReactComponent as EmptyWatchlistIcon } from "./empty_small_watchlist.svg"
import WatchlistItem from './WatchlistItem'
import { IWatchlistItem } from '../../helpers/watchlist'
import { actionSwitchCurrent } from '../../actions/current'
import { actionRemoveWatchlist } from '../../actions/watchlist'

import "./styles.scss"

interface IWatchlistProps {
    watchlist: IWatchlistItem[]
    tickers: any;
    current: any;
    actionSwitchCurrent: Function;
    actionRemoveWatchlist: Function;
};

const Watchlist = (props: IWatchlistProps) => {
    const { watchlist, tickers, current } = props;

    const [filterValue, setFilter] = useState('')
    const [editing, setEditing] = useState(false)

    const lowercasedFilterValue = filterValue.toLowerCase()
    const items = watchlist
        .filter((item: any) => item.title.toLowerCase().includes(lowercasedFilterValue))
    return (
        <>
            <div className="watchlist__searchfilter">
                <input
                    className="watchlist__searchfilter__input"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filterValue}
                    placeholder="SEARCH"
                />
                <div
                    className="watchlist__searchfilter__edit"
                    onClick={() => setEditing(!editing)}
                >
                    <PencilIcon />
                </div>
            </div>
            {/* <Filter
                name="pair"
                title="PAIR"
                onClick={() => {}}
            />
            <Filter
                name="volume"
                title="VOLUME"
                onClick={() => {}}
            />
            <Filter
                name="change"
                title="CHANGE"
                onClick={() => {}}
            /> */}
            <div className="watchlist__items">
                <div className="watchlist__pills">
                    <div className="watchlist__pills__pill">Pair</div>
                    <div className="watchlist__pills__pill">Volume</div>
                    <div className="watchlist__pills__pill">Change</div>
                </div>
                {(items.length === 0) &&
                    (<div className="watchlist__placeholder">
                        <EmptyWatchlistIcon />
                    </div>)
                }
                {items.map((item, index) =>
                    <WatchlistItem
                        key={index}
                        tickers={tickers}
                        item={item}
                        onClick={props.actionSwitchCurrent}
                        onDelete={() => props.actionRemoveWatchlist(index)}
                        active={isActive(item, current)}
                        canDelete={editing}
                    />
                )}
            </div>
        </>
    )
}

const isActive = (item: any, current: any) =>
    (item.exchange === current.exchange &&
        item.base === current.base &&
        item.quote === current.quote)

const mapStateToProps = (state: any) => ({
    watchlist: state.watchlist,
    tickers: state.tickers,
    current: state.current
})

export default connect(mapStateToProps, {
    actionSwitchCurrent,
    actionRemoveWatchlist
})(Watchlist);