import format from 'date-fns/format'
import { IDexSwap } from '../../sagas/coroutines/uniswap'
/**
 * Create a trade formatter
 * @param trade 
 * @returns 
 */
 const formatTrade = (trade: IDexSwap) => {
    if (trade.side === "sell") {
        return {
            side: 'Sell',
            send: trade.baseAmount,
            receive: trade.quoteAmount,
            price: trade.price,
            sendToken: trade.pair.token0,
            receiveToken: trade.pair.token1
        }
    }
    return {
        side: 'Buy',
        send: trade.quoteAmount,
        receive: trade.baseAmount,
        price: trade.price,
        sendToken: trade.pair.token1,
        receiveToken: trade.pair.token0
    }
}
/**
 * Receive timestamp in string and return a formatted date
 * @param timestamp 
 */
const formatTimestamp = (timestamp: number) =>
    format(timestamp, "dd.MM.yyyy kk:mm:ss")

export {
    formatTimestamp,
    formatTrade
}