export const NOTIF_SEND = "NOTIF_SEND";
export const NOTIF_DISMISS = "NOTIF_DISMISS";
export const NOTIF_CLEAR = "NOTIF_CLEAR";

const actionCreateNotification = (input: any) => {
  const payload = {
    kind: 'info',
    dismissAfter: 2000,
    id: new Date().getTime(),
    ...input
  }
  return ({ type: NOTIF_SEND, payload })
}

/**
 * Dismiss a notification by the given id.
 */
const notifDismiss = (id: any) => {
  return { type: NOTIF_DISMISS, payload: id };
}

/**
 * Clear all notifications
 */
const notifClear = () => {
  return { type: NOTIF_CLEAR };
}

export {
  actionCreateNotification,
  notifDismiss,
  notifClear
}