import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import reducers from '../reducers/index'
import { actionEthBlockMined, CONNECT } from '../actions/backend'
import { actionSwitchCurrent } from '../actions/current'
import { StorageKeys } from '../helpers/localStorage'
import accountsSaga from '../sagas/accountsSaga'
import infoSafa from '../sagas/infoSaga'
import navigateSaga from '../sagas/navigateSaga'
import connectSaga from '../sagas/dataSaga'
import selectTab from '../sagas/selectTab'
import positionsSaga from '../sagas/positionsSaga'
import premiumSaga from '../sagas/premiumSaga'
import notificationsSaga from '../sagas/notificationsSaga'
import watchlistSaga from '../sagas/watchlistSaga'
import tradingAccountsSaga from '../sagas/tradingAccountsSaga'
import { CONNECTION_LOST_WINDOW_EVENT, CONNECTION_RESUMED_WINDOW_EVENT } from '../core/wst';
import { actionOpenReconnecting, actionHideReconnecting, actionShowModal } from '../actions/modal';
import { getPageTypeFromUrl } from '../reducers/navigation';
import { actionInitialNavigation } from '../actions/navigation';
import ModalTypes from '../helpers/modals';
// import BinanceNetworkProvider from '../helpers/BinanceNetworkProvider';
const ethers = require('ethers')

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    reducers,
    // compose(
    //     applyMiddleware(sagaMiddleware),
    //     (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    // )
    applyMiddleware(sagaMiddleware)
);
/**
 * set initial navigation before sagas
 */
store.dispatch(actionInitialNavigation(getPageTypeFromUrl()))
/**
 * Check if customer is signed in
 */
const signedIn = !!localStorage.getItem(StorageKeys.session_id);
if (!signedIn) {
    store.dispatch(actionShowModal(ModalTypes.login, {}))
}

sagaMiddleware.run(accountsSaga)
sagaMiddleware.run(watchlistSaga)
sagaMiddleware.run(selectTab)
sagaMiddleware.run(connectSaga)
sagaMiddleware.run(infoSafa)
sagaMiddleware.run(navigateSaga)
sagaMiddleware.run(positionsSaga)
sagaMiddleware.run(notificationsSaga)
sagaMiddleware.run(premiumSaga)
sagaMiddleware.run(tradingAccountsSaga)

const currentState = localStorage.getItem(StorageKeys.current)
if (currentState === null) {
    store.dispatch(actionSwitchCurrent('binance', 'BTC', 'USDT'))
} else {
    const { exchange, base, quote } = JSON.parse(currentState)
    store.dispatch(actionSwitchCurrent(exchange, base, quote))
}
store.dispatch({ type: CONNECT }) // rates, markets, tickers

/**
 * Subscribe to web3 events
 */
const provider = (window as any).ethereum;
if (provider) {
    // Infura project ID
    const ethersProvider = new ethers.providers.InfuraProvider('homestead', ["73cef697dd77451f90d1a45055d734de"]);
    ethersProvider.on('block', (blockNumber: number) => {
        store.dispatch(actionEthBlockMined(blockNumber))
    })
}
/**
 * BNB net block event
 */
// const binanceNetProvder = new BinanceNetworkProvider()
// binanceNetProvder.on('block', (blockNumber: number) => {
//     store.dispatch(actionBnbBlockMined(blockNumber))
// })
/**
 * Subscribe to window events
 */
window.addEventListener(CONNECTION_LOST_WINDOW_EVENT, () => {
    store.dispatch(actionOpenReconnecting())
})
window.addEventListener(CONNECTION_RESUMED_WINDOW_EVENT, () => {
    store.dispatch(actionHideReconnecting())
})

export { store }