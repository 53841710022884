/**
 * Implements a table with assets
 */
import React from 'react'

interface IAssetsMarketsProps {
    markets: any[];
    onSelect: Function;
}

const AssetsMarkets = (props: IAssetsMarketsProps) => {
    return (
        <div className="assets__page__assets">
        <h2>Markets</h2>
        <table>
          <thead>
            <tr>
              <td>exchange</td>
              <td>symbol</td>
              <td>ask</td>
              <td>askVolume</td>
              <td>baseVolume</td>
              <td>bid</td>
              <td>bidVolume</td>
              <td>close</td>
              <td>high</td>
              <td>last</td>
              <td>low</td>
              <td>open</td>
              <td>change</td>
              <td>change(%)</td>
              {/* <td>previousClose</td> */}
              {/* <td>quoteVolume</td> */}
              {/* <td>vwap</td> */}
            </tr>
          </thead>
          <tbody>
            {props.markets.map((ticker: any, index: number) => {
              const classPrefix = (parseFloat(ticker.change) > 0.0) ?
                'assets__page__assets--green'
                : 'assets__page__assets--red'
              return (
                <tr key={index} onClick={(e) => props.onSelect(ticker.exchange, ticker.symbol)}>
                    <td>{ticker.exchange}</td>
                    <td>{ticker.symbol}</td>
                    <td>{ticker.ask}</td>
                    <td>{ticker.askVolume}</td>
                    <td>{ticker.baseVolume}</td>
                    <td>{ticker.bid}</td>
                    <td>{ticker.bidVolume}</td>
                    <td>{ticker.close}</td>
                    <td>{ticker.high}</td>
                    <td>{ticker.last}</td>
                    <td>{ticker.low}</td>
                    <td>{ticker.open}</td>
                    <td className={classPrefix}>{parseFloat(ticker.change).toFixed(2)}</td>
                    <td className={classPrefix}>{ticker.percentage}</td>
                    {/* <td>{ticker.previousClose}</td> */}
                    {/* <td>{ticker.quoteVolume}</td> */}
                    {/* <td>{ticker.vwap}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    )
}

export default AssetsMarkets