import { createSelector } from 'reselect'
import { IOrder } from '../helpers/tradingModes'
import { IWatchlistItem } from '../helpers/watchlist'
import { ICurrentState } from '../reducers/current'

const getTabs = (state: any) => state.tabs
const getWidgetsFromState = (state: any) => state.widgets
const getCurrent = (state: any): ICurrentState => state.current
const getAllOrders = (state: any) => state.orders

/**
 * Get active tab id
 */
export const getActiveTabId = createSelector([ getTabs ], (tabs) => {
    try {
      const active = tabs.filter((tab: any) => tab.active)[0]
      if (active) {
        return active.id
      } else {
        return tabs[0].id
      }
    } catch (err) {
      console.error(err)
      return 0
    }
  })
  
/**
 * Get list of widgets
 * @param state 
 */
export const getWidgets = createSelector([ getActiveTabId, getWidgetsFromState ],
    (activeId, widgets) => {
        return widgets[activeId] || [] // 2) get widgets array from state
    })

/**
 * Get open orders for this exchange and this symbol
 * @param current 
 * @param orders 
 */
export const getOrders = createSelector([ getCurrent, getAllOrders ],
    (current: any, orders: any) => {
        const { mode, base, quote, exchange } = current
        const symbol = `${base}/${quote}`
        const rawOrders = orders[mode] || []
        const result: any = {
            ask: [],
            bid: []
        }
        rawOrders
            .filter((order: IOrder) => order.exchange === exchange && order.symbol === symbol)
            .forEach((order: IOrder) => {
                (order.side === "sell") ?
                    result.ask.push(order)
                    : result.bid.push(order)
        })
        return result
})


const allMarkets = (state: any) => state.markets
const currentExchange = (state: any) => state.current.exchange
/**
 * Get markets for current exchange
 */
export const marketsForCurrentExchange = createSelector([ allMarkets, currentExchange],
  (markets: any, exchange: string) => {
    const target: any = {}
    Object.keys(markets)
      .filter((key: string) => key.indexOf(exchange) > -1)
      .forEach((key: string) => {
        target[key] = markets[key]
      })
    return target
  })

/**
 * Get current exchange|base/quote key
 */
export const getCurrentKey = createSelector([ getCurrent ], (current: ICurrentState): string =>
  `${current.exchange}|${current.base}/${current.quote}`)

/**
 * Return current market definiyion
 */
export const getCurrentMarket = createSelector([getCurrentKey, marketsForCurrentExchange],
  (currentKey: string, markets: any) => {
    const market = markets[currentKey]
    return market ? market : null
  })

/**
 * Return { baseContract, quoteContract } coins addreses for current pair 
 */
export const getCurrentPairContracts = createSelector([getCurrentMarket],
  (market: any) => {
    return market ? market.contract : null
  })

const accountsState = (state: any) => state.accounts
/**
 * Check if user has session
 */
export const isUserSignedIn = createSelector([ accountsState ], (accounts: any) => {
    const { session_id } = accounts
    return !!session_id
})

/**
 * return EExchangeType of current exchange
 */
export const currentExchangeTypeSelector = createSelector([ getCurrent ], (current: ICurrentState) => current.exchangeType)

const watchlist = (state: any) => state.watchlist
export const  getWatchlistKeysSelector = createSelector([ watchlist ], (watchlist: IWatchlistItem[]) =>
  watchlist.map((item: IWatchlistItem) => `${item.exchange}|${item.base}/${item.quote}`)
)