/**
 * Implements a Menu and Menu Item
 */
import React from 'react'
import "./styles.scss"

interface IMenuItemProps {
    title: string;
    active: boolean;
    onClick: any;
}

const MenuItem = ({ title, onClick, active }: IMenuItemProps) =>
    (<div className={active ? 'chart_menu__item chart_menu__item--active' : 'chart_menu__item'} onClick={onClick}>{title}</div>)

const Menu = ({ children }: any ) =>
    (<div className="chart_menu">{children}</div>)

export { Menu, MenuItem }