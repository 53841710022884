import { action } from 'typesafe-actions';

const entity = `current`;

const SWITCH = `${entity}/SWITCH`
const actionSwitchCurrent = (exchange: string, base: string, quote: string) =>
    action(SWITCH, { exchange, base, quote });

export {
    SWITCH,
    actionSwitchCurrent
}