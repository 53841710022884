import { action } from "typesafe-actions"

const entity = 'tickers'

const UPDATE = `${entity}/UPDATE`

const actionUpdateTickers = (tickers: any) =>
    action(UPDATE, tickers)

export {
    UPDATE,
    actionUpdateTickers
}