/**
 * WST - WebSocketTransport
 * inplace replacement for axios
 */
import { EventEmitter } from "events"
import { v4 } from 'uuid'
import { wsBackend } from './endpoints'

const CONNECTION_LOST_WINDOW_EVENT = "wst-connection-lost"
const CONNECTION_RESUMED_WINDOW_EVENT = "wst-connection-resume"

class BrokerSocket extends EventEmitter {
    ws: WebSocket;
    queue: string[];
    attemps: number;

    constructor() {
        super();
        this.attemps = 0;
        this.ws = new WebSocket(wsBackend)
        this.queue = [];
        this.connect();

        setInterval(() => {
            wst('ping')
        }, 15000)
    }

    emitConnectionError = () => {
        const evnt = new Event(CONNECTION_LOST_WINDOW_EVENT)
        window.dispatchEvent(evnt)
    }

    reconnect = () => {
        this.attemps += 1;
        if (this.attemps < 10) {
            setTimeout(() => {
                this.ws = new WebSocket(wsBackend)
                this.connect()
            }, 2000)
        } else {
            this.emitConnectionError()
            console.error('Failed reconnecting, show warning window')
        }
    }

    /**
     * onConnected
     */
    onConnected = () => {
        this.attemps = 0;
        // Hide modal when reconnected
        const evnt = new Event(CONNECTION_RESUMED_WINDOW_EVENT)
        window.dispatchEvent(evnt)
    }

    connect = () => {
        this.ws.addEventListener('open', () => {
            if (this.ws.readyState === this.ws.OPEN) {
                this.onConnected();
                this.queue.forEach((item: string) => {
                    this.ws.send(item);
                })
            } else {
                setTimeout(() => {
                    this.onConnected();
                    this.queue.forEach((item: string) => {
                        this.ws.send(item);
                    })
                }, 3000)
            }
            this.queue = [];
        });
        this.ws.addEventListener('message', (event) => {
            try {
                const { uuid, data } = JSON.parse(event.data)
                this.emit(uuid, data);
            } catch (err) {
                console.error(err);
            }
        });
        this.ws.addEventListener('close', this.reconnect)
        this.ws.addEventListener('error', this.reconnect)
    }

    send = (payload: any) => {
        if (this.ws.readyState === this.ws.OPEN) {
            this.ws.send(JSON.stringify(payload))
        } else {
            this.queue.push(JSON.stringify(payload))
        }
    }
}

const connection = new BrokerSocket();

/**
 * Replace for axios call
 * @param action 
 * @param mixins 
 */
const wst = (action: string, mixins?: any): Promise<any> =>
    new Promise((resolve, reject) => {
        const uuid = v4();
        connection.send({ action, uuid, ...mixins })
        connection.once(uuid, (data) => {
            if (data) {
                resolve(data)
            } else {
                reject(false)
            }
        })
    })

/**
 *
 */
const tryReconnect = () => {
    connection.attemps = 0;
    connection.reconnect();
}

export {
    BrokerSocket,
    CONNECTION_LOST_WINDOW_EVENT,
    CONNECTION_RESUMED_WINDOW_EVENT,
    tryReconnect,
    wst
}