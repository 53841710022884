/**
 * Navigation state
 * primary field: .page - PageType
 * In future it will be a single source of truth for pushState
 */
import { ACTION_NAVIGATE, ACTION_INITIAL_NAV } from "../actions/navigation"
import { PageType } from "../helpers/pageTypes"

const mapping: any = [
  ['/assets', PageType.assets],
  ['/scanner', PageType.marketscanner],
  ['/performance', PageType.performance],
  ['/settings', PageType.settings],
  ['/trading', PageType.trading]
]

export const getPageTypeFromUrl = () => {
  const path = window.location.pathname;
  const selected = mapping.find((item: any) => path.includes(item[0]))
  if (selected) {
    return selected[1]
  }
  return PageType.homepage
};


const navigation = (state = { page: PageType.homepage }, action: any) => {
  switch (action.type) {
    case ACTION_INITIAL_NAV:
    case ACTION_NAVIGATE:
      return action.payload
    default:
      return state
  }
}
export default navigation