import { action } from "typesafe-actions"

const entity = 'balances'

const UPDATE = `${entity}/UPDATE`

const actionUpdateBalances = (balances: any) =>
    action(UPDATE, balances)

export {
    UPDATE,
    actionUpdateBalances
}