import { action } from 'typesafe-actions';
import { IWidget } from '../helpers/widgets';

const entity = 'widget';

const ADD = `${entity}/ADD`
const ADD_LIST = `${entity}/ADD_LIST`
const ADD_LIST_DONE = `${entity}/ADD_LIST_DONE`
const CLOSE = `${entity}/REMOVE`
const UPDATE = `${entity}/UPDATE`
const MINIMIZE = `${entity}/MINIMIZE`
const LAYOUT_UPDATE = `${entity}/LAYOUT_UPDATE`

const actionAddWidget = (widget: IWidget, tabId: number) =>
    action(ADD, { widget, tabId })

const actionCloseWidget = (index: number, tabId: number) =>
    action(CLOSE, { index, tabId })

const actionUpdateWidget = (index: number, widget: IWidget, tabId: number) =>
    action(UPDATE, { index, widget, tabId })

const actionAddWidgetsList = (list: any, tabId: number) =>
    action(ADD_LIST, { list, tabId })

const actionAddWidgetsListDone = (list: any, tabId: number) =>
    action(ADD_LIST_DONE, { list, tabId })

const actionMinimizeWidget = (index: number, tabId: number) =>
    action(MINIMIZE, { index, tabId })

const actionLayoutChange = (layout: any, tabId: number) =>
    action(LAYOUT_UPDATE, { layout, tabId })

export {
    ADD,
    ADD_LIST,
    ADD_LIST_DONE,
    CLOSE,
    UPDATE,
    MINIMIZE,
    LAYOUT_UPDATE,
    actionAddWidget,
    actionAddWidgetsList,
    actionAddWidgetsListDone,
    actionCloseWidget,
    actionUpdateWidget,
    actionMinimizeWidget,
    actionLayoutChange
}