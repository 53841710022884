import { v4 } from "uuid";

const defaultWidgets = [{
    i: v4(),
    klass: 'TradingChart',
    x: 0,
    y: 0,
    w: 16,
    h: 31,
    minW: 5,
    static: false
},
{
    i: v4(),
    klass: 'OrderBook',
    x: 16,
    y: 0,
    w: 5,
    h: 20,
    minW: 5,
    static: false
},
{
    i: v4(),
    klass: 'PositionsOrders',
    x: 21,
    y: 0,
    w: 11,
    h: 17,
    minW: 8,
    static: false
},
{
    i: v4(),
    klass: 'TradingHistory',
    x: 16,
    y: 20,
    w: 5,
    h: 11,
    minW: 5,
    static: false
},
{
    i: v4(),
    klass: 'CreateOrder',
    x: 21,
    y: 17,
    w: 6,
    h: 14,
    minW: 5,
    static: false
},
{
    i: v4(),
    klass: 'Watchlist',
    x: 27,
    y: 17,
    w: 5,
    h: 14,
    minW: 5,
    static: false
}
];
const advancedWidgets = [
    {
        i: v4(),
        klass: 'Treemap',
        x: 0,
        y: 0,
        w: 16,
        h: 31,
        minW: 5,
        static: false
    },
    {
        i: v4(),
        klass: 'Chart',
        x: 0,
        y: 0,
        w: 16,
        h: 31,
        minW: 5,
        static: false
    },
    {
        i: v4(),
        klass: 'DexTrades',
        x: 0,
        y: 0,
        w: 8,
        h: 16,
        minW: 5,
        static: false
    },
    // {
//     i: 'IntoTheBlock', <-- any custom new widget type
//     x: 27,
//     y: 17,
//     w: 5,
//     h: 14,
//     minW: 5,
//     static: false
// }
]
export {
    defaultWidgets,
    advancedWidgets
};
