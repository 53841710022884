/**
 * Implements a chart to show maximum hype topic
 */
import React from "react";
import Chart from "react-apexcharts";
import { INewsItem } from "../../../core/news";

interface IHomepageHypeProps {
  news: INewsItem[];
  onSelect: Function;
}

/**
 * Calculate coin mentions from news
 * @param news
 */
const calculateMentions = (news: any) => {
  const result: any = {};
  const coins = news.map((n: any) => n.currencies).filter((a: any) => !!a);

  coins.forEach((coinArray: any) => {
    coinArray.forEach(({ code }: any) => {
      if (result[code]) {
        result[code] += 1;
      } else {
        result[code] = 1;
      }
    });
  });

  return result
};


const HomepageHype = ({ news, onSelect }: IHomepageHypeProps) => {
  const data = calculateMentions(news);

  const createOptions = (keys: any) => ({
    chart: {
      dark: true,
      height: 350,
      type: "bar",
      selection: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      events: {
        dataPointSelection: (_event: any, _chartContext: any, w: any) => { 
          onSelect(keys[w.dataPointIndex])
        }
      }
    },
    colors: ["rgb(53,53,53)"],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: false
    },
    grid: {
      show: false
    },
    xaxis: {
      show: false,
      categories: keys,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"], // rgb(53,53,53)"],
          fontSize: "12px",
        },
      },
    },
  })

  /**
   * Hide widgets when no news
   */
  if (news.length < 1) {
    return null
  }
  return (
    <div className="homepage__news">
      <h2>Mentions</h2>
      <div className="homepage__news__card">
        <Chart
          options={createOptions(Object.keys(data))}
          series={[{ data: Object.values(data) }]}
          type="bar"
          height={340}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default HomepageHype;
