/**
 * Implements a fetchable data for coin by id
 */
import React, { useState, useEffect } from 'react'
import { fetchCustomCoinStats } from '../../../core/coins'
import { iconsEndpoint } from '../../../core/endpoints'
// import AssetPriceChart from './AssetPriceChart'
// import AssetsNews from './AssetsNews'

interface IAssetDataProps {
    id: string
}

const AssetData = ({ id }: IAssetDataProps) => {
    const [loading, setLoading] = useState(true)
    const [image, showImage] = useState(true) // Hide image on error
    const [coin, setCoin] = useState<any>(null)

    useEffect(() => {
        const fetchFn = async () => {
            try {
                const data = await fetchCustomCoinStats([id])
                if (data[0]) {
                    setCoin(data[0])
                    setLoading(false)
                }
            } catch (err) {
                console.error(err)
            }
        }
        fetchFn()
    }, [id])

    if (loading) {
        return (<div className="assets__page__welcome">
            <h1>{id}</h1>
            {/* <AssetsNews id={id} /> */}
        </div>)
    }
    
    return (
        <div className="assets__page__welcome">
            <div className="assets__page__welcome__logo_bar">
                { image &&
                    (<img
                        src={`${iconsEndpoint}/${id.toLowerCase()}.svg`}
                        alt={`coin logo ${id}`}
                        onError={() => showImage(false)}
                    />)
                }
                <h1>{coin.title}</h1>
            </div>
            {/* <AssetsNews id={id} /> */}
            {/* <div className="assets__page__welcome__chart">
                <div className="assets__page__welcome__chart__cell">
                    <h2>Price 24h</h2>
                    <AssetPriceChart coin={coin} />
                </div>
                <div className="assets__page__welcome__chart__cell">
                    <h2>News</h2>
                    <AssetPriceChart coin={coin} />
                </div>
            </div> */}
        </div>
    )
}

export default AssetData