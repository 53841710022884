/**
 * Implements a widgets grid
 */
import React, { useState } from 'react'
import { connect } from 'react-redux';
import GridLayout from 'react-grid-layout';
import { IWidget } from '../../helpers/widgets';
import {
  actionCloseWidget,
  actionMinimizeWidget,
  actionUpdateWidget,
  actionLayoutChange
} from "../../actions/widgets";
import Widget from './Widget';
import WidgetControls from './WidgetControls';
import "./grid.css";
import "./resizeable.css";
import { getActiveTabId, getWidgets } from '../../selectors';

/**
 * Entry point
 */
const WidgetsGrid = (props: any) => {
  const [ maximizedWidget, setMaximizeWidget ] = useState<IWidget | null>(null)
  const { widgets, mapping, tabId } = props;
  const width = window.innerWidth;

  const onLayoutUpdate = (data: any) => {
    props.actionLayoutChange(data, tabId);
    const event = new Event('grid');
    window.dispatchEvent(event);
  }

  /**
   * Maximized widget
   */
  if (maximizedWidget) {
    const height = window.innerHeight - 70;
    return (<div style={{ height }} className="react-grid-item">
        <WidgetControls
          element={mapping[maximizedWidget.i]}
          data={maximizedWidget}
          onClose={() => setMaximizeWidget(null)}
          onMaximize={() => setMaximizeWidget(null)}
          onMinimize={() => setMaximizeWidget(null)}
      />
      <Widget
        data={maximizedWidget}
        element={mapping[maximizedWidget.i]}
        index={0}
      />
    </div>)
  }
  /**
   * Grid render
   */
  return (
    <GridLayout
      className="layout"
      onLayoutChange={onLayoutUpdate}
      draggableHandle=".widget_controls_caption"
      layout={widgets}
      cols={32}
      rowHeight={22}
      margin={[6, 6]}
      width={width}
    >
      {widgets.map((widget: IWidget, index: number) => 
        (<div className="widget" key={widget.i}>
          <WidgetControls
            element={mapping[widget.i]}
            data={widget}
            onClose={() => props.actionCloseWidget(index, tabId)}
            onMaximize={() => setMaximizeWidget(widget)}
            onMinimize={() => props.actionMinimizeWidget(index, tabId)}
          />
          <Widget
            data={widget}
            element={mapping[widget.i]}
            index={index}
          />
        </div>)
      )}
    </GridLayout>
  )
}

const mapStateToProps = (state: any) =>
  ({
    tabId: getActiveTabId(state),
    widgets: getWidgets(state),
    mapping: state.widgets.mapping
  })

export default connect(mapStateToProps, {
  actionCloseWidget,
  actionUpdateWidget,
  actionLayoutChange,
  actionMinimizeWidget
})(WidgetsGrid);