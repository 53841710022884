/**
 * Implements HomePage component
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ErrorBoundary from 'react-error-boundary'
import { fetchLastNews, INewsItem } from '../../../core/news'
import { actionNavigate } from '../../../actions/navigation'
import { actionSwitchCurrent } from '../../../actions/current'
import { PageType } from '../../../helpers/pageTypes'
// import HomepageTickers, { getTopTickers } from './HomepageTickers'
import HomepageNews from './HomepageNews'
import { ReactComponent as AppleIcon } from './apple.svg'
import { ReactComponent as WindowsIcon } from './windows.svg'
import "./styles.scss"
import HomepageAssets from './HomepageAssets'
import HomepageHype from './HomepageHype'

interface IHomePageProps {
    // tickers: any;
    actionSwitchCurrent: Function;
    actionNavigate: Function;
}
const HomePage = (props: IHomePageProps) => {
    const [news, setNews] = useState<INewsItem[]>([])
    useEffect(() => {
        const fn = async () => {
            try {
                const nItems = await fetchLastNews()
                setNews(nItems)
            } catch (err) {
                console.error(err)
            }
        }
        fn()
    }, [])

    return (
        <div className="homepage">
            <div className="homepage__welcome">
                <h1>Welcome to Kattana,</h1>
                <p>Track your portfolio, analyze price charts, and place trades with the tool every trader knows.</p>
                <div className="homepage__welcome__buttons">
                    <div
                        onClick={() => props.actionNavigate(PageType.trading)}
                        className="homepage__welcome__buttons__btn">
                        get started</div>
                </div>
            </div>
            <ErrorBoundary>
                <HomepageAssets />
                <div className="homepage__row">
                    <HomepageNews news={news} />
                    <HomepageHype
                        news={news}
                        onSelect={(coin: string) => {
                            props.actionNavigate(PageType.assets, coin)
                        }}
                    />
                </div>
            </ErrorBoundary>
        </div>
    )
}



export default connect(null, { actionSwitchCurrent, actionNavigate })(HomePage);
