/**
 * This component implements rendering candles on canvas
 */
import React, { useRef, useEffect, useState } from 'react'
import { IOrder } from '../../../helpers/tradingModes';
import { ChartTypes } from './Container';
import { setCrispCanvas } from './utils';
import { ISelection, ICandle, IPriceTick, ITimeTick } from './utils/interfaces';

interface ICandlesProps {
    width: number;
    height: number;
    chartType: ChartTypes;
    data: ICandle[];
    priceTicks: IPriceTick[];
    timeTicks: ITimeTick[];
    xScale: any;
    yScale: any;
    yVolumeScale: any;
    colors: any;
    orders: IOrder[];
    selection: null | ISelection;
    symbolName: string;
    lastCandle: ICandle;
    drawVolume: boolean;
    scopeStart?: any;
    scopeEnd?: any;

    onSectors: any;
    onCrosshair: any;
    onMove: (pixels: number) => void; // number in pixels to move
    onZoom: (level: number) => void;
    onStartDrag: (pixels: number) => void; // number in pixels to move
}

const Candles = (props: ICandlesProps) => {
    const {
        width,
        height,
        chartType,
        data,
        lastCandle,
        yScale,
        xScale,
        yVolumeScale,
        priceTicks,
        timeTicks,
        colors,
        orders,
        selection,
        drawVolume,
        scopeStart,
        scopeEnd,
        onSectors,
        onCrosshair,
        onStartDrag,
        onMove,
        onZoom
    } = props;
    const canvasRef = useRef(null);
    // const [startDrag, setStartDrag] = useState(0)
    const [pressed, setPressed] = useState(false)
    // const [scaling, setScaling] = useState(false)

    const onKeyDown = (e: any) => {
        switch (e.nativeEvent.code) {
            case 'ArrowLeft':
                onStartDrag(width * 0.5)
                setTimeout(() => {
                    onMove(width * 0.55)
                })
                break;
            case 'ArrowRight':
                onStartDrag(width * 0.5)
                setTimeout(() => {
                    onMove(width * 0.45)
                })
                break;
            default:
                break;
        }
    }
    /**
     * Generic mouse events
     */
    const onMouseMove = ({ nativeEvent }: any) => {
        const { offsetX, offsetY } = nativeEvent
        if (pressed) {
            onMove(offsetX);
        } else {
            onCrosshair(offsetX, offsetY);
        }
    }

    const onMouseDown = ({ clientX }: any) => {
        onStartDrag(clientX)
        setPressed(true)
    }

    const onMouseUp = ({ clientX }: any) => {
        setPressed(false)
        onMove(clientX)
    }
    const onMouseOut = ({ clientX }: any) => {
        if (pressed) {
            onMouseUp({ clientX })
        }
    }

    const onWheel = (e: any) => {
        if (e.ctrlKey) {
            onZoom(e.deltaY * 0.01)
        } else {
            onStartDrag(e.clientX)
            const shift = e.clientX - e.deltaX * 2
            setTimeout(() => {
                onMove(shift)
            })
        }
    }

    useEffect(() => {
        const { current }: any = canvasRef;
        if (current) {
            setCrispCanvas(current, width, height);
        }
    }, [width, height]);

    useEffect(() => {
        const { current }: any = canvasRef;
        if (current) {
            const context: any = current.getContext('2d')
            const volumeMaxHeight = Math.ceil(height * 0.15);
            requestAnimationFrame(() => {
                /**
                 * Rendering part
                 */
                context.clearRect(0, 0, width, height);
                context.fillStyle = colors.background;
                context.fillRect(0, 0, width, height);
                /**
                 * Draw priceTicks
                 */
                priceTicks.forEach(({ y }: IPriceTick) => {
                    context.beginPath();
                    context.moveTo(0, y);
                    context.lineTo(width, y);
                    context.strokeStyle = colors.ticks.price;
                    context.stroke();
                })
                /**
                 * Draw timeTicks
                 */
                timeTicks.forEach(({ x }: ITimeTick) => {
                    context.beginPath();
                    context.moveTo(x, 0);
                    context.lineTo(x, height);
                    context.strokeStyle = colors.ticks.price;
                    context.stroke();
                })

                if (data.length > 0) {
                    const gap = 2;
                    const lastCandleMiddle = xScale(data[data.length - 1].time) * width
                    const sectorWidth = Math.ceil((lastCandleMiddle  -  2 * gap) / data.length);

                    const narrowCandles = (sectorWidth <= 2 * gap);

                    const y = (arg: number) => height - Math.ceil(yScale(arg) * height);
                    
                    const sectors = data.map((item: ICandle, index: number) => {
                        const startX = index * sectorWidth;
                        const endX = startX + sectorWidth;
                        const halfWidth = Math.round(sectorWidth / 2);
                        const lineX = startX + halfWidth;

                        if (item) {
                            const { open, high, low, close, volume } = item;

                            const isGreen = close > open;
                            const color = isGreen ? colors.green : colors.red;
                            const volumeColor = isGreen ? colors.volume.green : colors.volume.red;
                            context.strokeStyle = color;
                            context.strokeWidth = 1;
                            /**
                             * Draw candles with sectors gap
                             */
                            if (chartType === ChartTypes.Candlestick) {
                                if (narrowCandles) {
                                    // 2px or 1px candles
                                    context.beginPath();
                                    context.moveTo(startX, y(open));
                                    context.lineTo(startX, y(close));
                                    context.stroke();
                                } else {
                                    // Draw top
                                    context.rect(startX + gap, y(open), sectorWidth - 2 * gap, y(close) - y(open));
                                    context.fillStyle = color;
                                    context.fill();
                                    // High wick
                                    context.beginPath();
                                    context.moveTo(lineX, y(Math.min(close, open)));
                                    context.lineTo(lineX, y(high));
                                    // Low wick
                                    context.moveTo(lineX, y(Math.max(close, open)));
                                    context.lineTo(lineX, y(low));
                                    context.stroke();
                                }
                                // Indicate last price
                                // Draw line from close to the end of the chart
                                if (item === lastCandle) {
                                    const yPoint = y(close);
                                    context.beginPath();
                                    context.moveTo(lineX, yPoint);
                                    context.lineTo(width, yPoint);
                                    context.stroke();
                                }
                            }
                            /**
                             * Draw volume
                             */
                            if (drawVolume) {
                                const volumeY = yVolumeScale(volume) * volumeMaxHeight;

                                if (narrowCandles) {
                                    context.strokeStyle = volumeColor;
                                    context.beginPath();
                                    context.moveTo(startX, height - volumeY);
                                    context.lineTo(startX, height);
                                    context.stroke();
                                } else {
                                    context.fillStyle = volumeColor;
                                    context.fillRect(startX + gap, height - volumeY, sectorWidth - 2 * gap, volumeY);
                                }
                            }
                        }
                        /**
                         * Selection
                         */
                        if (selection) {
                            if (selection.index === index) {
                                context.strokeStyle = colors.selection;
                                context.strokeWidth = 1;
                                context.setLineDash([10, 8]);
                                // Vertical line
                                context.moveTo(lineX, 0);
                                context.lineTo(lineX, height);
                                context.stroke();
                                // Horizontal line
                                context.moveTo(0, selection.y);
                                context.lineTo(width, selection.y);
                                context.stroke();
                                context.setLineDash([]);
                            }
                        }
                        
                        return ({ startX, item, endX, lineX })
                    });

                    /**
                     * Line chart should be drawn over mid points
                     * Sectors startX, endX should be ignored because of gaps
                     */
                    if (chartType === ChartTypes.Line) {
                        context.strokeStyle = colors.line;
                        context.beginPath();
                        context.moveTo(0, y(sectors[0].item.close));
                        
                        sectors.forEach(({ lineX, item }: any) => {
                            const avgY = y(item.close);
                            context.lineTo(lineX, avgY);
                            context.moveTo(lineX, avgY);
                        })
                        context.stroke();
                    }
                    // return sectors
                    onSectors(sectors);
                    /**
                     * Draw order lines
                     */
                    orders.forEach((order: IOrder) => {
                        const { price } = order;
                        const coordY = y(price);
                        context.beginPath();
                        context.moveTo(0, coordY);
                        context.lineTo(width - 160, coordY);
                        // Make space for div
                        context.moveTo(width - 19, coordY);
                        context.lineTo(width, coordY);
                        context.strokeStyle = order.side === "sell" ? colors.red : colors.green;
                        context.stroke();
                    })
                };
            });
        }
    }, [data, width, height, selection, orders, lastCandle, scopeStart, scopeEnd]);

    return (<canvas
        tabIndex={0}
        className="candlechart_candles"
        ref={canvasRef}
        width={width}
        height={height}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseOut={onMouseOut}
        onKeyDown={onKeyDown}
        onWheel={onWheel}
    />)
}

export default Candles;
