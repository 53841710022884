/**
 * Implements a Settings page
 * accounts mapped to trading_accounts in redux
 * o Customer can enable/disable exchanges
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import chunk from 'lodash.chunk'
import { actionShowModal } from '../../../actions/modal'
import {
    actionRequestExchangeAccounts,
    actionDeleteAccount,
    actionUpdateAccount
} from '../../../actions/trading_accounts'
import Navigation from './Navigation'
import AccountSessions from './AccountSessions'
import ProfileTab from './ProfileTab'
import ExchangeAccounts from './ExchangeAccounts'
import ModalTypes from '../../../helpers/modals'
import { ITradingAccount } from '../../../reducers/trading_accounts'
import "./styles.scss"

interface ISettingsPageProps {
    email: string;
    token: string;
    account_secret: string;
    signedin: boolean;
    items: any[];
    loading: boolean;
    actionShowModal: any;
    actionRequestExchangeAccounts: any;
    actionDeleteAccount: any;
    actionUpdateAccount: any;
}
enum SettingsNavigation {
    accounts = 'exchange accounts',
    settings = 'account settings',
    profile = 'profile',
    sessions = 'sessions'
}
class SettingsPage extends Component<ISettingsPageProps, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            navigation: SettingsNavigation.accounts
        }
    }

    /**
     * Render tab contents
     * @param current - selected tab
     * @param props - mixed input
     */
    renderTab = (current: any, props: any) => {
        switch (current) {
            case SettingsNavigation.accounts:
                return <ExchangeAccounts
                    signedin={props.signedin}
                    loading={props.loading}
                    rows={props.rows}
                    onItemClick={(data: ITradingAccount) => this.props.actionUpdateAccount(data)}
                    onItemDelete={(accountKey: string) => this.props.actionDeleteAccount(accountKey)}
                    onAddAccount={() => this.props.actionShowModal(ModalTypes.add_account)}
                />
            case SettingsNavigation.profile:
                return (<div className="settings__page">
                    <div className="settings__page__header">
                        <h1>Profile</h1>
                        { props.signedin && <ProfileTab />}
                        { !props.signedin && <div>Sign In to view this page</div>}
                    </div>
                </div>)
            case SettingsNavigation.sessions:
                return (
                <div className="settings__page">
                    <div className="settings__page__header">
                        <h1>Account sessions</h1>
                        <span>List of all your sessions.</span>
                        { props.signedin && <AccountSessions />}
                        { !props.signedin && <div>Sign In to view this page</div>}
                    </div>
                </div>)
            default:
                return null;
        }
    }

    render() {
        const { signedin, loading, items } = this.props
        const { navigation } = this.state
        const rows = chunk(items, 3)
        return (
            <>
                <Navigation
                    items={[SettingsNavigation.accounts, SettingsNavigation.profile, SettingsNavigation.sessions]}
                    active={navigation}
                    onSelect={(navigation: string) => this.setState({ navigation })}
                />
                {this.renderTab(navigation, { loading, rows, signedin })}
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    signedin: state.accounts.signedin,
    email: state.accounts.email,
    token: state.accounts.token,
    account_secret: state.accounts.account_secret,
    loading: state.trading_accounts.loading,
    items: state.trading_accounts.items
})

export default connect(mapStateToProps, { actionShowModal, actionRequestExchangeAccounts, actionUpdateAccount, actionDeleteAccount })(SettingsPage)