/**
 * Implements an interactive order element
 * supports onClose, onMove
 */
import React from 'react'
import { IOrder } from '../../../helpers/tradingModes'

interface IChartOrderProps {
    order: IOrder;
    yScale: Function;
    height: number;
    paddingRight: number;
    onClose: Function;
    onMove: Function;
}

/**
 * Stop loss doesn't fit into field
 * @param input 
 */
const decorateOrderType = (input: string): string => {
    if (input === 'stop_loss_limit') {
        return 'SLimit'
    }
    return input
}

const ChartOrder = ({ order, yScale, height, paddingRight, onClose }: IChartOrderProps) => {
    const { price } = order
    const y = (arg: number) => height - Math.ceil(yScale(arg) * height) - 13; // 13 is half of height
    const top = y(price);

    /**
     * Do not render order if it is not visible
     */
    if (top < 0) {
        return null
    }

    const right = paddingRight + 19;
    const classNames = `candlechart_container__row__order candlechart_container__row__order--${order.side}`
    return (
        <div style={{ top, right }} className={classNames}>
            <div className="candlechart_container__row__order__type">{order.side} {decorateOrderType(order.type)}</div>
            <div className="candlechart_container__row__order__amount">{order.amount}</div>
            <span className="candlechart_container__row__order__close" onClick={() => onClose()} />
        </div>
    )
}

export default ChartOrder