/* eslint-disable no-restricted-syntax */
const fixXSymbols = (name) => name.replace(/XBT/g, 'BTC');

class KrakenDatafeed {
    constructor(options = {}) {
        this.krakenHost = 'https://api.kraken.com';
        this.debug = options.debug || false;
        this.barsInterval = null;
        this.lasts = {};
    }

    krakenServerTime() {
        return fetch(`${this.krakenHost}/0/public/Time`)
            .then(res => res.json())
            .then(json => json.serverTime)
            .catch(() => []);
    }

    krakenSymbols() {
        return fetch(`${this.krakenHost}/0/public/AssetPairs`)
            .then(res => res.json())
            .then(json => Object.values(json.result))
            .then(data => data.map((item) => ({ ...item, validSymbol: fixXSymbols(item.altname) })))
            .catch(() => []);
    }

    // eslint-disable-next-line no-unused-vars
    krakenKlines(symbol, interval, startTime, endTime, limit) {
        const url = `${this.krakenHost}/0/public/OHLC?pair=${symbol}&interval=${interval}&since=${startTime}`; // &limit=${limit}&startTime=${startTime}&endTime=${endTime}`;
        return fetch(url)
            .then(res => res.json())
            .then(json => {
                this.lasts[symbol] = json.result && json.result.last;
                const key = Object.keys(json.result)[0];
                return json.result[key];
            })
            .catch(() => []);
    }

    krakenLastCandle(symbol, interval) {
        const url = `${this.krakenHost}/0/public/OHLC?pair=${symbol}&interval=${interval}`;
        return fetch(url)
            .then(res => res.json())
            .then(json => {
                const key = Object.keys(json.result)[0];
                return json.result[key];
            })
            .catch(() => []);
    }

    onReady = async (callback) => {
        try {
            const symbols = await this.krakenSymbols();
            this.symbols = symbols;
            callback({
                supports_marks: false,
                supports_timescale_marks: false,
                supports_time: true,
                supported_resolutions: [
                    '1', '5', '15', '30', '60', '240', '1D', '1W', '1M'
                ]
            });
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols
                .filter((symbol) => symbol.validSymbol.indexOf(input) >= 0)
                .map((symbol) => ({
                    symbol: symbol.altname,
                    full_name: symbol.altname,
                    altname: symbol.altname,
                    description: `${symbol.base} / ${symbol.quote}`,
                    pair: `${symbol.quote.toUpperCase()}-${symbol.base.toUpperCase()}`,
                    ticker: symbol.altname
                })));
    }

    resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);

        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

        for (const symbol of this.symbols) {
            if ((symbol.altname === symbolName) || (symbol.validSymbol === symbolName)) {
                setTimeout(() => {
                    const pricescale = (10 ** symbol.pair_decimals);
                    onSymbolResolvedCallback({
                        name: symbol.altname,
                        altname: symbol.altname,
                        description: `${symbol.base} / ${symbol.quote}`,
                        pair: `${symbol.quote.toUpperCase()}-${symbol.base.toUpperCase()}`,
                        ticker: symbol.altname,
                        session: '24x7',
                        minmov: 1,
                        pricescale,
                        timezone: 'UTC',
                        has_intraday: true,
                        has_daily: true,
                        has_weekly_and_monthly: true,
                        currency_code: symbol.quote
                    });
                }, 0);
                return;
            }
        }

        onResolveErrorCallback('not found');
    }

    getInterval = (resolution) => {
        const intervals = {
            1: '1',
            3: '3',
            5: '5',
            15: '15',
            30: '30',
            60: '60',
            120: '120',
            240: '240',
            1440: '1440',
            10080: '10080',
            21600: '21600',
            D: '1440',
            '1D': '1440',
            W: '10080',
            '1W': '10080',
            M: '21600',
            '1M': '21600',
        };
        return intervals[resolution];
    }
    getBars(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, resolution);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toGMTString());
            console.log('To:  ', to, new Date(to * 1000).toGMTString());
        }

        const interval = this.getInterval(resolution);

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }

            if (totalKlines.length === 0) {
                onHistoryCallback([], {
                    noData: true
                });
            } else {
                onHistoryCallback(totalKlines.map(kline => ({
                        time: kline[0] * 1000,
                        close: parseFloat(kline[4]),
                        open: parseFloat(kline[1]),
                        high: parseFloat(kline[2]),
                        low: parseFloat(kline[3]),
                        volume: parseFloat(kline[6])
                    })), {
                    noData: false
                });
            }
        };

        // eslint-disable-next-line no-shadow
        const getKlines = (from, to) => {
            this.krakenKlines(symbolInfo.name, interval, from, to, 500).then(klines => {
                totalKlines = totalKlines.concat(klines);
                if (klines.length === 720) {
                    // eslint-disable-next-line no-param-reassign
                    from = klines[klines.length - 1][0] + 1;
                    getKlines(from, to);
                } else {
                    finishKlines();
                }
                return null;
            }).catch(err => {
                console.error(err);
                onErrorCallback('Some problem');
            });
        };

        // eslint-disable-next-line no-param-reassign
        // from *= 1000;
        // eslint-disable-next-line no-param-reassign
        // to *= 1000;

        getKlines(from, to);
    }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);

        const { name } = symbolInfo;
        const interval = this.getInterval(resolution);
        this.barsInterval = setInterval(() => {
            this.krakenLastCandle(name, interval)
                .then((data) => {
                    const kline = data[0];
                    const bar = {
                        time: kline[0] * 1000,
                        close: parseFloat(kline[4]),
                        open: parseFloat(kline[1]),
                        high: parseFloat(kline[2]),
                        low: parseFloat(kline[3]),
                        volume: parseFloat(kline[6])
                    };
                    onRealtimeCallback(bar);
                    return null;
                }).catch((err) => {
                    console.error(err);
                });
        }, 15000);
    }

    unsubscribeBars(subscriberUID) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        clearInterval(this.barsInterval);
    }

    getServerTime = async (callback) => {
        try {
            const time = await this.krakenServerTime();
            callback(Math.floor(time / 1000));
        } catch (err) {
            console.error(err);
        }
    }
}

export default KrakenDatafeed;
