import React, { useState } from 'react'
import { connect } from 'react-redux'
import { PageType } from '../../../helpers/pageTypes'
import { requestChangePassword } from '../../../core/accountData'
import { actionNavigate } from '../../../actions/navigation'
import { actionCreateNotification } from '../../../actions/notifications'
import { ReactComponent as LoadingIcon } from './loader.svg'
import "./styles.scss"


interface IChangePasswordPageProps {
    token: string;
    account_secret: string;
    actionNavigate: Function;
    actionCreateNotification: Function;
}
const ChangePasswordPage = (props: IChangePasswordPageProps) => {
    const [oldPassword, onOldChange] = useState("")
    const [password, onChange] = useState("")
    const [confirmation, onConfirmationChange] = useState("")
    const [lock, setLock] = useState(false)
    const errors = validateFields(oldPassword, password, confirmation)

    const onSubmit = async () => {
        if (!lock) {
            setLock(true)
            const status = await requestChangePassword(
                oldPassword,
                password
            )

            if (status) {
                props.actionNavigate(PageType.settings)
            } else {
                props.actionCreateNotification({ message: "Could not change your password" })
            }

            setLock(false)
        } else {
            props.actionCreateNotification({ message: "Request already in process" })
        }
    }

    return (
        <div className="forgot_password__page">
            <form className="forgot_password__page__header" onSubmit={onSubmit}>
                <h1>Password recovery page</h1>
                <span>Enter your old password</span>
                <input
                    type="password"
                    value={oldPassword}
                    onChange={(e) => onOldChange(e.target.value)}
                />
                <span>New password:</span>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => onChange(e.target.value)}
                />
                <span>Confirm your new password</span>
                <input
                    type="password"
                    value={confirmation}
                    onChange={(e) => onConfirmationChange(e.target.value)}
                />
                <ul>
                    {errors.map((error: string, id: number) => <li key={id}>{error}</li>)}
                </ul>
                <div
                    className="forgot_password__page__btn"
                    onClick={onSubmit}
                >
                    {!lock && <>Submit</>}
                    {lock && <LoadingIcon />}
                </div>
            </form>
        </div>
    )
}

/**
 * Returns list of errors
 * @param oldPassword
 * @param password
 * @param confirmation
 */
const validateFields = (oldPassword: string, password: string, confirmation: string): string[] => {
    const results: string[] = []
    if (oldPassword.length < 6) {
        results.push("Old password should be longer")
    }
    if (password !== confirmation) {
        results.push("New password and confirmation don't match")
    }
    return results
}

const mapStateToProps = (state: any) => ({
    token: state.accounts.token,
    account_secret: state.accounts.account_secret,
})

export default connect(mapStateToProps, { actionNavigate, actionCreateNotification })(ChangePasswordPage)