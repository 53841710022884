/**
 * Tabs is a basic object with details like active, exchange, pair
 * Widgets stored separetly
 */
import {
    ACTION_NEW_TAB_DONE,
    ACTION_SELECT_TAB,
    ACTION_UPDATE_LAST_TAB,
    ACTION_CLOSE_TAB,
    ACTION_SELECT_TAB_BY_INDEX,
    ACTION_DRAGOUT
} from "../actions/tabs";
import { v4 } from 'uuid'
import { StorageKeys } from "../helpers/localStorage";
import { TABS_PREMIUM_MAX, TABS_NONPREMIUM_MAX } from "../helpers/premium";

interface ITab {
    id: string;
    exchange: string;
    base: string;
    quote: string;
    title: string;
    active: boolean;
}
/**
 * Default new tab with undefined values
 */
const newTab = () => ({
    id: v4(),
    exchange: "binance",
    base: "BTC",
    quote: "USDT",
    title: "binance btc/usdt",
    active: true
})
/**
 * Fetch default state from localStorage
 */
const getDefaultState = () => {
    const items = localStorage.getItem(StorageKeys.tabs)
    if (items) {
        try {
            return JSON.parse(items)
        } catch (err) {
            console.error('Could not parse tabs')
            return [newTab()]
        }
    }
    return [newTab()]
}

/**
 * Tabs are array of elements
 */
const tabsReducer = (state: ITab[] = getDefaultState(), action: any) => {
    switch (action.type) {
      case ACTION_NEW_TAB_DONE:
        return saveState(addNewTab(state, newTab(), action.payload.premium))
      case ACTION_SELECT_TAB:
        return saveState(selectTabByArgs(state, action.payload))
      case ACTION_SELECT_TAB_BY_INDEX:
        return saveState(selectTabByIndex(state, action.payload))
      case ACTION_UPDATE_LAST_TAB:
        return saveState(updateLastTab(state, action.payload))
      case ACTION_CLOSE_TAB:
        return saveState(closeTabByIndex(state, action.payload.index))
      case ACTION_DRAGOUT:
        return saveState(dragoutTab(state, action.payload.index))
      default:
        return state
    }
  }

/**
 * Limit: 4 tabs
 * Deselect previous tab elements, push new one
 */
const addNewTab = (state: ITab[], newTabElement: ITab, premium: boolean) => {
    const maxCount = premium ? TABS_PREMIUM_MAX : TABS_NONPREMIUM_MAX
    if (state.length < maxCount) {
        const pre = state.map((el) => ({ ...el, active: false }))
        return [...pre, newTabElement]
    } else {
        return state;
    }
}

/**
 * Select a proper tab by index
 */
const selectTabByArgs = (state: ITab[], { exchange, base, quote}: any) =>
    state.map((item: ITab) => {
        if (item.exchange === exchange && item.base === base && item.quote === quote) {
            return {...item, active: true}
        }
        return {...item, active: false}
    }).slice()

const selectTabByIndex = (state: ITab[], { index }: any) =>
    state.map((item: ITab, no: number) => {
        if (no === index) {
            return {...item, active: true}
        }
        return {...item, active: false}
    }).slice()

/**
 * Updates last tab to match UI
 */
const updateLastTab = (state: ITab[], { exchange, base, quote }: any) =>
    state.map((item: ITab) => {
        if (item.active) {
            const title = quote ? `${exchange} ${base}/${quote}` : `${exchange} ${base}`
            return {
                ...item,
                exchange,
                base,
                quote,
                title
            }
        }
        return item
    }).slice()

/**
 * Close tab by index
 * TODO: Should implement .active thing
 */
const closeTabByIndex = (state: ITab[], target: number) => {
    return (state.length > 1) ?
        state.filter((_item: ITab, index: number) => index !== target)
        : state
}

/**
 * Remove this tab and open a new window
 * @param state 
 */
const dragoutTab = (state: ITab[], target: number) => {
    if (state.length > 1) {
        // side effect
        window.open('https://web.kattana.trade', '_blank')
        // state manipulation
        return state.filter((_item: ITab, index: number) => index !== target)
    }
    return state
}
/**
 * Serialize and store tabs
 * Pass value down
 */
const saveState = (state: ITab[]) => {
    localStorage.setItem(StorageKeys.tabs, JSON.stringify(state))
    return state
}

export default tabsReducer