
const DAY = (1000 * 3600 * 24);

const addDays = (time: number, days: number) => time + days * DAY;
const removeDays = (time: number, days: number ) => time - days * DAY;
const addWeeks = (time: number, weeks: number) => time + weeks * DAY * 7;
const removeWeek = (time: number, weeks: number) => time - weeks * DAY * 7;

export {
    addDays,
    removeDays,
    addWeeks,
    removeWeek
}