/**
 * if user is logged in - save watchlist on server
 */
import { takeLatest, call, select } from "redux-saga/effects";
import { IWatchlistItem } from "../helpers/watchlist";
import { StorageKeys } from "../helpers/localStorage";
import { ADD, REMOVE, REMOVE_ARGS } from "../actions/watchlist";
import { updateWatchlist } from "../core/accountData";

/**
 * Serialize and store watchlist
 * Pass value down
 */
const saveState = (state: IWatchlistItem[]) => {
    localStorage.setItem(StorageKeys.watchlist, JSON.stringify(state))
    return state
}

function* onSaveWatchlist() {
    const state = yield select(state => state.watchlist)
    const { session_id } = yield select(state => state.accounts)

    if (session_id) {
        yield call(updateWatchlist, state)
    } else {
        saveState(state) // save locally
    }
}

export default function* accountsSaga() {
    yield takeLatest(ADD, onSaveWatchlist);
    yield takeLatest(REMOVE, onSaveWatchlist);
    yield takeLatest(REMOVE_ARGS, onSaveWatchlist);
}
  