import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import * as Treemap from 'treemap.js'
import { actionSwitchCurrent } from '../../actions/current'
import "./styles.scss"

interface ITreemapProps {
    width: number;
    height: number;
    tickers: any;
    rates: any;
    exchange: string;
    actionSwitchCurrent: Function;
}
const colors = {
    green: '#16C466',
    red: '#D04343'
}

const TreemapWidget = (props: ITreemapProps) => {
    const { width, height, tickers, rates, exchange } = props
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current && Object.keys(tickers).length > 0) {
            const onClick = (symbol: string) => {
                const [base, quote] = symbol.split('/')
                props.actionSwitchCurrent(exchange, base, quote)
            }

            const data = buildTreeMapData(tickers, rates, exchange)
            drawTreemap(ref.current, width, height, data, onClick)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, tickers, rates, exchange])
    return (
        <div
            className="treemap_widget"
            ref={ref}
        />
    )
}

const drawTreemap = (canvas: any, width: number, height: number, data: ITreemapData[], onClick: Function) => {
    let treemap = new Treemap(0, 0, width, height, {
        order: 'sort',
        direction: 'both',
        padding: 4,
    })

    treemap.addData(data, { value: 'weight' })
    treemap.calculate();
    canvas.innerHTML = ''; // delete all dom nodes
    treemap.draw((item: any) => {
        const node = document.createElement("span");
        node.style.position = 'absolute'
        node.style.top = `${item.y}px`
        node.style.left = `${item.x}px`
        node.style.width = `${item.w}px`
        node.style.height = `${item.h}px`
        node.style.lineHeight = `${item.h}px`
        node.style.backgroundColor = item.data.color;
        node.onclick = () => onClick(item.data.name);
        if (item.data.percentage > 0.0) {
            node.innerHTML = `${item.data.name} +${item.data.percentage}`;
        } else {
            node.innerHTML = `${item.data.name} ${item.data.percentage}`;
        }
        canvas.appendChild(node);
    });
}

interface ITreemapData {
    name: string;
    weight: number;
}
/**
 * 
 */
const buildTreeMapData = (tickers: any, rates: any, exchange: string): ITreemapData[] => 
    Object.keys(tickers)
        .filter((key: string) => key.includes(exchange))
        .map((key: string) => {
            const value = tickers[key]
            const { symbol, baseVolume, percentage } = value
            const symValue = symbol ? symbol : key.split('|')[1]
            const [ base ] = symValue.split('/')
            const basePrice = parseFloat(rates[base]) || 0.0
            const usdVolume = basePrice * baseVolume;

            return ({
                name: symbol,
                // weight: percentage,
                weight: usdVolume,
                percentage,
                color: percentage > 0.0 ? colors.green : colors.red
            })
        })
        .filter((_value: any, index: number) => index < 30)

const mapStateToProps = (state: any) => ({
    tickers: state.tickers,
    rates: state.rates,
    exchange: state.current.exchange
})

export default connect(mapStateToProps, { actionSwitchCurrent })(TreemapWidget)
