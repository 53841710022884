/**
 * Implements chart picker
 */
import React from 'react'

interface IChartModePickerProps {
    selected: number;
    onUpdate: Function;
}

const btnClassName = (mode: number, selected: number) => {
    if (mode === selected) {
        return `marketspage__chartswitcher__btn marketspage__chartswitcher__btn--${mode} marketspage__chartswitcher__btn--${mode}--active`
    }
    return `marketspage__chartswitcher__btn marketspage__chartswitcher__btn--${mode}`
}

const ChartModePicker = ({ selected, onUpdate }: IChartModePickerProps) => {
    const modes = [1, 2, 3, 4]
    return (
        <div className="marketspage__chartswitcher">
            {modes.map((mode: number) =>
                (<div
                    key={mode}
                    className={btnClassName(mode, selected)}
                    onClick={() => onUpdate(mode)}
                />))}
        </div>
    )
}

export default ChartModePicker