import { action } from 'typesafe-actions';
const entity = "positions";

const ACTION_SET_BUY_PRICE = `${entity}/SET_BUY_PRICE`;
const ACTION_SET_BUY_PRICE_DONE = `${entity}/SET_BUY_PRICE_DONE`;
const ACTION_DELETE_POSITION = `${entity}/DELETE_POSITION`;

const actionSetBuyPrice = (coin: string, price: string, currency: string) =>
    action(ACTION_SET_BUY_PRICE, { coin, price, currency })

const actionSetBuyPriceDone = (coin: string, price: string, currency: string, value: number) =>
    action(ACTION_SET_BUY_PRICE_DONE, { coin, price, currency, value })

const actionDeletePosition = (coin: string) =>
    action(ACTION_DELETE_POSITION, { coin })

export {
    ACTION_SET_BUY_PRICE,
    ACTION_SET_BUY_PRICE_DONE,
    ACTION_DELETE_POSITION,
    actionSetBuyPrice,
    actionSetBuyPriceDone,
    actionDeletePosition
}
