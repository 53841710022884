import React from "react";
import { TICKERS_EXCHANGES_LIST } from "../../../../helpers/exchanges";

interface IExchangesSelectorProps {
  value: string | null;
  onChange: (val: string | null) => void;
}
const ExchangesSelector = (props: IExchangesSelectorProps) => (
  <div className="tickers__panel__exchanges">
    <div
      className={
        props.value === null
          ? "tickers__panel__exchanges__exchange tickers__panel__exchanges__exchange--active"
          : "tickers__panel__exchanges__exchange"
      }
      onClick={(e: any) => props.onChange(null)}
    >
      All
    </div>
    {TICKERS_EXCHANGES_LIST.map((exchange: string) => (
      <div
        key={exchange}
        className={
          props.value === exchange
            ? "tickers__panel__exchanges__exchange tickers__panel__exchanges__exchange--active"
            : "tickers__panel__exchanges__exchange"
        }
        onClick={(e: any) => props.onChange(exchange)}
      >
        {exchange}
      </div>
    ))}
  </div>
);

export default ExchangesSelector;
