import { SWITCH } from '../actions/current';
import { takeLatest, delay, select, put, call } from "redux-saga/effects";
import { fetchMarketData } from '../core/tradingData'
import { actionUpdateOrderBook } from '../actions/orderbook';
import { actionUpdateHistory } from '../actions/history';
import { actionUpdateDexHistory } from '../actions/dex_trades'
import { actionUpdateLastTab } from '../actions/tabs'
import { PageType } from '../helpers/pageTypes';
import { getLastTradeFromHistory } from '../reducers/history';
import { getCurrentMarket } from '../selectors';
import { EExchangeType } from '../reducers/current';
import { ETH_BLOCK_MINED } from '../actions/backend';
import { DexTradesProvider, getPairContract } from './coroutines/dexTrades';

const emitLastPriceEvent = (exchange: string, base: string, quote: string, history: any) => {
    const lastTrade = getLastTradeFromHistory(history);
    const [ lastPrice ] = lastTrade 
    if (lastPrice > 0.0) {
        const lastPriceEvent = new CustomEvent(`lastPrice_${exchange}_${base}${quote}`, { detail: lastPrice });
        window.dispatchEvent(lastPriceEvent)
    }
}

/**
 * Select pair, convert price and emit event
 */
function* emitDexLastPriceEvent(): any {
    try {
        const { exchange, base, quote } = yield select(state => state.current)
        const { trades } = yield select(state => state.dex_trades)
        const [ trade ] = trades
        if (trade) {
            const lastPriceEvent = new CustomEvent(`lastPrice_${exchange}_${base}${quote}`, { detail: trade.price });
            window.dispatchEvent(lastPriceEvent)
        }
    } catch (err) {
        console.warn('Could not emit lastPrice event', err)
    }
}
/**
 * Check if pair is defined, otherwise try to create it
 */
function* fetchDexHistory(): any {
    const { pair } = yield select(state => state.dex_trades)
    const current = yield select(state => state.current)
    const { exchange } = current
    if (pair) {
        const trades = yield call(DexTradesProvider, exchange, pair, current)
        yield put(actionUpdateDexHistory({
            loading: false,
            trades
        }))
    } else {
        try {
            const market = yield select(getCurrentMarket)
            if (market) {
                const pair = yield call(getPairContract, exchange, market)
                const trades = yield call(DexTradesProvider, exchange, pair, current)
                yield put(actionUpdateDexHistory({
                    pair,
                    loading: false,
                    trades
                }))
                yield emitDexLastPriceEvent()
            }
        } catch (err) {
            console.warn(err)
            yield put(actionUpdateDexHistory({
                pair: null,
                loading: false,
                trades: []
            }))
        }
    }
}

/**
 * React to ETH mined block
 * @param action 
 */
function* onEthBlockMined(action: any): any {
    const { exchangeType } = yield select(state => state.current)
    if (exchangeType === EExchangeType.dex) {
        yield fetchDexHistory()
    }
}
/**
 * React to BNB mined block
 * @param action 
 */
//  function* onBnbBlockMined(action: any): any {
//     const { exchangeType, exchange } = yield select(state => state.current)
//     if (exchangeType === EExchangeType.dex && exchange === 'pancakeswap') {
//         yield fetchDexHistory()
//         yield delay(10000)
//     }
// }

/**
 * Fetch data only on homepage/trading
 */
function* tradingData(action: any): any {
    yield put(actionUpdateLastTab(action.payload))
    const { exchangeType } = yield select(state => state.current)
    /**
     * Reset history and orderbook when changing pair, currency
     */
    yield put(actionUpdateHistory([]))
    yield put(actionUpdateDexHistory({
        pair: null,
        loading: true,
        trades: []
    }))

    if (exchangeType === EExchangeType.dex) {
        yield fetchDexHistory()
    } else {
        while (true) {
            const { exchange, base, quote } = yield select(state => state.current)
            const { page } = yield select(state => state.navigation)
            if (page === PageType.trading || page === PageType.homepage) {
                const { orderbook, history } = yield call(fetchMarketData, exchange, base, quote)
                emitLastPriceEvent(exchange, base, quote, history)
                yield put(actionUpdateOrderBook(orderbook));
                yield put(actionUpdateHistory(history));
            }
            yield delay(1000);
        }
    }
}
export default function* dataSaga() {
    // yield takeEvery("backend/CONNECT", tradingData)
    yield takeLatest(SWITCH, tradingData);
    yield takeLatest(ETH_BLOCK_MINED, onEthBlockMined);
    // yield takeLatest(BNB_BLOCK_MINED, onBnbBlockMined);
}
  