import React from 'react'
import { ReactComponent as MoreTabs } from './tabs.svg'
import InfoModal from '../InfoModal'
import ModalTypes from '../../../helpers/modals'

const PremiumTabsModal = () =>
    (<InfoModal
        modalTitle="Premium"
        heading="Need more tabs?"
        text="Leveling up your trading? Upgrade. Never miss important info by keeping it neat & organized with multiple tabs."
        btnTitle="GET PREMIUM"
        onShowModal={ModalTypes.premium}
    >
        <MoreTabs />
    </InfoModal>)

export default PremiumTabsModal
