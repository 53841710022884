/**
 * This component meant to be heavy
 */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { actionSwitchCurrent } from '../../../actions/current'
import { actionCloseModal } from '../../../actions/modal'
import "./styles.scss"

interface IComplexSearchProps {
    markets: any;
    pairs: any;
    baseIndex: any
    actionSwitchCurrent: any;
    actionCloseModal: any;
}

const filterItemsByValue = (markets: any, baseIndex: any, pairs: any, value: string) => {
    const pairItems = Object.keys(pairs)
        .filter((pair: string) => pair.indexOf(value) > -1)
        .map((key: string) => pairs[key])
    const addValue = value.toLowerCase()
    const addressItems = Object.keys(baseIndex)
        .filter((addr: string) => addr.indexOf(addValue) > -1)
        .map((key: string) => baseIndex[key])

    const keys = Array.from(new Set([...addressItems, ...pairItems]))
    return keys.map((key: string) =>
        ({
            key,
            ...markets[key]
        })
    ).filter((items: any, index: number) => index < 25)
}

const ComplexSearch = ({ markets, pairs, baseIndex, actionSwitchCurrent, actionCloseModal }: IComplexSearchProps) => {
    const [value, setValue] = useState("")
    const [items, setItems] = useState<any[]>([])

    const onSelect = (exchange: string, pair: string) => {
        const [ base, quote ] = pair.split('/')
        actionSwitchCurrent(exchange, base, quote)
        actionCloseModal()
    }

    useEffect(() => {
        setItems(filterItemsByValue(markets, baseIndex, pairs, value))
    }, [value])

    const anyItems = items.length > 0;
    return (
        <div className="complex__search">
            <input
                type="text"
                value={value}
                onChange={(e: any) => setValue(e.target.value)}
                placeholder="Symbol name, contract address..."
            />
            {anyItems && <table>
                <thead>
                    <tr>
                        <th>exchange</th>
                        <th>pair</th>
                        <th>base currency contract</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item: any, index: number) => {
                        const [ exchange, pair ] = item.key.split('|')
                        const contract = item.contract?.baseAddress;
                        return (<tr key={item.key} onClick={() => onSelect(exchange, pair)}>
                            <td>{exchange}</td>
                            <td>{pair}</td>
                            <td>{contract ? contract : '-' }</td>
                        </tr>)
                    })}
                </tbody>
            </table>}
        </div>
    )
}

const markets = (state: any) => state.markets

const pairsIndexSelector = createSelector(markets, (markets) => {
    const acc: any = {}
    const keys = Object.keys(markets)
    keys.forEach((key: string) => {
        const [ _, pair ] = key.split('|')
        acc[pair] = key
    })
    return acc
})

export const marketsIndexSelector = createSelector(markets, (markets) => {
    const acc: any = {}
    const keys = Object.keys(markets)
    keys.forEach((key: string) => {
        if (key.includes('pancakeswap') || key.includes('uniswap')) {
            const value = markets[key]
            const { baseAddress } = value.contract
            acc[baseAddress.toLowerCase()] = key
        }
    })
    return acc
})

const mapStateToProps = (state: any) => ({
    markets: state.markets,
    pairs: pairsIndexSelector(state),
    baseIndex: marketsIndexSelector(state),
})

export default connect(mapStateToProps, { actionSwitchCurrent, actionCloseModal })(ComplexSearch);
