/**
 * Implements a Profile page:
 * can sign out
 * can change password
 * able to see messages
 */
import React from 'react'
import { actionSignOut } from '../../../actions/accounts'
import { actionNavigate } from '../../../actions/navigation'
import { connect } from 'react-redux'
import { PageType } from '../../../helpers/pageTypes'
import "./styles.scss"

interface IProfilePageProps {
    actionSignOut: Function;
    actionNavigate: Function;
}
const ProfileTab = (props: IProfilePageProps) => {
    return (
        <div className="profile__buttons">
            <NavigationButton
                key="log_out"
                onClick={() => props.actionSignOut()}
                title="LOG OUT"
            />
            <NavigationButton
                key="change_password"
                onClick={() => props.actionNavigate(PageType.change_password)}
                title="CHANGE PASSWORD"
            />
        </div>
    )
}

/**
 * Implements a Button
 */
const NavigationButton = ({ onClick, title }: any) =>
    (<div className="profile__buttons__btn" onClick={onClick}>{title}</div>)


export default connect(null, { actionSignOut, actionNavigate })(ProfileTab)