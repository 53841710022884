import { action } from "typesafe-actions"

const entity = 'rates'

const UPDATE = `${entity}/UPDATE`

const actionUpdateRates = (rates: any) =>
    action(UPDATE, rates)

export {
    UPDATE,
    actionUpdateRates
}