import { action } from "typesafe-actions"

const entity = 'markets'

const UPDATE = `${entity}/UPDATE`

const actionUpdateMarkets = (markets: any) =>
    action(UPDATE, markets)

export {
    UPDATE,
    actionUpdateMarkets
}