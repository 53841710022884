/**
 * Implements a tickers panel with search
 */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actionSwitchCurrent } from '../../../../actions/current'
import LoadingMarkets from "../../../Chart/chart/LoadingMarkets";
import { marketsIndexSelector } from "../../SearchModal/ComplexSearch";
import ExchangesSelector from "./ExchangesSelector";
import { AutoSizer, List } from "react-virtualized";
import "./styles.scss";
import { getCurrentKey, getWatchlistKeysSelector } from "../../../../selectors";
import { actionAddWatchlist, actionRemoveByArgsWatchlist } from "../../../../actions/watchlist"
import ExchangeIcon from "./ExchangeIcon";
import PairIcons from "./PairIcons";
import { IWatchlistItem } from "../../../../helpers/watchlist";
import { capitalize } from "../helper";

interface ISearchTickerItem {
  key: string;
  exchange: string;
  pair: string;
  contract: string;
  price: any;
  change: any;
  volume: any;
  liquidity: any;
}

interface ITickersPanelProps {
  tickers: any;
  markets: any;
  baseIndex: any;
  currentKey: string;
  watchlistKeys: string[];
  actionSwitchCurrent: (exchange: string, base: string, quote: string) => void;
  actionAddWatchlist: (item: IWatchlistItem) => void;
  actionRemoveByArgsWatchlist: (exchange: string, base: string, quote: string) => void;
}

const getContract = (market: any): string => {
  if (market) {
    if (market.contract) {
      const { baseAddress } = market.contract;
      return baseAddress;
    }
    return "";
  }
  return "";
};
/**
 * Converts uniswap|BTC/USDT to table object
 * @param keys
 * @param tickers
 * @param markets
 */
const keysToSearchItems = (
  keys: any[],
  tickers: any,
  markets: any
): ISearchTickerItem[] => {
  const result: ISearchTickerItem[] = [];
  keys.forEach((key: string) => {
    const ticker = tickers[key];
    const market = markets[key];
    const [exchange, pair] = key.split("|");

    result.push({
      key,
      exchange,
      pair,
      contract: getContract(market),
      price: ticker.last || ticker.close,
      change: ticker.percentage,
      volume: ticker.volume || ticker.baseVolume,
      liquidity: "",
    });
  });
  return result;
};

/**
 * Implements a search
 * @param search
 * @param exchange
 * @param tickers
 * @param markets
 */
const searchItems = (
  search: string,
  exchange: string | null,
  tickers: any,
  markets: any,
  baseIndex: any
): ISearchTickerItem[] => {
  // Search by contract
  if (search.includes("0x")) {
    const keys = Object.keys(baseIndex)
      .filter((addr: string) => addr.indexOf(search) > -1)
      .map((key: string) => baseIndex[key]);
    return keysToSearchItems(keys, tickers, markets);
  } else {
    const allTickers = Object.keys(tickers);
    const keys = allTickers.filter((ticker: string) => {
      if (exchange) {
        return ticker.includes(exchange) && (ticker.includes(search) || ticker.includes(search.toUpperCase()))
      } else {
        return ticker.includes(search) || ticker.includes(search.toUpperCase())
      }
    });
    return keysToSearchItems(keys, tickers, markets);
  }
};

/**
 * Trim contract address to fir pattern
 * @param contract 
 * @returns 
 */
const trimContract = (contract: string) => {
  if (contract) {
    return `${contract.substr(0, 5)}…${contract.substr(contract.length - 4, 4)}`
  }
  return ""
}

const formatCurrency = (value: number) => new Intl.NumberFormat().format(value) 
/**
 * Format volume as currency
 * @param volume 
 */
const formatVolume = (item: any) => {
  try {
    const volume = item.usdVolume || item.volume || item.baseVolume
    const value = parseFloat(volume)
    return `$ ${formatCurrency(Math.round(value))}`
  } catch (err) {
    return 0
  }
}
/**
 * 
 * @param input 
 */
const formatPrice = (input: string) => {
  try {
  const value = parseFloat(input)
  if (value > 1.0) {
    return value.toFixed(2.0)
  }
  return value.toFixed(6)
} catch (err) {
  return 0
}
}
/**
 * Change from string formatter
 */
const formatChange = (change: any) =>
  `${parseFloat(change).toFixed(2)}%`

const TickersPanel = (props: ITickersPanelProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState("");
  const [exchange, setExchange] = useState<null | string>(null);
  const [items, setItems] = useState<ISearchTickerItem[]>([]);

  useEffect(() => {
    setItems(
      searchItems(
        search,
        exchange,
        props.tickers,
        props.markets,
        props.baseIndex
      )
    );
  }, [search, exchange, props.tickers, props.markets, props.baseIndex]);

  useEffect(() => {
    const tickersPresent = Object.keys(props.tickers).length > 0;
    const marketsPresent = Object.keys(props.markets).length > 0;
    if (tickersPresent && marketsPresent && loading) {
      setLoading(false);
    }
  }, [props.tickers, props.markets, loading]);

  /**
   * On item clicked
   * @param item 
   */
  const onListItemClicked = ({ exchange, pair }: any) => {
    const [ base, quote ] = pair.split('/')
    props.actionSwitchCurrent(exchange, base, quote)
  }

  /**
   * Add or remove item from watchlist
   * @param symbol 
   * @param inlist 
   * @param last 
   * @param change 
   */
  const onToggleWatchlist = (exchange: string, pair: string, inlist: boolean, last: number, change: number) => {
    const [ base, quote ] = pair.split('/')
    if (inlist) {
      props.actionRemoveByArgsWatchlist(exchange, base, quote)
    } else {
      const item: IWatchlistItem = {
          exchange,
          base,
          quote,
          title: `${capitalize(exchange)} ${base}/${quote}`,
          priceChangePercent: change,
          price: last
      }
      props.actionAddWatchlist(item)
    }
}


  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }: any) => {
    const item = items[index]
    const even = index % 2 === 0
    const isActive = props.currentKey === item.key
    const isChangePositive = parseFloat(item.change) > 0.0
    const rowClassList = `tickers__panel__table__row ${isActive ? 'tickers__panel__table__row--active' : ''} ${even ? 'tickers__panel__table__row--even' : ''}`
    const isInWatchlist = props.watchlistKeys.includes(item.key)
    const watchlistClassName = isInWatchlist ? 'active' : 'normal'

    // if (isScrolling) {
    //   return <div className={rowClassList} key={item.key} style={style} onClick={() => onListItemClicked(item)} />
    // }

    return (
      <div className={rowClassList} key={item.key} style={style} onClick={() => onListItemClicked(item)}>
        <div className="tickers__panel__table__row__pair">
          <PairIcons pair={item.pair} />{item.pair}
        </div>
        <div className="tickers__panel__table__row__price">
          {formatPrice(item.price)}
        </div>
        <div className={`tickers__panel__table__row__change tickers__panel__table__row__change--${isChangePositive}`}>{item.change ? formatChange(item.change) : ''}</div>
        <div className="tickers__panel__table__row__liquidity">{item.liquidity}</div>
        <div className="tickers__panel__table__row__volume">{formatVolume(item)}</div>
        <div className="tickers__panel__table__row__contract">{trimContract(item.contract)}</div>
        <div className="tickers__panel__table__row__exchange">
          <ExchangeIcon exchange={item.exchange} />
        </div>
        <div
          onClick={(e: any) => {
            e.stopPropagation();
            onToggleWatchlist(item.exchange, item.pair, isInWatchlist, parseFloat(item.price), parseFloat(item.change))
          }}
          className={`tickers__panel__table__row__star tickers__panel__table__row__star--${watchlistClassName}`}
        />
      </div>
    );
  };

  return (
    <div className="tickers__panel">
      <input
        type="search"
        className="tickers__panel__search"
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
        placeholder="Search Market, Ticker or Token Address"
      />
      <ExchangesSelector value={exchange} onChange={setExchange} />
      <div className="tickers__panel__table">
        <div className="tickers__panel__table__header">
            <div className="tickers__panel__table__row__pair">Pair</div>
            <div className="tickers__panel__table__row__price">Price</div>
            <div className="tickers__panel__table__row__change">&nbsp;</div>
            <div className="tickers__panel__table__row__liquidity">Liquidity</div>
            <div className="tickers__panel__table__row__volume">Volume</div>
            <div className="tickers__panel__table__row__contract">Contract</div>
            <div className="tickers__panel__table__row__exchange">&nbsp;</div>
            <div className="tickers__panel__table__row__star">&nbsp;</div>
        </div>
        {loading && <LoadingMarkets />}
        {!loading && (
          <AutoSizer>
            {({ width, height }) => 
              (<List
                width={width}
                height={height}
                rowCount={items.length}
                rowHeight={27}
                rowRenderer={rowRenderer}
              />)}
          </AutoSizer>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  tickers: state.tickers,
  markets: state.markets,
  baseIndex: marketsIndexSelector(state),
  currentKey: getCurrentKey(state),
  watchlistKeys: getWatchlistKeysSelector(state)
});

export default connect(mapStateToProps, {
  actionSwitchCurrent,
  actionRemoveByArgsWatchlist,
  actionAddWatchlist
})(TickersPanel);
