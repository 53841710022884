import axios from "axios"
import { filterSwaps } from "./uniswap"

const getPancakePairContract = (market: any) => {
    return "0xa527a61703d82139f8a06bc30097cc9caa2df5a6"
}

/**
 * Fetch pancake swaps
 * @param pair 
 * @param current 
 * @returns 
 */
const fetchSwaps = async (pair: string, current: any) => {
    try {
        const payload = {
            operationName: null,
            query: "query ($allPairs: [Bytes]!) {\n  swaps(first: 100, where: {pair_in: $allPairs}, orderBy: timestamp, orderDirection: desc) {\n    transaction {\n      id\n      timestamp\n      __typename\n    }\n    id\n    pair {\n      token0 {\n        id\n        symbol\n        __typename\n      }\n      token1 {\n        id\n        symbol\n        __typename\n      }\n      __typename\n    }\n    amount0In\n    amount0Out\n    amount1In\n    amount1Out\n    amountUSD\n    to\n    __typename\n  }\n}\n",
            variables: {
                allPairs: [pair]
            }
        }
        const { data } = await axios.post('https://api.bscgraph.org/subgraphs/id/QmUDNRjYZ7XbgTvfVnXHj6LcTNacDD9GPXHWLjdTKi6om6', payload)
        const { swaps } = data.data
            
        return filterSwaps(swaps, current)
    } catch (err) {
        console.error(err)
        return []
    }
}
/**
 * Accepts provider and base/quote contract address
 * @returns array of dex trades
 */
const pancakeProvider = async (contract: string, current: any) =>
    fetchSwaps(contract, current)

export {
    pancakeProvider,
    getPancakePairContract
}