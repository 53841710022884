/**
 * Implements tabs on top of Tickers Modal
 */
import React from 'react'
import "./styles.scss"

interface ITabProps {
    tabs: string[];
    selected: number;
    onSelect: Function;
}
const Tabs = ({ tabs, selected, onSelect }: ITabProps) => {
    const style = { flex: `0 0 ${100/tabs.length}%` }
    return (
        <div className="tickers__modal__tabs">
            {tabs.map((title: string, index: number) => {
                const active = (selected === index)
                const className = active ?
                    "tickers__modal__tabs__tab--active"
                    : "tickers__modal__tabs__tab"
                return (<div key={index} style={style} className={className} onClick={() => onSelect(index)}>{title}</div>)
                })}
        </div>
    )
}

export default Tabs