enum ModalTypes {
    tickers = 'tickers',
    add_widget = 'add_widget',
    add_account = 'add_account',
    login = 'login',
    registration_okay = 'registration_okay',
    reconnecting = 'reconnecting',
    premium_feature_tabs = 'premium_feature_tabs',
    premium_widgets = 'premium_widgets',
    premium = 'premium',
    trading = 'trading',
    forgot_password = 'forgot_password',
    search = 'search'
}

export default ModalTypes