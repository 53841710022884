import axios from "axios"
import { analytics } from "./endpoints"

export interface ICoin {
    id: number;
    name: string;
    second_name: string;
    title: string;
    website: string;
    volume: string;
    change: string;
    created_at: string;
    twitter: string;
    tickers_count: number;
    values: any[];
}
/**
 * Fetch last news 
 */
const fetchCoinsList = async (): Promise<ICoin[]> => {
    try {
        const { data } = await axios(`${analytics}/coins/all`)
        const { coins } = data

        return coins
    } catch (err) {
        console.error(err)

        return []
    }
}
/**
 *  Fetch top coins and last 24 items for [volume,change]
 */
const fetchCoinStats = async (cancelToken: any): Promise<any[]> => {
    try {
        const { data } = await axios(`${analytics}/coins/last`, { cancelToken })
        const { coins } = data

        return coins
    } catch (err) {
        console.error(err)

        return []
    }
}
/**
 * fetchCustomCoinStats
 */
const fetchCustomCoinStats = async (shorts: string[]): Promise<any[]> => {
    try {
        const { data } = await axios.post(`${analytics}/coins/custom`, { coins: shorts })
        const { coins } = data

        return coins
    } catch (err) {
        console.error(err)

        return []
    }
}

interface IEthCoinDiscoveryToken {
    balance: any
    tokenInfo: any
    totalIn: number
    totalOut: number
}
/**
 * Use this call to discovery coins for this wallet
 * @param wallet 
 * @returns 
 */
const ethCoinDiscovery = async (ethWallet: string) => {
    try {
        const url = `https://api.ethplorer.io/getAddressInfo/${ethWallet}?apiKey=EK-3xec8-JqUVSqG-QuCJo`
        const { data } = await axios.get(url, { timeout: 5000 })

        const ethToken = {
            balance: data.ETH.balance,
            tokenInfo: {
                address: '0x00',
                price: data.ETH.price,
                decimals: 18,
                symbol: 'ETH',
                website: 'https://ethereum.org/'
            }
        };

        return [
            ...data.tokens,
            ethToken
        ] as IEthCoinDiscoveryToken[]

    } catch (err) {
        console.warn(err)
        return null
    }
}

export {
    fetchCoinsList,
    fetchCoinStats,
    fetchCustomCoinStats,
    ethCoinDiscovery
}