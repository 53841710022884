/* eslint-disable eqeqeq */
/**
 * Implements a generic chart dropdown
 * which accepts a list of strings and returns onChange event
 */
import React, { useState } from "react";
import { Menu, MenuItem } from "./menu";

interface IGenericChartDropdownProps {
  options: string[];
  selection: any;
  onChange: (selected: string) => void;
}

const GenericChartSelect = ({
  options,
  selection,
  onChange,
}: IGenericChartDropdownProps) => {
    const [isOpen, setOpen] = useState<Boolean>(false)

    if (isOpen) {
        return (<>
            <div className="candlechart_container__navbar__backdrop" onClick={() => setOpen(false)} />
            <div className="candlechart_container__navbar__select candlechart_container__navbar__select--opened">
                <Menu>
                    {options.map((option: string) =>
                        (<MenuItem
                            key={option}
                            title={option}
                            active={(option == selection)}
                            onClick={(e: MouseEvent) => {
                                e.preventDefault()
                                setOpen(false)
                                onChange(option)
                            }}
                        />)
                    )}
                </Menu>
            </div>
        </>);
    }

    return (<div className="candlechart_container__navbar__select candlechart_container__navbar__select--closed" onClick={(e: any) => setOpen(!isOpen)}>
        {selection}
    </div>);
}

export default GenericChartSelect;
