import { action } from 'typesafe-actions';
import { PageType } from '../helpers/pageTypes';
const entity = "navigation";

const ACTION_NAVIGATE = `${entity}/NAVIGATE`;
const ACTION_INITIAL_NAV = `${entity}/INITIAL_NAv`

const actionNavigate = (page: string | PageType, id?: any) =>
    action(ACTION_NAVIGATE, { page, id })

const actionInitialNavigation = (page: PageType) => 
    action(ACTION_INITIAL_NAV, { page })

export {
    ACTION_NAVIGATE,
    ACTION_INITIAL_NAV,
    actionNavigate,
    actionInitialNavigation
}