/**
 * Current reducer
 * Source of truth for tab & components
 */
import { SWITCH } from "../actions/current";
import { DEX_LIST } from "../helpers/exchanges";
import { StorageKeys } from "../helpers/localStorage";

export enum EExchangeType {
  cex,
  dex
}
export interface ICurrentState {
  exchange?: string;
  base?: string;
  quote?: string;
  mode?: string;
  exchangeType: EExchangeType;
}

const defaultState = {
  exchange: 'binance',
  base: 'BTC',
  quote: 'USDT',
  mode: 'spot',
  exchangeType: EExchangeType.cex
};
/**
 * Fetch default state from localStorage
 */
const getDefaultState = () => {
  const items = localStorage.getItem(StorageKeys.current)
  if (items) {
    try {
      return JSON.parse(items)
    } catch (err) {
      console.error('Could not parse current')
      return defaultState
    }
  }
  return defaultState
}

const currentReducer = (state: ICurrentState = getDefaultState(), action: any) => {
  switch (action.type) {
    case SWITCH:
      const mode = action.payload.exchange === 'bitmex' ? 'margin' : 'spot'
      return saveState({
        ...state,
        ...action.payload,
        mode,
        exchangeType: DEX_LIST.includes(action.payload.exchange) ? EExchangeType.dex : EExchangeType.cex
      })
    default:
      return state
  }
}

/**
 * Serialize and store current state
 * Pass value down
 */
const saveState = (state: ICurrentState) => {
  localStorage.setItem(StorageKeys.current, JSON.stringify(state))
  return state
}

export default currentReducer