/**
 * Implements POSITIONS state
 * Tickers stored as key-value hash,
 * key format: coin like BTC
 * value: { price, currency }
 */
import { ACTION_SET_BUY_PRICE, ACTION_DELETE_POSITION } from "../actions/positions"
import { StorageKeys } from "../helpers/localStorage"

const defaultState = JSON.parse(localStorage.getItem(StorageKeys.positions) || "{}")

const positions = (state = defaultState, action: any) => {
    switch (action.type) {
      case ACTION_SET_BUY_PRICE:
        return updateBuyPrice(state, action)
      case ACTION_DELETE_POSITION:
        return deletePosition(state, action.payload.coin)
      default:
        return state
    }
  }

/**
 * Return new state with deleted key
 * @param state 
 * @param action 
 */
const deletePosition = (state: any, coin: string) => {
    const newState = {
        ...state,
        ...{
            [coin]: undefined
        }
    }
    localStorage.setItem(StorageKeys.positions, JSON.stringify(newState))
    return newState
}

/**
 * Return new state with updated key
 * @param state 
 * @param action 
 */
const updateBuyPrice = (state: any, { payload }: any) => {
    const { coin, price, currency, value } = payload;
    const newState = {
        ...state,
        ...{
            [coin]: {
                price,
                currency,
                value
            }
        }
    }
    localStorage.setItem(StorageKeys.positions, JSON.stringify(newState))
    return newState
}

export default positions