/**
 * Draws a rectangle with border radius
 * Source: http://jsfiddle.net/robhawkes/gHCJt/
 * @param context 
 * @param rectX 
 * @param rectY 
 * @param rectWidth 
 * @param rectHeight 
 * @param cornerRadius 
 */
const fillRectRadius = (
    context: any,
    rectX: number,
    rectY: number,
    rectWidth: number,
    rectHeight: number,
    cornerRadius: number
    ) => {

    context.save(); // save styles before fn calls
    context.lineJoin = "round";
    context.lineWidth = cornerRadius;
    context.strokeRect(rectX+(cornerRadius/2), rectY+(cornerRadius/2), rectWidth-cornerRadius, rectHeight-cornerRadius);
    context.fillRect(rectX+(cornerRadius/2), rectY+(cornerRadius/2), rectWidth-cornerRadius, rectHeight-cornerRadius);
    context.restore(); // restore styles
}

export {
    fillRectRadius
}