import React from 'react'
import { ReactComponent as Loading } from '../assets/loader.svg'

const Loader = ({ width, height, colors }: any) => {
    return (
        <div
            className="candlechart_loader"
            style={{
                width,
                height,
                backgroundColor: colors.background
            }}
        >
            <div className="candlechart_loader__holder">
                <Loading />
            </div>
        </div>
    )
}

export default Loader