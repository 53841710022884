/**
 * Universal formatted to be used all across the app
 * @param input - string or number
 */
const formatAmount = (input: string | number): string => {
    let value: number;
    if (typeof input === 'string') {
        value = parseFloat(input)
    } else {
        value = input;
    }
    if (value < 1.0) {
        return value.toFixed(8);
    } else {
        if (value.toFixed(2).length < 10) {
            const strLen = value.toFixed(2).length;
            return value.toFixed(12 - strLen);
        } else {
            return value.toFixed(2);
        }
    }
}

export { formatAmount }