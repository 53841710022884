/**
 * Implements Tickers state
 * Tickers stored as key-value hash,
 * key format: exchange|base\quote
 */
import { UPDATE } from "../actions/tickers"

const tickers = (state = {}, action: any) => {
    switch (action.type) {
      case UPDATE:
        return action.payload
      default:
        return state
    }
  }
  export default tickers