import React from 'react'

interface IOrderButtonsProps {
    side: string;
    onUpdate: Function
}
/**
 * Decorate buttons
 */
const OrderButtons = ({ side, onUpdate }: IOrderButtonsProps) => {
    return (<div className="create__order__buttons">
        <div 
            className={`
                create__order__buttons__button
                create__order__buttons__button--buy
                ${(side === 'buy') ? 'create__order__buttons__button--buy--active': ''}`}
            onClick={() => onUpdate('buy')}
        >BUY</div>
        <div
            className={`
                create__order__buttons__button
                create__order__buttons__button--sell
                ${(side === 'sell') ? 'create__order__buttons__button--sell--active': ''}`}
            onClick={() => onUpdate('sell')}
        >SELL</div>
    </div>)
}

export default OrderButtons