/**
 * Implements Orders state
 * All orders are in ccxt order format (universal)
 */
import { UPDATE, UPDATE_REQUESTED, UPDATE_DONE } from "../actions/orders"
import { IOrdersState } from "../helpers/tradingModes"

const defaultState = {
  spot: [],
  margin: [],
  updating: false
}

const markets = (state: IOrdersState = defaultState, action: any) => {
    switch (action.type) {
      case UPDATE:
        return action.payload
      case UPDATE_REQUESTED:
        return {...state, updating: true}
      case UPDATE_DONE:
        return {...state, updating: false}
      default:
        return state
    }
  }
  export default markets