export enum StorageKeys {
  token = "kattana-token",
  positions = "kattana-positions-v1",
  account_secret = "kattana-account_secret",
  email = "email",
  tabs = "kattana-tabs-v1",
  current = "kattana-current-v1",
  watchlist = "kattana-watchlist-v1",
  tv_chart = "kattana-tv-chart-v1",
  session_id = "kattana-session_id"
}