import { action } from 'typesafe-actions';

const entity = "history";
const ACTION_CLEAR = `${entity}/CLEAR`;
const ACTION_ADD_TRADE = `${entity}/ADD_TRADE`
const ACTION_UPDATE_HISTORY = `${entity}/UPDATE`

const actionClearTradingHistory = () =>
    action(ACTION_CLEAR)

const actionAddTrade = (trade: any) =>
    action(ACTION_ADD_TRADE, trade)

const actionUpdateHistory = (trades: any) =>
    action(ACTION_UPDATE_HISTORY, trades)

export {
    ACTION_CLEAR,
    ACTION_ADD_TRADE,
    ACTION_UPDATE_HISTORY,
    actionAddTrade,
    actionClearTradingHistory,
    actionUpdateHistory
}