/**
 * Table with Assets (Coins)
 * Datasource: users balances > 0.0
 */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { fetchCustomCoinStats, ICoin } from "../../../core/coins"
import VolumeChart from "./VolumeChart"
import PriceChart from "./PriceChart"
import { actionNavigate } from '../../../actions/navigation'
import { PageType } from "../../../helpers/pageTypes"
import "./styles.scss"

interface IHomepageAssetsProps {
  rates: any;
  balances: any;
  actionNavigate: Function;
}
/**
 * Adjust table column width
 */
const advProps = {
  width: 300
}
const UserAssets = (props: IHomepageAssetsProps) => {
    const [loading, setLoading] = useState(true)
    const [visibility, setVisibility] = useState(false)
    const [coins, setCoins] = useState<any[]>([])
    
    useEffect(() => {
        const fetchFn = async () => {
            try {
                const coinList: string[] = processBalances(props.balances)
                if (coinList.length > 0) {
                    const data = await fetchCustomCoinStats(coinList)

                    setCoins(data)
                    setVisibility(true)
                    setLoading(false)
                }
            } catch (err) {
                console.error(err)
                
                setCoins([])
                setVisibility(false)
                setLoading(true)
            }
        }
        fetchFn()
    }, [props.balances, props.rates])
   
    const { balances } = props

    const formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
    })
    const collection: any[] = formSortedCollection(coins, balances)

    if (loading) {
      return (<div className="homepage__assets">
        Loading data, please wait
      </div>)
    }
  
    return (<div className="homepage__assets" style={{ visibility:  (visibility ? 'visible' : 'hidden') }}>
        <table>
          <thead>
            <tr>
              <td>#</td>
              <td>name</td>
              <td>quantity</td>
              <td>price (usd)</td>
              <td>value (usd)  ▼</td>
              {/* <td>portfolio (%)</td> */}
              <td {...advProps}>price 24h</td>
              <td {...advProps}>volume 24h</td>
            </tr>
          </thead>
          <tbody>
            {collection.map((coin: any, index: number) =>
              (
                <tr key={index} onClick={() => props.actionNavigate(PageType.assets, coin.name)}>
                  <td>{index + 1}</td>
                  <td>{coin.title}</td>
                  <td>{coin.balance}</td>
                  <td>{formatter.format(coin.lastPrice)}</td>
                  <td>{formatter.format(coin.value)}</td>
                  {/* <td>{value}</td> */}
                  <td>
                    <PriceChart
                      data={coin.prices}
                    />
                  </td>
                  <td>
                    <VolumeChart
                      data={coin.volumes}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    )
}
const formSortedCollection = (coins: ICoin[], balances: any): any[] =>
  coins
  .map((coin: ICoin) => {
    try {
      const lastPrice = coin.values[0][1]
      const prices = coin.values.filter(i => !!i[1]).map((i: any[]) => parseFloat(i[1]))
      const volumes = coin.values.map((i: any[]) => parseFloat(i[0]))
      const value = lastPrice * balances.spot[coin.name]
      const balance = balances.spot[coin.name]

      return {
        ...coin,
        lastPrice,
        prices,
        volumes,
        value,
        balance
      }
    } catch (err) {
      console.warn('formSortedCollection:', err)
      return undefined
    }
  })
  .filter((item: any) => !!item)
  .sort((a: any, b: any) => (b.value - a.value))

const mapStateToProps = ({ rates, balances }: any) => ({ rates, balances })

/**
 * Should process balances object and return list of short coin titles like ['BTC', 'USDT']
 * @param balances 
 */
const processBalances = (balances: any): string[] =>
    Object.keys(balances.spot).filter((coin: string) => !['total', 'used'].includes(coin))

export default connect(mapStateToProps, { actionNavigate })(UserAssets)