import axios from "axios";

interface IHash {
  [details: string]: string;
}

const mapping: IHash = {
  1: "M1",
  3: "M3",
  5: "M5",
  15: "M15",
  30: "M30",
  60: "H1",
  "1D": "D1",
  D: "D1",
  "1W": "D7",
};

interface ISymbol {
  symbol: string;
  baseAsset: string;
  quoteAsset: string;
  pricescal: number;
}
/* eslint-disable no-restricted-syntax */
class HitbtcDatafeed {
  hitbtcHost: string;
  debug: any;
  symbolsPairs: any;
  pairsSymbols: any;
  barsInterval: any;
  symbols: ISymbol[];

  //   symbolsPairs: any;
  constructor(options: any = {}) {
    this.hitbtcHost = 'https://broker.kattana.trade';
    this.debug = options.debug || false;
    this.symbolsPairs = {};
    this.pairsSymbols = {};
    this.barsInterval = null;
    this.symbols = [];
  }

  hitbtcSymbols = async () => {
    if (!(window as any).hitbtc_symbols) {
      try {
        const { data } = await axios(`${this.hitbtcHost}/hitbtc/symbols`);

        const formattedSymbols: ISymbol[] = data.data.map((sym: any) => {
          const [symbol, baseAsset, quoteAsset, pricescale] = sym;
          return {
            symbol,
            baseAsset,
            quoteAsset,
            pricescale,
          };
        });

        (window as any)["hitbtc_symbols"] = formattedSymbols;
        return formattedSymbols;
      } catch (err) {
        console.error(err);
        return [];
      }
    }
    return (window as any).hitbtc_symbols;
  };

  hitbtcOHLCV = async (
    symbol: string,
    interval: any,
    startTime: any,
    endTime: any,
    limit: number
  ) => {
    const url = `${this.hitbtcHost}/hitbtc/${symbol}/chart/${interval}/${startTime}/${endTime}/${limit}`;
    try {
      const { data } = await axios(url);
      return data.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  hitbtcLastCandle = async (symbol: string, interval: string) => {
    const url = `${this.hitbtcHost}/hitbtc/${symbol}/candle/${interval}`;
    try {
      const { data } = await axios(url);
      return data.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  onReady = async (callback: Function) => {
    try {
      const symbols = await this.hitbtcSymbols();
      symbols.forEach(({ symbol, baseAsset, quoteAsset }: ISymbol) => {
        const pair = `${baseAsset}-${quoteAsset}`;
        this.symbolsPairs[symbol] = pair;
        this.pairsSymbols[pair] = symbol;
      });
      this.symbols = symbols;
      callback({
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
        supported_resolutions: ["1", "3", "5", "15", "30", "60", "1D", "1W"],
      });
    } catch (err) {
      console.error(err);
    }
  };

  searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResultReadyCallback: Function
  ) {
    const input = userInput.toUpperCase();
    onResultReadyCallback(
      this.symbols
        .filter((symbol: ISymbol) => symbol.symbol.indexOf(input) >= 0)
        .map((symbol: ISymbol) => ({
          symbol: symbol.symbol,
          full_name: symbol.symbol,
          description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
          pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
          ticker: symbol.symbol,
        }))
    );
  }

  resolveSymbol(
    symbolName: string,
    onSymbolResolvedCallback: Function,
    onResolveErrorCallback: Function
  ) {
    if (this.debug) console.log("👉 resolveSymbol:", symbolName);

    const comps = symbolName.split(":");
    // eslint-disable-next-line no-param-reassign
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();
    // eslint-disable-next-line no-unused-vars
    const pricescale = ({ tickSize }: any) =>
      Math.round(1 / parseFloat(tickSize));

    const symbol: ISymbol = this.symbols.filter((symbol: ISymbol) =>
      symbolName.includes(symbol.symbol)
    )[0];
    if (symbol) {
      setTimeout(() => {
        onSymbolResolvedCallback({
          name: symbol.symbol,
          description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
          pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
          ticker: symbol.symbol,
          session: "24x7",
          minmov: 1,
          pricescale: pricescale(symbol),
          timezone: "UTC",
          has_intraday: true,
          has_daily: true,
          has_weekly_and_monthly: true,
          currency_code: symbol.baseAsset,
        });
      }, 0);
    } else {
      onResolveErrorCallback("not found");
    }
  }

  getInterval = (resolution: any) => mapping[resolution];

  getBars(
    symbolInfo: any,
    resolution: any,
    from: any,
    to: any,
    onHistoryCallback: Function,
    onErrorCallback: Function,
    firstDataRequest: any
  ) {
    if (this.debug) {
      console.log("👉 getBars:", symbolInfo.name, resolution);
      console.log("First:", firstDataRequest);
      console.log("From:", from, new Date(from * 1000).toUTCString());
      console.log("To:  ", to, new Date(to * 1000).toUTCString());
    }

    const interval = this.getInterval(resolution);

    if (!interval) {
      onErrorCallback("Invalid interval");
    }

    let totalKlines: any[] = [];

    const finishKlines = () => {
      if (this.debug) {
        console.log("📊:", totalKlines.length);
      }

      if (totalKlines.length === 0) {
        onHistoryCallback([], {
          noData: true,
        });
      } else {
        onHistoryCallback(
          totalKlines.map((kline) => ({
            time: Date.parse(kline.timestamp),
            close: parseFloat(kline.close),
            open: parseFloat(kline.open),
            high: parseFloat(kline.max),
            low: parseFloat(kline.min),
            volume: parseFloat(kline.volume),
          })),
          {
            noData: false,
          }
        );
      }
    };

    // eslint-disable-next-line no-shadow
    const getKlines = (from: any, to: any) => {
      this.hitbtcOHLCV(symbolInfo.name, interval, from, to, 1000)
        .then((klines) => {
          totalKlines = totalKlines.concat(klines);
          if (klines.length === 1000) {
            const lastCandle = klines[klines.length - 1];
            const { timestamp } = lastCandle;
            // eslint-disable-next-line no-param-reassign
            from = Date.parse(timestamp) + 1;
            if (Number.isNaN(from)) {
              finishKlines();
            } else {
              getKlines(from, to);
            }
          } else {
            finishKlines();
          }
          return null;
        })
        .catch((err) => {
          console.error(err);
          onErrorCallback("Some problem");
        });
    };

    // eslint-disable-next-line no-param-reassign
    from *= 1000;
    // eslint-disable-next-line no-param-reassign
    to *= 1000;

    getKlines(from, to);
  }

  // eslint-disable-next-line no-unused-vars
  subscribeBars(
    symbolInfo: any,
    resolution: any,
    onRealtimeCallback: Function,
    subscriberUID: any,
    onResetCacheNeededCallback: Function
  ) {
    if (this.debug) console.log("👉 subscribeBars:", subscriberUID);

    const { name } = symbolInfo;
    const interval = this.getInterval(resolution);
    this.barsInterval = setInterval(() => {
      this.hitbtcLastCandle(name, interval)
        .then((data: any) => {
          const kline = data[0];
          const bar = {
            time: Date.parse(kline.timestamp),
            close: parseFloat(kline.close),
            open: parseFloat(kline.open),
            high: parseFloat(kline.max),
            low: parseFloat(kline.min),
            volume: parseFloat(kline.volume),
          };
          onRealtimeCallback(bar);
          return null;
        })
        .catch((err: Error) => {
          console.error(err);
        });
    }, 15000);
  }

  unsubscribeBars(subscriberUID: any) {
    if (this.debug) console.log("👉 unsubscribeBars:", subscriberUID);
    clearInterval(this.barsInterval);
  }
}

export default HitbtcDatafeed;
