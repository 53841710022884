import React from 'react'
import { connect } from 'react-redux'
import ModalTypes from '../../helpers/modals';
import { actionCloseModal } from "../../actions/modal";
import TickersModal from '../modals/TickersModal';
import AddWidgetModal from '../AddWidget';
import LoginModal from '../modals/LoginModal';
import RegistrationSuccessModal from '../modals/RegistrationSuccessModal';
import ReconnectingModal from '../modals/ReconnectingModal';
import PremiumTabsModal from '../modals/PremiumTabsModal';
// import PremiumWidgetsModal from '../modals/PremiumWidgetsModal';
// import PremiumModal from '../modals/PremiumModal';
import AddAccountModal from '../modals/AddAccountModal';
import TradingModal from '../modals/TradingModal';
import "./styles.scss";
import PasswordResetModal from '../modals/PasswordResetModal';
import { StorageKeys } from '../../helpers/localStorage';
import SearchModal from '../modals/SearchModal';

interface IModalProps {
    modalName: string;
    modalProps?: any
    actionCloseModal: Function;
}
const Backdrop = ({ onClick }: any) =>
    (<div className="modal__backdrop" onClick={onClick} />)

/**
 * Entrypoint
 */
const Modal = (props: IModalProps) => {
    const signedIn = !!localStorage.getItem(StorageKeys.session_id);
    const { modalName, modalProps } = props;
    switch (modalName) {
        case ModalTypes.tickers:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <TickersModal {...modalProps} />
            </>
        case ModalTypes.add_widget:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <AddWidgetModal {...modalProps} />
            </>
        case ModalTypes.login:
            return <>
                <Backdrop />
                <LoginModal {...modalProps}/>
            </>
        case ModalTypes.forgot_password:
            return <>
                <Backdrop />
                <PasswordResetModal {...modalProps}/>
            </>
        case ModalTypes.registration_okay:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <RegistrationSuccessModal />
            </>
        case ModalTypes.reconnecting:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <ReconnectingModal />
            </>
        case ModalTypes.premium_feature_tabs:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <PremiumTabsModal />
            </>
        // case ModalTypes.premium_widgets:
        //     return <>
        //         <Backdrop onClick={() => props.actionCloseModal()} />
        //         <PremiumWidgetsModal />
        //     </>
        // case ModalTypes.premium:
        //     return <>
        //         <Backdrop onClick={() => props.actionCloseModal()} />
        //         <PremiumModal />
        //     </>
        case ModalTypes.add_account:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <AddAccountModal />
            </>
        case ModalTypes.trading:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <TradingModal />
            </>
        case ModalTypes.search:
            return <>
                <Backdrop onClick={() => props.actionCloseModal()} />
                <SearchModal />
            </>
        default:
            return signedIn ?
                null
                : (<>
                    <Backdrop />
                    <LoginModal {...modalProps}/>
                </>)
    }
}

const mapStateToProps = (state: any) =>
    ({
        modalName: state.modal.modalName,
        modalProps: state.modal.props
    })

export default connect(mapStateToProps, { actionCloseModal })(Modal)