import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_OUT,
  SIGN_IN_REQUEST,
  SIGN_UP_REQUEST,
  SIGN_UP_FAIL,
  SIGN_UP_SUCCESS,
} from "../actions/accounts"
import { StorageKeys } from "../helpers/localStorage"

/**
 * Implements accounts reducer
 * Initial state will be loaded from localStorage
 * We check token presence
 */
 interface IAccountsState {
  signedin: boolean;
  locked: boolean;
  token: string | null;
  account_secret: string | null;
  session_id: string | null;
  username: string;
  premium: boolean;
}

const token = localStorage.getItem(StorageKeys.token)
const session_id = localStorage.getItem(StorageKeys.session_id)
const account_secret = localStorage.getItem(StorageKeys.account_secret)
const email = localStorage.getItem(StorageKeys.email)

const defaultAccountsState = {
  signedin: token !== null,
  token,
  email,
  locked: false,
  account_secret,
  username: '',
  session_id,
  premium: false
}

/**
 * Sign out and remove ls keys
 */
const signedOutState = () => {
  localStorage.removeItem(StorageKeys.token)
  localStorage.removeItem(StorageKeys.account_secret)
  localStorage.removeItem(StorageKeys.email)
  localStorage.removeItem(StorageKeys.session_id)
  return ({
    signedin: false,
    locked: false,
    token: null,
    account_secret: null,
    username: '',
    premium: false,
    session_id: null
  })
}

const accountsReducer = (state: IAccountsState = defaultAccountsState, action: any) => {
    switch (action.type) {
      case SIGN_IN_SUCCESS:
        localStorage.setItem(StorageKeys.token, action.payload.token)
        localStorage.setItem(StorageKeys.account_secret, action.payload.account_secret)
        localStorage.setItem(StorageKeys.email, action.payload.email)
        localStorage.setItem(StorageKeys.session_id, action.payload.session_id)
        return {
          signedin: true,
          locked: false,
          token: action.payload.token,
          account_secret: action.payload.account_secret,
          session_id: action.payload.session_id,
          username: ''
        }
      case SIGN_IN_FAIL:
        
        return {
          ...state,
          locked: false
        }
      case SIGN_IN_REQUEST:
        return {
          ...state,
          locked: true
        }
      case SIGN_UP_REQUEST:
        return {
          ...state,
          locked: true
        }
      case SIGN_UP_FAIL:
        return {
          ...state,
          locked: false
        }
      case SIGN_UP_SUCCESS:
        return {
          ...state,
          locked: false
        }
      case SIGN_OUT:
        return signedOutState()
      default:
        return state
    }
}

export default accountsReducer