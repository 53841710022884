/**
 * Implements a generic chart interfaces
 */
import { IOrder } from "../../helpers/tradingModes"
import { marketsForCurrentExchange } from "../../selectors"

export interface IChartProps {
    exchange: string;
    base: string;
    quote: string;
    mode: string;
    orders: any;
    width: number;
    height: number;
    pricePrecision: number;
    markets: any
    actionCancelOrders: Function;
    actionShowModal?: Function;
}

/**
 * Filters orders 
 * @param exchange 
 * @param mode 
 * @param orders 
 */
export const chartFilterOrders = (exchange: string, base: string, quote: string, mode: string, orders: any): IOrder[] => {
    const collection = (mode === "margin") ?  orders.margin : orders.spot
    const symbol = quote ? `${base}/${quote}` : base
    return collection.filter((order: IOrder) =>
        (order.exchange === exchange)
            && (order.symbol === symbol)
            && ['active','open'].includes(order.status))
}

/**
 * 0.001 => 3
 * @param inv 
 */
const reversePrecision = (inv: number) =>
    inv.toString().split('.')[1].length
/**
 * Get precision from price
 * Some exchanges will return 2..8
 * Others could return 0.0001
 * @param state 
 */
const pricePrecisionSelector = (state: any) => {
    const market = state.markets[`${state.current.exchange}|${state.current.base}/${state.current.quote}`]
    if (market) {
        return (market.precision.price > 0) ?
            market.precision.price
            : reversePrecision(market.precision.price)
    }
    return 2
}

export const chartMapStateToProps = (state: any) =>
    ({
        exchange: state.current.exchange,
        base: state.current.base,
        quote: state.current.quote,
        mode: state.current.mode,
        orders: state.orders,
        markets: marketsForCurrentExchange(state),
        pricePrecision: pricePrecisionSelector(state)
    })