import React, { useState, useEffect, Component } from 'react'
import TickersModal from '../../modals/TickersModal'
import "./styles.scss"
import Chart from '../../Chart/TradingViewChart'
import ChartModePicker from './ChartModePicker'
import { ReactComponent as PanelVisible } from './panel-visible.svg'
import { ReactComponent as PanelHidden } from './panel-hidden.svg'
import { storeEvent, AnalyticsEvents } from '../../../core/analytics'
import { connect } from 'react-redux'
import { getCurrentKey } from '../../../selectors'
import { actionSwitchCurrent } from '../../../actions/current'
import SearchKeybinding from '../../SearchKeybinding'

/**
 * Parse url to get default state
 */
const getDefaultStateFromUrl = (props: any) => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('mode')) {
        return ({
            visible: searchParams.get('panel') === 'true',
            mode: Number(searchParams.get('mode')),
            id: searchParams.get('id')
        })
    } else {
        return ({
            visible: true,
            mode: 1,
            id: props.id
        })
    }
}
interface IMarketsPageProps {
    id: string;
    actionSwitchCurrent: (exchange: string, base: string, quote: string) => void;
}
class MarketsPage extends Component<IMarketsPageProps, any> {
    constructor(props: any) {
        super(props);
        this.state = getDefaultStateFromUrl(props)
        /**
         * If our key in URL don't match our state - update state
         */
        if (this.props.id !== this.state.id) {
            const [ exchange, pair ] = this.state.id.split('|')
            const [ base, quote ] = pair.split('/')
            this.props.actionSwitchCurrent(exchange, base, quote)
        }
    }

    /**
     * Update state from props
     * @param props 
     * @param state 
     */
    static getDerivedStateFromProps = (props: any, state: any) => {
        if (props.id) {
            return { id: props.id }
        }
        return state
    }

    componentDidMount() {
        storeEvent(AnalyticsEvents.openMarketScanner, {})
    }

    componentDidUpdate() {
        window.history.pushState(this.state, 'scanner', `/scanner?mode=${this.state.mode}&panel=${this.state.visible}&id=${this.state.id}`)
    }

    onChangePanelVisibility = () => {
        const prevMode = this.state.mode;
        this.setState({ mode: 0 })
        this.setState({ visible: !this.state.visible})        
        setTimeout(() => {
            this.setState({ mode: prevMode })
        }, 50)
    }

    render() {
        const { visible, mode } = this.state;
        return (<div className="marketspage">
        <SearchKeybinding />
        {visible && <>
            <TickersModal />
            <div
                className="modal__backdrop"
                onClick={() => this.setState({ visible: false })}
            />
        </>}
        <ChartContainer
            mode={mode}
            setMode={(m: number) => this.setState({ mode: m })}
            panel={visible}
            setPanel={this.onChangePanelVisibility}
        />
    </div>)
    }
}

const createChartsLayout = (mode: number, width: number, height: number) => {
    const rowHeight = (height / 2) - 12
    const halfWidth = (width / 2) - 6
    switch (mode) {
        case 0:
            return <div />
        case 1:
            return (<Chart
                width={width}
                height={height}
            />)
        case 2:
            return (<div className="marketspage__container__column" style={{ height }}>
                <div className="marketspage__container__row" style={{ height: rowHeight }}>
                    <Chart
                        width={width}
                        height={rowHeight}
                    />
                </div>
                <div className="marketspage__container__row" style={{ height: rowHeight }}>
                    <Chart
                        width={width}
                        height={rowHeight}
                    />
                </div>
            </div>)
        case 3:
            return (<div className="marketspage__container__column" style={{ height }}>
                <div className="marketspage__container__row" style={{ height: rowHeight }}>
                    <div className="marketspage__container__cell" style={{ width: halfWidth, height: rowHeight }}>
                        <Chart
                            width={halfWidth}
                            height={rowHeight}
                        />
                    </div>
                    <div className="marketspage__container__cell" style={{ width: halfWidth, height: rowHeight }}>
                        <Chart
                            width={halfWidth}
                            height={rowHeight}
                        />
                    </div>
                </div>
                <div className="marketspage__container__row" style={{ height: rowHeight }}>
                    <Chart
                        width={width}
                        height={rowHeight}
                    />
                </div>
            </div>)
        case 4:
            return (<div className="marketspage__container__column" style={{ height }}>
                <div className="marketspage__container__row" style={{ height: rowHeight }}>
                    <div className="marketspage__container__cell" style={{ width: halfWidth, height: rowHeight }}>
                        <Chart
                            width={halfWidth}
                            height={rowHeight}
                        />
                    </div>
                    <div className="marketspage__container__cell" style={{ width: halfWidth, height: rowHeight }}>
                        <Chart
                            width={halfWidth}
                            height={rowHeight}
                        />
                    </div>
                </div>
                <div className="marketspage__container__row" style={{ height: rowHeight }}>
                    <div className="marketspage__container__cell" style={{ width: halfWidth, height: rowHeight }}>
                        <Chart
                            width={halfWidth}
                            height={rowHeight}
                        />
                    </div>
                    <div className="marketspage__container__cell" style={{ width: halfWidth, height: rowHeight }}>
                        <Chart
                            width={halfWidth}
                            height={rowHeight}
                        />
                    </div>
                </div>
            </div>)
    }
}
interface IChartContainer {
    mode: number;
    panel: boolean;
    setPanel: Function;
    setMode: Function;
}
const ChartContainer = ({ mode, panel, setPanel, setMode }: IChartContainer) => {
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setVisible(true)
        })
    })
    const { width, height } = getWidthHeight()
    const ChartsFactory = createChartsLayout(mode, width, height)

    const containerClassName = `marketspage__container marketspage__container--hidden`

    if (!visible) {
        return <div className={containerClassName} />
    }

    return (<div className={containerClassName}>
        <div
            className="marketspage__container__togglepanel"
            onClick={() => setPanel()}
        >
            {panel ? <PanelVisible /> : <PanelHidden />}
        </div>
        <ChartModePicker
            selected={mode}
            onUpdate={setMode}
        />
        {ChartsFactory}
    </div>)
}

const getWidthHeight = () => {
    const element: any = document.getElementsByClassName('marketspage__container')[0]
    if (element) {
        return ({
            width: element.offsetWidth,
            height: element.offsetHeight
        })
    }
    return ({
        width: 100,
        height: 100
    })
}

const mapStateToProps = (state: any) => ({
    id: getCurrentKey(state)
})

export default connect(mapStateToProps, { actionSwitchCurrent })(MarketsPage)
