/**
 * trading accounts state
 */
import {
  FETCH_REQUEST,
  ADD_REQUEST,
  UPDATE,
  UNLOCK,
  DELETE
} from "../actions/trading_accounts";

export interface ITradingAccount { 
  exchangeName: string;
  connected: boolean;
  accountKey: string;
  accountSecret: string;
}
interface ITradingAccountsState {
  items: ITradingAccount[];
  loading: boolean;
}
const tradingAccounts = (
  state: ITradingAccountsState = { items: [], loading: false },
  action: any
) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UNLOCK:
      return {
        ...state,
        loading: false,
      };
    case DELETE:
      return {
        items: state.items.filter((item: ITradingAccount) => item.accountKey !== action.payload.accountKey),
        loading: false
      }
    default:
      return state;
  }
};

export default tradingAccounts;
