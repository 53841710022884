import { wst } from './wst'
import unpackTickers from './helpers/unpackTickers';

/**
 * Fetches Crypto to USD rates
 */
const fetchRates = async () => {
    try {
        return wst('rates')
    } catch (e) {
        console.error("Could not fetch rates from server", e)
        return {}
    }
}
/**
 * Merge hashes
 * @param input 
 */
const mergeHashes = (input: any[]) => {
    let result = {};
    input.forEach((group: any) => {
        result = { ...result, ...group }
    })
    return result;
}
/**
 * Convert arrays to tickers
 * So we got arrays of {key, value}
 * @param input 
 */
const mergeArraysToTickers = (input: any[]): any => {
    let result: any = {};
    input.forEach((group: any) => {
        group.forEach((item: any) => {
            result[item.key] = item.value
        });
    })
    return result;
}

/**
 * Fetched ticker data for multiple exchanges
 */
const fetchTickers = async (exchanges: string[]) =>
    mergeHashes(await Promise.all(exchanges.map(fetchTickersForExchange)))

/**
 * fetchMarkets for multiple exchanges
 */
const fetchMarkets = async (exchanges: string[]) =>
    mergeArraysToTickers(await Promise.all(exchanges.map(fetchMarketsForExchange)))

/**
 * Fetched ticker data
 * Convert array to object
 */
const fetchTickersForExchange = async (exchange: string) => {
    try {
        const data = await wst('tickers', { query: { exchange }})
        return unpackTickers(data)
    } catch (e) {
        console.error("Could not fetch tickers from server for ", exchange, e)
        return {}
    }
}

/**
 * Fetches market info like precision
 */
const fetchMarketsForExchange = async (exchange: string) => {
    try {
        return wst('markets', { query: { exchange }})
    } catch (e) {
        console.error("Could not fetch markets from server", e)
        return []
    }
}

/**
 * Fetches market info like precision
 */
const fetchBalances = async (token: string, account_secret: string) => {
    try {
        const headers = {
            token,
            account_secret
        }
        const data = await wst('balances', { headers })
        const { spot, margin, exchanges } = data

        return { spot, margin, exchanges }
    } catch (e) {
        console.error("Could not fetch balances from server", e)

        return { spot: {}, margin: {}, exchanges: {} }
    }
}

const fetchOrders = async (token: string, account_secret: string, symbol?: string) => {
    try {
        const data = await wst('orders', {
            query: {
                symbol
            },
            headers: {
                token,
                account_secret
            }
        })

        return data
    } catch (e) {
        console.error("Could not fetch balances from server", e)

        return { spot: [], margin: [] }
    }
}

/**
 * Fetches orderbook & history
 * @param exchange 
 * @param base 
 * @param quote 
 */
const fetchMarketData = async (exchange: string, base: string, quote: string) => {
    try {
        let id = `${exchange}|${base}/${quote}`
        if (!quote) {
            id = `${exchange}|${base}`
        }
        const data = await wst('data', {
            query: { id }
        })

        return data
    } catch (e) {
        console.error("Could not fetch tickers from server", e)

        return ({
            orderbook: { asks: {}, bids: {}},
            history: []
        })
    }
}

export interface IOrderPayload {
    exchange: string;
    base: string;
    quote: string;
    orderType: string;
    side: string;
    price?: number;
    stopPrice?: number;
    amount: number;
}
export interface ISubmitOrderState {
    status: boolean;
    message?: string;
}
/**
 * 
 * @param token 
 * @param account_secret 
 * @param orderPayload 
 */
const createOrder = async (token: string, account_secret: string, body: IOrderPayload): Promise<ISubmitOrderState> => {
    try {
        const data = await wst(`placeOrder`, {
            headers: {
                token,
                account_secret
            },
            body
        })

        return data
    } catch (e) {
        console.error("Could not submit order", e)
        return { status: false }
    }
}
interface ICancelOrderPayload {
    exchange: string;
    id: string;
    symbol: string;
}
/**
 * 
 * @param token 
 * @param account_secret 
 * @param orderPayload 
 */
const cancelOrder = async (token: string, account_secret: string, body: ICancelOrderPayload) => {
    try {
        const data = await wst(`cancelOrder`, {
            headers: {
                token,
                account_secret
            },
            body
        })

        return data.status
    } catch (e) {
        console.error("Could not cancel order", e)
        return { status: false }
    }
}

export {
    fetchRates,
    fetchTickers,
    fetchMarkets,
    fetchBalances,
    fetchOrders,
    fetchMarketData,
    createOrder,
    cancelOrder,
}