import { action } from 'typesafe-actions';

const entity = 'tab';
const ACTION_NEW_TAB = `${entity}/NEW`;
const ACTION_NEW_TAB_DONE = `${entity}/NEW_DONE`;
const ACTION_SELECT_TAB = `${entity}/SELECT`;
const ACTION_SELECT_TAB_BY_INDEX = `${entity}/SELECT_BY_INDEX`;
const ACTION_UPDATE_LAST_TAB = `${entity}/UPDATE_LAST_TAB`
const ACTION_CLOSE_TAB = `${entity}/CLOSE`
const ACTION_DRAGOUT = `${entity}/DRAGOUT`

const actionNewTab = () =>
    action(ACTION_NEW_TAB)

const actionNewTabDone = (premium: boolean) =>
    action(ACTION_NEW_TAB_DONE, { premium })

const actionSelectTab = (exchange: string, base: string, quote: string) =>
    action(ACTION_SELECT_TAB, { exchange, base, quote })

const actionSelectTabByIndex = (index: number) =>
    action(ACTION_SELECT_TAB_BY_INDEX, { index })

const actionCloseTab = (index: number) =>
    action(ACTION_CLOSE_TAB, { index })

const actionUpdateLastTab = (payload: any) =>
    action(ACTION_UPDATE_LAST_TAB, payload)

const actionDragoutTab = (payload: any) =>
    action(ACTION_DRAGOUT, payload)

export {
    ACTION_NEW_TAB,
    ACTION_NEW_TAB_DONE,
    ACTION_SELECT_TAB,
    ACTION_SELECT_TAB_BY_INDEX,
    ACTION_UPDATE_LAST_TAB,
    ACTION_CLOSE_TAB,
    ACTION_DRAGOUT,
    actionNewTab,
    actionNewTabDone,
    actionSelectTab,
    actionSelectTabByIndex,
    actionUpdateLastTab,
    actionCloseTab,
    actionDragoutTab
}