import { takeLatest, put, call } from "redux-saga/effects";
import { requestSignIn, requestSignUp } from '../core/accountData'
import { SIGN_IN_REQUEST, SIGN_OUT, actionSignInFailure, actionSignUpFailure, actionSignIn, SIGN_UP_REQUEST, SIGN_IN_FAIL, SIGN_UP_FAIL } from "../actions/accounts";
import { actionCloseModal, actionShowModal } from "../actions/modal";
import { actionNavigate } from "../actions/navigation";
import { PageType } from "../helpers/pageTypes";
import ModalTypes from "../helpers/modals";
import { onRefreshPositionsOrders } from "./infoSaga";
import { AnalyticsEvents, storeEvent } from "../core/analytics";
import { actionCreateNotification } from "../actions/notifications";

/**
 * Login flow
 */
function* signInRequestFn(action: any) {
    const { payload } = action
    const { email, password } = payload
    try {
        const reqState = yield call(requestSignIn, email, password)
        const { status } = reqState
        if (status === "error") {
            yield put(actionSignInFailure(reqState.message))
        } else {
            const { email, token, account_secret, session_id } = reqState
            yield put(actionSignIn(token, account_secret, email, session_id ))
            yield put(actionCloseModal())
            yield call(storeEvent, AnalyticsEvents.signIn)
            // Refresh orders & balances
            yield onRefreshPositionsOrders()
            // Navigate to trading after sign in
            yield put(actionNavigate(PageType.trading))
        }
    } catch (err) {
        console.error(err)

        yield put(actionSignInFailure("Network error"))
    }
}

/**
 * Registration flow
 */
function* signUpRequestFn(action: any) {
    const { payload } = action
    const { email, password } = payload
    try {
        const reqState = yield call(requestSignUp, email, password)
        const { status } = reqState
        if (status === "error") {
            yield put(actionSignUpFailure(reqState.message))
        } else {
            const { token, account_secret, session_id } = reqState
            yield put(actionSignIn(token, account_secret, email, session_id))
            yield put(actionShowModal(ModalTypes.registration_okay, {}))
            yield call(storeEvent, AnalyticsEvents.authenticate)
            // Navigate to trading after sign up
            yield put(actionNavigate(PageType.trading))
        }
    } catch (err) {
        console.error(err)

        yield put(actionSignUpFailure("Network error"))
    }
}

function* signOutRedirect(action: any): any {
    yield put(actionNavigate(PageType.homepage))
}

function* onNotifyOnSignInFail(): any {
    yield put(actionCreateNotification({ message: "Invalid login or password" }))
}
function* onNotifyOnSignUpFail(): any {
    yield put(actionCreateNotification({ message: "Could not create account" }))
}

export default function* accountsSaga() {
    yield takeLatest(SIGN_IN_REQUEST, signInRequestFn);
    yield takeLatest(SIGN_UP_REQUEST, signUpRequestFn);
    yield takeLatest(SIGN_OUT, signOutRedirect);
    yield takeLatest(SIGN_IN_FAIL, onNotifyOnSignInFail)
    yield takeLatest(SIGN_UP_FAIL, onNotifyOnSignUpFail)
}
  