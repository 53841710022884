import { action } from 'typesafe-actions';

const entity = 'orderbook';
const UPDATE = `${entity}/UPDATE`;
const CLEAR = `${entity}/CLEAR`;

const actionUpdateOrderBook = (data: any) =>
    action(UPDATE, data)

const actionClearOrderbook = () =>
    action(CLEAR)

export {
    UPDATE,
    CLEAR,
    actionUpdateOrderBook,
    actionClearOrderbook
}