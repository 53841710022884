import axios from "axios";

enum RequestType {
  candles,
  candlesUSD,
  lastCandle,
  lastCandleUSD
}
interface IFetchCandles {
  baseAddress: string;
  quoteAddress: string;
  from: string; // ISO String
  to?: string; // ISO string
  interval: number; // minutes
  protocol: string
  exchangeName: string
}
/**
 * Fetch candles in USD values
 */
const fetchCandlesUSD = async ({
  baseAddress,
  quoteAddress,
  from,
  to,
  interval,
  protocol,
  exchangeName
}: IFetchCandles) => {
  try {
    const payload = JSON.stringify({
      query: createQuery(RequestType.candlesUSD, exchangeName),
      variables: {
        from,
        to,
        interval,
        baseAddress,
        quoteAddress,
        protocol,
        exchangeName
      },
    });
    const { data } = await axios.post("https://graphql.bitquery.io/", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return normalizeCandlesInUSD(data.data.ethereum.dexTrades);
  } catch (err) {
    console.warn("Could not call bitquery", err);
    return [];
  }
};

/**
 * 
 * @param param0 
 * @returns 
 */
const fetchLastCandlesUSD = async ({
  baseAddress,
  quoteAddress,
  from,
  interval,
  protocol,
  exchangeName
}: IFetchCandles) => {
  try {
    const payload = JSON.stringify({
      query: createQuery(RequestType.lastCandleUSD, exchangeName),
      variables: {
        from,
        interval,
        baseAddress,
        quoteAddress,
        protocol,
        exchangeName
      },
    });
    const { data } = await axios.post("https://graphql.bitquery.io/", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return normalizeCandlesInUSD(data.data.ethereum.dexTrades);
  } catch (err) {
    console.warn("Could not call bitquery", err);
    return [];
  }
};
interface IBitqueryUSDResponse {
  buyAmount: number; // 1567.2309856773227
  buyCurrency: any; // {symbol: "WETH", address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"}
  close: string; // "0.023811535999999998"
  high: number; // 0.02407309051315374
  low: number; // 0.023811535999999998
  open: string; // "0.023889378419837706"
  sellCurrency: any; // {symbol: "WBTC", address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"}
  timeInterval: any; // {minute: "2020-12-27T00:00:00Z"}
  trades: number; // 76
  volume: number; // 37.51866637
  usd_amount: number; 
}

/**
 * So we got volume (which is quote_amount) and usd_amount, so we can create quote/USD rate and convert all to base/USD
 * Weird? Yes
 * Will work? Sure
 * @param dexTrades 
 */
const normalizeCandlesInUSD = (dexTrades: IBitqueryUSDResponse[]) =>
  dexTrades.map((candle: IBitqueryUSDResponse) => {
    const date = Date.parse(
      candle.timeInterval.minute || candle.timeInterval.hour
    );
    const rate = candle.usd_amount / candle.volume; // get price of 1 quote in USD
    const open = parseFloat(candle.open) * rate;
    const close = parseFloat(candle.close) * rate;
    const high = candle.high * rate
    const low = candle.low * rate
    return [date, open, high, low, close, candle.usd_amount];
  });

const candlesQuery = (exchangeName: string) => {
  if (exchangeName === 'Uniswap') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $to: ISO8601DateTime!\n $interval: Int\n $protocol: String\n $exchangeName: String\n ) {\n ethereum(network: ethereum) {\n dexTrades(\n protocol: { is: $protocol }\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n date: { between: [$from, $to] }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n }\n }\n }\n'
  }
  if (exchangeName === 'Pancake') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $to: ISO8601DateTime!\n $interval: Int\n $exchangeName: String\n ) {\n ethereum(network: bsc) {\n dexTrades(\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n date: { between: [$from, $to] }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n }\n }\n }\n'
  }
}

/**
 * Don't have $to
 */
const lastCandlesQuery = (exchangeName: string) => {
  if (exchangeName === 'Uniswap') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $interval: Int\n $protocol: String\n $exchangeName: String\n ) {\n ethereum(network: ethereum) {\n dexTrades(\n protocol: { is: $protocol }\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n options: {limit: 10, desc: "timeInterval.minute"} \n date: { since: $from }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n }\n }\n }\n'
  }
  if (exchangeName === 'Pancake') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $interval: Int\n $exchangeName: String\n ) {\n ethereum(network: bsc) {\n dexTrades(\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n options: {limit: 10, desc: "timeInterval.minute"} \n date: { since: $from }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n }\n }\n }\n'
  }
}

const candlesUSDQuery = (exchangeName: string) => {
  if (exchangeName === 'Uniswap') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $to: ISO8601DateTime!\n $interval: Int\n $protocol: String\n $exchangeName: String\n ) {\n ethereum(network: ethereum) {\n dexTrades(\n protocol: { is: $protocol }\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n date: { between: [$from, $to] }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n usd_amount: quoteAmount(in: USD) \n }\n }\n }\n'
  }
  if (exchangeName === 'Pancake') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $to: ISO8601DateTime!\n $interval: Int\n $exchangeName: String\n ) {\n ethereum(network: bsc) {\n dexTrades(\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n date: { between: [$from, $to] }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n usd_amount: quoteAmount(in: USD) \n }\n }\n }\n'
  }
}

const lastCandlesUSDQuery = (exchangeName: string) => {
  if (exchangeName === 'Uniswap') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $interval: Int\n $protocol: String\n $exchangeName: String\n ) {\n ethereum(network: ethereum) {\n dexTrades(\n protocol: { is: $protocol }\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n options: {limit: 10, desc: "timeInterval.minute"}\n date: { since: $from }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n usd_amount: quoteAmount(in: USD) \n }\n }\n }\n'
  }
  if (exchangeName === 'Pancake') {
    return '\n query(\n $baseAddress: String\n $quoteAddress: String\n $from: ISO8601DateTime!\n $interval: Int\n $exchangeName: String\n ) {\n ethereum(network: bsc) {\n dexTrades(\n baseCurrency: { is: $baseAddress }\n quoteCurrency: { is: $quoteAddress }\n options: {limit: 10, desc: "timeInterval.minute"}\n date: { since: $from }\n exchangeName: { is: $exchangeName }\n priceAsymmetry: { lt: 0.7 }\n any: [\n {tradeAmountUsd: { gt: 0.00001 }},\n {tradeAmountUsd: { is: 0 }}\n ]\n ) {\n timeInterval {\n minute(format:"%FT%TZ", count: $interval)\n }\n buyCurrency: baseCurrency {\n symbol\n address\n }\n buyAmount: baseAmount\n sellCurrency: quoteCurrency {\n symbol\n address\n }\n volume: quoteAmount\n trades: count\n high: quotePrice(calculate: maximum)\n low: quotePrice(calculate: minimum)\n open: minimum(of: block, get: quote_price)\n close: maximum(of: block, get: quote_price)\n usd_amount: quoteAmount(in: USD) \n }\n }\n }\n'
  }
}
/**
 * Workaround:
 * uniswap - requires protocol and network: etherium
 * pancake - requires network: bsc
 * @param mode 
 * @param exchangeName 
 * @returns 
 */
const createQuery = (mode: RequestType, exchangeName: string) => {
  switch (mode) {
    case RequestType.candles:
      return candlesQuery(exchangeName)
    case RequestType.lastCandle:
      return lastCandlesQuery(exchangeName)
    case RequestType.candlesUSD:
      return candlesUSDQuery(exchangeName)
    case RequestType.lastCandleUSD:
      return lastCandlesUSDQuery(exchangeName)
    default:
      return candlesQuery(exchangeName)
  }
}
/**
 * Fetch candles in original values
 * @param param0
 */
const fetchCandles = async ({
  baseAddress,
  quoteAddress,
  from,
  to,
  interval,
  protocol,
  exchangeName
}: IFetchCandles) => {
  try {
    const payload = JSON.stringify({
      query: createQuery(RequestType.candles, exchangeName),
      variables: {
        from,
        to,
        interval,
        baseAddress,
        quoteAddress,
        protocol,
        exchangeName
      },
    });
    const { data } = await axios.post("https://graphql.bitquery.io/", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return normalizeCandles(data.data.ethereum.dexTrades);
  } catch (err) {
    console.warn("Could not call bitquery", err);
    return [];
  }
};
/**
 * Last candles
 * @param param0 
 * @returns 
 */
const fetchLastCandles = async ({
  baseAddress,
  quoteAddress,
  from,
  interval,
  protocol,
  exchangeName
}: IFetchCandles) => {
  try {
    const payload = JSON.stringify({
      query: createQuery(RequestType.lastCandle, exchangeName),
      variables: {
        from,
        interval,
        baseAddress,
        quoteAddress,
        protocol,
        exchangeName
      },
    });
    const { data } = await axios.post("https://graphql.bitquery.io/", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return normalizeCandles(data.data.ethereum.dexTrades);
  } catch (err) {
    console.warn("Could not call bitquery", err);
    return [];
  }
};
interface IBitqueryResponse {
  buyAmount: number; // 1567.2309856773227
  buyCurrency: any; // {symbol: "WETH", address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"}
  close: string; // "0.023811535999999998"
  high: number; // 0.02407309051315374
  low: number; // 0.023811535999999998
  open: string; // "0.023889378419837706"
  sellCurrency: any; // {symbol: "WBTC", address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"}
  timeInterval: any; // {minute: "2020-12-27T00:00:00Z"}
  trades: number; // 76
  volume: number; // 37.51866637
}

/**
 * Normalize to binance-like format:
 * [timestamp, open, high, low, close, volume]
 * @param dexTrades
 */
const normalizeCandles = (dexTrades: IBitqueryResponse[]) =>
  dexTrades.map((candle: IBitqueryResponse) => {
    const date = Date.parse(
      candle.timeInterval.minute || candle.timeInterval.hour
    );
    const open = parseFloat(candle.open);
    const close = parseFloat(candle.close);
    const volume = candle.volume;
    return [date, open, candle.high, candle.low, close, volume];
  });

export {
  fetchCandles,
  fetchCandlesUSD,
  fetchLastCandlesUSD,
  fetchLastCandles
};
