/**
 * Implements a generic modal
 */
import React from 'react'
import { connect } from 'react-redux'
import { actionCloseModal, actionShowModal } from '../../../actions/modal'
import { ReactComponent as CloseIcon } from '../LoginModal/close.svg'
import "./styles.scss"

interface IInfoModalProps {
    modalTitle: string;
    heading: string;
    text: string;
    children: any;
    btnTitle: string;
    onClick?: Function;
    onShowModal?: string;
    actionCloseModal: Function;
    actionShowModal: Function;
}

const InfoModal = (props: IInfoModalProps) => {
    const { modalTitle, heading, text, children, btnTitle, onClick, onShowModal } = props;

    const onBtnClicked = () => {
        if (onShowModal) {
            props.actionShowModal(onShowModal, {})
        } else {
            if (onClick) {
                onClick()
            }
            props.actionCloseModal()
        }
    }
    return (<div className="info__modal info__modal--small">
        <div className="info__modal__header">
            <span className="info__modal__header__caption">{modalTitle}</span>
            <span className="info__modal__header__close" onClick={() => props.actionCloseModal()}><CloseIcon /></span>
        </div>
        <div className="info__modal__success">
            {children}
            <h2>{heading}</h2>
            <p className="info__modal__success__text">{text}</p>
            <div
                className="info__modal__form__btn info__modal__form__btn--small"
                onClick={onBtnClicked}>
                {btnTitle}
            </div>
        </div>
    </div>)
}

export default connect(null, { actionCloseModal, actionShowModal })(InfoModal)
