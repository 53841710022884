/**
 * Presentation component
 * Request handled in saga
 */
import React, { useState } from 'react'
import { ReactComponent as LoadingIcon } from './loader.svg'

interface ILoginFormProps {
    locked: boolean;
    onSignIn: Function;
    onForgotPassword: (event: React.MouseEvent) => void;
    onNotify: (message: string) => void; 
}

const LoginForm = (props: ILoginFormProps) => {
    const { locked } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const onSendForm = (e: any) => {
        if (e && e.preventDefault) {
            e.preventDefault()
        }
        if (!locked) {
            if (email.includes('@')) {
                props.onSignIn(email, password)
            } else {
                props.onNotify("Email is not valid")
            }
        }
    }

    return (
        <form className="login__modal__form" onSubmit={onSendForm}>
            <input
                type="email"
                className="login__modal__form__text"
                placeholder="Email address"
                autoComplete="username"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
            />
            <input
                type="password"
                className="login__modal__form__text"
                placeholder="Password"
                autoComplete="current-password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
            />
            <div className="login__modal__form__links">
                <span onClick={props.onForgotPassword}>Forgot password?</span>
            </div>
            <button className="login__modal__form__btn" onClick={onSendForm}>
                {locked && <LoadingIcon />}
                {!locked && <>LOGIN</>}
            </button>
        </form>
    )
}

export default LoginForm