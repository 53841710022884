import { CLEAR, UPDATE } from "../actions/orderbook";

const emptyState = {
  bids: {},
  asks: {}
}

const orderbook = (state = emptyState, action: any) => {
    switch (action.type) {
      case CLEAR:
        return emptyState
      case UPDATE:
        return action.payload
      default:
        return state
    }
  }

export default orderbook