import axios from 'axios';
import { wst } from './wst';
import { accountsEndpoint, backend } from './endpoints';
import { StorageKeys } from '../helpers/localStorage';

/**
 * Default auth headers
 */
const getSessionHeaders = () => ({ session_id: localStorage.getItem(StorageKeys.session_id) })
/**
 * Fetch exchange accounts from server
 * @param token - jwt
 * @param account_secret
 */
const requestExchangeAccounts = async (token: string, account_secret: string): Promise<any[]> => {
    try {
        const headers = {
            token,
            account_secret
        }
        const data = await wst('accounts', { headers })

        return data.items
    } catch (err) {
        console.error(err)
        return []
    }
}
/**
 * Send new account data to server
 * @param token - jwt
 * @param account_secret
 * @package body - any js object
 */
const requestCreateAccount = async (token: string, account_secret: string, body: any): Promise<Boolean> => {
    try {
        const headers = {
            token,
            account_secret
        }
        const { data } = await axios.post(`${backend}/v1/accounts`, body, { headers })

        return data.status
    } catch (err) {
        console.error(err)
        return false
    }
}
/**
 * Delete account by apiKey
 * @param token - jwt
 * @param account_secret
 * @package body - any js object
 */
const requestDeleteAccount = async (token: string, account_secret: string, accountKey: any): Promise<Boolean> => {
    try {
        const headers = {
            token,
            account_secret
        }
        const { data } = await axios.delete(`${backend}/v1/accounts`,{ data: { accountKey }, headers })

        return data.status
    } catch (err) {
        console.error(err)
        return false
    }
}
/**
 * Update account
 * @param token - jwt
 * @param account_secret
 * @package body - any js object
 */
const requestUpdateAccount = async (token: string, account_secret: string, body: any): Promise<Boolean> => {
    try {
        const headers = {
            token,
            account_secret
        }
        const { data } = await axios.put(`${backend}/v1/accounts`, { data: body, headers })

        return data.status
    } catch (err) {
        console.error(err)
        return false
    }
}

interface IAccountNotifications {
    message: string;
    created_at: number;
}
/**
 * Fetch account notifications
 * @param token - jwt
 * @param account_secret
 */
const requestMessagesAccounts = async (token: string, account_secret: string): Promise<IAccountNotifications[]> => {
    try {
        const headers = {
            token,
            account_secret
        }
        const data = await wst('messages', { headers })

        return data.messages || []
    } catch (err) {
        console.error(err)
        return []
    }
}

/**
 * Call accounts login
 * @param email 
 * @param password 
 */
const requestSignIn = async (email: string, password: string) => {
    try {
        const { data } = await axios.post(`${accountsEndpoint}/login`, { email, password })
        return data
    } catch (err) {
        console.error(err)

        return null
    }
}
/**
 * Create account call
 * @param email 
 * @param password 
 */
const requestSignUp = async (email: string, password: string) => {
    try {
        const { data } = await axios.post(`${accountsEndpoint}/create`, { email, password })

        return data
    } catch (err) {
        console.error(err)

        return null
    }
}

/**
 * Sends a request to reset account email
 * @param email - valid input
 */
const requestForgotPassword = async (email: string): Promise<boolean> => {
    try {
        const { data } = await axios.post(`${accountsEndpoint}/forgot`, { email })

        return (data.status !== "error")
    } catch (err) {
        console.error(err)

        return false
    }
}
/**
 * Sends a request to change account password
 * @param password - valid password
 * @param newPassword - new password
 */
const requestChangePassword = async (password: string, newPassword: string): Promise<boolean> => {
    try {
        const { data } = await axios.post(`${accountsEndpoint}/change_password`,
        {
            password,
            newPassword
        }, {
            headers: getSessionHeaders()
        })

        return (data.status !== "error")
    } catch (err) {
        console.error(err)

        return false
    }
}

/**
 * Fetch account data like subscription details, payments
 * @param email 
 * @param token 
 */
const requestAccountData = async (): Promise<any> => {
    try {
        const { data } = await axios(`${accountsEndpoint}/state`, { headers: getSessionHeaders() })

        return data
    } catch (err) {
        console.error(err)

        return null
    }
}
/**
 * Update watchlist
 * @param watchlist - json array
 */
const updateWatchlist = async (watchlist: any): Promise<any> => {
    try {
        const { data } = await axios.put(`${accountsEndpoint}/watchlist`, watchlist, { headers: getSessionHeaders() })

        return data
    } catch (err) {
        console.error(err)

        return null
    }
}

export interface ISession {
    browser: string; // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.89 Safari/537.36"
    deleted: number; // 0
    enabled: number; //1
    id: number; // 10
    ip: string; // "::1"
    os: string;
    tabs: string | null;
    user_id?: number; // 
    uuid: string; // "48f823ae-7ea9-43cf-8235-7d0fcc96d9bf"
    widgets: string | null
}
/**
 * Fetch account data like subscription details, payments
 * @param email 
 * @param token 
 */
const requestAccountSessions = async (): Promise<ISession[]> => {
    try {
        const { data } = await axios.get(`${accountsEndpoint}/sessions`, { headers: getSessionHeaders() })

        return data.sessions
    } catch (err) {
        console.error(err)

        return []
    }
}

/**
 * Request symbols because we need them for markets
 */
const requestBinanceMarkets = async (): Promise<any[]> => {
    try {
        const { data } = await axios.get(`${backend}/binance/symbols`)

        return data.data.symbols
    } catch (err) {
        console.error(err)

        return []
    }
}

/**
 * Delete session
 * @param id 
 */
const deleteAccountSession = async (id: any): Promise<any> => {
    try {
        const { data } = await axios.delete(`${accountsEndpoint}/sessions/${id}`, { headers: getSessionHeaders() })

        return data.sessions
    } catch (err) {
        console.error(err)

        return []
    }
}

export {
    getSessionHeaders,
    requestSignIn,
    requestSignUp,
    requestExchangeAccounts,
    requestCreateAccount,
    requestDeleteAccount,
    requestUpdateAccount,
    requestMessagesAccounts,
    requestForgotPassword,
    requestChangePassword,
    requestAccountData,
    updateWatchlist,
    requestAccountSessions,
    deleteAccountSession,
    requestBinanceMarkets
}