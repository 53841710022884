import React, { useState, useEffect } from 'react'
import { ITradingAccount } from '../../../reducers/trading_accounts';

interface IExchangeAccountProps {
    onClick: (account: ITradingAccount) => void;
    onDelete: any;
    exchangeName: string;
    connected: boolean;
    accountKey: string;
    accountSecret: string;
}

const ExchangeAccount = (props: IExchangeAccountProps) => {
    const { onClick, onDelete, exchangeName, connected, accountKey, accountSecret } = props;
    const [ apiKey, setKey ] = useState(accountKey)
    const [ apiSecret, setPassword ] = useState(accountSecret)
    // React to changing props
    useEffect(() => {
        setKey(props.accountKey)
        setPassword(props.accountSecret)
    }, [props.accountKey, props.accountSecret])

    const onSubmit = () => {
        const notEmpty = (apiKey.length > 5 && apiSecret.length > 5)
        if (!connected && notEmpty) {
            onClick({
                exchangeName,
                connected: true,
                accountKey: apiKey,
                accountSecret: apiSecret
            })
        }
    }

    return (
        <div className="exchange__item">
            <form className={`exchange__item__container ${connected ? 'exchange__item__container--connected' : ''}`}>
                <div className="exchange__item__caption">{connected ? `Connected to ${exchangeName}` : `Connect to ${exchangeName}`}</div>
                <div
                    className="exchange__item__trash"
                    onClick={onDelete}
                />
                <div className="exchange__item__field exchange__item__field--api">
                    <label htmlFor="apiInput">API</label>
                    <input
                        type="text"
                        name="apiInput"
                        disabled={connected}
                        value={apiKey}
                        onChange={(e) => setKey(e.target.value)}
                    />
                </div>
                <div className="exchange__item__field exchange__item__field--secret">
                    <label htmlFor="secretInput">Secret</label>
                    <input
                        type="password"
                        name="secretInput"
                        disabled={connected}
                        value={apiSecret}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div
                    className={`exchange__item__btn ${connected ? 'exchange__item__btn--connected' : ''}`}
                    onClick={onSubmit}
                >CONNECT</div>
            </form>
        </div>
    )
}

export default ExchangeAccount