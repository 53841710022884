/**
 * Balances reducer
 * Spot/margin should be stored seperately
 */
import { UPDATE } from "../actions/balances"

const defaultState: IBalanceState = {
  spot: {
    total: {},
    used: {}
  },
  margin: {
    total: {},
    used: {}
  },
  exchanges: {}
}
export interface IBalanceEntity {
  total: any;
  used: any;
}
export interface IBalanceState {
  spot: IBalanceEntity;
  margin: IBalanceEntity;
  exchanges: any;
}

const balances = (state: IBalanceState = defaultState, action: any) => {
  switch (action.type) {
    case UPDATE:
      return action.payload
    default:
      return state
  }
}

export default balances