/**
 * Implements a logic to switch between CEX and DEX trading
 */
import React from 'react'
import { connect } from 'react-redux'
import { EExchangeType } from '../../reducers/current'
import { currentExchangeTypeSelector } from '../../selectors'
import CEXTrading from './CEXTrading'
import DEXTrading from './DEXTrading'
import "./styles.scss"

interface ICreateOrderProps {
    exchangeType: EExchangeType;
}
const CreateOrder = ({ exchangeType }: ICreateOrderProps) => {
    if (exchangeType === EExchangeType.dex) {
        return <DEXTrading />
    }
    return <CEXTrading />
}

const mapStateToProps = (state: any) => ({
    exchangeType: currentExchangeTypeSelector(state)
})

export default connect(mapStateToProps)(CreateOrder)