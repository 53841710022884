/**
 * Implements PriceChart
 */
import React from 'react'
import SimpleLineChart from './SimpleLineChart'

const PriceChart = ({ data }: any) => {
    const stroke = isRising(data) ? '#16C466' : '#D04343'
    return (<SimpleLineChart
        width={300}
        height={55}
        data={data}
        stroke={stroke}
        title={data[0]}
    />)
}

/**
 * If first elements lower than last than trend is rising
 * @param dots 
 */
const isRising = (dots: number[]) => (dots[0] < dots[dots.length - 1])

export default PriceChart