/**
 * Implements Trading Performance page
 * UI works only for spot balances
 */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getTotalValue, getCoins } from './helper'
import ExchangeExposure from './ExchangeExposure'
import TotalBalance from './TotalBalance'
import TradingPerformance from './TradingPerformance'
import CryptoExposureChart from './CryptoExposure'
import { storeEvent, AnalyticsEvents } from '../../../core/analytics'

interface IPerformancePageProps {
    balances: any;
    rates: any;
}
const PerformancePage = (props: IPerformancePageProps) => {
    const { balances, rates } = props
    const [selected, onSelect] = useState("")
    const [totalValue, setTotalValue] = useState("")
    const [coins, setCoins] = useState<any>([])

    useEffect(() => {
        storeEvent(AnalyticsEvents.openTradingPerformance, {})
    }, [])

    useEffect(() => {
        setTotalValue(getTotalValue(balances, rates))
        setCoins(getCoins(balances.spot, rates, parseFloat(totalValue)))
    }, [totalValue, balances, rates])
   
    
    return (
        <div className="trading_performance_page">
            <TotalBalance
                balances={balances}
                rates={rates}
                coins={coins}
                totalValue={totalValue}
                selection={selected}
                onSelect={onSelect}
            />
            <CryptoExposureChart
                balances={balances.spot}
                rates={rates}
                coins={coins}
                totalValue={totalValue}
                selection={selected}
                onSelect={onSelect}
            />
            <ExchangeExposure
                positions={balances.exchanges}
                exchanges={Object.keys(balances.exchanges)}
                rates={rates}
                // coins={coins}
                totalValue={totalValue}
                selection={selected}
                onSelect={onSelect}
            />
            {/* <TradingPerformance /> */}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    balances: state.balances,
    rates: state.rates
})

export default connect(mapStateToProps)(PerformancePage)