import { action } from "typesafe-actions"

const entity = 'orders'

const UPDATE = `${entity}/UPDATE`
const UPDATE_REQUESTED = `${entity}/UPDATE_REQUESTED`
const UPDATE_DONE = `${entity}/UPDATE_DONE`

const CANCEL = `${entity}/CANCEL`

const actionUpdateOrders = (markets: any) =>
    action(UPDATE, markets)

const actionUpdateOrdersRequested = () =>
    action(UPDATE_REQUESTED)

const actionUpdateOrdersDone = () =>
    action(UPDATE_DONE)

const actionCancelOrders = (orders: string[]) =>
    action(CANCEL, orders)

export {
    UPDATE,
    UPDATE_REQUESTED,
    UPDATE_DONE,
    CANCEL,
    actionUpdateOrders,
    actionUpdateOrdersRequested,
    actionUpdateOrdersDone,
    actionCancelOrders
}