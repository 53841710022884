/**
 * Implements a uniswap datafeed
 */
// import { backend } from "../../../core/endpoints"
import {
    fetchCandles,
    fetchCandlesUSD,
    fetchLastCandlesUSD,
    fetchLastCandles
} from './helpers/bitquery';

interface IDexSymbol {
    symbol: string; //WETH/USDT
    symbolName: string; // WETHUSDT
    baseAsset: string; // WETH
    quoteAsset: string; // USDT
    baseAddress: string;
    quoteAddress: string;
    precision?: any
}
/**
 * Route name used in backend
 */

interface IUniswapConstructorOptions {
    exchange: string;
    markets: any;
    debug: boolean
}
/* eslint-disable no-restricted-syntax */
class UniswapDatafeed {
    host: string
    debug: boolean
    symbols: IDexSymbol[]
    protocol: string;
    exchangeName: string;
    subscriptions: any;
    lastBar: any;
    onRealtimeCallback: any;
    barsInterval: any;

    constructor(options: IUniswapConstructorOptions) {
        this.protocol = 'Uniswap v2' // (options.exchange === 'uniswap') ? "Uniswap v2" : 'Pancake'
        this.exchangeName = (options.exchange === 'uniswap') ? "Uniswap" : 'Pancake'
        this.host = 'https://chart.kattana.trade'
        this.symbols = []
        this.debug = options.debug || false
        this.fetchSymbols(options.markets);
        this.subscriptions = {}
    }

    /**
     * Create symbols
     */
    fetchSymbols = (markets: any) => {
        const keys = Object.keys(markets)
        const symbols: any = []

        const uniqueBaseSymbols: string[] = []

        keys.forEach((key: string) => {
            const market = markets[key]
            const symbol = key.split('|')[1]
            const [ baseAsset, quoteAsset ] = symbol.split('/')

            symbols.push({
                symbol: symbol.toUpperCase(),
                symbolName: `${baseAsset}${quoteAsset}`.toUpperCase(), // well, this is how we get input
                baseAsset,
                quoteAsset,
                baseAddress: market.contract.baseAddress,
                quoteAddress: market.contract.quoteAddress,
                precision: market.precision
            })

            if (!uniqueBaseSymbols.includes(baseAsset)) {
                uniqueBaseSymbols.push(baseAsset)
                /**
                 * Add symbol for calculated USD from quote
                 */
                symbols.push({
                    symbol: `${baseAsset}USD`.toUpperCase(),
                    symbolName: `${baseAsset}USD`.toUpperCase(),
                    baseAsset,
                    quoteAsset: 'USD',
                    baseAddress: market.contract.baseAddress,
                    quoteAddress: market.contract.quoteAddress,
                    precision: market.precision
                })
            }
        })
        this.symbols = symbols;
    }

    fetchKlines = async (symbol: any, interval: number | string, startTime: number, endTime: number, limit: number = 500) => {
        try {
            const convertedUSD = symbol.data.quoteAsset === 'USD'
            const fetchFn = convertedUSD ? fetchCandlesUSD : fetchCandles; 
            const data = await fetchFn({
                baseAddress: symbol.data.baseAddress,
                quoteAddress: symbol.data.quoteAddress,
                interval: Number(interval),
                from: new Date(startTime).toISOString(),
                to: new Date(endTime).toISOString(),
                protocol: this.protocol,
                exchangeName: this.exchangeName
            })
            return data
        } catch (err) {
            console.error(err)
            return []
        }
    }

    /**
     * Wrapper for fetchKlines
     * @param symbol 
     * @param interval 
     * @returns 
     */
    getLastCandle = async (symbol: any, interval: number | string) => {
        try {
            const lastTime = Number(this.lastBar.time)
            const startTime = new Date(lastTime)

            const convertedUSD = symbol.data.quoteAsset === 'USD'
            const fetchFn = convertedUSD ? fetchLastCandlesUSD : fetchLastCandles; 
            const data = await fetchFn({
                baseAddress: symbol.data.baseAddress,
                quoteAddress: symbol.data.quoteAddress,
                interval: Number(interval),
                from: startTime.toISOString(),
                protocol: this.protocol,
                exchangeName: this.exchangeName
            })
            const [ kline ] = data
            if (kline) {
                const candle = this.klineToCandle(kline)
                this.lastBar = candle
                return candle
            } else {
                return null
            }
        } catch (err) {
            console.error(err)
            return null
        }
    }

    onReady = (callback: any) => {
        try {
            setTimeout(() => {
                callback({
                    supports_marks: false,
                    supports_timescale_marks: false,
                    supports_time: true,
                    supported_resolutions: [
                        '1', '3', '5', '15', '30', '60', '120', '240', '360', '480', '720'
                    ]
                });
            }, 0)
        } catch (err) {
            console.error(err);
        }
    }

    searchSymbols(userInput: any, exchange: any, symbolType: any, onResultReadyCallback: any) {
        const input = userInput.toUpperCase();
        onResultReadyCallback(this.symbols
                .filter((symbol) => symbol.symbol.indexOf(input) >= 0)
                .map((symbol) => ({
                    symbol: symbol.symbol,
                    full_name: symbol.symbol,
                    description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
                    pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
                    ticker: symbol.symbol,
                    exchange: this.exchangeName
                })));
    }

    resolveSymbol(symbolName: any, onSymbolResolvedCallback: any, onResolveErrorCallback: any) {
        const GENERIC_PRICESCALE = 10 ** 8
        if (this.debug) console.log('👉 resolveSymbol:', symbolName);
        const comps = symbolName.split(':');
        // eslint-disable-next-line no-param-reassign
        symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

        const symbol = this.symbols.find((symbol: any) =>
            symbol.symbol === symbolName || symbol.symbolName === symbolName)

        if (symbol) {
            setTimeout(() => {
                onSymbolResolvedCallback({
                    name: symbol.symbol,
                    description: `${symbol.baseAsset} / ${symbol.quoteAsset}`,
                    pair: `${symbol.quoteAsset}-${symbol.baseAsset}`,
                    ticker: symbol.symbol,
                    session: '24x7',
                    minmov: 1,
                    pricescale: GENERIC_PRICESCALE,
                    timezone: 'UTC',
                    has_intraday: true,
                    has_daily: true,
                    has_weekly_and_monthly: true,
                    currency_code: symbol.quoteAsset,
                    data: symbol,
                    exchange: this.exchangeName
                });
            }, 0);
        } else {
            onResolveErrorCallback('not found');
        }
    }

    /**
     * Convert Binance's kline to candle
     * @param {*} kline 
     */
    klineToCandle = (kline: any) => ({
        time: kline[0],
        open: kline[1],
        high: kline[2],
        low: kline[3],
        close: kline[4],
        volume: kline[5]
    })

    getBars(symbolInfo: any, interval: number, from: any, to: any, onHistoryCallback: any, onErrorCallback: any, firstDataRequest: any) {
        if (this.debug) {
            console.log('👉 getBars:', symbolInfo.name, interval);
            console.log('First:', firstDataRequest);
            console.log('From:', from, new Date(from * 1000).toUTCString());
            console.log('To:  ', to, new Date(to * 1000).toUTCString());
        }

        if (!interval) {
            onErrorCallback('Invalid interval');
        }

        let totalKlines: any[] = [];

        const finishKlines = () => {
            if (this.debug) {
                console.log('📊:', totalKlines.length);
            }
  
            if (totalKlines.length === 0) {
                onHistoryCallback([], { noData: true });
            } else {
                this.lastBar = this.klineToCandle(totalKlines[totalKlines.length - 1])
                onHistoryCallback(totalKlines.map(this.klineToCandle), { noData: false });
            }
        };
  
        // eslint-disable-next-line no-shadow
        const getKlines = (from: number, to: number) => {
            this.fetchKlines(symbolInfo, interval, from, to, 500).then(klines => {
              totalKlines = totalKlines.concat(klines);
              finishKlines();
              return null;
            }).catch(err => {
              console.error(err);
              onErrorCallback('Some problem');
            });
        };
  
        // eslint-disable-next-line no-param-reassign
        from *= 1000;
        // eslint-disable-next-line no-param-reassign
        to *= 1000;
  
        getKlines(from, to);
    }

    getSubscriptionName = (symInfo: any, subscriberUID: string) => {
        const exchange = this.exchangeName.toLocaleLowerCase();
        const { data } = symInfo
        const { baseAsset, quoteAsset } = data
        const subscription = `lastPrice_${exchange}_${baseAsset}${quoteAsset}`
        this.subscriptions[subscriberUID] = subscription
        return subscription
    }

    onLastPrice = (message: any) => {
        const { detail } = message
        if (detail) {
            if (this.lastBar) {
                this.onRealtimeCallback({
                    ...this.lastBar,
                    close: detail
                })
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    subscribeBars(symbolInfo: any, resolution: any, onRealtimeCallback: any, subscriberUID: any, onResetCacheNeededCallback: any) {
        if (this.debug) console.log('👉 subscribeBars:', subscriberUID);
        this.onRealtimeCallback = onRealtimeCallback;
        window.addEventListener(this.getSubscriptionName(symbolInfo, subscriberUID), this.onLastPrice)

        /**
         * Subscribe to timer based bars
         */
        this.barsInterval = setInterval(async () => {
            const candle = await this.getLastCandle(symbolInfo, resolution)
            if (candle) {
                onRealtimeCallback(candle)
            } else {
                onResetCacheNeededCallback()
            }
        }, 30000);
    }

    unsubscribeBars(subscriberUID: any) {
        if (this.debug) console.log('👉 unsubscribeBars:', subscriberUID);
        clearInterval(this.barsInterval)
        const subscriptionsName = this.subscriptions[subscriberUID]
        if (subscriptionsName) {
            window.removeEventListener(subscriptionsName, this.onLastPrice);
        }
    }
}

export default UniswapDatafeed;
