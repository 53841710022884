/**
 * Implements simple line chart
 * operates array of numbers
 */
import React, { useState, useEffect } from 'react'

interface ISimpleLineChartProps {
    width: number;
    height: number;
    stroke: string;
    title: string;
    data: number[];
}
const SimpleLineChart = ({ width, height, stroke, title, data }: ISimpleLineChartProps) => {
    const [points, setPoints] = useState<string[]>([])

    useEffect(() => {
        const [min, max] = getMinMax(data)

        const fX = (index: number) => 
            (index * (width / data.length))

        /**
         * Get position (%) between max and min
         * @param volume 
         */
        const fY = (volume: number) => {
            const percent = (volume - min) / (max - min); // volume %
            return (height - (height * percent)) * 0.95; // how many points in height?
        }

        const points = data.map((volume: number, index: number) =>
            `${fX(index)},${fY(volume)}`)

        setPoints(points)
    }, [width, height, data])
    return (
        <svg viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
            <text
                className="homepage__chart_hint"
                stroke={stroke}
                x="0"
                y="20">{title}</text>
            <polyline
                fill="none"
                stroke={stroke}
                strokeWidth="2"
                points={points.join(' ')}
            />
    </svg>)
}

/**
 * Get min max of array
 * @param collection 
 */
const getMinMax = (collection: number[]) =>
    ([
        Math.min.apply(null, collection),
        Math.max.apply(null, collection)
    ])

export default SimpleLineChart