/**
 *  amount0In	BigDecimal	amount of token0 sold
 *  amount1In	BigDecimal	amount of token1 sold
 *  amount0Out	BigDecimal	amount of token0 received
 *  amount1Out	BigDecimal	amount of token1 received
 */
import axios from 'axios'
import { Token, Fetcher, ChainId } from "@uniswap/sdk";

interface IDexToken {
    __typename: string; // "Token",
    id: string; // "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    symbol: string; // "WBTC"
}
interface IDexPair {
    token0: IDexToken
    token1: IDexToken
}
interface IDexTransaction {
    id: string; // "0xf7184fc5d3ec8dcde1f4d8b7f5e24fd783bd3e5718760fa0a2b5df1fa2708d8e"
    timestamp: string;
}

export interface IDexSwap {
    side: string;
    amount0In: string; // "0.03"
    amount0Out:  string; //"0"
    amount1In:  string; // "0"
    amount1Out:  string; //"0.945996040963791364"
    baseAmount: string;
    quoteAmount: string;
    amountUSD:  string; //"1675.509365385532962200889343933724"
    price: number;
    id:  string; //"0xf7184fc5d3ec8dcde1f4d8b7f5e24fd783bd3e5718760fa0a2b5df1fa2708d8e-0"
    pair: IDexPair; //  {__typename: "Pair",…}
    to: string // "0xbe19c32b4cd202407e8eeb73e4e2949438461ae3"
    transaction: IDexTransaction;
    timestamp: number; // converted timestamp
    __typename: string; // "Swap"
}

const getUniswapPairContract = async (market: any) => {
    try {
        const { contract, precision } = market
        const { baseAddress, quoteAddress, pairAddress } = contract
        const { base, quote } = precision

        if (pairAddress) {
            return pairAddress
        }

        const baseToken = new Token(
            ChainId.MAINNET,
            baseAddress,
            base
        )
        const quoteToken = new Token(
            ChainId.MAINNET,
            quoteAddress,
            quote
        )
        const pair = await Fetcher.fetchPairData(baseToken, quoteToken)
        return pair.liquidityToken.address.toLowerCase()
    } catch (err) {
        console.warn(err)
        return null
    }
}

/**
 * amount0In > 0 than token was sold
 *  Imagine on BTC example:
 *  a) Sell
 *      amount0In in 1 btc
 *      amount1Out 50000
 *      price = amount0In / amoun1Out
 *  b) Buy
 *      amount0In is 0
 *      amount0Out is 1btc
 *      amount1In is 50000
 *      amount0Out is 0
 *      price = amount0Out / amount1In
 * @param trade 
 * @returns 
 */
const getPrice = (trade: any): number =>
    (trade.amount0In === "0") ?
        (parseFloat(trade.amount0Out) / parseFloat(trade.amount1In))
        : (parseFloat(trade.amount0In) / parseFloat(trade.amount1Out))

/**
 * The same but reversed
 * @param trade 
 * @returns 
 */
 const getReversedPrice = (trade: any): number =>
    (trade.amount1In === "0") ?
        (parseFloat(trade.amount1Out) / parseFloat(trade.amount0In))
        : (parseFloat(trade.amount1In) / parseFloat(trade.amount0Out))
    

/**
 * Reverse base and quote
 * @param swaps 
 * @returns 
 */
const filterSwaps = (ops: any[], current: any): IDexSwap[] => {
    const { base } = current;
    return ops.map((operation: IDexSwap) => {
        if (operation.pair.token0.symbol === base) {
            return {
                ...operation,
                price: getReversedPrice(operation),
                baseAmount: operation.amount0In === "0" ? operation.amount0Out : operation.amount0In,
                quoteAmount: operation.amount1In === "0" ? operation.amount1Out : operation.amount1In,
                side: operation.amount0In === "0" ? "buy" : "sell",
                timestamp: Number(operation.transaction.timestamp) * 1000
            }
        } else {
            return {
                ...operation,
                pair: {
                    token0: operation.pair.token1,
                    token1: operation.pair.token0
                },
                baseAmount: operation.amount1In === "0" ? operation.amount1Out : operation.amount1In,
                quoteAmount: operation.amount0In === "0" ? operation.amount0Out : operation.amount0In,
                price: getPrice(operation),
                side: operation.amount1In === "0" ? "buy" : "sell",
                timestamp: Number(operation.transaction.timestamp) * 1000
            }
        }
    })
}

const uniswapProvider = async (contract: string, current: any): Promise<IDexSwap[]> => {
    try {
        const payload = {
            operationName: null,
            // Query with mints/swaps, don't any trace of price
            // query: "query ($allPairs: [Bytes]!) {\n  mints(first: 20, where: {pair_in: $allPairs}, orderBy: timestamp, orderDirection: desc) {\n    transaction {\n      id\n      timestamp\n      __typename\n    }\n    pair {\n      token0 {\n        id\n        symbol\n        __typename\n      }\n      token1 {\n        id\n        symbol\n        __typename\n      }\n      __typename\n    }\n    to\n    liquidity\n    amount0\n    amount1\n    amountUSD\n    __typename\n  }\n  burns(first: 20, where: {pair_in: $allPairs}, orderBy: timestamp, orderDirection: desc) {\n    transaction {\n      id\n      timestamp\n      __typename\n    }\n    pair {\n      token0 {\n        id\n        symbol\n        __typename\n      }\n      token1 {\n        id\n        symbol\n        __typename\n      }\n      __typename\n    }\n    sender\n    liquidity\n    amount0\n    amount1\n    amountUSD\n    __typename\n  }\n  swaps(first: 30, where: {pair_in: $allPairs}, orderBy: timestamp, orderDirection: desc) {\n    transaction {\n      id\n      timestamp\n      __typename\n    }\n    id\n    pair {\n      token0 {\n        id\n        symbol\n        __typename\n      }\n      token1 {\n        id\n        symbol\n        __typename\n      }\n      __typename\n    }\n    amount0In\n    amount0Out\n    amount1In\n    amount1Out\n    amountUSD\n    to\n    __typename\n  }\n}\n",
            // Direct swaps
            query: "query ($allPairs: [Bytes]!) {\n  swaps(first: 100, where: {pair_in: $allPairs}, orderBy: timestamp, orderDirection: desc) {\n    transaction {\n      id\n      timestamp\n      __typename\n    }\n    id\n    pair {\n      token0 {\n        id\n        symbol\n        __typename\n      }\n      token1 {\n        id\n        symbol\n        __typename\n      }\n      __typename\n    }\n    amount0In\n    amount0Out\n    amount1In\n    amount1Out\n    amountUSD\n    to\n    __typename\n  }\n}\n",
            variables: {
                allPairs:
                [contract]
            }
        }
        const { data } = await axios.post('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', payload)
        const { swaps } = data.data
        
        return filterSwaps(swaps, current)
    } catch (err) {
        console.error(err)
        return []
    }
}

export {
    getUniswapPairContract,
    uniswapProvider,
    filterSwaps
}