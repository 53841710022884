/**
 * Implements a decorator component which converts exchange name into icons and caption
 */
import React from 'react'
import Binance from './icons/binance.svg'
import Sushiswap from './icons/sushiswap.svg'
import Uniswap from './icons/uniswap.svg'

interface IExchangeIconProps {
    exchange: string;
}

const ExchangeIcon = ({ exchange }: IExchangeIconProps) => {
    switch (exchange) {
        case "binance":
            return <span>Bin<img src={Binance} alt="binance"/></span>
        case "uniswap":
            return <span>Uni<img src={Uniswap} alt="uniswap"/></span>
        case "sushiswap":
            return <span>Sushi<img src={Sushiswap} alt="sushiswap" /></span>
        case "hitbtc":
            return <span>HitBTC</span>
        case "pancakeswap":
            return <span>Pancake</span>
        default:
            return <span>{exchange}</span>
    }
}

export default ExchangeIcon;