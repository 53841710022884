/**
 * Presentation component:
 * Displays success state of signup
 */
import React from 'react'
import { connect } from 'react-redux'
import { actionCloseModal } from '../../../actions/modal'
import { ReactComponent as CloseIcon } from '../LoginModal/close.svg'
import { ReactComponent as SuccessImage } from './success.svg'
import "../LoginModal/styles.scss"

interface IRegistrationSuccessModalProps {
    actionCloseModal: Function;
}

const RegistrationSuccessModal = (props: IRegistrationSuccessModalProps) =>
    (<div className="login__modal">
        <div className="login__modal__header">
            <span className="login__modal__header__caption">PROFILE</span>
            <span className="login__modal__header__close" onClick={() => props.actionCloseModal()}><CloseIcon /></span>
        </div>
        <div className="login__modal__success">
            <SuccessImage />
            <h2>You're successfully signed up!</h2>
            <div className="login__modal__form__btn login__modal__form__btn--signup-success" onClick={() => props.actionCloseModal()}>
                GOT IT
            </div>
        </div>
    </div>)

export default connect(null, { actionCloseModal })(RegistrationSuccessModal)