import React from 'react'
import { ITradingAccount } from '../../../reducers/trading_accounts'
import ExchangeAccount from './ExchangeAccount'
import { ReactComponent as LoadingIcon } from './loader.svg'

interface IExchangeAccountsProps {
    signedin: boolean;
    loading: boolean;
    rows: any[];
    onItemClick: (account: ITradingAccount) => void;
    onItemDelete: (apiKey: string) => void;
    onAddAccount: any;
}

const ExchangeAccounts = (props: IExchangeAccountsProps) => {
    if (!props.signedin) {
        return (<div className="settings__page">
            <div className="settings__page__header">
                <h1>Exchange accounts</h1>
                <div>Sign In to view this page</div>
            </div>
        </div>
        )
    }

    return (<div className="settings__page">
    <div className="settings__page__header">
        <h1>Exchange accounts</h1>
        <span>Insert API keys to connect exchange accounts.</span>
        <div className="settings__page__header__button" onClick={(_e: any) => props.onAddAccount()}>
            Add Account
        </div>
        {props.loading &&
            (<div className="settings__page__holder">
                <LoadingIcon />
            </div>)}
        {props.rows.map((row: any, rowId: number) =>
            (<div className="settings__page__holder" key={rowId}>
                {row.map((item: ITradingAccount, index: number) =>
                    (<ExchangeAccount
                        key={index}
                        exchangeName={item.exchangeName}
                        connected={item.connected}
                        accountKey={item.accountKey}
                        accountSecret={item.accountSecret}
                        onDelete={() => props.onItemDelete(item.accountKey)}
                        onClick={props.onItemClick}
                    />)
                )}
            </div>)
        )}
    </div>
</div>)
}

export default ExchangeAccounts